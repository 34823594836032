
import Card from 'primevue/card';
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {defineComponent, watch} from "vue";

export default defineComponent({
	name: "QueueNavigatorCard",
	components: {
		Card,
	},
	props: {
		className: String,
		currentCount: Number,
		errorCount: Number,
	},
	directives: {
		'tooltip': Tooltip,
	},
	setup() {
		/** Store */
		const store = useSqueezeStore();

		/** Check global fontSize to fix the margin-top of counts
		 * Because we use the card, the count cannot be displayed in any other way and the font size must be observed.
		 */
		const checkFontSize = () => {
			const currentFontSize = store.state.userSettings.viewSettings.fontSize;
			if (currentFontSize === 0.7) {
				document.documentElement.style.setProperty('--element-margin-top', '-9rem');
			} else if (currentFontSize === 0.725) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.75rem');
			} else if (currentFontSize === 0.75) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.5rem');
			} else if (currentFontSize === 0.775) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.25rem');
			} else if (currentFontSize === 0.8) {
				document.documentElement.style.setProperty('--element-margin-top', '-8rem');
			} else if (currentFontSize === 0.825) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.75rem');
			} else if (currentFontSize === 0.85) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.5rem');
			} else if (currentFontSize === 0.875) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.25rem');
			} else {
				document.documentElement.style.setProperty('--element-margin-top', '-7rem');
			}
		}

		checkFontSize();

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => store.state.userSettings.viewSettings.fontSize, () => {
			checkFontSize();
		})
	},
});

/*
@Options({

	watch: {
		'store.state.userSettings.viewSettings.fontSize': function() {
			this.checkFontSize();
		},
	},
})*/

