<template>
	<div class="p-grid p-mx-2">
		<div class="p-col-12 p-py-2 p-px-4 p-d-none p-d-md-flex sticky-header">
			<QueueTimeline :allDocumentClasses="allDocumentClasses" />
		</div>
		<div class="p-col-12 p-lg-12 p-xl-8 p-xxl-8 dashboard-container">
			<QueueNavigatorView />
		</div>
		<div class="p-col-12 p-lg-12 p-xl-4 p-xxl-4 dashboard-container search-container">
			<DashboardTile class="search-content" :title="$t('Platform.Search.Title')">
				<DocumentClassSearchMask/>
			</DashboardTile>
		</div>
	</div>
</template>

<script lang="ts">
import QueueTimeline from '@/apps/squeeze/components/QueueTimeline.vue';
import DashboardTile from "@/components/DashboardTile.vue";
import DocumentClassSearchMask from "@/apps/squeeze/components/DocumentClassSearchMask.vue";
import {defineComponent, onMounted, ref} from 'vue';
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import QueueNavigatorView from "@/apps/squeeze/views/QueueNavigatorView.vue";

export default defineComponent({
	components: {
		QueueNavigatorView,
		DashboardTile,
		QueueTimeline,
		DocumentClassSearchMask,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Document API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** List of all DocumentClasses */
		const allDocumentClasses = ref<DocumentClass[]>([]);

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			documentClassApi.getAllDocumentClasses()
				.then((documentClasses) => {
					allDocumentClasses.value = documentClasses;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		/** On view ready */
		onMounted(() => {
			getAllDocumentClasses();
		})

		return {
			t, toast, allDocumentClasses, getAllDocumentClasses,
		};
	},
});
</script>
<style lang="scss" scoped>

@media screen and (min-width: 1800px) {
	.p-xxl-4 {
		width: 33.3333%;
	}
}

@media screen and (min-width: 1800px) {
	.p-xxl-1, .p-xxl-2, .p-xxl-3, .p-xxl-4, .p-xxl-5, .p-xxl-6, .p-xxl-7, .p-xxl-8, .p-xxl-9, .p-xxl-10, .p-xxl-11, .p-xxl-12 {
		flex: 0 0 auto;
	}
}

@media screen and (min-width: 768px) {
	.dashboard-container {
		margin-top: 8rem;
	}
}

@media screen and (max-width: 767px) {
	.dashboard-container {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 1199px) {
	.search-container {
		margin-top: 0;
	}
}

// Sticky Header
.sticky-header {
	position: fixed;
	top: 0;
	width: 95%;
	z-index: 2;
	background-color: var(--surface-a);
}

.search-content {
	max-height: calc(100vh - 8.5rem);
	overflow-y: auto;
	margin-bottom: 0 !important;
}

</style>
