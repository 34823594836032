<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-4">
			<label for="active">{{$t('Squeeze.DocumentClasses.Active')}}</label>
			<Dropdown
				id="active"
				v-model="value.active"
				:options="dbLookupActive"
				optionValue="id"
				optionLabel="name"
				@change="update"
			/>
		</div>
		<div class="p-field p-col-4" v-if="value.active === true">
			<label for="mode">{{$t('Squeeze.DocumentClasses.DbLookupAllowCustomValues')}}</label>
			<Dropdown
				id="mode"
				v-model="value.allowCustomValues"
				:options="dbLookupModes"
				optionValue="id"
				optionLabel="name"
			/>
		</div>
		<div class="p-field p-col-4" v-if="value.active === true">
			<label for="minInputLength">{{$t('Squeeze.DocumentClasses.MinInputLength')}} *</label>
			<InputNumber id="minInputLength" v-model="value.minInputLength" :class="{'p-invalid':v$.minInputLength.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="v$.minInputLength.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.MinInputLength')}) }}
			</small>
		</div>
		<div v-if="value.active === true" class="p-field p-col-6">
			<label for="tableId">{{$t('Squeeze.DocumentClasses.Table')}} *</label>
			<Dropdown
				id="tableId"
				style="min-width: 15.625rem"
				v-model="value.tableId"
				:options="allTables"
				optionValue="id"
				:optionLabel="getColumnLabel"
				:filter="true"
				:showClear="true"
				@change="onChangeTable"
				:class="{'p-invalid':v$.tableId.$invalid && showErrorMessage}"
				@show="showDropdownOverlay"
			/>
			<small v-if="v$.tableId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Table')}) }}
			</small>
		</div>
		<div v-if="value.active === true && value.tableId" class="p-field p-col-6">
			<label for="resultKeyColumnId">{{$t('Squeeze.DocumentClasses.ResultKeyColumn')}} *</label>
			<Dropdown
				id="resultKeyColumnId"
				style="min-width: 15.625rem"
				v-model="value.resultKeyColumnId"
				:options="allTableColumn"
				optionValue="id"
				:optionLabel="getColumnLabel"
				:filter="true"
				:showClear="true"
				@change="update"
				:class="{'p-invalid':v$.resultKeyColumnId.$invalid && showErrorMessage}"
				@show="showDropdownOverlay"
			/>
			<small v-if="v$.resultKeyColumnId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.ResultKeyColumn')}) }}
			</small>
		</div>
		<div v-if="value.active === true && value.tableId" class="p-field p-col-12">
			<label for="searchColumnIds">{{$t('Squeeze.DocumentClasses.SearchColumns')}} *</label>
			<MultiSelect
				id="searchColumnIds"
				style="min-width: 15.625rem"
				v-model="value.searchColumnIds"
				:options="allTableColumn"
				optionValue="id"
				:optionLabel="getColumnLabel"
				display="chip"
				:filter="true"
				@change="update"
				:class="{'p-invalid':v$.searchColumnIds.$invalid && showErrorMessage}"
				@show="showDropdownOverlay"
			/>
			<small v-if="v$.searchColumnIds.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.SearchColumns')}) }}
			</small>
		</div>
		<div v-if="value.active === true && value.tableId" class="p-field p-col-12">
			<label for="resultValueColumnIds">{{$t('Squeeze.DocumentClasses.ResultValueColumns')}} *</label>
			<MultiSelect
				id="resultValueColumnIds"
				style="min-width: 15.625rem"
				v-model="value.resultValueColumnIds"
				:options="allTableColumn"
				optionValue="id"
				:optionLabel="getColumnLabel"
				display="chip"
				:filter="true"
				@change="update"
				:class="{'p-invalid':v$.resultValueColumnIds.$invalid && showErrorMessage}"
				@show="showDropdownOverlay"
			/>
			<small v-if="v$.resultValueColumnIds.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.ResultValueColumns')}) }}
			</small>
		</div>
		<div v-if="value.active === true && value.tableId" class="p-field p-col-6">
			<label for="ignoreInputValue">{{ $t('Squeeze.DocumentClasses.IgnoreInputValue') }}</label>
			<Dropdown
				id="ignoreInputValue"
				v-model="value.ignoreInputValue"
				:options="dbLookupActive"
				optionValue="id"
				optionLabel="name"
				@change="update"
			/>
		</div>
		<div v-if="value.active === true && value.tableId" class="p-field p-col-6">
			<label for="maxLookupResultValues">{{ $t('Squeeze.DocumentClasses.MaxLookupResultValues') }}</label>
			<InputNumber id="maxLookupResultValues" v-model="value.maxLookupResultValues" :max="1000" @change="update" />
			<small v-if="v$.maxLookupResultValues.notZeroValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.InvalidNullEntry', { field: $t('Squeeze.DocumentClasses.MaxLookupResultValues')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref} from "vue";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import Dropdown from "primevue/dropdown";
import MultiSelect from 'primevue/multiselect';
import InputNumber from '@/components/DexInputNumber.vue';
import useVuelidate from "@vuelidate/core";
import {notZeroValidation} from "@/util/ValidationHelper";
import {required} from "@vuelidate/validators";
import {LookupDefinition, MasterDataColumn, MasterDataTable} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: 'DbLookupForm',
	components: {
		Dropdown, MultiSelect, InputNumber,
	},
	props: {
		dbLookUpEntry: {
			type: Object as PropType<LookupDefinition>,
			default: {
				active: true,
			},
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Object of Db-Lookup-Entry */
		const value = reactive<any>(props.dbLookUpEntry);

		/** Determines the required rules for validation */
		const rules = {
			minInputLength: { required },
			tableId: { required },
			resultKeyColumnId: { required },
			searchColumnIds: { required },
			resultValueColumnIds: { required },
			maxLookupResultValues: { notZeroValidation },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value, { $scope: false });

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all tables available from Master-Data */
		const allTables = ref<MasterDataTable[]>([]);

		/** List of all Columns of a table */
		const allTableColumn = ref<MasterDataColumn[]>([]);

		/** List with Yes/No */
		const dbLookupActive = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			] as { id: boolean; name: string }[]
		});

		/** List with Lookup-Modes */
		const dbLookupModes = dbLookupActive;

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param column Current-Column
		 */
		const getColumnLabel = (column: MasterDataColumn): string => {
			return column.description + " (" + column.name + ")";
		}

		/** Reloads the Columns for the Dropdowns */
		const reloadColumns = () => {
			if (props.dbLookUpEntry.tableId) {
				masterDataService.getMasterDataTableColumns(props.dbLookUpEntry.tableId)
					.then(data => {
						allTableColumn.value = data;
					})
			}
		}

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			if (!value.active) {
				emit("update", value, false)
			} else {
				if (!value.maxLookupResultValues && value.maxLookupResultValues !== 0) {
					// set default value
					value.maxLookupResultValues = 50;
				}

				emit("update", value, v$.value.$invalid);
			}
		}

		/** Triggered when the Table-Dropdown changes */
		const onChangeTable = () => {
			value.searchColumnIds = [];
			value.resultKeyColumnId = null;
			value.resultValueColumnIds = [];
			update();
			reloadColumns();
		}

		onMounted(() => {
			masterDataService.getAllMasterDataTables()
				.then(data => {
					allTables.value = data;
					if(value.tableId === 0) {
						value.tableId = null;
					}
				})
				.catch((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				});

			reloadColumns();

			// set default ignore input value
			if (!value.ignoreInputValue) {
				value.ignoreInputValue = false;
			}
		});

		return {
			t,
			toast,
			v$,
			value,
			allTables,
			allTableColumn,
			dbLookupActive,
			dbLookupModes,
			getColumnLabel,
			reloadColumns,
			onChangeTable,
			update,
			showDropdownOverlay,
		}
	},
});
</script>
