<template>
	<DataTable
		:value="records"
		:loading="loading"
		class="p-shadow-2 p-datatable-documents p-datatable-sm"
		:lazy="true"
		:scrollable="true"
		scrollHeight="flex"
		:paginator="true"
		v-model:expandedRows="expandedRows"
		v-model:selection="selection"
		:rows="pagination.pageSize"
		:totalRecords="totalSize"
		@sort="onSort($event)"
		@page="onPage($event)"
		@rowClick="onRowClick"
		@rowExpand="onRowExpand"
		@rowCollapse="onRowCollapse"
		@filter="onFilter($event.filters)"
		v-model:filters="filters"
		filterDisplay="row"
		:rowHover="true"
		:removableSort="true"
		:first="(firstEntry)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:rowsPerPageOptions="[25,50,100]"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Freeze.SearchMask.Entries') }) }}
		</template>
		<Column :expander="true" headerStyle="width: 3rem" style="min-width: 4rem; max-width: 4rem;" class="expander"/>
		<Column field="id" header="ID" headerStyle="width: 10rem" style="min-width: 12rem; max-width: 12rem;" :sortable="true">
			<template #body="{ data }">
				<div class="column-cell" style="display: flex; flex-flow: row; align-items: center">
					<div v-tooltip.bottom="data.id">
						{{data.id.slice(0, 8)}} ...
					</div>
				</div>
			</template>
		</Column>
		<Column field="headerFields._archiveDateTime" :header="$t('Freeze.SearchMask.Date')" style="min-width: 20rem;" :sortable="true">
			<template #body="{ data }">
				<div class="column-cell">
					{{formatDate(data.headerFields._archiveDateTime)}}
				</div>
			</template>
		</Column>
		<Column field="headerFields._archiverLogin" :header="$t('Freeze.SearchMask.Creator')" style="min-width: 20rem;" :sortable="true"/>
		<Column v-for="column of searchFields" :field="column.name" :filterField="'' + column.name" :header="column.desc"  :key="column.name" :showFilterMenu="true"
				:sortable="true"  :style="'min-width: 15rem; overflow: hidden;'">
			<template #body="{ data }">
				<div class="column-cell" v-tooltip.bottom="data.recordFields[column.name]">
					{{data.recordFields[column.name]}}
				</div>
			</template>
			<template #filter="{filterModel,filterCallback}">
				<InputText :style="'min-width: 14rem; max-width: 14rem;'" class="filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" placeholder=""/>
			</template>
		</Column>
		<template #expansion="slotProps">
			<div style="display: flex; flex-direction: row">
				<div class="p-ml-sm-0 p-ml-md-6">
					<h2 class="p-my-1 p-pl-2">Felder</h2>
					<table class="recordTable" style="height: min-content">
						<template v-for="(value, name) in slotProps.data.recordFields"  v-bind:key="name">
							<tr class="p-p-2">
								<td class="p-text-bold p-p-2">
									{{ name }}:
								</td>
								<td>
									{{value}}
								</td>
							</tr>
						</template>
					</table>
				</div>
				<div class="p-ml-sm-0 p-ml-md-6">
					<h2 class="p-my-1 p-pl-2">Metadaten</h2>
					<table class="recordTable" style="height: min-content">
						<template v-for="(value, name) in slotProps.data.headerFields"  v-bind:key="name">
							<tr class="p-p-2">
								<td class="p-text-bold p-p-2">
									{{ $t(`Freeze.Eas.HeaderFields.${name}`) }}:
								</td>
								<td>
									{{value}}
								</td>
							</tr>
						</template>
					</table>
				</div>
				<div class="p-ml-sm-0 p-ml-md-6">
					<h2 class="p-my-1">{{ $t('Freeze.SearchMask.Attachments') }}</h2>
					<ul style="list-style-type:none;">
						<li v-if="(!slotProps.data.attachments || slotProps.data.attachments.length === 0) && !slotProps.data.attachmentsLoading">{{$t('Freeze.SearchMask.NoResults')}}</li>
						<li v-if="slotProps.data.attachmentsLoading">{{ $t('Freeze.SearchMask.Loading') }}</li>
						<template v-for="(attachment) in slotProps.data.attachments" v-bind:key="attachment.id">
							<li @mouseover="onMouseOverAttachment(slotProps.data, attachment.documentBase64, attachment.name)" @click="openDocument(attachment.documentBase64, attachment.name )" style="cursor: pointer">{{ attachment.name }} ({{(attachment.size / 1024).toFixed(0)}} KB)</li>
						</template>
						<li v-if="slotProps.data.attachments && slotProps.data.attachments.length > 0 && slotProps.data.currentDocumentBase64">
							<br/><b>{{$t('Freeze.SearchMask.CurrentDocument')}}: </b> {{slotProps.data.currentDocumentName}}
							(<a :download="slotProps.data.currentDocumentName" :href="'data:;base64, ' + slotProps.data.currentDocumentBase64">Download</a>)<br/>
							<template v-if="slotProps.data.currentDocumentName && slotProps.data.currentDocumentName.toLowerCase().indexOf('.pdf') !== -1">
								<iframe :src="createPdfUrlPath(slotProps.data.currentDocumentBase64)" height="500" width="100%"></iframe>
							</template>
							<template v-else>
								{{$t('Freeze.SearchMask.NoPreview')}}
							</template>
						</li>
					</ul>
				</div>
			</div>
		</template>
		<template #paginatorLeft>
			<Button type="button" v-tooltip="$t('Squeeze.General.Refresh')" icon="mdi mdi-refresh" class="p-button-text p-ml-r" @click="onReload" :disabled="loading" />
			<Button type="button" v-tooltip="$t('Squeeze.General.ClearSearch')" icon="mdi mdi-filter-variant-remove" class="p-button-text p-ml-r p-mr-2" @click="clearSearch" :disabled="loading" />
			<InputText style="width: auto;" class="filter-field" type="text" :value="fullText" v-on:input="emit('update:fullText', $event.target.value)" @keydown.enter="onReload" :placeholder="$t('Squeeze.General.Search') + '...'"/>
		</template>
		<template #paginatorRight></template>
	</DataTable>
	<Dialog :header="currentDocumentName" v-model:visible="showDocument" :modal="true" :closable="true" :dismissableMask="true" :baseZIndex="1000" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw', height: '100vh'}">
		<iframe :src="createPdfUrlPath(currentDocumentBase64)" height="100%" width="100%" style="height: 100vh; width: 100%;"></iframe>
	</Dialog>
</template>

<script lang="ts">
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from "primevue/tooltip";
import {defineComponent, inject, PropType, ref} from "vue";
import {PaginationDto} from "@dex/squeeze-client-ts";
import {FreezeSearchMaskField} from "@/apis/freeze/Types";
import {ToastManager} from "@/util/ToastManager";
import Dialog from "primevue/dialog";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import InputText from "primevue/inputtext";
import {EasClientKey} from "@/apps/freeze/DI";
import {createPdfUrlPath} from "@/util/BlobHelper";

/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default defineComponent({
	name: "RecordList",
	components: {
		DataTable,
		Column,
		Dialog,
		InputText,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		pagination: {
			type: Object as PropType<PaginationDto>,
			default: {},
		},
		records: {
			type: Array as PropType<any[]>,
			default: [],
		},
		totalSize: {
			type: Number,
			default: 0,
		},
		searchFields: {
			type: Array as PropType<FreezeSearchMaskField[]>,
			default: [],
		},
		storeId: {
			type: String,
			default: "",
		},
		fullText: {
			type: String,
			default: '',
		},
		firstEntry: {
			type: Number,
			default: 0,
		},
		filterOptions: Object as PropType<DocumentFilterObject>,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "onFilter", "onSort", "update:fullText", "clearSearch"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const { locale } = useI18n({ useScope: 'global' })
		// const store = useStore();
		const toast = useToast();

		/** Array with the expanded rows-data */
		const expandedRows = ref<any[]>([]);

		const refArray = ref<any[]>([]) as any;

		/** Currently-Selected row */
		const selection = ref([]);

		/** Show Document Popup? */
		const showDocument = ref(false);

		/** Current selected Document in base64 */
		const currentDocumentBase64 = ref('');

		/** Currently selected Document Name */
		const currentDocumentName = ref('');

		/** Api for Squeeze-Requests */
		const easApi = inject(EasClientKey)!;

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Triggered when the next page is selected */
		const onPage = (event: any) => {
			emit("onPage", event)
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: string }) => {
			emit("onSort", event.sortField, event.sortOrder)
		}

		/** Triggered on Filter of column */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		 */
		const onRowClick = (event: { originalEvent: MouseEvent; data: any; index: number }) => {
			// Row-click events, if not clicked on the expander for expand
			if (event.originalEvent && !(event.originalEvent.target as HTMLElement).matches('.expander')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/** Triggered when a row is expanded */
		const onRowExpand = async function(event: { originalEvent: { originalEvent: MouseEvent }; data: any; index: number }) {
			const record = event.data;
			if (record.attachments == null) {
				try {
					record.attachmentsLoading = true;
					record.attachments = await easApi.getRecordAttachments(props.storeId!, record.id);

					//record.attachments.forEach((attachment: any) => record.url = `${ClientManager.getInstance().freezeUrl}/eas/archives/${props.storeId}/record/${record.id}/attachment/${attachment.id}`)

					for (const attachment of record.attachments) {
						const documentBase64 = await easApi.getAttachment(props.storeId!, record.id, attachment.id!);
						attachment.documentBase64 = documentBase64;
					}

					if (record.attachments && record.attachments[0]) {
						const firstAttachment = record.attachments[0];
						record.currentDocumentBase64 = firstAttachment.documentBase64;
						record.currentDocumentName = firstAttachment.name;
					}
				} catch (err) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err); // FIXME: Localization
				} finally {
					record.attachmentsLoading = false;
				}
			}
		}

		/** Triggered when a row is closed */
		const onRowCollapse = async function(event: { originalEvent: { originalEvent: MouseEvent }; data: any; index: number }) {
			// Do nothing as of now
		}

		/**
		 * Shows the overlay
		 * @param record
		 * @param base64Document
		 * @param documentName
		 */
		const onMouseOverAttachment = (record: any, base64Document: string, documentName: string) => {
			record.currentDocumentBase64 = base64Document;
			record.currentDocumentName = documentName;
		}

		/**
		 * Opens a document in wider view
		 * @param base64Document
		 * @param documentName
		 */
		const openDocument = (base64Document: string, documentName: string) => {
			if (!documentName.toLowerCase().includes(".pdf")) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Freeze.SearchMask.OnlyPdf'));
				return;
			}

			currentDocumentBase64.value = base64Document;
			showDocument.value = true;
			currentDocumentName.value = documentName;
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			emit("onReload");
		}

		/** Clears the current search */
		const clearSearch = () => {
			emit("clearSearch");
		}

		/**
		 * Formats a date from default JSON-Datestring
		 * @param dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options);
				}
			}
			return dateToFormat;
		}

		return {
			showDocument,
			currentDocumentBase64,
			currentDocumentName,
			expandedRows,
			selection,
			filters,
			emit,
			clearSearch,
			formatDate,
			onReload,
			onPage,
			onRowClick,
			onRowExpand,
			onRowCollapse,
			onSort,
			onMouseOverAttachment,
			openDocument,
			onFilter,
			createPdfUrlPath,
			refArray,
		}
	},
});
</script>
<style lang="scss" scoped>
/** Reduce height of buttons */
.p-button.p-button-icon-only.p-button-rounded {
	height: 1rem;
}

// Table row toggler button
.p-datatable ::v-deep(.p-datatable-tbody tr td .p-row-toggler.p-link.p-row-toggler) {
	height: 1.8rem;
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column) {
	padding:0 0 0.2rem 0.2rem;
	color: var(--filter-field-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-filter-column .p-column-filter-menu-button, .p-column-filter-clear-button) {
	color: var(--filter-field-color);
}

// @todo Hover sortable icon have to be white!

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
	margin-left: 0.4rem;
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--dex-text-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

::placeholder {
	color: var(--filter-field-color);
}

.status-badge {
	font-weight: bold;
	&.initial {
		border-color: var(--queuestate-initial-primary);
		color: var(--queuestate-initial-darker);
	}

	&.work {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.waiting {
		border-color: var(--queuestate-waiting-primary);
		color: var(--queuestate-waiting-darker);
	}

	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}

	&.suspend {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.suspend-marked {
		border-color: var(--queuestate-suspend-darker);
		color: var(--queuestate-suspend-darker);
	}

	&.clarification {
		border-color: var(--queuestate-clarification-primary);
		color: var(--queuestate-clarification-darker);
	}

	&.finished {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}
}

::v-deep(button.p-column-filter-menu-button.p-link) {
	display: none;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.recordTable {
	width: 31.25rem;
}

</style>

