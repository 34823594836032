<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="countryCode">{{$t('Squeeze.System.CountryCode')}} *</label>
			<InputText id="countryCode" v-model="value.countryCode" :class="{'p-invalid':v$.countryCode.$invalid && showErrorMessage}" @change="update" :disabled="language.id" autofocus />
			<small v-if="v$.countryCode.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.CountryCode')}) }}
			</small>
		</div>
		<!--div class="p-field p-col-6">
			<label for="translatedDescription">{{$t('Squeeze.System.TranslatedDescription')}}</label>
			<InputText id="translatedDescription" v-model="value.translatedDescription" @change="update" :disabled="language.id" />
		</div>
		<div class="p-field p-col-12">
			<label for="translationKey">{{$t('Squeeze.System.TranslationKey')}} *</label>
			<InputText id="translationKey" v-model="value.translationKey" @change="update" :disabled="language.id" />
		</div-->
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.Locators.Description')}} *</label>
			<InputText id="description" v-model="value.description" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" :disabled="language.id" />
			<small v-if="v$.description.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Description')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.System.Name')}} *</label>
			<InputText id="name" v-model="value.name" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" :disabled="language.id" />
			<small v-if="v$.name.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.Name')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="active">{{$t('Squeeze.System.Active')}}</label><br/>
			<Checkbox id="active" v-model="value.active" :binary="true" @change="update" :disabled="value.countryCode === 'en' || value.countryCode === 'de'" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import {TranslationCountry} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "LanguageForm",
	components: {
		InputText,
		Checkbox,
	},
	props: {
		language: {
			type: Object as PropType<TranslationCountry>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<TranslationCountry>({
			countryCode: '',
			translatedDescription: '',
			translationKey: '',
			description: '',
			name: '',
			active: false,
		});

		/** Determines the required rules for validation */
		const rules = {
			countryCode: { required },
			description: { required },
			name: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.language);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.language, async () => {
			Object.assign(value, props.language);
			await nextTick();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			store,
			loading,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});

</script>

<style scoped>

</style>
