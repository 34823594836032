<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="settingName">{{$t('Squeeze.BatchClasses.SettingName')}} *</label>
			<Dropdown
				id="settingName"
				style="min-width: 15.625rem"
				v-model="value.settingName"
				:options="allBatchClassSettings"
				optionValue="settingName"
				optionLabel="settingName"
				@change="onChangeSettingName"
				:disabled="disableSettingName"
				:class="{'p-invalid':v$.settingName.$invalid && showErrorMessage}"
				:filter="true"
				@show="showDropdownOverlay"
			/>
			<small v-if="v$.settingName.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.BatchClasses.SettingName')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label>{{$t('Squeeze.BatchClasses.Value')}} *</label>
			<InputText
				v-if="schema.type === 'string' && !schema.enum"
				v-model="value.value"
				required="true"
				@input="update"
				:placeholder="schema.example ? schema.example : ''"
			/>
			<InputText
				v-else-if="schema.type === 'integer' && !schema.enum"
				v-model="value.value"
				required="true"
				@input="update"
				:placeholder="schema.example ? schema.example : ''"
				class="amount-field"
				@keydown="checkIntegerValue($event)"
			/>
			<Dropdown
				v-else-if="schema.type === 'boolean' && !schema.enum"
				style="min-width: 15.625rem"
				v-model="value.value"
				:options="listOfBooleans"
				optionValue="id"
				optionLabel="name"
				@change="update"
				:class="{'p-invalid':v$.value.$invalid && showErrorMessage}"
				@show="showDropdownOverlay"
			/>
			<Dropdown
				v-else-if="schema.enum"
				style="min-width: 15.625rem"
				v-model="value.value"
				:optionLabel="getSchemaEnums"
				:options="schema.enum"
				@change="update"
				:class="{'p-invalid':v$.value.$invalid && showErrorMessage}"
				:filter="true"
				@show="showDropdownOverlay"
			/>
			<InputText
				v-else
				v-model="value.value"
				required="true"
				@input="update"
			/>
			<small v-if="value.settingName && v$.value.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.BatchClasses.Value')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {BatchClassSetting} from "@dex/squeeze-client-ts";
import Dropdown from "primevue/dropdown";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "BatchClassAttributesForm",
	components: {
		InputText, Dropdown,
	},
	props: {
		batchClassSetting: {
			type: Object as PropType<BatchClassSetting>,
			required: true,
		},
		allBatchClassSettings: {
			type: Array as PropType<BatchClassSetting[]>,
			required: true,
		},
		disableSettingName: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["update"],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Internal Object with all Field-Values */
		const value = reactive<BatchClassSetting>({value: '', settingName: '', schema: {}});

		/** Schema object */
		const schema = reactive<any>({});

		/** Determines the required rules for validation */
		const rules = {
			value: { required },
			settingName: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** List of booleans */
		const listOfBooleans = computed(() => {
			return [
				{
					id: 'true',
					name: t('Squeeze.General.Yes'),
				},
				{
					id: 'false',
					name: t('Squeeze.General.No'),
				},
			]
		});

		onMounted(async () => {
			Object.assign(value, props.batchClassSetting);

			// set schema by editing
			if (value.schema) {
				Object.assign(schema,value.schema);
				if (schema.default && value.value === '') {
					// set default value
					value.value = schema.default;
				}
			}

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entires
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.batchClassSetting, () => {
			Object.assign(value, props.batchClassSetting);

			// set schema
			if (value.schema) {
				Object.assign(schema, value.schema);
				if (schema.default && value.value === '') {
					// set default value
					value.value = schema.default;
				}
			}

			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			await nextTick(); // Otherwise the change of the dropdown will not be recognised fast enough
			emit("update", value, v$.value.$invalid);
		}

		/** Triggered on change of settingName field */
		const onChangeSettingName = () => {
			schema.example = '';
			schema.enum = undefined;
			schema.default = undefined;
			value.schema = {};
			value.value = '';
			if (value.settingName) {
				// get schema from selected setting
				const selectedSetting = props.allBatchClassSettings.find((bcSetting: BatchClassSetting) => bcSetting.settingName === value.settingName);
				if (selectedSetting) {
					Object.assign(value.schema, selectedSetting.schema);
					Object.assign(schema, value.schema);
					if (selectedSetting.schema.default) {
						value.value = selectedSetting.schema.default; // set default value
					}
				}
			}
			update();
		}

		/** Return all schema enums for dropdown */
		const getSchemaEnums = (schemaEnum: string): string => {
			return schemaEnum;
		}

		/**
		 * Check integer input value
		 * @param event
		 */
		const checkIntegerValue = (event: KeyboardEvent) => {
			const regex = /[0-9]|\./;
			if (!regex.test(event.key) && event.keyCode !== 8) {
				event.preventDefault();
				if (value.value) {
					value.value = value.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
				}
				return;
			}
		}

		return {value, schema, v$, listOfBooleans, update, getSchemaEnums, onChangeSettingName, showDropdownOverlay, checkIntegerValue};
	},
});
</script>

<style scoped>

input.p-inputtext.amount-field{
	text-align: right;
}
</style>

