<template>
	<div class="p-fluid p-formgrid p-grid">
		<template v-for="idy in 10" v-bind:key="idy">
			<div class="p-field p-col-12">
				<div class="p-input-filled">
					<Skeleton height="1.193rem" width="6.5rem"/>
					<Skeleton class="validation-field p-input-filled" height="2.816rem" />
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Skeleton from 'primevue/skeleton';

@Options({
	name: "ValidationFieldSetSkeleton",
	components: {
		Skeleton,
	},
})
export default class ValidationFieldSetSkeleton extends Vue {

}
</script>

<style lang="scss" scoped>

.validation-field {
	margin-top: 0.5rem;
}
</style>
