<template>
	<!-- TODO: Check how to use stacked without massive space-between or table grid blow-out
		responsiveLayout="stack"
		breakpoint="1946px" -->
	<DataTable
		class="p-shadow-2"
		:value="queueEntries"
		:lazy="true"
		:paginator="true"
		:loading="!loaded || lockTable"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		columnResizeMode="fit"
		responsiveLayout="scroll"
		:scrollable="true"
		scrollHeight="flex"
		v-model:selection="selectedRows"
		@row-click="onRowClick"
		@page="onPage($event)"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:rowHover="true"
		:metaKeySelection="false"
	>
		<template #empty>
			{{$t('Squeeze.Queue.Filter.NoEntries')}}
		</template>

		<Column v-if="store.state.isAdminUser" style="flex: 0 0 3rem; justify-content: center;" selectionMode="multiple"></Column>

		<Column field="id" :header="$t('Squeeze.Queue.Fields.id')" style="flex: 0 0 4rem; padding: 0.6rem;"></Column>

		<Column field="status" :header="$t('Squeeze.Queue.Fields.status')" style="padding: 0.6rem; max-width: 10rem;">
			<template #body="slotProps">
				<div style="text-align: left!important; white-space:nowrap!important;">
					<span :class="'status-badge ' + slotProps.data.workflowContext.status.toLowerCase()" class="p-py-1">
						{{$t("Squeeze.Queue.States." + slotProps.data.workflowContext.status)}}
					</span>
				</div>
			</template>
		</Column>

		<Column field="errorText" :header="$t('Squeeze.General.Comment')" style="padding: 0.6rem; min-width: 4rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.workflowContext.errorText">
					{{slotProps.data.workflowContext.errorText}}
				</div>
			</template>
		</Column>

		<Column field="createdTs" :header="$t('Squeeze.WorkflowContext.createdTs')" style="padding: 0.6rem;">
			<template #body="slotProps">
				<div style="white-space:nowrap!important;">
					{{formatDate(slotProps.data.workflowContext.createdTs)}}
				</div>
			</template>
		</Column>

		<Column field="modifiedTs" :header="$t('Squeeze.WorkflowContext.modifiedTs')" style="padding: 0.6rem;">
			<template #body="slotProps">
				<div style="white-space:nowrap!important;">
					{{formatDate(slotProps.data.workflowContext.modifiedTs)}}
				</div>
			</template>
		</Column>

		<!-- Refresh / Delete -->
		<Column style="justify-content: flex-end; padding: 0.6rem;">
			<template #body="slotProps">
				<Button v-if="store.state.isAdminUser" v-tooltip="$t('Squeeze.Queue.Requeue.Requeue')" icon="mdi mdi-file-refresh-outline" class="p-button p-button-rounded p-button-success p-button-text" @click="openRequeueMenu($event, slotProps.data)" aria-haspopup="true" aria-controls="single_entry_menu" />
				<Menu id="single_entry_menu" ref="single_entry_menu" :model="steps" :popup="true" />
				<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text" disabled />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button type="button" v-tooltip="$t('Squeeze.General.Refresh')" icon="mdi mdi-refresh" class="p-button-text" @click="onReload" :disabled="!loaded" />
			<Button v-if="store.state.isAdminUser" type="button" icon="mdi mdi-file-refresh-outline" class="p-button-text" :label="$t('Squeeze.Queue.Requeue.Requeue')" @click="openMultiRequeueMenu" :disabled="!selectedRows.length" aria-haspopup="true" aria-controls="multi_entry_menu" />
			<Menu id="multi_entry_menu" ref="multi_entry_menu" :model="steps" :popup="true" />
			<!--Button type="button" icon="mdi mdi-folder-open" class="p-button-text" :label="$t('Squeeze.Queue.General.GoTo')" @click="openGoToMenu" aria-haspopup="true" aria-controls="multi_entry_menu_goto" />
			<Menu id="multi_entry_menu_goto" ref="multi_entry_menu" :model="steps" :popup="true" /-->
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {DocumentField, PaginationDto, QueueEntry} from "@dex/squeeze-client-ts";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import ConfirmDialog from 'primevue/confirmdialog';
import Column from 'primevue/column';
import {FilterMatchMode} from 'primevue/api';
import {DEFAULT_LOCALE} from "@/lang";
import { i18n } from "@/main";

import {DateTimeOptions} from "@/util/DateTimeOptions";
import {ToastManager} from "@/util/ToastManager";
import { getSqueezeStepIconMap } from "@/apps/squeeze/App.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {useSqueezeStore} from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";

interface MenuItem {
	label: string;
	items: MenuSubItem[];
}

interface MenuSubItem {
	label: string;
	icon: string;
	command: {};
}

@Options({
	name: "QueueEntries",
	components: {
		DataTable,
		Column,
		Button,
		Menu,
		ConfirmDialog,
	},
	props: {
		documentClassId: Number,
		documentClassName: String,
		queueEntries: Array,
		loaded: Boolean,
		paginationInfo: Object,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage"],
})
/**
 * @description Uses Queue API endpoint instead of Elastic Search API endpoint
 */
export default class QueueEntries extends Vue {

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** ID of document class */
	documentClassId!: number;

	/** Name of document class */
	documentClassName = "";

	/** List of all QueueEntries in QueueStep Validation */
	queueEntries: QueueEntry[] = [];

	/** Pagination information */
	paginationInfo: PaginationDto = {};

	filters: any = {
		"id": {value: null, matchMode: FilterMatchMode.EQUALS},
		"create_ts": {value: null, matchMode: FilterMatchMode.CONTAINS},
		"status": {value: null, matchMode: FilterMatchMode.EQUALS},
		"errorText": {value: null, matchMode: FilterMatchMode.CONTAINS},
	};

	/** Requeue steps to choose in menu */
	steps: MenuItem[] = [];

	/** Indicates end of request */
	loaded = false;

	/** Currently selected row or rows */
	selection: QueueEntry | null = null
	selectedRows: QueueEntry[] = [];

	lockTable = false;

	/** Current locale */
	lang = DEFAULT_LOCALE;

	/** Current Vuex Store */
	store = useSqueezeStore();

	mounted() {
		this.steps = [{
			label: this.$t('Squeeze.Queue.General.Steps'),
			items: [],
		}];
		getSqueezeStepIconMap().forEach((icon: string, step: string) => {
			this.steps[0].items.push({
				label: this.$t('Squeeze.Queue.Steps.' + step),
				icon: icon,
				command: (ev: { item: any; originalEvent: any }) => {
					let messageText = '';
					let acceptFn = null;

					// get parent element of target
					const el = ev.originalEvent.target as HTMLElement;
					const closestParent = el.closest('div');

					if (closestParent && closestParent.matches('#multi_entry_menu_goto')) {
						// If entry in multi_menu is clicked (Requeue-Button under table for multi-selection)
						messageText = this.$t('Squeeze.Queue.Requeue.ForwardEntries');
						acceptFn = () => {
							this.requeueSelectedEntries(step);
						};
					}
					else if ((ev.originalEvent.target as HTMLElement).matches('#single_entry_menu *')) {
						// If entry in single_menu is clicked (Requeue-Button in every single row)
						messageText = this.$t('Squeeze.Queue.Requeue.ForwardEntry');
						acceptFn = () => {
							this.requeueEntry(step);
						};
					}
					else {
						return;
					}

					this.$confirm.require({
						message: messageText + ": " + this.$t('Squeeze.Queue.Steps.' + step) + " ?",
						header: this.$t('Squeeze.General.Confirmation'),
						icon: 'pi pi-exclamation-triangle',
						accept: acceptFn,
						reject: () => { /**/ },
					});
				},
			});
		});
	}

	/**
	 * Triggered when a row is clicked
	 * @param event
	 */
	onRowClick(event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) {
		/* Prevent the row-click in the selection-column with the checkbox
		* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
		* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
		*/
		if (event.originalEvent
			&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
			&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
			this.$emit("onRowSelect", event.data);
		}
		return;
	}

	/** Triggered when the next page is selected */
	onPage(event: any) {
		this.$emit("onPage", event)
	}

	/** Triggered when table content should be reloaded */
	onReload() {
		this.$emit("onReload");
	}

	/** Opens requeue menu */
	openRequeueMenu(event: MouseEvent, data: QueueEntry) {
		this.selection = data;
		(this.$refs.single_entry_menu as any).toggle(event);
	}

	/**
	 * Opens the Requeue Menu
	 * @param event
	 */
	openMultiRequeueMenu(event: any) {
		if (!this.selectedRows.length) {
			return;
		}
		(this.$refs.multi_entry_menu as any).toggle(event);
	}

	/**
	 * Opens the Requeue Menu
	 * @param event
	 */
	openGoToMenu(event: any) {
		(this.$refs.multi_entry_menu as any).toggle(event);
	}

	/** Requeues selected QueueEntry to specified step */
	requeueEntry(step: string) {
		if(this.selection && this.selection.id) {
			// REQUEUE API ENDPOINT
			this.lockTable = true;
			this.queueApi.requeueEntry(this.selection.id, step).then(() => {
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.Queue.Requeue.Success'), this.$t('Squeeze.Queue.Steps.' + step));
			}).catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			}).finally(() => {
				this.selection = null;
				this.lockTable = false;
				this.onReload();
			})
		} else {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Select.Entry.One'));
		}
	}

	requeueSelectedEntries(step: string) {
		if (!this.selectedRows || !this.selectedRows.length) {
			return;
		}

		this.lockTable = true;
		this.selectedRows.reduce((chain, { id }) => {
			if (id == undefined) {
				return chain;
			}
			return chain.finally(() => this.queueApi.requeueEntry(id, step).catch(() => { /* swallow errors */ }))
		}, Promise.resolve()).finally(() => {
			ToastManager.showSuccess(this.$toast, this.$t('Squeeze.Queue.Requeue.Success'), this.$t('Squeeze.Queue.Steps.' + step));
			this.selectedRows = [];
			this.lockTable = false;
			this.onReload();
		});
	}

	/** Formats date string to desired locale string */
	formatDate(dateToFormat: string) {
		if(dateToFormat && dateToFormat.length) {
			const dateNumber = Date.parse(dateToFormat);

			if(!isNaN(dateNumber)) {
				const date = new Date(dateToFormat);
				const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
				this.lang = i18n.global.locale;
				return date.toLocaleDateString(this.lang.toLowerCase() + '-' + this.lang.toUpperCase(), options);
			}
		}
		return "";
	}

	/** Returns field value of passed DocumentField array */
	getFieldValue(fields: DocumentField[], fieldId: number, type: string) {
		if(fields && fieldId) {
			const fieldValue = fields.find((field) => {
				return field.id === fieldId;
			});

			if(fieldValue != undefined) {
				if(fieldValue.value && fieldValue.value.value) {
					switch(type.toLowerCase()) {
					case "amount": return this.parseAmounts(fieldValue.value.value);
					case "date": return this.formatDate(fieldValue.value.value);
					default: return fieldValue.value.value;
					}
				}
			}
		}

		return "";
	}

	/** Parses an amount (string) to locale string */
	parseAmounts(value: string) {
		value = value.replace(/[^0-9.,]/g, "");

		if(value.indexOf(",") != -1) {
			value = value.replaceAll(".", "").replace(",", ".");
		}

		if(value.length > 0) {
			return parseFloat(value).toLocaleString(this.lang.toLowerCase() + '-' + this.lang.toUpperCase(), {minimumFractionDigits: 2});
		} else {
			return "0,00";
		}
	}

}
</script>
<style lang="scss" scoped>

// Table header
::v-deep(th) {
	background-color: var(--dex-primary-light-color) !important;
	color: var(--dex-text-color);
}

.status-badge {
	font-weight: bold;
	&.initial {
		border-color: var(--queuestate-initial-primary);
		color: var(--queuestate-initial-darker);
	}

	&.work {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.waiting {
		border-color: var(--queuestate-waiting-primary);
		color: var(--queuestate-waiting-darker);
	}

	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}

	&.suspend {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.suspend-marked {
		border-color: var(--queuestate-suspend-darker);
		color: var(--queuestate-suspend-darker);
	}

	&.clarification {
		border-color: var(--queuestate-clarification-primary);
		color: var(--queuestate-clarification-darker);
	}

	&.finished {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}
}

/** Reduce height of edit/delete buttons */
button.p-button.p-button-icon-only.p-button-rounded {
	height: 0rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
