
import {
	computed,
	defineComponent, inject,
	onMounted,
	ref
	// watch
} from 'vue';

import DocumentSearchMask from "@/components/DocumentSearchMask.vue";
import {DocumentSearchRequestDto} from "@dex/squeeze-client-ts";
import Dropdown from 'primevue/dropdown';
import {showDropdownOverlay} from "@/util/StylesHelper";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import router from "@/router";
import {FreezeSearchMask, FreezeSearchMaskField} from "@/apis/freeze/Types";
import {FreezeClientKey} from "@/apps/freeze/DI";

export default defineComponent({
	name: 'StoreSearchMask',
	components: {
		Dropdown,
		DocumentSearchMask,
	},
	emits: ['onChangeStore', 'onChangeHitList'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Api for Freeze-Requests */
		const freezeApi = inject(FreezeClientKey)!

		/** List of all Stores */
		const stores = ref<any[]>([]);

		/** Currently selected Store */
		const selectedStore = ref('');

		/** List of all Stores */
		const searchMasks = ref<any[]>([]);

		/** Currently selected Store */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		/** List of all Document-Class-Fields */
		const searchFields = ref<FreezeSearchMaskField[]>([]);

		/** Search-Mask-Fields */
		const searchMask = computed(() => {
			return {
				id: selectedMask.value,
				fields: searchFields.value.map((field: any) => {
					return {
						id: field.name,
						name: field.desc,
						type: 'string',
					};
				}),
			} as any
		});

		/**
		 * Get all Search Masks
		 */
		const getAllSearchMasks = async () => {
			try {
				const data = await freezeApi.getAllSearchMasks();
				searchMasks.value = data;

				if (searchMasks.value[0]) {
					selectedMask.value = searchMasks.value[0];
					searchFields.value = searchMasks.value[0].fields;
					emit("onChangeHitList", selectedMask.value);
				}
			} catch(e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		/** set the selected store */
		const selectStore = (event: { value: string }) => {
			selectedStore.value = event.value;
			emit("onChangeStore", selectedStore.value);
		}

		/** set the selected search mask */
		const selectSearchMask = (event: { value: FreezeSearchMask }) => {
			selectedMask.value = event.value;
			searchFields.value = selectedMask.value.fields;

			emit("onChangeHitList", selectedMask.value);
		}

		/**
		 * Triggered when the submit button is clicked
		 * @param search
		 */
		const submit = (search: DocumentSearchRequestDto) => {
			router.push({name: "FreezeRecords", params: {
				storeId: selectedStore.value,
				searchMaskId: selectedMask.value.name,
				searchRequest: encodeURIComponent(JSON.stringify(search)),
			}});
		}

		/**
		 * Get All Stores
		 */
		const getAllStores = async () => {
			try {
				const data = await freezeApi.getAllStores();
				stores.value = data;

				if (stores.value[0]) {
					selectedStore.value =stores.value[0].name;
					emit("onChangeStore", selectedStore.value);
				}
			}catch (e) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + e);
			}
		}

		onMounted(() => {
			/** Get all Document Classes */
			getAllStores();
			getAllSearchMasks();
		});

		return {
			stores,
			selectedStore,
			searchMasks,
			selectedMask,
			searchMask,
			submit,
			showDropdownOverlay,
			selectStore,
			selectSearchMask,
		};
	},
});

