<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="name">{{$t('Squeeze.MasterData.Name')}} *</label>
			<InputText id="name" v-model="value.name" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" autofocus />
			<small v-if="v$.name.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Name')}) }}
			</small>
			<small v-else-if="!!value.id">
				{{ $t('Squeeze.Admin.WarnRename') }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.MasterData.Description')}} *</label>
			<InputText id="description" v-model="value.description" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.Description')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="sourceColumn">{{$t('Squeeze.MasterData.SourceColumn')}} *</label>
			<InputText id="sourceColumn" v-model="value.sourceColumn" :class="{'p-invalid':v$.sourceColumn.$invalid && showErrorMessage}" @change="update" />
			<small v-if="(v$.sourceColumn.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.MasterData.SourceColumn')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {MasterDataColumn} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "MasterDataColumnsForm",
	components: {
		InputText,
	},
	props: {
		masterDataColumn: {
			type: Object as PropType<MasterDataColumn>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<MasterDataColumn>({name: '', description: '', sourceColumn: ''});

		/** Determines the required rules for validation */
		const rules = {
			name: { required },
			description: { required },
			sourceColumn: { required},
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.masterDataColumn);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataColumn, () => {
			Object.assign(value, props.masterDataColumn);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
