import  de from './translations/de.json';
import  ch from './translations/de.json';
import  en from './translations/en.json';
import  us from './translations/en.json';
import  es from './translations/es.json';
import  fr from './translations/fr.json';
import  pl from './translations/pl.json';
import  it from './translations/it.json';
import  nl from './translations/nl.json';
import  be from './translations/nl.json';
import  dk from './translations/da.json';

export const Messages: { [lang: string]: any } = {
	de,
	en,
	pl,
	it,
	nl,
	dk,
	fr,
	es,
	us,
	ch,
	be,
};


export const SupportedLangs = Object.keys(Messages);

export const DEFAULT_LOCALE = "en";

// This is a monkey-patched type declaration for the baseUrl var defined in public/index.html
// Used to allow dynamic baseURL (when served behind proxy for example)
declare const baseUrl: string;

/**
 * Returns the Messages for the currently active language
 */
export const getActiveLanguageMessages = (): any => {
	let LanguageMessages = Messages[DEFAULT_LOCALE];
	let path = window.location.pathname;

	if (baseUrl) {
		path = path.replace(baseUrl, "");
	}

	const pathName = path.split("/");
	if (pathName[0] && SupportedLangs.includes(pathName[0])) {
		LanguageMessages = Messages[pathName[0]];
	}

	return LanguageMessages
}


/**
 * Returns the currently active language
 */
export const getActiveLanguage = (): string => {
	let language = DEFAULT_LOCALE;
	let path = window.location.pathname;

	if (baseUrl) {
		path = path.replace(baseUrl, "");
	}

	const pathName = path.split("/");
	if (pathName[0] && SupportedLangs.includes(pathName[0])) {
		language = pathName[0];
	}

	return language
}
