<template>
	<div class="p-pb-2 p-component">
		<p>{{ $t('Squeeze.UserManagement.TakeOverRolesDescription') }}</p>
	</div>
	<UserTakeOverRolesForm
		:loading="loading"
		:usersFrom="usersFrom"
		:usersTo="usersTo"
		:usersTakeOverRoles="usersTakeOverRoles"
		:showErrorMessage="showErrorMessage"
		@update="onUpdate"
	/>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {User} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import UserTakeOverRolesForm from "@/apps/administration/components/users/UserTakeOverRolesForm.vue";

export default defineComponent({
	name: "UserTakeOverRolesView",
	components: { UserTakeOverRolesForm },
	props: {
		userId: {
			type: Number,
			default: null,
		},
		usersTakeOverRoles: {
			type: Object,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** Array of Users */
		const usersFrom = ref<User[]>([]);

		/** Array of Users */
		const usersTo = ref<User[]>([]);

		/** Get all Users */
		const getAllUsers = () => {
			loading.value = true;
			userService.getAllUsers()
				.then(data => {
					usersTo.value = data as User[];
					// filter users
					const allUsersFrom = data as User[];
					usersFrom.value = allUsersFrom.filter((user) => user.id !== props.userId);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getAllUsers();
		})

		/** Triggered on update of attribute-form */
		const onUpdate = (data: any, isInvalid: boolean) => {
			emit("update", data, isInvalid);
		}

		return {
			t,
			toast,
			loading,
			usersFrom,
			usersTo,
			onUpdate,
		}
	},
});

</script>

<style scoped>

</style>
