
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import Substitutions from "@/apps/administration/components/locators/tabs/Substitutions.vue";
import {LocatorSubstitution} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import SubstitutionsForm from "@/apps/administration/components/locators/tabs/SubstitutionsForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "SubstitutionsView",
	components: {
		Substitutions, DialogDelete, SubstitutionsForm, EntryDialog, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		'onLocatorChange',
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const substitutionsForm = ref<InstanceType<typeof SubstitutionsForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Substitutions invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all Substitutions */
		const substitutions = ref<LocatorSubstitution[]>([]);

		/** Should the Entry-Dialog for substitutions be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active substitution */
		const substitution = reactive<LocatorSubstitution>({
			orgChar: '',
			newChar: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Is Substitution changed? */
		const changeSubstitution = ref<boolean>(false);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: LocatorSubstitution, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(substitution, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (substitution.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.Tabs.Substitution') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.Substitution') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: LocatorSubstitution) => {
			Object.assign(substitution, row);
			showDialog.value = true;
		}

		/**
		 * Reloads the table
		 */
		const reloadData = () => {
			loading.value = true;
			locatorService.getLocatorSubstitutions(props.locatorId)
				.then(data => {
					substitutions.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Get Type of current locator on load
		 */
		onMounted(() =>{
			reloadData();
		})

		/** Saves an attribute */
		const saveSubstitution = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}

			showValidationMessage.value = false;

			loadingDialog.value = true;
			loading.value = true;

			let promise = null;
			// make sure locatorId always right
			substitution.locatorId = props.locatorId;

			if (!substitution.id) {
				promise = locatorService.postLocatorSubstitution(props.locatorId, substitution);
			} else {
				promise = locatorService.putLocatorSubstitution(props.locatorId, substitution.id, substitution);
			}

			promise.then(() => {
				if(!changeSubstitution.value) {
					reloadData();
				}

				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					substitution.orgChar = '';
					substitution.newChar = '';
					substitutionsForm.value?.$el.querySelector('input').focus()
					isValidationInvalid.value = true;
				}
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
				changeSubstitution.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: LocatorSubstitution) => {
			deleteDialog.value = true;
			Object.assign(substitution, row);
		}

		/**
		 * Deletes a Substitution
		 */
		const deleteEntry = () => {
			if (substitution && substitution.id) {
				loading.value = true;
				locatorService.deleteLocatorSubstitution(props.locatorId, substitution.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/**
		 * Triggered when a input field changed
		 * @param row
		 * @param isInvalid
		 */
		const onChangeInputField = (row: LocatorSubstitution, isInvalid: boolean) => {
			changeSubstitution.value = true;
			isValidationInvalid.value = isInvalid;
			Object.assign(substitution, row)
			saveSubstitution()
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, showDialog, headerText,
			substitutions, substitution, deleteDialog, changeSubstitution, substitutionsForm, onChangeInputField,
			deleteEntry, openDeleteDialog, onUpdate, onEntrySelect, saveSubstitution};
	},
});

