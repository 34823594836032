
import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { DocumentClass } from '@dex/squeeze-client-ts';
import Tooltip from "primevue/tooltip";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";

@Options({
	name: 'DocumentClassList',
	components: {
		DataTable, Column, InputText,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		documentClasses: {
			type: Array,
			default: [],
		},
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		isAdministrationPathOpen: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEditDialog", "openDeleteDialog", "onRowSelect"],
})

export default class DocumentClassList extends Vue {

	/** All Document-Classes */
	documentClasses?: DocumentClass[] = []

	/** A single item (used for insert/edit/delete */
	rowData?: DocumentClass = {name: "", description: ""}

	/** Currently-Selected row */
	selection = null;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Should the clear filter button be shown in the table? */
	showBtnClearFilter: boolean = false;

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	beforeMount() {
		this.initFilters();
	}

	/**
	 * Opens the Delete-Dialog
	 * @param {DocumentClass} row The row to delete
	 */
	openDeleteDialog(row: DocumentClass) {
		this.rowData = {...row};
		this.$emit("openDeleteDialog", this.rowData)
	}

	/**
	 * Opens the Edit-Dialog with "New Entry"-Setting
	 */
	openNewEntryDialog() {
		this.rowData = {name: '', description: ''}
		this.$emit("openEntryDialog", this.rowData)
	}

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentClass} data Data to edit
	 */
	openEditDialog(data: DocumentClass) {
		this.rowData = {...data}
		this.$emit("openEditDialog", this.rowData)
	}

	/** Triggered when a row is selected */
	onRowSelect(event: any) {
		this.$emit("onRowSelect", event.data.id)
	}

	/**
	 * Triggered when a filter has been entered
	 * @param event
	 */
	onFilter(event: any) {
		if (event.filteredValue.length < this.documentClasses!.length) {
			this.showBtnClearFilter = true;
		} else {
			this.showBtnClearFilter = false;
		}
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

}
