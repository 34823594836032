<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="firstName">{{$t('Squeeze.BatchClasses.Name')}} *</label>
			<InputText id="firstName" v-model="value.title" :class="{'p-invalid':v$.title.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.title.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.BatchClasses.Name')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="lastName">{{$t('Squeeze.BatchClasses.Description')}} *</label>
			<InputText id="lastName" v-model="value.description" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.description.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.BatchClasses.Description')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="parentRoleId">{{$t('Squeeze.UserManagement.RoleUnder')}} *</label>
			<Dropdown :disabled="!!value.id" id="parentRoleId" v-model="value.parentRoleId" :options="parentRoles" :loading="loading" :class="{'p-invalid':v$.parentRoleId.$invalid && showErrorMessage}" optionValue="id" optionLabel="description" @change="update"  />
			<small v-if="v$.parentRoleId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.RoleUnder')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import {UserRole} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "RoleForm",
	components: {
		InputText, Dropdown,
	},
	props: {
		role: {
			type: Object as PropType<UserRole>,
			required: true,
		},
		parentRoles: {
			type: Array as PropType<UserRole>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<any>({
			title: '',
			description: '',
			parentRoleId: 0,
		});

		/** Determines the required rules for validation */
		const rules = {
			title: { required },
			description: { required },
			parentRoleId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.role);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.role, async () => {
			Object.assign(value, props.role);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
		}
	},
});

</script>

<style scoped>

</style>
