<template>
	<div class="p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2">
		<div class="p-col-12">
			<p>
				{{$t('Squeeze.Login.ResetPasswordInfoText')}}
			</p>
		</div>
		<div class="p-field p-col-12">
			<label for="password">{{$t('Squeeze.Login.Password')}}</label>
			<Password id="password" v-model.trim="password" required="true" @change="onChangeField" :feedback="false" @keydown="onKeyDown" toggleMask autofocus />
		</div>
		<div class="p-field p-col-12">
			<label for="passwordRepeat">{{$t('Squeeze.Login.PasswordRepeat')}}</label>
			<Password id="passwordRepeat" v-model.trim="passwordRepeat" required="true" :feedback="false" @change="onChangeField" @keydown="onKeyDown" toggleMask />
		</div>
		<div class="p-col-12 p-mt-2">
			<Button :loading="loading" :label="$t('Squeeze.Login.Send')" @click="onClickSend()"/>
		</div>
		<div v-if="showErrorMandatory" class="p-col-12">
			<Message severity="error" :closable="false">{{ $t('Squeeze.Login.MandatoryPasswords') }}</Message>
		</div>
		<div v-if="showErrorEqual" class="p-col-12">
			<Message severity="error" :closable="false">{{ $t('Squeeze.Login.PasswordsMustBeEqual') }}</Message>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import Password from "primevue/password";
import Message from 'primevue/message';

export default defineComponent({
	name: "ResetPasswordForm",
	props: {
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	components: {
		Password, Message,
	},
	emit: ['onClickSend'],
	setup(props, {emit}) {
		/** Password of the user */
		const password = ref('');

		/** Repeat of password */
		const passwordRepeat = ref('');

		/** Should the error be shown? */
		const showErrorEqual = ref(false);

		/** Should the error message be shown? */
		const showMessage = ref(false);

		/** Should the error message for mandatory fields be shown? */
		const showErrorMandatory = ref(false);

		/**
		 * Checks if the the two passwords are valid
		 */
		const arePasswordsValid = () => {
			showErrorEqual.value = false;
			showErrorMandatory.value = false;
			if (password.value.length === 0 || passwordRepeat.value.length === 0) {
				showErrorMandatory.value = true;
				return false;
			}

			if (password.value !== passwordRepeat.value) {
				showErrorEqual.value = true;
				return false;
			}

			return true;
		}

		/** Triggered on change of password fields */
		const onChangeField = () => {
			if (showMessage.value && arePasswordsValid()) {
				showErrorEqual.value = false;
			}
		}

		/** Triggered on click of send */
		const onClickSend = () => {
			if (!arePasswordsValid()) {
				showMessage.value = true;
				return;
			}

			emit('onClickSend', password.value);
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter" && !showErrorEqual.value) {
				emit('onClickSend', password.value);
			}
		}

		return {password, passwordRepeat, showErrorEqual, showErrorMandatory,
			onChangeField, onClickSend, onKeyDown, arePasswordsValid};
	},
})
</script>

<style scoped>

</style>
