<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="netKeywordLocatorId">{{$t('Squeeze.Locators.Tabs.KeyWords')}} {{$t('Squeeze.Locators.Tabs.NetAmount')}}</label>
			<Dropdown id="netKeywordLocatorId" v-model="value.netKeywordLocatorId" :options="locators" :loading="loading" :showClear="true" :filter="true" optionValue="id" optionLabel="description" @change="update" @show="showDropdownOverlay" />
		</div>
		<div class="p-field p-col-12">
			<label for="taxKeywordLocatorId">{{$t('Squeeze.Locators.Tabs.KeyWords')}} {{$t('Squeeze.Locators.Tabs.TaxAmount')}}</label>
			<Dropdown id="taxKeywordLocatorId" v-model="value.taxKeywordLocatorId" :options="locators" :loading="loading" :showClear="true" :filter="true" optionValue="id" optionLabel="description" @change="update" @show="showDropdownOverlay" />
		</div>
		<div class="p-field p-col-12">
			<label for="totalKeywordLocatorId">{{$t('Squeeze.Locators.Tabs.KeyWords')}} {{$t('Squeeze.Locators.Tabs.TotalAmount')}}</label>
			<Dropdown id="totalKeywordLocatorId" v-model="value.totalKeywordLocatorId" :options="locators" :loading="loading" :showClear="true" :filter="true" optionValue="id" optionLabel="description" @change="update" @show="showDropdownOverlay" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {DocumentLocator, InvoiceAmountLocatorDetails} from "@dex/squeeze-client-ts";
import Dropdown from 'primevue/dropdown';
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "InvoiceAmountsForm",
	components: { Dropdown },
	props: {
		invoiceAmount: {
			type: Object as PropType<InvoiceAmountLocatorDetails>,
			default: {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<InvoiceAmountLocatorDetails>({});

		onMounted(async () => {
			Object.assign(value, props.invoiceAmount);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			emit("update", value);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.invoiceAmount, async () => {
			Object.assign(value, props.invoiceAmount);
			emit("update", value);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value);
		}

		return {
			value,
			props,
			Dropdown,
			update,
			showDropdownOverlay,
		}
	},
});

</script>

<style scoped>

</style>
