
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {DocumentClass, DocumentTable, LineItemLocatorDetails} from "@dex/squeeze-client-ts";
import Dropdown from 'primevue/dropdown';
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "SearchForLineItemsForm",
	components: { Dropdown },
	props: {
		lineItemLocatorValue: {
			type: Object as PropType<LineItemLocatorDetails>,
			default: {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		positionTables: {
			type: Array as PropType<DocumentTable[]>,
			default: [],
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		/** Current Object of all input-fields */
		const value = reactive<LineItemLocatorDetails>({
			locatorId: 0,
		});

		/** Determines the required rules for validation */
		const rules = {
			tableId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.lineItemLocatorValue);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.lineItemLocatorValue, async () => {
			Object.assign(value, props.lineItemLocatorValue);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param column Current-Column
		 */
		const getTableLabel = (table: DocumentTable): string => {
			const documentClass = props.documentClasses?.find(documentClass => documentClass.id === table.documentClassId);

			if (documentClass) {
				return table.description + " (ID: " + table.id +  ", " + t('Squeeze.DocumentClasses.DocumentClass') +": " + documentClass.description + ")";
			}else {
				return table.description + " (" + table.name + ")";
			}
		}

		return {
			value,
			rules,
			v$,
			props,
			Dropdown,
			update,
			showDropdownOverlay,
			getTableLabel,
		}
	},
});

