<template>
	<DataTable
		:value="exportStates"
		:loading="loading"
		class="p-datatable-sm"
		columnResizeMode="fit"
		:lazy="true"
		@page="onPage($event)"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="paginationInfo.pageSize"
		:totalRecords="paginationInfo.total"
		:paginator="true"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		:autoLayout="true"
		:scrollable="true"
		scrollHeight="flex"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.DocumentClasses.ExportStates')}) }}
		</template>
		<Column field="id" header="ID" style="min-width: 4rem; max-width: 4rem;" />
		<Column field="documentId" :header="$t('Squeeze.DocumentClasses.Document')" style="min-width: 7rem; max-width: 7rem;" />
		<Column field="interfaceId" :header="$t('Squeeze.DocumentClasses.ExportInterface')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getNameFromExportInterface(slotProps.data.interfaceId)">
					{{ getNameFromExportInterface(slotProps.data.interfaceId) }}
				</div>
			</template>
		</Column>
		<Column field="status" :header="$t('Squeeze.DocumentClasses.Status')" style="min-width: 10rem;" />

		<template #paginatorLeft></template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {ExportInterfaceDto, PaginationDto} from "@dex/squeeze-client-ts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {ExportStatus} from "@dex/squeeze-client-ts/dist/api";
import {useI18n} from "vue-i18n";
import Tooltip from "primevue/tooltip";

export default defineComponent({
	name: 'DocumentClassExportStates',
	components: {DataTable, Column},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All export states */
		exportStates: {
			type: Array as PropType<ExportStatus>,
			default: [],
		},
		paginationInfo: {
			type: Object as PropType<PaginationDto>,
		},
		allExportInterfaces: {
			type: Array as PropType<ExportInterfaceDto[]>,
			default: [],
			required: true,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onPage",
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Triggered when the next page is selected */
		const onPage = (event: PaginationDto) => {
			emit("onPage", event);
		}

		/**
		 * Gets the Name of a export interface
		 * @param interfaceId Id of the export interface to get the name
		 * @return Name of the export Interface
		 */
		const getNameFromExportInterface = (interfaceId: number) => {
			if (interfaceId == null) {
				return t('Squeeze.General.None');
			}
			const exportInterface = props.allExportInterfaces.find(i => i.id === interfaceId);

			if (!exportInterface) {
				return String(interfaceId)
			}
			return exportInterface.interfaceName
		}

		return {
			t, onPage, getNameFromExportInterface,
		}
	},
})
</script>

<style scoped>

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
