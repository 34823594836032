<template>
	<div class="p-p-2 child-content">
		<BatchClassAttributes :attributes="attributes" :loading="loading" @onClickDelete="openDeleteDialog" @onEntrySelect="onEntrySelect" />
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />

	<!-- Dialog für Felder -->
	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false, isSettingInvalid = true, showValidationMessage = false"
		@onConfirm="saveAttribute"
		:loading="loadingDialog"
		:headerText="headerText"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<BatchClassAttributesForm
					:batchClassSetting="batchClassSetting"
					:allBatchClassSettings="allBatchClassSettings"
					:disableSettingName="disableSettingName"
					@update="onUpdate"
					:showErrorMessage="showValidationMessage"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import DialogDelete from "@/components/DialogDelete.vue";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import BatchClassAttributes from "@/apps/administration/components/batchclasses/BatchClassAttributes.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import BatchClassAttributesForm from "@/apps/administration/components/batchclasses/BatchClassAttributesForm.vue";
import {BatchClassSetting} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "BatchClassAttributesListView",
	components: {
		BatchClassAttributes, DialogDelete, EntryDialog, BlockUI, BatchClassAttributesForm,
	},
	props: {
		batchClassId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Batch-Class-Api */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		/** List of all attributes */
		const attributes = ref<BatchClassSetting[]>([]);

		/** Currently active attribute */
		const batchClassSetting = reactive<BatchClassSetting>({});

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** Show loading in dialog? */
		const loadingDialog = ref<boolean>(false);

		/** Should the Entry-Dialog for classifications be shown? */
		const showDialog = ref<boolean>(false);

		/** Show the loading for Save-Button of classifications? */
		const loadingSaveClassification = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t("Squeeze.BatchClasses.SaveAttribute"));

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** List of all available Settings */
		const allBatchClassSettings = ref<BatchClassSetting[]>([]);

		/** Should the display-name be shown as disabled? */
		const disableSettingName = ref(false);

		/** Is the Form of the Attribute invalid? */
		const isSettingInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Is BatchClassSetting changed? */
		const changeBatchClassSetting = ref<boolean>(false);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: BatchClassSetting, isInvalid: boolean) => {
			isSettingInvalid.value = isInvalid;
			Object.assign(batchClassSetting, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			const promise1 = batchClassApi.getPossibleSettingsForBatchClass();
			const promise2 = batchClassApi.getBatchClassSettings(props.batchClassId);

			// Wait until promises are finished
			Promise.all([promise1, promise2]).then(values => {
				allBatchClassSettings.value = values[0] as BatchClassSetting[];
				attributes.value = values[1] as BatchClassSetting[];
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/**
		 * Gets all Classification-Classes on Mounted
		 */
		onMounted(() =>{
			reloadData();
		});

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: any) => {
			deleteDialog.value = true;
			Object.assign(batchClassSetting, row);
		}

		/**
		 * Deletes the selected entry
		 */
		const deleteEntry = () => {
			if (props.batchClassId && batchClassSetting.settingName) {
				loading.value = true;
				batchClassApi.deleteBatchClassSettingsByName(props.batchClassId, batchClassSetting.settingName).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 * @param disableSettingNameEdit
		 */
		const onEntrySelect = (row: any, disableSettingNameEdit: boolean) => {
			disableSettingName.value = disableSettingNameEdit;
			Object.assign(batchClassSetting, row);
			showDialog.value = true;
		}

		/** Saves an attribute */
		const saveAttribute = () => {
			if (isSettingInvalid.value) {
				showValidationMessage.value = true;
				return;
			}

			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			batchClassApi.putBatchClassSettingsByName(props.batchClassId, batchClassSetting.settingName!, batchClassSetting.value)
				.then(() => {
					if(!changeBatchClassSetting.value) {
						reloadData();
					}
					showDialog.value = false;
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingDialog.value = false;
					loading.value = false;
					changeBatchClassSetting.value = false;
				})
		}

		return {
			attributes, batchClassSetting, allBatchClassSettings, isSettingInvalid, showValidationMessage,
			loading, deleteDialog, showDialog, loadingSaveClassification, headerText, loadingDialog, changeBatchClassSetting,
			openDeleteDialog, deleteEntry, reloadData, onEntrySelect, saveAttribute, onUpdate, disableSettingName,
		}
	},
})
</script>

<style scoped>

.child-content {
	height: calc(100vh - 5.6rem - 3.538rem);
}

</style>
