<template>
	<div class="p-grid p-flex-column p-m-0 p-p-4 login-container p-jc-center p-ai-center" :style="loginBackgroundImageStyles">
		<div class="p-col-12 p-md-6 p-lg-4">
			<Panel class="p-shadow-2">
				<template #header>
					<div v-if="!loading" :style="loginLogoStyles"></div>
				</template>
				<div class="p-grid">
					<div class="p-col-12 p-text-center">
						<h1 class="p-component view-title">{{ $t('Squeeze.Login.SQUEEZE') }}</h1>
						<h4 class="p-component client-title">{{ publicSystemInformation.tenantName }}</h4>
					</div>
				</div>
				<LoginForm @loginStatus="loginStatus"/>
			</Panel>
		</div>
	</div>
</template>

<script lang="ts">
import Panel from 'primevue/panel';
import LoginForm from "@/components/LoginForm.vue";
import router from "@/router";
import {ToastManager} from "@/util/ToastManager";
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {SystemInfoDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "Login",
	components: {
		Panel, LoginForm,
	},
	emits: [
		"loginStatus", // Called when the user is logged in
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Object with System Information */
		const publicSystemInformation = reactive<SystemInfoDto>({});

		/** Service for getting the public-data */
		const publicService = ClientManager.getInstance().squeeze.public;

		/** Service for getting the theme-data */
		const themeService = ClientManager.getInstance().squeeze.theme;

		/** URL of client background image */
		const urlClientBackground = ref<string>('');

		/** Styles of background Image in login */
		const loginBackgroundImageStyles = computed(() => {
			return {
				'background-image': 'url('+urlClientBackground.value+')',
				'height': '100%',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'cover',
			}
		})

		/** URL of client logo */
		const urlClientLogo = ref<string>('');

		/** Styles of logo image in login */
		const loginLogoStyles = computed(() => {
			return {
				'background-image': 'url('+urlClientLogo.value+')',
				'width': '100%',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
				'background-size': 'contain',
				'max-width': '10rem',
				'max-height': '10rem',
				'min-height': '4rem',
			}
		})

		/**
		 * Receives the login-status from the Form
		 * @param isLoggedIn Is the User Logged in?
		 */
		const loginStatus = (isLoggedIn: boolean) => {
			emit("loginStatus", isLoggedIn);

			if (isLoggedIn) {
				router.push({ name: 'SqueezeDashboard'});
			}
		}

		/** Get all public System Information */
		const getPublicSystemInformation = () => {
			publicService.getPublicSystemInformation()
				.then(data => {
					Object.assign(publicSystemInformation, data);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get background image of client */
		const getBackgroundImage = () => {
			themeService.getThemeFile('loginBackground.jpg')
				.then(data => {
					urlClientBackground.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get Logo of client */
		const getLogo = () => {
			loading.value = true;
			themeService.getThemeFile('loginLogo.png')
				.then(data => {
					urlClientLogo.value = data.url;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() => {
			getPublicSystemInformation();
			getBackgroundImage();
			getLogo();
		})

		return { loading, publicSystemInformation, urlClientBackground, loginBackgroundImageStyles, urlClientLogo, loginLogoStyles, loginStatus }
	},

})
</script>

<style lang="scss" scoped>

.login-container {
	min-height: 100vh;
}

h1.view-title {
	margin-bottom: 1rem;
	color: #08314A;
}

.client-title {
	font-size: 1.5rem;
	opacity: 0.5;
	margin-top: 0;
}

@media only screen and (max-width: 1280px) {
	h1.view-title {
		font-size: 3rem;
	}
}

</style>
