<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="name">{{$t('Squeeze.UserManagement.Name')}} *</label>
			<InputText id="name" v-model="value.name" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" autofocus />
			<small v-if="v$.name.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Name')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import {ApiKey} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "ApiKeyForm",
	components: {
		InputText,
	},
	props: {
		apiKey: {
			type: Object as PropType<ApiKey>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<ApiKey>({
			name: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			name: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.apiKey);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.apiKey, async () => {
			Object.assign(value, props.apiKey);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			update,
		}
	},
});

</script>

<style scoped>

</style>
