<template>
	<div class="p-grid p-flex-column p-m-0 p-p-4 container p-jc-center p-ai-center">
		<div class="p-col-12 p-text-center">
			<h1 class="p-component view-title">{{ $t('Squeeze.Login.SQUEEZE') }}</h1>
			<h2 v-if="route.query.status === '403'" class="p-component">{{ $t('ErrorPage.' + route.query.status + '.Label', { position: $t('ErrorPage.Position.Document')}) }}</h2>
			<h2 v-else-if="route.query.status === undefined" class="p-component">{{ $t('ErrorPage.404.Label') }}</h2>
			<h2 v-else class="p-component">{{ $t('ErrorPage.' + route.query.status + '.Label') }}</h2>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useRoute} from "vue-router";

export default defineComponent({
	name: 'ErrorPage',
	components: {},
	props: {
		apps: {
			type: Array,
		},
	},
	setup() {
		const route = useRoute();

		return { route }
	},
});
</script>

<style scoped lang="scss">

.container {
	min-height: 100vh;
}

h1,h2 {
	&.view-title {
		color: var(--dex-primary-color);
		margin: 0 0 2rem;
	}
}

h1.view-title {
	font-size: 4rem;
}

h2 {
	font-size: 2rem;
}

</style>
