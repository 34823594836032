<template>
	<div class="p-component p-py-4 p-px-4 p-mb-3 p-shadow-3" style="border-radius: .25rem">
		<div v-if="title" class="p-mb-3" style="font-size: 2rem; color: var(--dex-primary-color)">
			{{title}}
		</div>
		<slot></slot>
	</div>
</template>
<script lang="ts">
import {
	defineComponent
	// ref,
	// reactive,
	// computed,
	// onMounted,
	// watch
} from 'vue';

export default defineComponent({
	name: 'DashboardTile',
	props: {
		title: { type: String, required: false },
	},
	setup() {
		//
	},
});

</script>
<style lang="scss" scoped>

</style>
