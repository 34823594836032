<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.DocumentClasses.Name')}} *</label>
			<InputText id="name" v-model.trim="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
			<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
			<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.DocumentClasses.Description')}} *</label>
			<InputText id="description" v-model.trim="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Description')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="locatorId">{{$t('Squeeze.DocumentClasses.Locator')}}</label>
			<Dropdown id="locatorId" v-model="value.locatorId" :options="locators" :filter="true" optionValue="id" optionLabel="description" :showClear="true" :editable="false" @change="onChangeDropDown" @show="showDropdownOverlay" />
		</div>
		<div class="p-field p-col-6">
			<label for="externalName">{{$t('Squeeze.DocumentClasses.ExternalName')}}</label>
			<InputText id="externalName" v-model.trim="value.externalName" required="true" />
		</div>
		<div class="p-field p-col-12">
			<label for="tableBehaviour">{{$t('Squeeze.DocumentClasses.TableBehaviour')}}</label>
			<span class="p-input-icon-right" v-tooltip.bottom="$t('Squeeze.DocumentClasses.TableBehaviourDescription')">
				<i class="mdi mdi-comment-question-outline p-mr-4" style="top: 47%;"/>
				<Dropdown id="tableBehaviour" v-model="value.tableBehaviour" :options="tablesBehaviours" optionValue="id" optionLabel="description" @change="onChangeDropDown" @show="showDropdownOverlay" />
			</span>
		</div>
		<div class="p-field p-col-12">
		</div>
		<div class="p-field p-col-3">
			<label for="mandatory">{{$t('Squeeze.DocumentClasses.Mandatory')}}</label><br/>
			<Checkbox id="mandatory" v-model="value.mandatory" :binary="true" @change="onChangeCheckBox('mandatory')" />
		</div>
		<div class="p-field p-col-3">
			<label for="readonly">{{$t('Squeeze.DocumentClasses.ReadOnly')}}</label><br/>
			<Checkbox id="readonly" v-model="value.readonly" :binary="true" @change="onChangeCheckBox('readonly')" />
		</div>
		<div class="p-field p-col-3">
			<label for="hidden">{{$t('Squeeze.DocumentClasses.Hidden')}}</label><br/>
			<Checkbox id="hidden" v-model="value.hidden" :binary="true" @change="onChangeCheckBox('hidden')" />
		</div>
		<div class="p-field p-col-3">
			<label for="forceValidation">{{$t('Squeeze.DocumentClasses.ForceValidation')}}</label><br/>
			<Checkbox id="forceValidation" v-model="value.forceValidation" :binary="true" @change="onChangeCheckBox('forceValidation')" />
		</div>
		<Message v-if="(tablesWithLocators.length > 0 || activeTableId !== value.id || activeTableId === 0) && activeTableId !== -1" severity="warn" :closable="false">
			<template v-if="tablesWithLocators.length > 0">
				{{$t('Squeeze.Locators.CautionLineItems')}}<br/>
				<template v-for="table in tablesWithLocators" v-bind:key="table.id">
					{{table.description}} (ID: {{table.id}}, {{$t('Squeeze.DocumentClasses.DocumentClass')}}: {{getDocumentDocumentClassLabel(table.documentClassId)}})<br/>
				</template>
				<br/>{{$t('Squeeze.Locators.CautionLineItems2')}}<br/><br/>
			</template>
			<template v-if="activeTableId !== value.id && activeTableId !== 0">
				{{ $t('Squeeze.Locators.CautionLineItems4')}}<br/>
				{{getTableDescription(activeTableId)}}
			</template>
			<template v-else-if="activeTableId === 0">
				{{ $t('Squeeze.Locators.CautionLineItems5')}}<br/>
			</template>
		</Message>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentClass, DocumentLocator, DocumentTable} from "@dex/squeeze-client-ts/dist/api";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {technicalValidation} from "@/util/ValidationHelper";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Message from "primevue/message";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;

export default defineComponent({
	name: "TableForm",
	components: {
		Checkbox, InputText, Dropdown, Message,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<DocumentTable>,
			default: {},
			required: true,
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
		tableField: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
		tablesWithLocators: {
			type: Array as PropType<DocumentTable[]>,
			default: [],
		},
		documentTables: {
			type: Array as PropType<DocumentTable[]>,
			default: [],
		},
		allDocumentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: [],
		},
		activeTableId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Array with the tables behaviours */
		const tablesBehaviours = computed(() => {
			return [
				{
					id: TableBehaviourEnum.Dynamic,
					description: t('Squeeze.DocumentClasses.Dynamic'),
				},
				{
					id: TableBehaviourEnum.Open,
					description: t('Squeeze.DocumentClasses.Open'),
				},
				{
					id: TableBehaviourEnum.Closed,
					description: t('Squeeze.DocumentClasses.Closed'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<DocumentTable>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})


		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}


		const onChangeDropDown = async () => {
			await update();
		}

		/**
		 * Is triggered when a checkbox from mandatory, forceValidation or hidden is clicked
		 * @param fieldName Name of the field
		 */
		const onChangeCheckBox = (fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (value.mandatory === true || value.forceValidation === true) {
					value.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behaviour
				if (value.hidden) {
					value.mandatory = false;
					value.forceValidation = false;
				}
				break;
			}
			}
		}

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param documentClassId
		 */
		const getDocumentDocumentClassLabel  = (documentClassId: number): string => {
			const documentClass = props.allDocumentClasses?.find(documentClass => documentClass.id === documentClassId);

			if (documentClass) {
				return documentClass.description;
			}else {
				return ""+ documentClassId;
			}
		}

		const getTableDescription = (activeTableId: number): string => {
			const table = props.documentTables?.find(locatorTable => locatorTable.id === activeTableId);

			if (table) {
				return table.description + " (ID: " +activeTableId +")";
			}else {
				return ""+ activeTableId;
			}
		}

		return {
			t,
			v$,
			tablesBehaviours,
			value,
			update,
			onChangeCheckBox,
			showDropdownOverlay,
			onChangeDropDown,
			getDocumentDocumentClassLabel,
			getTableDescription,
		}
	},
});
</script>

<style scoped>

</style>
