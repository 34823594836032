
import {Options, Vue} from 'vue-class-component';
import {DocumentField, FieldLookupFilter} from "@dex/squeeze-client-ts";
import InputText from 'primevue/inputtext';
import InputNumber from '@/components/DexInputNumber.vue';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import ScrollPanel from 'primevue/scrollpanel';
//import AutoComplete from 'primevue/autocomplete';
import AutoComplete from '@/components/DexAutocomplete.vue';
import {ClientManager} from "@/singletons/ClientManager";
import {AutoCompleteOnCompleteEvent} from "@/shims-prime-vue";
import Badge from 'primevue/badge';
import Tooltip from "primevue/tooltip";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {LookupDefinition, ValidationFieldDto} from "@dex/squeeze-client-ts";
import Divider from 'primevue/divider';

interface LookupDefinitionWithFilters extends LookupDefinition {
	lookupFieldFilters: FieldLookupFilter[];
}

interface DocumentFieldWithLookupFilter extends DocumentField{
	lookup?: LookupDefinitionWithFilters;
}

interface UiHeadFields {
	name: string;
	field: DocumentField;
	element: any;
}

@Options({
	name: "ValidationFieldSet",
	components: {
		InputText,
		InputNumber,
		Textarea,
		Calendar,
		ScrollPanel,
		AutoComplete,
		Badge,
		Divider,
	},
	props: {
		documentClassFields: Array,
		documentFields: Array,
		id: Number,
		name: String,
		description: String,
		layoutDetails: Array,
		isReadOnlyMode: Boolean,
		documentId: Number,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		'onFocusField',
		'onBlur',
		'allHeadRefFields',
		'enterDocumentGroup',
		'onHoverItemAutocomplete',
	],
})
export default class ValidationFieldSet extends Vue {

	/** List of all DocumentFields of document class */
	documentClassFields!: DocumentField[];

	/** List of all DocumentFields of document */
	documentFields!: DocumentField[];

	/** Field group id */
	id!: number;

	/** Document id */
	documentId!: number;

	/** Technical field group name */
	name!: string;

	/** Field group description */
	description!: string;

	/** Is the current mode of component readonly? */
	isReadOnlyMode!: boolean;

	/** Object with the current filtered values */
	filteredValues?: any = []

	/** Object with the current alternative values */
	alternativeValues?: any = []

	/** Service for getting the master-data-lookups */
	masterDataService = ClientManager.getInstance().squeeze.masterData;

	/** Service for getting the master-data-lookups */
	documentService = ClientManager.getInstance().squeeze.document;

	/** Array with Fields  */
	headRefField: UiHeadFields[] = [];

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Field Layout Details */
	layoutDetails!: string;

	/** Emits the onFocusField-Event with the current-field  */
	onFocusField(event: FocusEvent, field: DocumentField) {
		if (event && event.target) {
			(event.target as HTMLInputElement).select();
		}

		this.$emit("onFocusField", field);
	}

	/** Emits the onFocusField-Event with the current-field  */
	onHoverItemAutocomplete(event: any, field: DocumentField) {
		// Only trigger event if there is something in the values to emit
		if (event.value && event.value.completeValue) {
			this.$emit("onHoverItemAutocomplete", event.value.completeValue);
		}
	}

	/** Emits the onValidationRequest-Event with the current-field */
	emitValidationRequest(event: any, field: DocumentField) {
		// In AutoComplete box if entry in popup list is clicked
		// first the input's blur event is triggered.
		// But in this case no further action (Validation) should be taken
		// but only after real blur (leave) of input.
		if (event && event.target) {
			const node = event.target as Node;
			if (node.parentElement && node.parentElement.getAttribute('aria-expanded') === 'true') {
				return;
			}
		}

		if(field.forceValidation && field.state !== "OK") {
			field.state = "OK";
		}

		// Check if the type is 'button', then return (prevent duplicate validation request)
		if (event.relatedTarget && event.relatedTarget.type && event.relatedTarget.type === 'button') {
			return;
		}

		this.$emit("onBlur", field);
	}

	/** Return index of document field */
	getDocumentFieldIndex(name: string) {
		return this.documentFields.findIndex(field => field.name == name);
	}

	/**
	 * Event that is triggered when an item is selected on Autocomplete
	 * @param event Event of Autocomplete
	 * @param documentClassField Current documentClassField
	 */
	onItemSelect(event: any, documentClassField: DocumentField) {
		// At this point we used the value (instead of the label), because we want to write the ID in the lookup field.
		this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.value = event.value.value;

		if (event.value.completeValue) {
			this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.boundingBox = event.value.completeValue.boundingBox;
		} else {
			this!.documentFields[this.getDocumentFieldIndex(documentClassField.name!)]!.value!.boundingBox = undefined;
		}
	}

	/**
	 * Gets the value from a field by field id
	 * @param fieldId
	 */
	getValueFromField(fieldId: number) {
		const field = this.documentFields.find(field => field.id === fieldId);

		if (field && field.value) {
			return field.value!.value;
		}

		// If no value can be found, search for something illogical that never can be found
		return "FieldNotFound";
	}

	/**
	 * Event that is triggered when users make autocomplete-inputs
	 * @param event Event of Autocomplete
	 * @param field Current documentClassField
	 */
	searchAutocomplete(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.setAutocompleteValues(event, field);
	}

	/**
	 * Emits on field confirm via enter with the current-field
	 * @param event
	 * @param field
	 */
	onEnter(event: KeyboardEvent, field: DocumentField) {
		event.preventDefault();

		if(field.forceValidation && field.state !== "OK") {
			field.state = "OK";
			this.$emit("onBlur", field);
		}

		const allVisibleFields = this.headRefField.filter(field => field.field.hidden === false);
		let index = allVisibleFields.findIndex(refField => refField.field.name === field.name);
		if (event.shiftKey) {
			index -= 1;
		} else {
			index += 1;
		}

		if (allVisibleFields[index]) {
			allVisibleFields[index].element.$el.focus();
			if (allVisibleFields[index].field.lookup?.active === true && !this.isReadOnlyMode ||
				(allVisibleFields[index].field.alternatives!.length > 0 && allVisibleFields[index].element.$el.firstElementChild && allVisibleFields[index].element.$el.firstElementChild != null)) {
				allVisibleFields[index].element.$el.firstElementChild.focus();
			}
		} else {
			// If a field is the last one of a group, also emit onblur, so the validation request will be triggered
			if (event.shiftKey) {
				this.$emit("enterDocumentGroup", this.id, false);
			} else {
				this.$emit("enterDocumentGroup", this.id, true);
			}
			return;
		}

		if (allVisibleFields.length === index) {
			this.$emit("enterDocumentGroup", this.id, true);
		} else if (index === -1) {
			this.$emit("enterDocumentGroup", this.id, false);
		}
	}

	/**
	 * Set field reference
	 * @param element
	 * @param field
	 */
	setFieldReference(element: Element, field: DocumentField) {
		const refField = this.headRefField.find(refField => refField.name === field.name);
		if (field.readonly === true) {
			return
		}

		if (!refField) {
			const newField: UiHeadFields = {
				name: field.name || "Field name",
				field: field,
				element: element,
			};

			this.headRefField.push(newField)
		}
	}

	/**
	 * Sets the Value for the autocomplete/lookup
	 * @param event
	 * @param field
	 */
	async setAutocompleteValues(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.alternativeValues[field.name!] = [];
		if (!field.lookup!.active && field.alternatives!.length > 0) {
			// show all alternatives in dropdown
			const alternatives = field.alternatives!
				.map(alternative => {
					const label = alternative.value;
					const value = alternative.value;
					const completeValue = alternative;
					return {value, label, completeValue};
				})

			this.filteredValues[field.name!] = alternatives;

			// Add current entry if text is written to the field
			if (event.query) {
				const currentValue = {
					value: event.query,
					label: event.query,
					completeValue: {
						boundingBox: {
							x1: 0,
							y1: 0,
							page: 0,
							x0: 0,
							y0: 0,
						},
					},
				}
				this.filteredValues[field.name!].unshift(currentValue);
			}
		} else if (field.lookup!.active) {
			// Otherwise the reference to this.documentfields is referenced and will be changed
			const documentFieldClone = JSON.parse(JSON.stringify(this.documentFields)) as ValidationFieldDto[];
			if (!event.query) {
				event.query = "";
			}

			// Sometimes value is null when the field is cleared. If it is, it's an empty field
			const autocompleteField = documentFieldClone.find((documentField: DocumentField) => documentField.name === field.name);
			if (autocompleteField) {
				if(!autocompleteField.value!.value) {
					autocompleteField.value!.value = "";
				}
			}

			const rows = await this.documentService.getDocumentFieldLookupValues(this.documentId, Number(field.id), {
				documentFields: documentFieldClone,
				fieldSearchValue: event.query,
			}) as any;
			const resultColumns = field.lookup?.resultValueColumnIds;

			const alternatives = rows
				.map((row: any) => {
					const completeValue = row;
					const value = row.resultValue;
					const label = resultColumns?.map(col => row.displayColumnResults[col]).join(" | "); // Map result columns to a single string to be displayed
					return {value, label, completeValue};
				})

			this.filteredValues[field.name!] = alternatives;

			// Add current entry if text is written to the field
			if (event.query && field.lookup?.allowCustomValues) {
				const currentValue = {
					value: event.query,
					label: event.query,
					completeValue: {
						boundingBox: {
							x1: 0,
							y1: 0,
							page: 0,
							x0: 0,
							y0: 0,
						},
					},
				}
				this.filteredValues[field.name!].unshift(currentValue);
			}
		}
	}

	/**
	 * Triggered when a Dropdown is clicked. Currently this triggered by clicking the Autocomplete-Field.
	 * @param event
	 * @param field
	 */
	onClickDropdown(event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) {
		this.setAutocompleteValues(event, field);
	}

	/**
	 * Event that is triggered on click on autocomplete-fields
	 * @param event
	 * @param field Current documentClassField
	 */
	onClickAutocomplete(event: unknown, field: DocumentField) {
		// Trigger onDropDownClick-Event, so the alternatives are shown when there are alternatives. Otherwise do nothing
		if (field.alternatives!.length > 1 || (field.lookup?.active === true && field.lookup.minInputLength === 0)) {
			const index = this.headRefField.findIndex(refField => refField.name === field.name);
			this.headRefField[index].element.onDropdownClick(event, field);
		}
	}

	/**
	 * Check the length of the description
	 * @param description
	 */
	checkDescriptionLength(description: string) {
		if (description.length > 15 && !description.includes('-') && !description.includes(' ')) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Triggered on keydown
	 * @param {KeyboardEvent} event
	 * @param {DocumentField} field Current documentClassField
	 */
	onKeydown(event: KeyboardEvent, field: DocumentField) {
		// if key ctrl and arrow down pressed, then open the autocomplete popup
		if (event.code === 'ArrowDown' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			this.onClickAutocomplete(event, field);
		}
	}

	mounted() {
		this.$emit("allHeadRefFields", this.headRefField);
	}

}
