
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Chip from 'primevue/chip';
import InputNumber from '@/components/DexInputNumber.vue';
import Dropdown from "primevue/dropdown";
import {
	ExportInterfaceDocumentationDto,
	ExportInterfaceDto,
	ExportInterfacePropertyDocumentationDto
} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Password from "primevue/password";
import MultiSelect from "primevue/multiselect";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Tooltip from "primevue/tooltip";

export default defineComponent({
	name: 'ExportInterfaceForm',
	components: {
		Checkbox, InputText, InputNumber, Dropdown, Password, MultiSelect, Chip,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<ExportInterfaceDto>,
			default: {},
			required: true,
		},
		allExportInterfaces: {
			type: Array as PropType<ExportInterfaceDocumentationDto[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ['onChangeCheckbox', 'update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<ExportInterfaceDto>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			interfaceName: { required },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** List of booleans */
		const listOfBooleans = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/**
		 * Gets the properties for an Export-Interface
		 * @param name Name of the Export-Interface
		 */
		const getExportInterfaceProperties = (name: string) => {
			const exportInterface = props.allExportInterfaces.find(i => i.id === name);

			if (exportInterface) {
				return exportInterface.properties;
			}

			return [];
		}

		/**
		 * Checks if the Export-Interfaces Values are valid. Because of the generous structure, this can't be done
		 * with vuelidate
		 */
		const areExportInterfaceValuesInValid = (): boolean => {
			const properties = getExportInterfaceProperties(value.interfaceName);
			let isInvalid = false
			properties?.forEach((property: ExportInterfacePropertyDocumentationDto) => {
				// Only non-optional-values can be invalid
				if (!property.optional) {
					// Use Vuelidates default-required-check here
					if (!helpers.req(value.configuration[property.id!])) {
						isInvalid = true;
					}
				}
			})

			return isInvalid;
		}

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid || areExportInterfaceValuesInValid());
		});

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid || areExportInterfaceValuesInValid());
		}

		/**
		 * Triggered when the tooltip is shown
		 * @param values
		 */
		const mapTooltipValues = (values: string[]) => {
			return values
				.map((value: string, index: number) => {
					return `${index + 1}: ${value}`;
				})
				.join("\n");
		}

		/**
		 * Triggered when clicked to remove a selected item
		 * @param event
		 * @param propertyOption
		 */
		const onRemovePropertyOption = (event: any, propertyOption: string) => {
			const exportFolderStructure = value.configuration.SharePointExportFolderStructure;
			const index = exportFolderStructure.findIndex((option: string) => option === propertyOption);
			if (index !== -1) {
				// remove selected option
				exportFolderStructure.splice(index, 1);
			}
		}

		return {
			t,
			v$,
			value,
			listOfBooleans,
			getExportInterfaceProperties,
			update,
			areExportInterfaceValuesInValid,
			helpers,
			showDropdownOverlay,
			mapTooltipValues,
			onRemovePropertyOption,
		}
	},
});
