<template>
	<div class="p-p-2 child-content">
		<Regex :regexes="regexes" :loading="loading" :showErrorMessage="showValidationMessage" :showSingleLinedForm="showSingleLinedForm" @onClickDelete="openDeleteDialog" @onEntrySelect="onEntrySelect" @onChangeRegex="onChangeRegex" />
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />

	<!-- Dialog für Felder -->
	<EntryDialog
			:show="showDialog"
			@onClose="showDialog = false, isValidationInvalid = true, showValidationMessage = false"
			@onConfirm="saveRegex"
			:loading="loadingDialog"
			:headerText="headerText"
			:showKeepDialogOpen="!regex.id"
		>
			<template #content>
        <BlockUI :blocked="loadingDialog">
          <RegexForm
            ref="regexForm"
            :regex="regex"
            @update="onUpdate"
            :showErrorMessage="showValidationMessage"
          />
        </BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {LocatorRegEx} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import RegexForm from "@/apps/administration/components/locators/tabs/RegexForm.vue";
import Regex from "@/apps/administration/components/locators/tabs/Regex.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "RegexView",
	components: {
		RegexForm, DialogDelete, Regex, EntryDialog, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const regexForm = ref<InstanceType<typeof RegexForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Regexes invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all Regexes */
		const regexes = ref<LocatorRegEx[]>([]);

		/** Should the Entry-Dialog for regexes be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active regex */
		const regex = reactive<LocatorRegEx>({
			regEx: '',
		})

		/** Is Regex changed? */
		const changeRegex = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Trigged on update of attribute-form */
		const onUpdate = (data: LocatorRegEx, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(regex, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (regex.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.Tabs.Regex') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.Regex') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: LocatorRegEx) => {
			Object.assign(regex, row);
			showDialog.value = true;
		}

		/** Reloads the table */
		const reloadData = () => {
			loading.value = true;
			locatorService.getLocatorRegExes(props.locatorId)
				.then(data => {
					regexes.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get Type of current locator on load */
		onMounted(() =>{
			reloadData();
		})

		/** Saves an Regex */
		const saveRegex = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let locatorChangePromise = null;

			// make sure locatorId always right
			regex.locatorId = props.locatorId;

			if (!regex.id) {
				locatorChangePromise = locatorService.postLocatorRegEx(props.locatorId, regex);
			} else {
				locatorChangePromise = locatorService.putLocatorRegEx(props.locatorId, regex.id, regex);
			}

			locatorChangePromise.then(() => {
				if(!changeRegex.value) {
					reloadData();
				}

				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					regex.regEx = '';
					regexForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
				changeRegex.value = false;
			})
		}

		/**
		 * Trigged on change of attribute-form
		 * @param row
		 * @param isInvalid
		 */
		const onChangeRegex = (row: LocatorRegEx, isInvalid: boolean) => {
			changeRegex.value = true;
			isValidationInvalid.value = isInvalid;
			Object.assign(regex, row)
			saveRegex();
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: LocatorRegEx) => {
			deleteDialog.value = true;
			Object.assign(regex, row);
		}

		/** Deletes a Regex */
		const deleteEntry = () => {
			if (regex && regex.id) {
				loading.value = true;
				locatorService.deleteLocatorRegEx(props.locatorId, regex.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, showDialog,
			headerText, regexes, regex, deleteDialog, changeRegex, regexForm,
			deleteEntry, openDeleteDialog, onUpdate, onChangeRegex, onEntrySelect, saveRegex};
	},
});

</script>

<style scoped>

.child-content {
	height: calc(100vh - 5.6rem - 3.538rem);
}

</style>
