<template>
	<DataTable
			:value="editingRows"
			dataKey="pos"
			:paginator="false"
			class="p-datatable-sm"
			@row-reorder="onRowReorder"
			showGridlines
			:scrollable="true"
			scrollHeight="flex"
			editMode="row"
			v-model:editingRows="editingRows"
			v-model:selection="selectedRows"
			selectionMode="multiple"
			:metaKeySelection="false"
			:resizableColumns="true"
			columnResizeMode="fit"
			responsiveLayout="scroll"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Validation.General.Position') }) }}
		</template>

		<Column v-if="!isReadOnlyMode"
				:rowReorder="true"
				style="min-width: 4rem; justify-content: center;"
				:style="[
					customTableActions.length > 2 ? (customTableActions.length > 3 ? 'max-width: 7rem;' : 'max-width: 5.5rem;') : 'max-width: 4rem;',
					store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' : ''
				]"
		>
			<template #header v-if="customTableActions.length > 0">
				<div :style="customTableActions.length >= 5 ? 'overflow-x: scroll;' : ''">
					<template v-for="(action, index) in customTableActions" :key="index">
						<Button type="button"
								v-tooltip="action.description"
								:icon="action.icon ? action.icon : ''"
								class="p-button-sm"
								:class="index >= 1 ? (customTableActions.length >= 5 ? 'p-ml-1 p-mb-1 p-mt-1' : 'p-ml-1') : (customTableActions.length >= 5 ? 'p-mb-1 p-mt-1' : '')"
								style="min-width: 1.25rem; min-height: 1rem; padding: 0.15rem; width: 1.25rem;"
								@click="executeCustomTableAction(action.id)"
						/>
					</template>
				</div>
			</template>
		</Column>

		<Column selectionMode="multiple" style="min-width: 4rem; max-width: 4rem; justify-content: center;" :style="store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''" />

		<Column field="id" :header="$t('Squeeze.Validation.General.PositionHeadline')" style="min-width: 4rem; max-width: 4rem;" :style="store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''">
			<template #editor="slotProps">
				<span class="column-id">{{slotProps.data["pos"]}}</span>
				<Button v-if="slotProps.data.model.state === 'ERROR'" v-tooltip="$t('Squeeze.Validation.General.ErrorCells')
				+ ' '
				+ slotProps.data.model.errorText" icon="mdi mdi-alert-outline" class="p-button p-button-rounded p-button-danger p-button-text" />
			</template>
		</Column>

		<template v-for="col of allVisibleColumns" :field="col.name" :key="col.id">
			<Column :field="col.name" :headerClass="(col.dataType || '').toLowerCase() === 'amount' ? 'table-header-amount' : ''" style="min-width: 10rem;" :style="store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''">
				<template #header>
					<span class="p-column-title" v-tooltip="col.description">{{col.description}}</span>
				</template>

				<template #body="rows">
					{{rows.data.cells.find(cell => cell.columnName === col.name).value}}
				</template>

				<template #editor="editingRows">
					<!-- FIXME: Access model.cells.value in v-model -->
					<AutoComplete
						v-if="!isReadOnlyMode && (col.dataType || '').toLowerCase() === 'text' && col.lookup && col.lookup.active === true && !col.readonly"
						type="text"
						v-model="editingRows.data.cells[col.name].value"
						:minLength="col.lookup.minInputLength"
						:suggestions="filteredValues[col.name]"
						field="label"
						valueField="value"
						class="input-table input-autocomplete"
						:class="{
							'table-cell-border-by-error': editingRows.data.cells[col.name].state === 'ERROR',
							'table-cell-border-by-force': editingRows.data.cells[col.name].state === 'FORCEAPPROVAL',
							'table-cell-border-by-ok': editingRows.data.cells[col.name].state === 'OK',
							'readonly': col.readonly || isReadOnlyMode,
						}"
						:inputClass="{
							'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === true
						}"
						@keydown.enter.tab.up.down.left.right="navigateCells($event, editingRows.data, col, editingRows.data.cells[col.name])"
						@keydown="onKeydown($event, editingRows.data, col)"
						@blur="onFieldChange"
						:ref="el => { setCellReference(el, editingRows.data, col) }"
						:forceSelection="!col.lookup.allowCustomValues"
						:readonly="col.readonly || isReadOnlyMode"
						@complete="searchAutocomplete($event, col, editingRows.data)"
						@item-select="onItemSelect($event, col, editingRows.data, editingRows.data.cells[col.name])"
						@focus="onFocusField($event, editingRows.data.cells[col.name], editingRows.data, col)"
						:dropdownMode="'current'"
						@click="onClickAutocomplete($event, editingRows.data, col)"
						:auto-highlight="true"
						:dropdown="(!col.readonly && col.lookup.minInputLength === 0) && !isReadOnlyMode"
					/>
					<!--
					<InputNumber
						v-else-if="col.dataType.toLowerCase() === 'amount'"
						v-model="editingRows.data.cells[col.name].value"
						mode="decimal"
						:minFractionDigits="2"
					/>-->
					<!-- FIXME: Access model.cells.value in v-model-->
					<InputText v-else class="input-table" v-model="editingRows.data.cells[col.name].value"
						@keydown.enter.tab.up.down.left.right="navigateCells($event, editingRows.data, col, editingRows.data.cells[col.name])"
						@change="onFieldChange"
						:class="{
							'validation-field': store.state.userSettings.viewSettings.smallDistanceBetweenFields === true,
							'table-cell-amount': ((col.dataType || '').toLowerCase() === 'amount'),
							'table-cell-border-by-error': editingRows.data.cells[col.name].state === 'ERROR',
							'table-cell-border-by-force': editingRows.data.cells[col.name].state === 'FORCEAPPROVAL',
							'table-cell-border-by-ok': editingRows.data.cells[col.name].state === 'OK',
							'readonly': col.readonly || isReadOnlyMode,
						}"
						@focus="onFocusField($event, editingRows.data.cells[col.name], editingRows.data, col)"
						:ref="el => { setCellReference(el, editingRows.data, col) }"
						:readonly="col.readonly || isReadOnlyMode"
					/>
				</template>
			</Column>
		</template>

		<Column style="min-width: 8rem; max-width: 8rem; justify-content: flex-end;" :style="store.state.userSettings.viewSettings.smallDistanceBetweenFields ? 'height: 2.438rem;' :  ''">
			<template #editor="slotProps">
				<Button v-if="!hideButtons" v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text deleteButtonRow" @click="deleteRow(slotProps.data.pos)" />
			</template>
		</Column>

		<template #footer>
			<div class="speed-dials">
				<ul class="dials">
					<li :class="showButton ? 'pop-in' : 'pop-out'">
						<Button
							v-if="!hideButtons"
							v-tooltip.left="$t('Squeeze.Validation.Buttons.DeleteSelectedRows')"
							type="button"
							icon="mdi mdi-delete-sweep-outline"
							class="p-button-rounded p-button-danger p-shadow-18"
							@click="deleteSelectedRows()"
							:disabled="selectedRows.length < 1"
						/>
					</li>
					<li :class="showButton ? 'pop-in' : 'pop-out'">
						<Button
							v-if="!hideButtons"
							v-tooltip.left="$t('Squeeze.Validation.Buttons.AddRow')"
							type="button"
							icon="mdi mdi-plus"
							class="p-button-rounded p-shadow-18"
							@click="createNewRow(null)"
						/>
					</li>
				</ul>
				<Button type="button" v-tooltip.left="$t('Squeeze.Validation.Buttons.TableOptions')" class="p-button-rounded p-shadow-18" :icon="showButton ? 'mdi mdi-table-arrow-down' : 'mdi mdi-table-arrow-up'" @click="showButton = !showButton"></Button>
			</div>
		</template>
	</DataTable>
</template>

<script lang="ts">
/* eslint max-lines: off */
import { Options , Vue } from 'vue-class-component';
import {ClientManager} from "@/singletons/ClientManager";
import {
	CustomValidationActionDto,
	DocumentField,
	DocumentTable,
	DocumentTableCell,
	DocumentTableColumn,
	DocumentTableRow,
	FieldLookupFilter
} from "@dex/squeeze-client-ts";
import DataTable from "@/components/DexDataTable.vue";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import InputNumber from "primevue/inputnumber";
import AutoComplete from "@/components/DexAutocomplete.vue";
import {AutoCompleteOnCompleteEvent} from "@/shims-prime-vue";
import {LookupDefinition, ValidationFieldDto} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {PropType} from "vue";

interface DocumentTableCellWithIndex extends DocumentTableCell {
	cellIndex: number;
}

interface UiTableRow {
	model: DocumentTableRow;
	cells: {
		[columnName: string]: DocumentTableCell;
	};
	pos: number;
}

interface UiTableCell {
	pos: number;
	name: string;
	element: any;
	column: DocumentTableColumn;
}

interface LookupDefinitionWithFilters extends LookupDefinition {
	lookupFieldFilters: FieldLookupFilter[];
}

interface DocumentTableColumnWithLookupFilter extends DocumentTableColumn{
	lookup?: LookupDefinitionWithFilters;
}

@Options({
	name: "ValidationTable",
	components: {DataTable, Column, Checkbox, InputText, Dropdown, MultiSelect, InputNumber, AutoComplete},
	props: {
		documentFields: Array,
		table: {
			type: Object,
			default: {},
		},
		hideButtons: {
			type: Boolean,
			default: false,
		},
		isReadOnlyMode: Boolean,
		documentId: Number,
		customTableActions: {
			type: Array as PropType<CustomValidationActionDto[]>,
			default: [],
		},
	},
	watch: {
		table: function(newTable: DocumentTable, oldTable: DocumentTable) {
			// Only if the table has changed, the columns should be reloaded on change of the table.
			// Otherwise only reload the data
			if (newTable.name === oldTable.name) {
				this.reloadData(false);
			}
			else {
				this.reloadData(true);
			}
		},
	},
	computed: {
		allVisibleColumns() {
			// Filter all visible Cells from Array
			return this.columns?.filter((column: any) => column.hidden === false)
		},
	},
	emits: [
		'onFocusField',
		'onChange',
		'allTableRefCells',
		'focusLastHeadField',
		'executeCustomTableAction',
	],
})
export default class ValidationTable extends Vue {

	/** Should the buttons of the table be hidden? */
	hideButtons!: boolean;

	/** Is the current mode of component readonly? */
	isReadOnlyMode!: boolean;

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Service for getting the master-data-lookups */
	masterDataService = ClientManager.getInstance().squeeze.masterData;
	documentService = ClientManager.getInstance().squeeze.document;

	table: DocumentTable = {
		id: 0,
		documentClassId: 0,
		fieldGroupId: 0,
		locatorId: 0,
		name: "",
		description: "",
		mandatory: false,
		readonly: false,
		hidden: false,
		forceValidation: false,
		externalName: "",
	}

	/** Array with all row-values */
	rows?: DocumentTableRow[] = [];

	/** Array with Table-Fields */
	columns?: DocumentTableColumn[] | null = null;

	/** Should the row be edited? */
	editingRows: UiTableRow[] = [];

	/** Array with the currently filtered values */
	filteredValues?: any = []

	/** Object with the current default-values  */
	defaultValues?: {[key: string]: any} = {};

	/** Array with Table-Cells  */
	tableCells: UiTableCell[] = [];

	/** Next Table-Cell by Enter or Tab */
	nextTableCell: HTMLElement | null = null;

	/** List of readonly-columns */
	noFocusColumns: DocumentTableColumn[] = [];

	/** Should the buttons of the table be hidden? */
	showButton: boolean = false;

	/** Currently selected rows */
	selectedRows: UiTableRow[] = [];

	/** Current documentId **/
	documentId!: number;

	/** List of all DocumentFields of document */
	documentFields!: ValidationFieldDto[];

	mounted() {
		this.reloadData();
		this.$emit("allTableRefCells", this.tableCells);
	}

	/**
	 * Reloads the Table-data
	 * @param reloadColumns
	 */
	reloadData(reloadColumns: boolean = true) {
		this.editingRows = [];

		if(!this.table || !this.table.columns) {
			return;
		}

		// If the colums are always reloading, there will be errors with focusing
		if (reloadColumns) {
			this.columns = this.table.columns;
			this.noFocusColumns = this.table.columns.filter(col => col.readonly || col.hidden);
		}

		const rows = this.table.rows;

		if (rows) {
			let cellIndex = 0;
			this.editingRows = rows.map((row: DocumentTableRow, index: number) => {
				const editingRow: UiTableRow = {
					model: Object.assign({}, row),
					pos: (index + 1),
					cells: {},
				};

				row.cells?.forEach((cell: any) => {
					const cellWithIndex = cell as DocumentTableCellWithIndex;
					if (!this.noFocusColumns.find(col => col.name === cell.columnName)) {
						cellWithIndex.cellIndex = cellIndex;
						cellIndex++;
					}
					editingRow.cells[cell.columnName!] = cellWithIndex;
				});

				return editingRow;
			});
		} else {
			// Create Reference to to Rows-Object if there is no Reference before
			this.table.rows = [];
		}

		/** Create default-values */
		if (this.columns) {
			this.columns.forEach(column => {
				if (this.defaultValues) {
					this.defaultValues["id"] = 0;
					if (column.name) {
						this.defaultValues[column.name] = {};
						this.defaultValues[column.name].value = "";
					}
				}
			})
		}
	}

	/**
	 * Event that is triggered when an item is selected on Autocomplete
	 * @param event Event of Autocomplete
	 * @param documentClassField Current documentClassField
	 * @param row
	 */
	onItemSelect(event: any, documentClassField:  DocumentTableColumn, row: UiTableRow, cellData: DocumentTableCell) {
		// Set the field-value to "value", not the label

		if (documentClassField && documentClassField.name) {
			row.cells[documentClassField.name].value = event.value.value;

			if(cellData.state === "FORCEAPPROVAL") {
				cellData.state  = "OK";
				this.$emit("onChange", this.table);
			}
		}
	}

	/**
	 * Event that is triggered when users make autocomplete-inputs
	 * @param event Event of Autocomplete
	 * @param field Current documentClassField
	 */
	async searchAutocomplete(event: AutoCompleteOnCompleteEvent, field: DocumentTableColumn, data: UiTableRow) {
		const rows = await this.documentService.getDocumentTableCellLookupValues(this.documentId, this.table.id!, field.id!, {
			documentFields: this.documentFields,
			fieldSearchValue: event.query,
			documentTableRow: data.model,
		})
		const resultColumns = field.lookup?.resultValueColumnIds;
		const alternatives = rows
			.map(row => {
				const completeValue = row;
				const value = row.displayColumnResults![field.lookup!.resultKeyColumnId];
				const label = resultColumns?.map(col => row.displayColumnResults![col!]!).join(" | "); // Map result columns to a single string to be displayed
				return {value, label, completeValue};
			})

		this.filteredValues[field.name!] = alternatives;
	}

	/**
	 * Creates a new row and adds it to the bottom if the table.
	 * @param currentRowIndex
	 */
	async createNewRow(currentRowIndex: number) {
		if (this.defaultValues && this.editingRows && this.table.rows) {
			const model = this.createEmptyRowModelObject();

			if (currentRowIndex) {
				// Allow inserting at any place in the table
				this.table.rows.splice(currentRowIndex, 0, model);
			} else {
				this.table.rows.push(model);
			}

			// Map table-rows to editing-table
			this.tableCells.splice(0);
			let cellIndex = 0;
			this.editingRows = this.table.rows.map((row: DocumentTableRow, index: number) => {
				const editingRow: UiTableRow = {
					model: Object.assign({}, row),
					pos: (index + 1),
					cells: {},
				};

				row.cells?.forEach((cell: DocumentTableCell) => {
					const cellWithIndex = cell as DocumentTableCellWithIndex;
					if (!this.noFocusColumns.find(col => col.name === cell.columnName)) {
						cellWithIndex.cellIndex = cellIndex;
						cellIndex++;
					}
					editingRow.cells[cell.columnName!] = cellWithIndex;
				});

				return editingRow;
			});
		}

		// validate the new rows
		await this.$emit("onChange");

		if (!currentRowIndex) {
			await this.scrollToTableBottom();
		} else {
			await this.$nextTick();
			const firstCellOfCopiedRow = this.tableCells.find(cell => cell.pos == currentRowIndex +1);
			this.activateCell(firstCellOfCopiedRow);
		}
	}

	/**
	 * Creates a copy row of the currentRow and adds it to the next line if the table.
	 * @param currentRowIndex
	 */
	async copyRow(currentRowIndex: number) {
		const currentRow: DocumentTableRow | undefined = this.table.rows?.find((row, index: number) => index === currentRowIndex - 1);

		if (currentRow && this.table.rows) {
			this.table.rows.splice(currentRowIndex, 0, currentRow);
			let cellIndex = 0;
			this.editingRows = this.table.rows.map((row: DocumentTableRow, index: number) => {
				const editingRow: UiTableRow = {
					model: Object.assign({}, row),
					pos: (index + 1),
					cells: {},
				};

				row.cells?.forEach((cell: any, indexCol) => {
					const cellWithIndex = cell as DocumentTableCellWithIndex;
					if (!this.noFocusColumns.find(col => col.name === cell.columnName)) {
						cellWithIndex.cellIndex = cellIndex;
						cellIndex++;
					}
					editingRow.cells[cell.columnName!] = cellWithIndex;
				});

				return editingRow;
			});
		}

		// validate the new rows
		await this.$emit("onChange");

		await this.$nextTick();
		const firstCellOfCopiedRow = this.tableCells.find(cell => cell.pos == currentRowIndex +1);
		this.activateCell(firstCellOfCopiedRow);
	}

	async scrollToTableBottom() {
		await this.$nextTick();

		const lengthOfAllTableRows = this.table.rows ? this.table.rows.length : 0;
		const firstCellOfLastRow = this.tableCells.find(cell => cell.pos == lengthOfAllTableRows);

		if (firstCellOfLastRow) {
			this.activateCell(firstCellOfLastRow);
		}
	}

	createEmptyRowModelObject(): DocumentTableRow {
		/** Create default-values */
		const row = {
			cells: [] as DocumentTableCell[],
			value: {
				value: '',
				page: 0,
				text: '',
				type: '',
				confidence: 0,
			},
		}

		if (!this.columns) {
			return row;
		}

		const cells: DocumentTableCell[] = [];
		this.columns.forEach(column => {
			const cell: DocumentTableCell = {
				columnName: column.name,
				value: '',
			}

			cells.push(cell);
		})
		row.cells = cells;

		return row;
	}

	/** Deletes all selected rows in table */
	deleteSelectedRows() {
		// delete all rows
		if (this.selectedRows.length === this.editingRows.length) {
			this.editingRows.splice(0);
			this.table.rows = [];
			this.selectedRows = [];
			return
		}

		this.selectedRows.forEach(selectedRow => {
			const selectedRowIndex = this.editingRows.findIndex(editingRow => editingRow.pos === selectedRow.pos);
			if (selectedRowIndex || selectedRowIndex === 0) {
				// delete the selected row
				this.editingRows.splice(selectedRowIndex, 1);

				this.selectedRows = [];
			}
		});

		// set new row position
		this.editingRows.forEach((row, index) => {
			row.pos = (index + 1);
		});

		this.table.rows = this.editingRows.map(row => {
			return {
				value: row.model.value,
				cells: row.model.cells,
			}
		});
	}

	/** Deletes a row row the table */
	async deleteRow(index: number) {
		this.editingRows.splice(index -1, 1);

		this.editingRows.forEach((row, index) => {
			row.pos = (index + 1);
		});

		this.table.rows = this.editingRows.map(row => {
			return {
				value: row.model.value,
				cells: row.model.cells,
			}
		});

		this.$emit("onChange", this.table);

		await this.$nextTick();

		if (this.editingRows.length >= index) {
			this.activateCell(this.findCell(index));
		} else if (this.editingRows.length) {
			this.activateCell(this.findCell(this.editingRows.length));
		}
	}

	/** Creates the default values that are used for new entries */
	createDefaultValues() {
		/** Create default-values */
		if (!this.columns) {
			return;
		}

		this.columns.forEach(column => {
			if (this.defaultValues) {
				this.defaultValues["id"] = 0;
				if (column.name) {
					this.defaultValues[column.name] = {};
					this.defaultValues[column.name].value = "";
				}
			}
		})
	}

	/**
	 * Triggered when a field is focused
	 * @param event
	 * @param cell
	 * @param row
	 */
	onFocusField(event: FocusEvent, cell: DocumentTableCell, row: UiTableRow, col: DocumentTableColumn) {
		if (event && event.target) {
			(event.target as HTMLInputElement).select();
		}

		// Remove Reference and create Document Field from cell-data (for error-messages)
		const tableField = JSON.parse(JSON.stringify(cell)) as DocumentField;
		tableField.description = col.description  + " (" + this.$t("Squeeze.Validation.General.Row") + " " + row.pos + ")";
		tableField.value = {
			value: cell.value,
			state: cell.state,
			errorText: cell.errorText,
		}

		// Set message for fields, TODO: change when backend does this
		if (cell.errorCode !== -1 && cell.errorCode != null) {
			tableField.value!.errorText = this.$t("Squeeze.Validation.ErrorCode." + cell.errorCode);
		} else if (cell.state?.toLowerCase() === "ok") {
			tableField.value!.errorText = this.$t("Squeeze.Validation.ErrorCode.0");
		}

		this.$emit("onFocusField", cell, row.model, row.pos, tableField);
	}

	findCell(row: number, col?: DocumentTableColumn) {
		if (col) {
			return this.tableCells.find(cell => cell.pos === row && cell.name === col.name);
		}
		return this.tableCells.find(cell => cell.pos === row);
	}

	navigateCells(event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn, cellData: DocumentTableCell) {
		let nextCell: UiTableCell | undefined;
		const target = event.target as HTMLElement;
		const parent = target.parentElement;

		if(cellData.state === "FORCEAPPROVAL") {
			cellData.state  = "OK";
			this.$emit("onChange", this.table);
		}

		if (event.key === 'Enter') {
			if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
				// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
				return;
			}
			nextCell = this.findCell((event.shiftKey) ? rowData.pos - 1 : rowData.pos + 1);

			if(!nextCell) {
				this.$emit("focusLastHeadField");
			} else {
				this.scrollToTableCell(nextCell, event.shiftKey);
			}
		} else if (event.key === 'Tab') {
			const index = this.tableCells.findIndex(f => f.name === column.name && f.pos === rowData.pos);

			if (event.shiftKey) {
				if (index > 0) {
					nextCell = this.tableCells[index - 1];
				}
			} else {
				if (index < this.tableCells.length - 1) {
					nextCell = this.tableCells[index + 1];
				}
			}

			if(!nextCell) {
				event.preventDefault();
				this.$emit("focusLastHeadField");
			} else {
				this.scrollToTableCell(nextCell, event.shiftKey);
			}
		} else if (event.key === 'ArrowDown') {
			if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
				// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
				return;
			}
			nextCell = this.findCell(rowData.pos + 1, column);
		} else if (event.key === 'ArrowUp') {
			if (target.tagName === 'INPUT' && parent?.tagName === 'SPAN' && parent?.getAttribute('aria-expanded') === 'true') {
				// Autocomplete offen -> Navigation im Autocomplete hat Vorrang
				return;
			}
			nextCell = this.findCell(rowData.pos - 1, column);
			if (nextCell) {
				this.scrollToTableCell(nextCell, true);
			}
		} else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
			const input = target as HTMLInputElement;
			const len = input.value ? input.value.length : 0;

			if (input.selectionStart !== input.selectionEnd) {
				return;
			}

			if (event.key === 'ArrowLeft' && input.selectionStart === 0 && input.selectionEnd === 0) {
				const index = this.tableCells.findIndex(f => f.name === column.name && f.pos === rowData.pos);
				if (index > 0) {
					nextCell = this.tableCells[index - 1];
					if (nextCell) {
						this.scrollToTableCell(nextCell, true);
					}
				}
			} else if (event.key === 'ArrowRight' && input.selectionStart === len && input.selectionEnd === len) {
				const index = this.tableCells.findIndex(f => f.name === column.name && f.pos === rowData.pos);
				if (index < this.tableCells.length - 1) {
					nextCell = this.tableCells[index + 1];
					if (nextCell) {
						this.scrollToTableCell(nextCell, false);
					}
				}
			}
		} else {
			return;
		}

		if (nextCell) {
			event.preventDefault();
			this.activateCell(nextCell);
		}
	}

	activateCell(cell?: UiTableCell) {
		if (cell && cell.element && cell.column && cell.column.lookup) {
			const $el = cell.element.$el;
			const el = (!cell.column.lookup.active || this.isReadOnlyMode ? $el : $el.firstElementChild) as HTMLInputElement;
			this.nextTableCell = el;

			el.focus({ preventScroll: false });
		}
	}

	/**
	 * Scroll to table cell
	 * @param nextCell
	 * @param isPrevElement is next cell a previous element?
	 */
	scrollToTableCell(nextCell: UiTableCell, isPrevElement: boolean) {
		// scroll to nextCellElement position when next row not completely visible
		const container = document.querySelector('.p-datatable-wrapper');
		const visibleRows: number[] = this.determineVisibleTableRows(container!);
		visibleRows.shift();
		if (!visibleRows.find((row: number) => row === (nextCell.pos -1))) {
			const nextCellElement = nextCell.element.$el;
			if (nextCellElement && container) {
				// check if nextCell on row previous or next element, when not then scrollIntoView
				if (!visibleRows.find((row: number) => row -1 === nextCell.pos -1 || row +1 === nextCell.pos -1)) {
					nextCellElement.scrollIntoView(false);
				} else {
					container.scrollTo({
						top: isPrevElement ? (container.scrollTop - nextCellElement.scrollHeight - 6) : (container.scrollTop + nextCellElement.scrollHeight + 6),
						left: 0,
						behavior: 'smooth',
					});
				}
			}
		}
	}

	/**
	 * Check if table row in viewport
	 * @param parent table wrapper
	 * @param row current row element
	 * @returns returns a boolean if row in parent bounding client rect
	 */
	isTableRowInViewport(parent: Element, row: Element) {
		const rowRect = row.getBoundingClientRect();
		const parRect = parent.getBoundingClientRect();
		return (
			rowRect.top >= parRect.top &&
			rowRect.left >= parRect.left &&
			rowRect.bottom <= parRect.bottom &&
			rowRect.right <= parRect.right
		);
	}

	/**
	 * Calculates what table rows are visible to the user
	 * @param container table wrapper
	 * @returns Indexes of table rows visible to the user
	 */
	determineVisibleTableRows(container: Element) {
		const rowElement = container.querySelectorAll('.p-selectable-row');
		const visibleRows: number[] = [];
		let i: number, j: number, currentRow: Element;

		for (i = 0, j = rowElement.length; i < j; i++) {
			currentRow = rowElement[i];

			if (this.isTableRowInViewport(container, currentRow)) {
				visibleRows.push(i);
			}
		}
		return visibleRows;
	}

	onFieldChange(event: FocusEvent) {
		// In AutoComplete box if entry in popup list is clicked
		// first the input's blur event is triggered.
		// But in this case no further action (Validation) should be taken
		// but only after real blur (leave) of input.
		if (event && event.target) {
			const node = event.target as Node;
			if (node.parentElement && node.parentElement.getAttribute('aria-expanded') === 'true') {
				return;
			}
		}

		this.$emit("onChange", this.table);
	}

	/**
	 * Set cell reference
	 * @param element
	 * @param rowData
	 * @param column
	 */
	async setCellReference(element: Element, rowData: UiTableRow, column: DocumentTableColumn) {
		if (column.readonly === true || column.hidden === true) {
			return
		}

		const cell = rowData.cells[column.name!] as DocumentTableCellWithIndex;
		this.tableCells[cell.cellIndex] = {
			pos: rowData.pos,
			name: column.name || '',
			element: element,
			column: column,
		};
	}

	/**
	 * Event that is triggered on click on autocomplete-fields
	 * @param {KeyboardEvent} event
	 * @param {UiTableRow} rowData
	 * @param {DocumentTableColumn} column
	 */
	onClickAutocomplete(event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn) {
		// Trigger onDropDownClick-Event, so the alternatives are shown when there are alternatives. Otherwise do nothing
		if (column.lookup?.active === true && column.lookup.minInputLength === 0) {
			const cell = this.findCell(rowData.pos, column);

			// Open Dropdown if there is one
			if (cell && cell.element && cell.element.onDropdownClick) {
				cell.element.onDropdownClick(event, column);
			}
		}
	}

	/**
	 * Triggered when a Dropdown is clicked. Currently this triggered by clicking the Autocomplete-Field.
	 * @param event
	 * @param field
	 */
	onClickDropdown(event: AutoCompleteOnCompleteEvent, field: DocumentTableColumnWithLookupFilter) {
		event.query = "";
	}

	/**
	 * Triggered when the rows are reordered
	 * @param event
	 */
	onRowReorder(event: any) {
		if (!event.value) {
			return
		}

		this.table.rows = event.value.map((row: UiTableRow) => {
			return {
				value: row.model.value,
				cells: row.model.cells,
			}
		});

		this.$emit("onChange", this.table);
	}

	/**
	 * Execute the custom action in table
	 * @param actionId
	 */
	executeCustomTableAction(actionId: string) {
		this.$emit("executeCustomTableAction", actionId);
	}

	/**
	 * Triggered on keydown
	 * @param {KeyboardEvent} event
	 * @param {UiTableRow} rowData
	 * @param {DocumentTableColumn} column
	 */
	onKeydown(event: KeyboardEvent, rowData: UiTableRow, column: DocumentTableColumn) {
		// if key ctrl and arrow down pressed, then open the autocomplete popup
		if (event.code === 'ArrowDown' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
			this.onClickAutocomplete(event, rowData, column);
		}
	}

}

</script>
<style lang="scss" scoped>

.readonly {
	color: #2b2b2b;
	opacity: 0.5;
}

// Input fields inside table cell
.input-table {
	width: 100%;
	border-radius: 0;
	border: none;
	padding-left: 1rem;
	background: transparent;

	// Input field focussed
	&:enabled:focus {
		box-shadow: inset 0 0 0  0.1rem var(--dex-primary-color);
	}
}

// Table cell by autocomplete
::v-deep(input) {
	border: none!important;
	width: 100%;
	border-radius: 0;
	background: transparent;

	&:enabled:focus {
		box-shadow: inset 0 0 0  0.1rem var(--dex-primary-color);
	}
}

.input-autocomplete {
	padding-left: 0;
}

// Table Header
::v-deep(th, .table-header-amount) {
	background-color: #e3ebee !important;
	border-bottom-color: #e4e4e4 !important;
}

// Table cell
::v-deep(.p-cell-editing) {
	padding: 0!important;
}

// Table cell title
::v-deep(.p-column-title) {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	color: black;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Table cell editable
::v-deep(.p-editable-column.p-cell-editing) {
	padding: 0!important;
}

// Table first cell editable
::v-deep(.p-editable-column.p-cell-editing:first-child) {
	max-width: 4rem!important;
	justify-content: center;
}

// Table last cell editable
::v-deep(.p-editable-column.p-cell-editing:last-child) {
	max-width: 8rem!important;
}

// Span inside table cell of column "id"
::v-deep(.column-id) {
	padding-left: 1rem!important;
}

// Table cell state error
.table-cell-border-by-error {
	box-shadow: inset 0 0 0  0.1rem var(--error-color);
}

// Table cell state force
.table-cell-border-by-force {
	box-shadow: inset 0 0 0  0.1rem var(--validationfield-forceapproval-color);
}

// Table cell state ok
.table-cell-border-by-ok {
	border: none;
}

// Hover over each row
/*
::v-deep(tr.p-selectable-row:hover) {
	background-color: var(--dex-primary-color)!important;
	color: var(--dex-text-color);
}
*/

// Delete button at the end of each row
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height:3rem;
}

.table-cell-amount {
	text-align: right;
}

::v-deep(th.table-header-amount) {
	justify-content: flex-end;
}

::v-deep(.p-datatable-footer) {
	position: absolute;
	bottom: 0.5rem;
	right: 1rem;
	background: transparent!important;
	border-width: 0!important;
}

::v-deep(.p-datatable-thead tr th .p-column-header-content) {
	overflow: hidden;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown) {
	background: #4894ac4d;
}

::v-deep(.p-autocomplete .p-autocomplete-dropdown span.p-button-icon) {
	color: var(--primary-color);
}

// speed dial
@keyframes pop-in {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes pop-out {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

.speed-dial {
	text-align: center;
}

.dials {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin-bottom: 0.25rem;
	}

	li.pop-in {
		animation: pop-in .3s ease both;
	}

	li.pop-out {
		animation: pop-out .3s ease both;
	}
}

::v-deep(.p-datatable-footer) {
	z-index: 1000;
	margin-right: 2rem;
}

.validation-field {
	padding: 0.5629rem 0.5629rem !important;
}

::v-deep(input.p-autocomplete-input.p-inputtext.p-component.validation-field.p-autocomplete-dd-input) {
	padding: 0.5629rem 0.5629rem !important;
}

::v-deep(button.p-button.p-component.p-button-icon-only.p-autocomplete-dropdown) {
	width: 2.5rem;
	padding: 0.5629rem 0.5629rem !important;
}

</style>
