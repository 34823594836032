<template>
	<Dialog v-model:visible="entryDialog"
			:style="{width: width}"
			:header="headerText"
			:modal="true"
			@hide="closeDialog()"
			:position="position"
			:maximizable="maximizable"
			:closeOnEscape="!abortKeydownEvent"
	>
		<slot name="content"></slot>
		<Message v-if="message !== ''" :severity="messageType">{{message}}</Message>
		<template #footer v-if="showKeepDialogOpen || showAbortButton || showSaveButton">
				<div class="p-field-checkbox p-justify-end">
					<Checkbox v-if="showKeepDialogOpen" id="keepDialogOpen" name="keepDialogOpen" :binary="true" v-model="keepDialogOpen" />
					<label class="p-mr-2" v-if="showKeepDialogOpen" for="keepDialogOpen">{{keepDialogOpenText || $t('Squeeze.General.KeepDialogOpen') }}</label>
					<Button  v-if="showAbortButton" :label="abortButtonText || $t('Squeeze.General.Abort')" icon="mdi mdi-close" class="p-button-text" @click="closeDialog"/>
					<Button  v-if="showSaveButton" :loading="loading" :label="saveButtonText || $t('Squeeze.General.Save')" icon="mdi mdi-check" class="p-button-text" @click="saveEntry" />
				</div>
		</template>
	</Dialog>
</template>

<script lang="ts">

import Dialog from "primevue/dialog";
import Message from "primevue/message";
import  {Options, Vue} from "vue-class-component";
import Checkbox from "primevue/checkbox";

@Options({
	components: {
		Dialog, Message, Checkbox,
	},
	props: {
		width: {
			type: String,
			default: '40rem',
		},
		message: {
			type: String,
			default: '',
		},
		messageType: {
			type: String,
			default: 'success',
		},
		position: {
			type: String,
			default: "center",
		},
		show: {
			type: Boolean,
			default: false,
		},
		headerText: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showKeepDialogOpen: {
			type: Boolean,
			default: false,
		},
		showAbortButton: {
			type: Boolean,
			default: true,
		},
		showSaveButton: {
			type: Boolean,
			default: true,
		},
		saveButtonText: {
			type: String,
			default: undefined,
		},
		abortButtonText: {
			type: String,
			default: undefined,
		},
		keepDialogOpenText: {
			type: String,
			default: undefined,
		},
		maximizable: {
			type: Boolean,
			default: false,
		},
		abortKeydownEvent: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		show: function() {
			// Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent
			this.entryDialog = this.show;

			// not in mounted because mounted is trigger to early
			if(this.show) {
				// Setup keyboard events that are active for the whole view
				document.addEventListener("keydown", this.onKeydown);
			} else {
				document.removeEventListener("keydown", this.onKeydown)
			}
		},
	},
	emits: ["onClose", "onConfirm"],
})

export default class EntryDialog extends Vue {

	/** Prop for Message */
	message!: string

	/** Prop for message-type */
	messageType!: string
	/** Prop from Show-Dialog */
	show!: boolean;

	/** Width of the Edit-Dialog */
	width!: string

	/** Prop for header-text */
	headerText!: string

	/** Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent */
	entryDialog = this.show;

	/** Position to show the Dialog (default: top) */
	position!: string

	/** Should the save-button show loading? */
	loading!: boolean

	/** Should the possibility to keep the dialog open be shown? (default: false) */
	showKeepDialogOpen!: boolean;

	/** Should the dialog be kept open? */
	keepDialogOpen = false;

	/** Optional Text for the save button */
	saveButtonText!: string;

	/** Optional maximizable of dialog */
	maximizable!: boolean;

	/** Should the keydown event be aborted?
	 * Optional Parameter
	 * Useful when several dialogs are open at the same time
	 */
	abortKeydownEvent!: boolean;

	/**
	 * Emit onClose-Event to parent, when "no" or "close" is clicked
	 */
	closeDialog() {
		document.removeEventListener("keydown", this.onKeydown);
		this.entryDialog = false;
		this.$emit("onClose");
	}

	/**
	 * Emit the "onConfirm-Event to parent if "yes" is clicked
	 */
	saveEntry() {
		// Is the option is not shown, this dialog should never be kept open
		if (!this.showKeepDialogOpen) {
			this.keepDialogOpen = false;
		}

		// Only abort the keydown event when more than one dialog is open
		if (this.abortKeydownEvent) {
			return;
		}

		this.$emit("onConfirm", this.keepDialogOpen);
	}

	/** If key escape, ctrl and S or enter pressed, then close or save the dialog entry */
	onKeydown(event: any) {
		// Only do something if the dialog is visible
		if(!this.entryDialog) {
			return;
		}

		// by key escape close the dialog, when
		// If more than one dialog was displayed, then check if it does not contain an input field
		// especially for the case during import
		if (event.key === "Escape" && !this.abortKeydownEvent) {
			this.closeDialog();
		}

		// check if the event parent element is a dropdown element
		const isDropdownElement = event.target.parentElement.parentElement?.classList.contains('p-dropdown');

		// by key STRG + s or enter saved
		if (event.code === "KeyS" && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)
			|| event.key === 'Enter' && !isDropdownElement) {
			event.preventDefault();
			this.saveEntry();
		}
	}

	/** Is triggered before a Component unmounts. Available since Vue 3.0 */
	beforeUnmount() {
		document.removeEventListener("keydown", this.onKeydown)
	}

}
</script>

<style scoped>
</style>
