
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentClass} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {technicalValidation} from "@/util/ValidationHelper";

export default defineComponent({
	name: "DocumentClassForm",
	components: {
		InputText,
	},
	props: {
		documentClass: {
			type: Object as PropType<DocumentClass>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<DocumentClass>({name: '', description: ''});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.documentClass);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.documentClass, () => {
			Object.assign(value, props.documentClass);
		})

		/** Triggered when keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
