<template>
	<DataTable
		:value="regexes"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		editMode="cell"
		@cell-edit-init="onCellEdit"
		@cell-edit-complete="onCellComplete"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.Tabs.Regex') }) }}
		</template>

		<!--<Column field="id" header="ID" :sortable="false" class="id-column" />-->
		<Column field="regEx" :header="$t('Squeeze.Locators.Tabs.Regex')" style="min-width: 10rem;" class="regex-column">
			<template #body="regex">
				<Textarea v-model="regex.data.regEx" :autoResize="true" rows="1" :cols="showSingleLinedForm ? '50' : '100'" />
			</template>
		</Column>
		<Column style="min-width: 16rem; max-width: 16rem; justify-content: end;">
			<template #body="regex">
				<small v-if="isInvalidRegex(regex.data)" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.Regex')}) }}
				</small>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="regex">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(regex.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(regex.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, nextTick, onMounted, PropType, reactive} from "vue";
import {LocatorRegEx} from "@dex/squeeze-client-ts";
import Textarea from 'primevue/textarea';
import {helpers} from "@vuelidate/validators";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "Regex",
	components: {
		DataTable, Column, Textarea,
	},
	props: {
		regexes: {
			type: Array as PropType<LocatorRegEx>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", 'onChangeRegex',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorRegEx>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorRegEx) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {regEx: '', id: 0, locatorId: 0})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: LocatorRegEx) => {
			emit("onEntrySelect", row)
		}

		/** Check the regex is invalid */
		const isInvalidRegex = (row: LocatorRegEx) => {
			return !helpers.req(row.regEx)
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: any) => {
			if (event.type === 'outside') {
				emit("onChangeRegex", event.data, isInvalidRegex(event.data));
			} else if (entryDataCheck.regEx !== event.data.regEx) {
				emit("onChangeRegex", event.data, isInvalidRegex(event.data));
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, entryDataCheck, DataTable, Column, openDeleteDialog, onNewEntryClick, openEditDialog, isInvalidRegex, onCellEdit, onCellComplete, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(textarea.p-inputtextarea.p-inputtext.p-component.p-filled.p-inputtextarea-resizable) {
	margin-bottom: -4px;
}

::v-deep(td.regex-column.p-editable-column.p-cell-editing) {
	padding: 0;
}

::v-deep(textarea.p-inputtextarea.p-inputtext.p-component.p-filled.p-inputtextarea-resizable) {
	border: none;
	border-radius: 0;
}

::v-deep(.p-inputtextarea) {
	overflow-wrap: anywhere;
}

</style>
