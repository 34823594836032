<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<!--div class="p-field p-col-6">
			<label for="fileType">{{ $t('Squeeze.System.FileType') }}</label>
			<Dropdown
				id="fileType"
				v-model="value.fileType"
				:options="fileTypes"
				:filter="false"
				optionValue="id"
				optionLabel="description"
				:showClear="false"
			/>
		</div>
		<div class="p-field p-col-6 p-grid">
			<div class="p-field p-col-11">
				<label for="searchValue">{{ $t('Squeeze.System.CsvSeparator') }} *</label>
				<InputText id="searchValue" v-model="value.csvSeparator" maxLength="3" disabled />
				<small v-if="v$.csvSeparator.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.System.CsvSeparator')}) }}
				</small>
			</div>
			<div class="p-field p-col-1 p-as-end">
				<Button id="startDownload" v-tooltip.top="$t('Squeeze.System.StartDownload')" icon="mdi mdi-file-download-outline" @click="startFileDownload" />
			</div>
		</div-->
		<div class="p-field p-col-12">
			<Button id="startDownload" :label="$t('Squeeze.System.StartDownload')" icon="mdi mdi-file-download-outline" @click="startFileDownload" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, reactive} from 'vue';
/*import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";*/
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "SystemCsvTranslationExport",
	components: {},
	props: {
		fileTypes: {
			type: Array,
		},
	},
	emits: ['startTranslationFileDownload'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<any>({csvSeparator: ';', fileType: 'csv', filePath: ''});

		/** Start download/export of translation file */
		const startFileDownload = () => {
			emit('startTranslationFileDownload', value.fileType);
		}

		return {
			t,
			value,
			startFileDownload,
		}
	},
})
</script>

<style scoped>

</style>
