
import {Options, Vue} from 'vue-class-component';
import router from "@/router";
import {RouteLocationRaw} from 'vue-router';

import Skeleton from 'primevue/skeleton';
import {FilterMatchMode} from "primevue/api";

import QueueTimeline from '@/apps/squeeze/components/QueueTimeline.vue';
import DocumentList from '@/apps/squeeze/components/DocumentList.vue';
import QueueEntryList from "@/apps/squeeze/components/QueueEntryList.vue";

import {
	Document,
	DocumentSearchRequestDto,
	FieldValueFilterDto,
	PaginationDto,
	QueueEntry,
	WorkflowContextFilterDto
} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

import {FieldColumn} from "@/apps/squeeze/interfaces/FieldColumn";
import {ListDocument, ListDocumentField} from "@/apps/squeeze/interfaces/ListDocument";
import {Filter, DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";

@Options({
	name: "ValidationDocumentList",
	components: {
		QueueEntryList,
		QueueTimeline,
		DocumentList,
		Skeleton,
	},
	props: {
		documentClassId: Number,
	},
	emits: ["onRowSelect"],
})
/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default class ValidationDocumentList extends Vue {

	/** ID of document class */
	documentClassId!: number;

	/** Name of document class */
	documentClassName = "";

	/** Search API endpoint */
	searchApi = ClientManager.getInstance().squeeze.search;

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	/** Documents to list */
	documents: ListDocument[] = [];

	/** List of fields as columns array for datatable */
	fieldColumns: FieldColumn[] = [];

	/** Indicates end of request */
	loaded = false;

	/** Indicates end of mounted */
	initialLoaded = false;

	/** Indicates that queue entries are loaded instead of searching for documents via the ES search endpoint */
	usingQueueEntryFallback = false;

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** DocumentClass API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** List of all QueueEntries in QueueStep Validation */
	queueEntries: QueueEntry[] = [];

	/** Currently-Selected row */
	selection = null

	/** Workflow Filter Standard for validation */
	standardWorkflowFilter = [
		{
			fieldName: "queueStep",
			comp: "eq",
			searchValue: "validation",
			fieldType: "text",
		},
	]

	/** Info / Options of pagination */
	paginationOption: PaginationDto = {
		pageSize: 25,
		page: 0,
		total: 0,
	};

	/** Filters of Documentlist */
	filters: DocumentFilterObject = {
		"id": {value: null, matchMode: FilterMatchMode.EQUALS},
		"queueStatus": {value: null, matchMode: FilterMatchMode.EQUALS},
	};

	/** Search request for document search filtering */
	searchRequest: DocumentSearchRequestDto = {};

	/** On view ready */
	async mounted() {
		// Initialize pagination info / options
		this.paginationOption.pageSize = 25;
		this.paginationOption.page = 0;
		this.paginationOption.total = 0;

		const wfQuery = this.$route.query.wf;
		if(wfQuery && typeof wfQuery === "string") {
			const wfValueFilters = JSON.parse(decodeURI(wfQuery));
			if (wfValueFilters) {
				this.searchRequest.workflowContextFilters = wfValueFilters;
			}
		} else {
			this.searchRequest.workflowContextFilters = this.standardWorkflowFilter;
		}
		const fieldQuery = this.$route.query.f;
		if(fieldQuery && typeof fieldQuery === "string") {
			const fieldValueFilters = JSON.parse(decodeURI(fieldQuery));
			if(fieldValueFilters) {
				this.searchRequest.fieldFilters = fieldValueFilters;
			}
		}

		await this.reloadData();
		this.initialLoaded = true;
	}

	/** Triggered when a row is selected */
	onRowSelect(document: Document) {
		const route: RouteLocationRaw = {
			name: 'ValidateEntry',
			params: {
				documentId: document.id as any,
				searchRequest: JSON.stringify(this.searchRequest),
				pagination: JSON.stringify(this.paginationOption),
			}};
		router.push(route);
	}

	/** Triggered when the next page should be loaded */
	onPage(event: any) {
		// Set current page
		this.paginationOption.page = event.page;
		this.paginationOption.pageSize = event.rows;
		this.reloadData();
	}

	/** Encode query element for router */
	encodeFilterQuery() {
		const query: {[key: string]: string} = {};
		if (this.searchRequest.workflowContextFilters && this.searchRequest.workflowContextFilters.length > 0) {
			query.wf = encodeURI(JSON.stringify(this.searchRequest.workflowContextFilters));
		}

		if (this.searchRequest.fieldFilters && this.searchRequest.fieldFilters.length > 0) {
			query.f = encodeURI(JSON.stringify(this.searchRequest.fieldFilters));
		}

		return query;
	}
	/** Sets available filters of search request for Vue to update */
	setDataTableFilter() {
		const searchRequestFilters: {[type: string]: FieldValueFilterDto[] | WorkflowContextFilterDto[] | undefined} = {
			"fields": this.searchRequest.fieldFilters,
			"wf": this.searchRequest.workflowContextFilters,
		};

		for(const filterType in searchRequestFilters) {
			const isField = filterType === "fields";
			const filters = searchRequestFilters[filterType];

			if(filters) {
				// TODO: TELL TYPESCRIPT TYPE OF FILTER
				filters.forEach((filter: any) => {
					const id = isField ? '' + filter.fieldId : filter.fieldName;
					const listFilter = {value: filter.searchValue, matchMode: FilterMatchMode.CONTAINS};

					if(!isField && filter.fieldName === "queueStatus") {
						listFilter.value = listFilter.value.toUpperCase();
					}

					switch(filter.comp) {
					case "eq": listFilter.matchMode = FilterMatchMode.EQUALS; break;
					case "bw": listFilter.matchMode = FilterMatchMode.STARTS_WITH; break;
					case "ew": listFilter.matchMode = FilterMatchMode.ENDS_WITH; break;
					default: break;
					}

					this.filters[id] = listFilter;
				});
			}
		}
	}

	/** Triggered when a filter has been entered */
	async onFilter(tableFilters: DocumentFilterObject) {
		this.searchRequest.fulltextFilters = [];
		this.searchRequest.fieldFilters = [];
		this.searchRequest.workflowContextFilters = this.standardWorkflowFilter;

		this.filters = tableFilters;
		const fieldFilters: FieldValueFilterDto[] = [];
		const workflowContextFilter: WorkflowContextFilterDto[] = [];

		for(const id in tableFilters) {
			const filterId = Number(id);
			const isField = !isNaN(filterId);

			const filter: Filter = tableFilters[id];
			if(filter && filter.value != null && filter.value != "") {
				this.paginationOption.page = 0;

				let comp = "";
				switch(filter.matchMode) {
				case FilterMatchMode.CONTAINS: comp = "like"; break;
				case FilterMatchMode.STARTS_WITH: comp = "bw"; break;
				case FilterMatchMode.ENDS_WITH: comp = "ew"; break;
				case FilterMatchMode.EQUALS: comp = "eq"; break;
				default: ToastManager.showInfo(this.$toast, this.$t('Toasts.Title.Info'), this.$t('Squeeze.Queue.Filter.Unknown')); break;
				}

				if(comp != "") {
					if(!isField) {
						// WF CONTEXT
						workflowContextFilter.push({
							fieldName: id,
							searchValue: filter.value.toLowerCase(),
							comp: comp,
							fieldType: "text",
						});
					} else {
						// FIELD
						const fieldType = this.fieldColumns.filter((column) => {
							return column.id && column.id === filterId;
						});

						if(fieldType.length > 0) {
							fieldFilters.push({
								fieldId: filterId,
								searchValue: filter.value,
								comp: comp as any,
								fieldType: fieldType[0].type.toLowerCase(),
							})
						}
					}
				}
			}
		}

		this.searchRequest.fieldFilters = fieldFilters;
		this.searchRequest.workflowContextFilters = workflowContextFilter;

		// SET FILTER QUERY
		const route: RouteLocationRaw = { name: 'DocumentList', params: { documentClassId: this.documentClassId}};
		route.query =  this.encodeFilterQuery();
		await this.$router.replace(route);
		await this.reloadData();
	}

	/** Reloads QueueStepEntries for step Validation */
	async reloadData() {
		this.loaded = false;

		// load fields of the documentClasses
		await this.documentClassApi.getAllDocumentClassFields(this.documentClassId)
			.then(fields => {
				fields.forEach(field => {
					if (field.hidden !== true) {
						this.fieldColumns.push({
							id: field.id,
							name: field.name as string,
							header: field.description as string,
							type: field.dataType as string,
						});
						this.filters['' + field.id] = {value: null, matchMode: FilterMatchMode.CONTAINS};
					}
				});
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			})

		// Load documents
		try {
			await this.loadDocuments();
			this.usingQueueEntryFallback = false;
		} catch (err) {
			ToastManager.showInfo(this.$toast, this.$t('Toasts.Title.Info'), this.$t('Squeeze.Queue.QueueEntryFallback.Info'));
			// todo: Send to sentry
			try {
				this.usingQueueEntryFallback = true;
				await this.loadQueueEntries();
			} catch (err) {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(err));
			}
		}

		this.loaded = true;
	}

	/** Loads all queue entries of a specified document class in queue step "Validation" */
	private async loadQueueEntries() {
		const result = await this.queueApi.getQueueStepEntries("Validation", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.paginationOption.page, this.paginationOption.pageSize as number);
		const entries = result.elements;
		this.paginationOption = result.pagination;

		if (entries && entries.length > 0) {
			this.queueEntries = entries;
		} else {
			throw new Error("Missing entries in documents class!")
		}
	}
	/** @deprecated JUST FOR PRESENTATION PURPOSE */
	private sortFieldColumnsById(colA: FieldColumn, colB: FieldColumn) {
		if(colA && colB) {
			if((colA.id && colB.id) && (colA.id < colB.id)) {
				return -1;
			}
			if((colA.id && colB.id) && (colA.id > colB.id)) {
				return 1;
			}
		}
		return 0;
	}

	/** Loads all documents of a specified document class in queue step "Validation" */
	private async loadDocuments() {
		const result = await this.documentApi.searchDocuments(this.documentClassId, this.searchRequest, 'db', this.paginationOption.page, this.paginationOption.pageSize, ["id:asc"]);
		const documents = result.elements as Document[];
		const pagination = result.pagination;
		if(pagination != undefined) this.paginationOption = pagination;
		this.documents = [];

		// Add documents to list
		documents.forEach((document) => {
			const listDocument: ListDocument = {
				id: document.id as any,
				createdAt: document.createdAt,
				//batchClassId: document.batchClassId as any,
				//documentClassId: document.documentClassId as any,
				workflowContext: document.workflowContext,
				fields: {},
				exports: [],
			};

			// Add fields to document
			const fields = document.fields;
			fields.forEach((field) => {
				if(field.id) {
					const listField: ListDocumentField = {
						id: field.id,
						name: field.name as any,
						value: (field.value && field.value.value) ? field.value.value : "",
						dataType: field.dataType as any,
					};
					listDocument.fields[field.id] = listField;
				}
			});

			this.documents.push(listDocument);
		});
		this.fieldColumns.sort(this.sortFieldColumnsById);
		this.setDataTableFilter();
	}

}
