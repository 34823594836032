
import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import {DocumentField, LocatorSetting} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

@Options({
	name: 'LocatorAttributes',
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** All available Locators */
		locatorSettings: {
			type: Array,
			default: [],
		},
		/** All Valid Settings */
		supportedLocatorSettings: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "openDelete"],
})

export default class LocatorAttributes extends Vue  {

	/** List of all locators **/
	locatorSettings!: LocatorSetting[];

	/** List of valid-settings */
	supportedLocatorSettings!: LocatorSetting[];

	/** Currently active Locator-ID */
	locatorId!: number;

	/** Should the loading be shown in the table? */
	loading!: boolean;

	/** Vuex Store */
	store = useSqueezeStore();

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current table list pagination */
	tableListPagination: TableListSettings = {};

	/** Data of selected row */
	rowData = {};

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentField} data Data to edit
	 */
	openEditDialog(data: DocumentField) {
		this.rowData = {...data};
		this.$emit("openEntryDialog", this.rowData, this.locatorId)
	}

	/**
	 * Opens the Delete Dialog
	 * @param {LocatorSettingDto} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: LocatorSetting) {
		this.rowData = {...data};
		this.$emit("openDelete", this.rowData)
	}

	getSettingsDescription(settingName: string) {
		if (!this.supportedLocatorSettings) {
			return settingName
		}

		const locatorType = this.supportedLocatorSettings.find((i) => {
			return i.setting === settingName
		});

		if (!locatorType) {
			return settingName
		}

		return locatorType.setting
	}

	/** on page mounted */
	mounted() {
		this.tableListPagination.pageSize = TableSettings.getTableListPagination(this.store, this.$route.name);
	}

	/** Triggered when page is changed */
	onChangePage(event: { page: number; rows: number }) {
		this.tableListPagination.pageSize = event.rows;
		TableSettings.saveTableListPagination(this.$t, this.$toast, this.store, this.userApi, this.$route.name, event.rows);
	}

}
