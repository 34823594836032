<template>
	<Dialog
		:header="headerText"
		v-model:visible="showDialogComponent"
		:breakpoints="{'960px': '80vw', '640px': '100vw'}"
		@hide="onCloseDialog"
		:modal="true"
		:closable="false"
		position="top"
		:closeOnEscape="false"
	>
		{{$t('Squeeze.Validation.Dialogs.Locked.LockedByLongText')}}<br/><br/>
		<div v-if="isUserAdmin">
			{{$t('Squeeze.Validation.Dialogs.Locked.NextDocumentOrList')}}<br/><br/>
			{{$t('Squeeze.Validation.Dialogs.Locked.TakeOverMessage')}}
		</div>
		<div v-else>
			{{$t('Squeeze.Validation.Dialogs.Locked.ShowDocumentOrList')}}<br/><br/>
			{{$t('Squeeze.Validation.Dialogs.Locked.NoEditDocument')}}
		</div>

		<template #footer>
			<Button v-if="isUserAdmin"
					:label="$t('Squeeze.Validation.Dialogs.Locked.UnlockDocument')"
					icon="mdi mdi-sort-variant-lock-open"
					class="p-button-text"
					@click="$emit('unlockDocument')" />

			<Button :label="$t('Squeeze.Validation.Dialogs.Locked.ShowOnly')"
					icon="mdi mdi-eye-outline"
					class="p-button-text"
					@click="$emit('showOnly')" />

			<Button :label="$t('Squeeze.Validation.Dialogs.Locked.ToOverview')"
					icon="mdi mdi-skip-backward"
					class="p-button-text"
					@click="$emit('backToOverview')" />
		</template>
	</Dialog>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import {ClientManager} from "@/singletons/ClientManager";
import {useSqueezeStore} from "@/apps/squeeze/store";

@Options({
	name: "DialogLocked",
	components: {
		Dialog,
		Dropdown,
		InputText,
		Card,
	},
	props: {
		showDialog: Boolean,
		lockingUserId: {
			type: Number,
			default: null,
		},
	},
	watch: {
		showDialog: function() {
			this.showDialogComponent = this.showDialog;
		},
		lockingUserId: function() {
			this.reloadUserData();
		},
	},
	emits: ["closeDialog", "unlockDocument", "backToOverview", "showOnly"],
})
export default class DialogLocked extends Vue {

	/** Should the dialog be shown? */
	showDialog!: boolean;

	/** Id of the blocking user */
	lockingUserId!: number;

	/** V-Model for dialog show */
	showDialogComponent = false;

	/** Text of the header */
	headerText = "";

	/** Name of the current user */
	userName = "mir!";

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Is user a admin? */
	isUserAdmin: boolean = false;

	mounted() {
		this.headerText = this.$t('Squeeze.Validation.Dialogs.Locked.DocumentLocked')
		if(this.store.state.isAdminUser) {
			this.isUserAdmin = true;
		} else {
			this.isUserAdmin = false;
		}
	}

	onCloseDialog() {
		this.$emit("closeDialog")
	}

	reloadUserData() {
		if (this.lockingUserId) {
			this.userApi.getUserById(this.lockingUserId).then(data => {
				this.userName = data.firstName + " " + data.lastName;
				this.headerText = this.$t('Squeeze.Validation.Dialogs.Locked.DocumentLockedBy', { userName: this.userName })
			})
		}
	}

}

</script>
