<template>
	<TreeTable
		:value="nodes"
		:loading="loading"
		:autoLayout="true"
		columnResizeMode="fit"
		:scrollable="true"
		scrollHeight="flex"
		:filters="filters"
		:filterMode="filterMode.value"
		:expandedKeys="expandedRows"
	>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.MasterData.Data') }) }}
		</template>

		<Column field="name" :header="$t('Squeeze.System.Name')" expander style="min-width: 7rem;">
			<template #body="slotProps">
				<Skeleton v-if="showSkeleton"></Skeleton>
				<span v-else>{{slotProps.node.data.name}}</span>
			</template>
			<template #filter>
				<InputText v-model="filters['name']" type="text" class="p-column-filter filter-field" style="min-width: 100%;"/>
			</template>
		</Column>
		<Column field="type" :header="$t('Squeeze.System.Type')" style="min-width: 7rem;">
			<template #body="slotProps">
				<Skeleton v-if="showSkeleton"></Skeleton>
				<span v-else>{{slotProps.node.data.type}}</span>
			</template>
			<template #filter>
				<InputText v-model="filters['type']" type="text" class="p-column-filter filter-field" style="min-width: 100%;"/>
			</template>
		</Column>
		<Column field="message" :header="$t('PrimeVue.Message')" style="min-width: 7rem;">
			<template #body="slotProps">
				<Skeleton v-if="showSkeleton"></Skeleton>
				<div v-else class="column-cell" style="color: var(--error-color);" v-tooltip.bottom="slotProps.node.data.message">
					{{ slotProps.node.data.message }}
				</div>
			</template>
			<template #filter>
				<InputText v-model="filters['message']" type="text" class="p-column-filter filter-field" style="min-width: 100%;"/>
			</template>
		</Column>
		<Column field="status" :header="$t('Squeeze.System.Status')" style="min-width: 5rem; max-width: 7rem;">
			<template #body="slotProps">
				<Skeleton v-if="showSkeleton"></Skeleton>
				<div v-else class="column-cell">
					<Tag v-if="slotProps.node.data.status === 'success'" severity="success" :value="$t('Squeeze.General.Success')"/>
					<Tag v-if="slotProps.node.data.status === 'info'" severity="info" :value="$t('Squeeze.General.Info')"/>
					<Tag v-if="slotProps.node.data.status === 'warning'" severity="warning" :value="$t('Squeeze.General.Warning')"/>
					<Tag v-if="slotProps.node.data.status === 'error'" severity="danger" :value="$t('Squeeze.General.Error')"/>
				</div>
			</template>
			<template #filter>
				<Dropdown v-model="filters['status']" :options="statusTypes" class="p-column-filter filter-field" placeholder="" style="height: 2.111rem; min-width: 100%;" :showClear="true">
					<template #value="slotProps">
						<span v-if="slotProps.value">{{ $t("Squeeze.General." + slotProps.value.charAt(0).toUpperCase() + slotProps.value.slice(1)) }}</span>
						<span v-else>{{ slotProps.placeholder }}</span>
					</template>
					<template #option="slotProps">
						<span :class="'status-badge ' + slotProps.option">{{ $t("Squeeze.General." + slotProps.option.charAt(0).toUpperCase() + slotProps.option.slice(1)) }}</span>
					</template>
				</Dropdown>
			</template>
		</Column>
		<Column class="buttonColumn" style="min-width: 8rem; max-width: 8rem; justify-content: end;">
			<template #body="slotProps">
				<Skeleton v-if="showSkeleton"></Skeleton>
				<template v-else-if="!showSkeleton && slotProps.node.data.status && slotProps.node.data.message">
					<Button :disabled="slotProps.node.data.status === 'success'" icon="mdi mdi-skip-next-outline" v-tooltip="$t('Squeeze.General.Skip')" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openSkipDialog(slotProps.node)" />
					<!--Button :disabled="slotProps.node.data.status === 'success'" icon="mdi mdi-file-replace-outline" v-tooltip="$t('Squeeze.General.Replace')" class="p-button-lg p-button-rounded p-button-warning p-button-text" @click="openReplaceDialog(slotProps.node)" /-->
					<Button :disabled="slotProps.node.data.status === 'success'" icon="mdi mdi-pencil-outline" v-tooltip="$t('Squeeze.General.Customize')" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.node)" />
				</template>
			</template>
		</Column>
	</TreeTable>

	<EntryDialog
		:show="editDialog"
		@onClose="onCloseForm"
		@onConfirm="onSaveForm"
		:headerText="headerText"
		:showKeepDialogOpen="false"
	>
		<template #content>
			<FieldGroupForm :fieldEntry="rowData" :showErrorMessage="showErrorMessage" @update="onUpdate" />
		</template>
	</EntryDialog>
</template>

<script lang="ts">
/* eslint max-lines: off */
import {defineComponent, onMounted, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import TreeTable from 'primevue/treetable';
import Column from "primevue/column";
import Tag from 'primevue/tag';
import EntryDialog from "@/components/EntryDialog.vue";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Skeleton from 'primevue/skeleton';
import {LocatorConfig, SqueezeConfig} from "@dex/squeeze-configframework";
import {useToast} from "primevue/usetoast";
import Tooltip from "primevue/tooltip";
import FieldGroupForm from "@/apps/administration/components/documentclasses/FieldGroupForm.vue";
import {ToastManager} from "@/util/ToastManager";
import {DocumentClass, DocumentClassDto, DocumentField, DocumentFieldGroup, DocumentLocator, DocumentTable, DocumentTableColumn, MasterDataTable} from "@dex/squeeze-client-ts";
import {DocumentClassConfig, FieldsConfig, TableConfig} from "@dex/squeeze-configframework/build/export/SqueezeConfig";
import {MasterDataConfig} from "@dex/squeeze-configframework/build/export/MasterDataConfig";

export interface DataInfo {
	name?: string;
	type?: string;
	status: string;
	message: string;
	onEdit?: () => {};
	onCheck?: () => {};
	replace?: boolean;
}

export interface DataEntry {
	key: string;
	data: DataInfo;
	children?: DataEntry[];
}

export default defineComponent({
	name: "ConfigurationsList",
	components: {
		FieldGroupForm,
		TreeTable,
		Column,
		Tag,
		EntryDialog,
		InputText,
		Dropdown,
		Skeleton,
	},
	props: {
		fileData: {
			type: Object as PropType<SqueezeConfig>,
		},
		apiClients: {
			type: Object,
		},
		showSkeleton: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onDataChange", "onShowEditDialog"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** All Data matched with the system */
		const nodes = ref<DataEntry[]>([]);

		/** Object with the expanded rows-data */
		const expandedRows = ref<any>({});

		/** Should the Edit-Dialog be shown? */
		const editDialog = ref<boolean>(false);

		/** Show Loading on Save in dialog */
		const loadingDialog = ref<boolean>(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Data of the current active data-set */
		const rowData = reactive<any>({});

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Filters of list (Currently not activated) */
		const filters = ref<any>({});

		/** Filter mode
		 * Option 1: { label: 'Lenient', value: 'lenient' }
		 * Option 2: { label: 'Strict', value: 'strict' }
		 */
		const filterMode = ref({ label: 'Strict', value: 'strict' });

		/** List with status types */
		const statusTypes = ref<string[]>(["success", "info", "warning", "error"]);

		/**
		 * Check status of changed data
		 * @param dataElement
		 * @param existsElement
		 * @param currentEl
		 * @param typeDescription
		 */
		const checkStatusOfChangedData = async (dataElement: any, existsElement: any, currentEl: any, typeDescription: string) => {
			if (existsElement && dataElement.data.name === currentEl.data.name) {
				currentEl.data.status = "error";
				currentEl.data.message = `${typeDescription} '${dataElement.data.name}' already exists`;
			} else if (dataElement.children && dataElement.children.length > 0 && dataElement.key === currentEl.key) {
				// set the status on all child elements to success
				dataElement.children.forEach((child: any) => {
					child.data.status = "success";
					child.data.message = "";

					if (child.children) {
						child.children.forEach((c: any) => {
							c.data.status = "success";
							c.data.message = "";
						})
					}
				})

				currentEl.data.status = "success";
				currentEl.data.message = "";
			}
		}

		/**
		 * Process all data of document class children
		 * @param fieldGroups
		 * @param tables
		 * @param docClassId
		 */
		const processDocumentClassChildren = async (fieldGroups: FieldsConfig[], tables: TableConfig[], docClassId?: number) => {
			const allFieldGroups: FieldsConfig[] = [];
			const allTables: TableConfig[] = [];
			if (fieldGroups.length > 0) {
				// get all field groups of document class
				let allDcFieldGroups: DocumentFieldGroup[] = [];
				if (docClassId) {
					allDcFieldGroups = await props.apiClients!.documentClassApi.getAllFieldGroups(docClassId);
				}
				for (const [fieldGroupIndex, fieldGroup] of fieldGroups.entries() as any) {
					fieldGroup.data = {
						name: fieldGroup.fieldGroup.name,
						type: t('Squeeze.DocumentClasses.FieldGroup'),
						status: "success",
						message: "",
						onEdit: () => {
							headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Group') });
						},
						onCheck: (data: any, currentEl: any) => {
							data.forEach((dataElement: any) => {
								const existsFieldGroup = allDcFieldGroups.find((el: DocumentFieldGroup) => el.name === dataElement.data.name);
								checkStatusOfChangedData(dataElement, existsFieldGroup, currentEl, "Field group");
							})
						},
						replace: false,
					};
					fieldGroup.key = 'fg-' + fieldGroupIndex;
					fieldGroup.children = [];

					// check if field group exists
					const existsFieldGroup = allDcFieldGroups.find((fg: DocumentFieldGroup) => fg.name === fieldGroup.fieldGroup.name);
					if (existsFieldGroup) {
						fieldGroup.data.status = "error";
						fieldGroup.data.message = `Field group '${fieldGroup.fieldGroup.name}' already exists`;
					}

					if (fieldGroup.fields.length) {
						// get all fields of document class
						let allFields: DocumentField[] = [];
						if (docClassId) {
							allFields = await props.apiClients!.documentClassApi.getAllDocumentClassFields(docClassId);
						}
						fieldGroup.fields.forEach((field: any, fieldIndex: number) => {
							field.data = {
								name: field.name,
								type: t('Squeeze.DocumentClasses.Field'),
								status: "success",
								message: "",
								onEdit: () => {
									headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Field') });
								},
								onCheck: (data: any, currentEl: any) => {
									data.forEach((dataElement: any) => {
										const existsField = allFields.find((el: DocumentField) => el.name === dataElement.data.name);
										checkStatusOfChangedData(dataElement, existsField, currentEl, "Field");
									})
								},
								replace: false,
							};
							field.key = 'field-' + fieldIndex;

							// check if field exists
							const existsField = allFields.find((c: any) => c.name === field.name);
							if (existsField) {
								fieldGroup.data.status = "error";
								field.data.status = "error";
								field.data.message = `Field '${field.name}' already exists`;
							}

							fieldGroup.children.push(field);
						})
					}
					allFieldGroups.push(fieldGroup);
				}
			}

			if (tables.length > 0) {
				// get all tables of document class
				let allDcTables: DocumentTable[] = [];
				if (docClassId) {
					allDcTables = await props.apiClients!.documentClassApi.getAllDocumentClassTables(docClassId);
				}
				for (const [tableIndex, table] of tables.entries() as any) {
					table.data = {
						name: table.table.name,
						type: t('Squeeze.DocumentClasses.Table'),
						status: "success",
						message: "",
						onEdit: () => {
							headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Table') });
						},
						onCheck: (data: any, currentEl: any) => {
							data.forEach((dataElement: any) => {
								const existsTable = allDcTables.find((el: DocumentTable) => el.name === dataElement.data.name);
								checkStatusOfChangedData(dataElement, existsTable, currentEl, "Table");
							})
						},
						replace: false,
					};
					table.key = 'table-' + tableIndex;
					table.children = [];

					// check if table exists
					const existsTable = allDcTables.find((t: DocumentTable) => t.name === table.table.name);
					if (existsTable && existsTable.id) {
						table.data.status = "error";
						table.data.message = `Table '${table.table.name}' already exists`;
					}

					if (table.columns.length) {
						// get all table columns of document class
						let allTableColumns: DocumentTableColumn[] = [];
						if (existsTable && existsTable.id) {
							allTableColumns = await props.apiClients!.documentClassApi.getAllDocumentClassTableColumns(docClassId, existsTable.id);
						}
						table.columns.forEach((column: any, columnIndex: number) => {
							column.data = {
								name: column.name,
								type: t('Squeeze.DocumentClasses.TableColumn'),
								status: "success",
								message: "",
								onEdit: () => {
									headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.TableColumn') });
								},
								onCheck: (data: any, currentEl: any) => {
									data.forEach((dataElement: any) => {
										const existsTableColumn = allTableColumns.find((el: DocumentTableColumn) => el.name === dataElement.data.name);
										checkStatusOfChangedData(dataElement, existsTableColumn, currentEl, "Table column");
									})
								},
								replace: false,
							};
							column.key = 'column-' + columnIndex;

							// check if table column exists
							const existsColumn = allTableColumns.find((c: DocumentTableColumn) => c.name === column.name && c.dataType === column.dataType);
							if (existsColumn) {
								table.data.status = "error";
								column.data.status = "error";
								column.data.message = `Table column '${column.name}' already exists`;
							}

							table.children.push(column);
						})
					}

					allTables.push(table);
				}
			}

			// Document class data
			const dcData: any[] = [];
			return dcData.concat(fieldGroups, tables);
		}

		/**
		 * Process all data of document class
		 * @param documentClasses
		 */
		const processDocumentClass = async (documentClasses: DocumentClassConfig[]) => {
			if (documentClasses.length > 0) {
				const newDocumentClasses: any[] = [];
				const allDocumentClasses: DocumentClassDto[] = await props.apiClients!.documentClassApi.getAllDocumentClasses();
				for (const [dcIndex, dc] of documentClasses.entries() as any) {
					dc.documentClass.data = {
						name: dc.documentClass.name,
						type: t('Squeeze.DocumentClasses.DocumentClass'),
						status: "success",
						message: "",
						onEdit: () => {
							headerText.value = t('Squeeze.Validation.Dialogs.ChangeDocumentClass.Change');
						},
						onCheck: (data: any, currentEl: any) => {
							data.forEach((dataElement: any) => {
								const existsDocClass = allDocumentClasses.find((DC: DocumentClass) => DC.name === dataElement.data.name);
								checkStatusOfChangedData(dataElement, existsDocClass, currentEl, "Document class");
							})
						},
						replace: false,
					};
					dc.key = 'dc-' + dcIndex;
					const existsDocClass = allDocumentClasses.find((DC: DocumentClass) => DC.name === dc.documentClass.name);
					if (existsDocClass) {
						dc.documentClass.data.status = "error";
						dc.documentClass.data.message = `Document class '${dc.documentClass.name}' already exists`;
						dc.documentClass.children = await processDocumentClassChildren(dc.fieldGroups, dc.tables, existsDocClass.id!);
					} else {
						dc.documentClass.children = await processDocumentClassChildren(dc.fieldGroups, dc.tables);
					}

					newDocumentClasses.push(dc.documentClass);
				}
				return newDocumentClasses;
			}
		}

		/**
		 * Process all data of locators or master data tables
		 * @param property
		 * @param allData
		 * @param element
		 */
		const processProperty = async (property:  (LocatorConfig | MasterDataConfig)[], allData:  (DocumentLocator | MasterDataTable)[], element: "Locator" | "MasterDataTable") => {
			const p: any[] = [];
			property.forEach((prop: any) => {
				prop.data = {
					name: prop[element[0].toLowerCase() + element.slice(1)].name,
					type: t('Squeeze.DocumentClasses.' + element),
					status: "success",
					message: "",
					onEdit: () => {
						headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.' + element) });
					},
					onCheck: (data: any, currentEl: any) => {
						data.forEach((dataElement: any) => {
							const existsProperty = allData.find((d: DocumentLocator | MasterDataTable) => d.name === dataElement.data.name);
							checkStatusOfChangedData(dataElement, existsProperty, currentEl, element);
						})
					},
					replace: false,
				};
				const existsElement = allData.find((d: DocumentLocator | MasterDataTable) => d.name === prop.data.name);
				if (existsElement) {
					prop.data.status = "error";
					prop.data.message = `${element} '${prop[element[0].toLowerCase() + element.slice(1)].name}' already exists`;
				}
				p.push(prop);
			})
			return p;
		}

		onMounted(async () => {
			if (props.fileData) {
				loading.value = true;
				try {
					if (props.fileData.documentClasses.length > 0) {
						nodes.value.push({
							key: '0',
							data: {
								name: t('Squeeze.DocumentClasses.DocumentClasses'),
								status: "",
								message: "",
							},
							children: await processDocumentClass(props.fileData.documentClasses),
						});

						// expand document classes in tree table
						expandedRows.value = {'0': true};
					}
					if (props.fileData.locators.length > 0) {
						const allLocators: DocumentLocator[] = await props.apiClients!.locatorApi.getAllLocators();
						nodes.value.push({
							key: '1',
							data: {
								name: t('Squeeze.Breadcrumbs.Locators'),
								status: "",
								message: "",
							},
							children: await processProperty(props.fileData.locators, allLocators, "Locator"),
						});

						// expand locators in tree table
						expandedRows.value = {'1': true};
					}
					if (props.fileData.masterDataTables.length > 0) {
						// Table columns will not be checked - changed it later
						const allMasterDataTables: MasterDataTable[] = await props.apiClients!.masterDataApi.getAllMasterDataTables();
						nodes.value.push({
							key: '2',
							data: {
								name: t('Squeeze.Breadcrumbs.MasterDataList'),
								status: "",
								message: "",
							},
							children: await processProperty(props.fileData.masterDataTables, allMasterDataTables, "MasterDataTable"),
						})

						// expand master data in tree table
						expandedRows.value = {'2': true};
					}
				} catch (err) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				} finally {
					loading.value = false;
					emit("onDataChange", nodes.value);
				}
			}
		})

		/**
		 * Skip element loop to remove this element
		 * @param elements
		 * @param currentRowElement
		 */
		const skipElementLoop = (elements: DataEntry[], currentRowElement: any) => {
			elements.forEach((element: DataEntry, elementIndex: number) => {
				if (element.children) {
					const index = element.children.findIndex((el: DataEntry) => el.data.name === currentRowElement.data.name && el.data.type === currentRowElement.data.type);
					if (index > -1) {
						element.children.splice(index, 1);

						// remove empty parent element
						if (element.children.length === 0) {
							elements.splice(elementIndex, 1);
						}
					} else {
						skipElementLoop(element.children, currentRowElement);
					}
				}
			})

			emit("onDataChange", nodes.value);
		}

		/**
		 * Triggered when the skip button is clicked
		 * @param row The row to remove
		 */
		const openSkipDialog = (row: any) => {
			skipElementLoop(nodes.value, row);
		}

		/**
		 * Triggered when the replace button is clicked
		 * @param row The row to edit
		 */
		const openReplaceDialog = (row: any) => {
			if (!row.data.replace) {
				row.data.replace = true;
				row.data.status = "success";
				row.data.message = "";
			}

			emit("onDataChange", nodes.value);
		}

		/**
		 * Triggered when the edit button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: any) => {
			Object.assign(rowData, row);

			if (row.fieldGroup) {
				Object.assign(rowData, row.fieldGroup);
			} else if (row.table) {
				Object.assign(rowData, row.table);
			} else if (row.locator) {
				Object.assign(rowData, row.locator);
			} else if (row.masterDataTable) {
				Object.assign(rowData, row.masterDataTable);
			} else {
				row.data.onEdit();
			}

			rowData.name = rowData.data.name;
			editDialog.value = true;

			emit("onShowEditDialog", true);
		}

		/** Triggered on update of attribute-form
		 * @param data
		 * @param valid
		 */
		const onUpdate = (data: any, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(rowData, data);
			emit("onDataChange", nodes.value);
		}

		/**
		 * Check the changed data
		 * @param data
		 */
		function checkChangedData(data: any) {
			data.forEach((node: any) => {
				if (node.children) {
					const currentEl = node.children.find((child: any) => child.data.name === rowData.data.name && child.data.type === rowData.data.type);
					if (currentEl) {
						if (currentEl.locator) {
							currentEl.locator.name = rowData.name;
							currentEl.locator.description = rowData.description;
						} else if (currentEl.masterDataTable) {
							currentEl.masterDataTable.name = rowData.name;
							currentEl.masterDataTable.description = rowData.description;
						} else {
							currentEl.name = rowData.name;
							currentEl.description = rowData.description;
						}

						currentEl.data.name = rowData.name;
						currentEl.data.description = rowData.description;
						currentEl.data.status = "success";
						currentEl.data.message = "";
						if (node.children.length > 0) {
							currentEl.data.onCheck(node.children, currentEl);
						}
					} else {
						checkChangedData(node.children);
					}
				}
			})
		}

		/**
		 *  Is triggered when a Field triggers a save
		 */
		const onSaveForm = () => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;

			try {
				loadingDialog.value = true;
				checkChangedData(nodes.value);

				emit("onShowEditDialog", false);
			} catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			} finally {
				loadingDialog.value = false;
				editDialog.value = false;
			}
		}

		/** Trigger to close the Edit-Dialog */
		const onCloseForm = () => {
			editDialog.value = false;
			showErrorMessage.value = false;
			isInvalid.value = true;
			emit("onShowEditDialog", false);
		}

		return {
			t,
			toast,
			loading,
			nodes,
			expandedRows,
			editDialog,
			loadingDialog,
			headerText,
			rowData,
			showErrorMessage,
			isInvalid,
			filters,
			filterMode,
			statusTypes,
			openSkipDialog,
			openReplaceDialog,
			openEditDialog,
			onUpdate,
			onSaveForm,
			onCloseForm,
		};
	},
});

</script>

<style lang="scss" scoped>

/** Reduce height of edit/delete buttons */
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.p-treetable ::v-deep(.p-treetable-thead tr th) {
	background-color: var(--dex-primary-light-color);
	padding: 0.6rem 0.6rem 0.2rem 0.3rem;
	border-bottom-color: var(--dex-primary-light-color);
	color: var(--dex-text-color);
}

.p-treetable ::v-deep(.p-treetable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

.p-treetable ::v-deep(.p-treetable-thead .p-dropdown.filter-field) {
	padding:0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

.status-badge {
	font-weight: bold;
	&.success {
		border-color: var(--queuestate-finished-primary);
		color: var(--queuestate-finished-darker);
	}

	&.info {
		border-color: var(--queuestate-suspend-primary);
		color: var(--queuestate-suspend-darker);
	}

	&.warning {
		border-color: var(--queuestate-work-primary);
		color: var(--queuestate-work-darker);
	}

	&.error {
		border-color: var(--queuestate-error-primary);
		color: var(--queuestate-error-darker);
	}
}

</style>
