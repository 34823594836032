

interface DataTypes {
	id: string | undefined;
	text: string;
}

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import  {Options, Vue} from "vue-class-component";
import {DocumentTableColumn, DocumentLocator} from '@dex/squeeze-client-ts';
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Tooltip from "primevue/tooltip";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: 'DocumentClassTableColumns',
	components: {
		DataTable, Column, Checkbox, DialogDelete, InputText, Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array,
			default: [],
		},
		/** All available Locators */
		locators: {
			type: Array,
			default: [],
		},
		tableId: {
			type: Number,
			default: 0,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox", "onChangeSortOrder"],
})

export default class DocumentClassTableColumns extends Vue {

	/** Show Loading of table? */
	loading!: boolean

	/** List of all locators **/
	locators!: DocumentLocator[];

	/** Table-ID of these Columns */
	tableId!: number

	/** all rows */
	rows!: DocumentTableColumn[];

	/** Data of selected row */
	rowData = {};

	/** Is the Search activated? */
	searchActive = false;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Is Filtering active? */
	isFilterActive: boolean = false;

	/** List of Data-Types */
	dataTypes: DataTypes[] = [];

	mounted() {
		this.dataTypes = [
			{
				id: 'Text',
				text: this.$t('Squeeze.General.Text'),
			},
			{
				id: 'Date',
				text: this.$t('Squeeze.General.Date'),
			},
			{
				id: 'Amount',
				text: this.$t('Squeeze.General.Amount'),
			},
		];
	}

	/**
	 * Opens the Delete Dialog
	 * @param  data Data from the Table that should be deleted
	 */
	openDeleteDialog(data: DocumentTableColumn) {
		this.rowData = {...data};
		this.$emit("deleteEntry", this.rowData)
	}

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentTableColumn} data Data to edit
	 */
	openEditDialog(data: DocumentTableColumn) {
		if (!data.lookup) {
			data.lookup = {
				active: false,
				allowCustomValues: true,
				tableId: 0,
				resultKeyColumnId: 0,
				searchColumnIds: [],
				resultValueColumnIds: [],
				minInputLength: 1,
			};
		}

		data.tableId = this.tableId;
		this.rowData = {...data};
		this.$emit("openEntryDialog", this.rowData)
	}

	/**
	 * Gets the Description of a locator
	 * @param {Number} locatorId Id of the Locator to get the Description from
	 * @return {String} Description of the Locator
	 */
	getDescriptionFromLocator(locatorId: number) {
		// No locator at all
		if (locatorId === 0) {
			return ''
		}

		if (!this.locators) {
			return String(locatorId)
		}

		const locator = this.locators.find(i => i.id === locatorId);

		if (!locator) {
			return String(locatorId)
		}

		return locator.description
	}

	/** Emits the "deleteEntry"-Event */
	deleteEntry() {
		this.$emit("deleteEntry", this.rowData)
	}

	/** Triggered when a checkbox is clicked
	 * @param {DocumentTableColumn} data Data to edit
	 * @param fieldName Name of the current field
	 */
	onChangeCheckbox(data: DocumentTableColumn, fieldName: string) {
		this.$emit("onChangeCheckbox", data, fieldName)
	}

	/**
	 * Triggered when the rows are reordered
	 * @param event
	 */
	onRowReorder(event: any) {
		const orderedList: number[] = [];
		event.value.forEach((column: DocumentTableColumn) => {
			orderedList.push(column.id!);
		});
		this.$emit("onChangeSortOrder", orderedList, this.tableId, this.isFilterActive);
	}

	/** Triggered when a filter has been entered */
	onFilter(event: any) {
		if (event.filteredValue.length < this.rows.length) {
			this.isFilterActive = true;
		} else {
			this.isFilterActive = false;
		}
	}

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"headerLocatorId": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"valueLocatorId": {value: null, matchMode: FilterMatchMode.EQUALS},
			"externalName": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

	beforeMount() {
		this.initFilters();
	}

}
