<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.Locators.Tabs.OrgChar')}} *</label>
			<InputText maxLength="1" id="name" v-model="value.orgChar" :class="{'p-invalid':v$.orgChar.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
			<small v-if="v$.orgChar.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.OrgChar')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.Locators.Tabs.NewChar')}} *</label>
			<InputText maxLength="1" id="description" v-model="v$.newChar.$model" :class="{'p-invalid':v$.newChar.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.newChar.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.NewChar')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorSubstitution} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "SubstitutionsForm",
	components: {
		InputText,
	},
	props: {
		substitution: {
			type: Object as PropType<LocatorSubstitution>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorSubstitution>({orgChar: '', newChar: ''});

		/** Determines the required rules for validation */
		const rules = {
			orgChar: { required },
			newChar: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.substitution);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.substitution, () => {
			Object.assign(value, props.substitution);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
