<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<template v-for="column of masterDataColumns" v-bind:key="column.name">
			<div v-if="column.name.toLowerCase() !== 'id'" class="p-field p-col-6">
				<label :for="column.name">{{column.description}}</label>
				<InputText :id="column.name" v-model="value[column.name]" @change="update" autofocus maxLength="255" />
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {MasterDataColumn} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "MasterDataRowsForm",
	components: {
		InputText,
	},
	props: {
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
		},
		masterDataRow: {
			type: Object,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<any>({});

		onMounted(async () => {
			Object.assign(value, props.masterDataRow);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataRow, () => {
			Object.assign(value, props.masterDataRow);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value);
		}

		return {
			value,
			props,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
