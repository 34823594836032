
import { Options , Vue } from 'vue-class-component';
import Editor from 'primevue/editor';

@Options({
	name: "EmailForm",
	components: {Editor},
	props: {
		value: String,
	},
})
export default class EmailForm extends Vue {

	/** Value of the Form */
	value!: string;

}

