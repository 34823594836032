<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="email">{{$t('Squeeze.UserManagement.Email')}} *</label>
			<InputText id="email" v-model="value.email" :class="{'p-invalid':v$.email.$invalid && showErrorMessage}" @change="update" style="text-transform: lowercase;" />
			<small v-if="showErrorMessage" class="p-error">
				<template v-if="v$.email.email.$invalid">
					{{$t('Forms.Val.Email')}}
				</template>
				<template v-else-if="v$.email.$invalid">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Email')}) }}
				</template>
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="password">{{$t('Squeeze.Login.Password')}} *</label>
			<Password id="password" v-model="value.password" :class="{'p-invalid':v$.password.$invalid && showErrorMessage}" @change="update" :feedback="false" toggleMask />
			<small v-if="v$.password.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Login.Password')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import Password from 'primevue/password';
import {MailZipPassword} from "@dex/squeeze-client-ts";
import {email, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "PasswordForm",
	components: {
		InputText, Password,
	},
	props: {
		password: {
			type: Object as PropType<MailZipPassword>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Object of all input-fields */
		const value = reactive<MailZipPassword>({
			id: undefined,
			email: '',
			password: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			email: { required, email},
			password: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.password);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.password, () => {
			Object.assign(value, props.password);
		})

		/** Triggered on change of any field */
		const update = async () => {
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			value,
			props,
			v$,
			update,
		}
	},
});

</script>

<style scoped>

</style>
