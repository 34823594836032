<template>
	<div class="p-grid p-component nested-grid" style="justify-content:center">
		<div id="left" class="p-col-6">
			<div class="p-grid p-flex-column">
				<BlockUI :blocked="loading">
					<draggable
						class="p-col group"
						v-model="thumbnails"
						group="images"
						itemKey="name"
						key="name"
						tag="transition-group"
						v-bind="dragOptions"
						:component-data="{
							tag: 'div',
							type: 'transition-group',
							name: !drag ? 'flip-list' : null
						}"
						@start="drag = true"
						@end="onDragEnd"
					>
						<template #item="{ element }">
							<div class="document-card p-my-2 p-shadow-2" @click="clickThumbnail($event, element)"
								v-bind:class="{
									'chosen': element.clicked,
									'dragging': drag
								}"
							>
								<img class="thumbnail" :src="'data:image/png;base64, ' + element.model.base64" :alt="element.model.fileName" />
								<div class="document-overlay">{{element.model.id}}</div>
							</div>
						</template>
						<template #header>
							<Button
								:disabled="thumbnails.filter(thumbnail => thumbnail.clicked).length === 0"
								style="width: 70%"
								class="p-button-md p-shadow-2 p-mb-4"
								:loading="loading"
								:badge="selectedThumbnails"
								v-tooltip="$t('Squeeze.Validation.Dialogs.SplitDocument.Move')"
								icon="mdi mdi-skip-forward"
								@click="movePages"
							/>
						</template>
					</draggable>
				</BlockUI>
			</div>
		</div>
		<div id="right" class="p-col-6">
			<div class="p-grid p-flex-column">
				<BlockUI :blocked="loading">
					<draggable
						class="p-col group"
						v-model="listSelected"
						group="images"
						itemKey="name"
						key="name"
						tag="transition-group"
						v-bind="dragOptions"
						:component-data="{
							tag: 'div',
							type: 'transition-group',
							name: !drag ? 'flip-list' : null
						}"
						@start="drag = true"
						@end="drag = false"
					>
						<template #item="{ element }">
							<div class="document-card p-my-2 p-shadow-2" @click="clickThumbnail($event, element)"
								v-bind:class="{
									'chosen': element.clicked,
									'dragging': drag && element.clicked
								}"
							>
								<img class="thumbnail" :src="'data:image/png;base64, ' + element.model.base64" :alt="element.model.fileName" />
								<div class="document-overlay">{{ element.model.id }}</div>
							</div>
						</template>
						<template #header>
							<Button
								:disabled="listSelected.length === 0"
								style="width: 70%"
								:loading="loading"
								class="p-button-md p-shadow-2 p-mb-4"
								v-tooltip="$t('Squeeze.Validation.Dialogs.SplitDocument.SplitSites')"
								icon="mdi mdi-file-move-outline" @click="splitDocument"/>
						</template>
					</draggable>
				</BlockUI>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import draggable from 'vuedraggable'
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentSplitStatus, FileDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";

interface UiSplitElement {
	model: FileDto;
	clicked: boolean;
}

export default defineComponent({
	name: "DocumentSplit",
	components: {
		BlockUI,
		draggable,
	},
	props: {
		/** ID of currently active Document */
		documentId: {
			type: String,
		},
	},
	emits: ["onImageClick", "onEmptyList"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();

		/** List with available Thumbnails */
		const thumbnails = ref<UiSplitElement[]>([]);

		/** List with all selected Thumbnails */
		const listSelected = ref<UiSplitElement[]>([]);

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** Is the Item currently dragged? */
		const drag = ref<boolean>(false);

		/** Drag options. Taken from https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/transition-example-2.vue */
		const dragOptions = reactive<any>({
			animation: 200,
			group: "images",
			disabled: false,
			ghostClass: "ghost",
		});

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Is Queue item active? */
		const selectedThumbnails = computed(() => {
			const clickedElements = thumbnails.value.filter((element: UiSplitElement) => element.clicked);
			if (clickedElements) {
				return clickedElements.length;
			} else {
				return 0;
			}
		})

		/**
		 * Triggered when a key is press on the window
		 * @param event
		 */
		const onKeyPress = (event: any) => {
			if (event.code === "ArrowRight") {
				const clickedElements = thumbnails.value.filter(element => element.clicked);

				if (clickedElements) {
					clickedElements.forEach(element => {
						listSelected.value.push({...element});
					})

					thumbnails.value = thumbnails.value.filter(element => !element.clicked);
				}
			}


			if (event.code === "ArrowLeft") {
				const clickedElements = listSelected.value.filter(element => element.clicked);

				if (clickedElements) {
					clickedElements.forEach(element => {
						const newElement = {...element};
						newElement.clicked = false;
						listSelected.value.push(newElement);
					})

					listSelected.value = listSelected.value.filter(element => !element.clicked);
				}
			}
		}

		/**
		 * Triggered when an element is clicked
		 * @param event
		 * @param splitElement
		 */
		const clickThumbnail = (event: any, splitElement: UiSplitElement) => {
			splitElement.clicked = !splitElement.clicked;
			emit("onImageClick", splitElement.model.id);
		}

		/**
		 * Triggered when an element on the right side is clicked
		 * @param event
		 * @param splitElement
		 */
		const clickThumbnailRight = (event: any, splitElement: UiSplitElement) => {
			emit("onImageClick", splitElement.model.id);
		}

		/** Moves the Pages to right side */
		const movePages = () => {
			const clickedElements = thumbnails.value.filter(element => element.clicked);

			if (clickedElements) {
				clickedElements.forEach(element => {
					const newElement = {...element};
					newElement.clicked = false;
					listSelected.value.push(newElement);
				})

				thumbnails.value = thumbnails.value.filter(element => !element.clicked);
			}
		}

		/** Is triggered when the dragging has ended */
		const onDragEnd = () => {
			drag.value = false;

			// Don't show any element as clicked on the right side
			listSelected.value.forEach(element => {
				element.clicked = false;
			});
		}

		/** Splits the chosen documents */
		const splitDocument = () => {
			const pageIds = listSelected.value.map(element => {
				return Number(element.model.id);
			});

			const pages: any = {
				pageIds: pageIds,
			};

			loading.value = true;

			documentApi.splitDocument(Number(props.documentId), pages)
				.then(() => {
					listSelected.value = [];

					if (listSelected.value.length === 0 && thumbnails.value.length === 0) {
						emit("onEmptyList");
					}
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onBeforeMount(() => {
			window.addEventListener('keyup', onKeyPress);
		})

		onBeforeUnmount(() => {
			window.removeEventListener("keyup", onKeyPress);
		})

		onMounted(() =>{
			const promiseSplitStatus = documentApi.getDocumentSplitStatus(Number(props.documentId));
			const promiseThumbnails = documentApi.getDocumentThubnails(Number(props.documentId), true)

			Promise.all([promiseSplitStatus, promiseThumbnails])
				.then(promises => {
					const splitStatus: DocumentSplitStatus =  promises[0];
					let allThumbnails = promises[1];

					allThumbnails = allThumbnails.filter(thumbnail => splitStatus.unsplitPages?.indexOf(Number(thumbnail.id)) != -1);

					thumbnails.value = allThumbnails.map((thumbnail: FileDto) => {
						return {
							model: thumbnail,
							clicked: false,
						}
					})
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})
		})

		return {
			t,
			toast,
			thumbnails,
			listSelected,
			loading,
			drag,
			dragOptions,
			selectedThumbnails,
			onKeyPress,
			clickThumbnail,
			clickThumbnailRight,
			movePages,
			onDragEnd,
			splitDocument,
		};
	},
});

</script>

<style lang="scss" scoped>

::v-deep(.p-col) {
	text-align: center;
}

.group {
	min-height: 85vh;
	min-width: 13.313rem;
}

.img-wrapper {
	text-align:center;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail {
	border-width: 0.063rem;
	border-style: solid;
	border-color: lightgrey;
}

.document-card {
	position: relative;
	display: inline-block;
	background-color: darkgrey;
	text-align: center;
	cursor:pointer;
}

.chosen {
	background-color: var(--dex-primary-light-color);
}

.document-overlay {
	position: absolute;
	justify-content: center;
	bottom: 0.25rem;
	width: 100%;
	color: var(--dex-text-color);
	background: rgba(0, 0, 0, 0.5);
	font-size: 2rem;
	//opacity: 0;
}

.dragging {
	z-index: 2;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
	//transition: z-index 0.2s;
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0.5s;
}
.ghost {
	opacity: 0.5;
	background-color: var(--dex-primary-light-color);
}

</style>
