import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ErrorPage from '../views/ErrorPage.vue';
import * as SqueezeAppRouter from "@/apps/squeeze/router";
import * as AdministrationRouter from "@/apps/administration/router";
import * as FreezeRouter from "@/apps/freeze/router";
import RecoverPassword from "@/views/RecoverPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {squeezeStore} from "@/apps/squeeze/store";
import {productStore} from "@/store/product-store"
import KeyCloakLoginCheck from "@/components/KeyCloakLoginCheck.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "",
		redirect: {
			name: 'SqueezeDashboard',
		},
	},
	{
		path: "/home",
		name: "Home",
		component: Home,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: {
			requiresAuth: false,
			translate: true,
		},
	},
	{
		path: "/recover",
		name: "Recover",
		component: RecoverPassword,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/pwreset",
		name: "PasswordReset",
		component: ResetPassword,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/checkLogin",
		name: "KeyCloakLoginCheck",
		component: KeyCloakLoginCheck,
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/:reason(.*)',
		name: 'ErrorPage',
		component: ErrorPage,
	},
]

const addRouteFunc = (route: RouteRecordRaw) => routes.push(route);
AdministrationRouter.createRoutes("/app/administration").forEach(addRouteFunc);
SqueezeAppRouter.createRoutes("/app/squeeze").forEach(addRouteFunc);
FreezeRouter.createRoutes("/app/freeze").forEach(addRouteFunc);

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
})


router.beforeEach(async (to, from, next) => {
	if (squeezeStore.state.isLoggedIn) {
		next();
		return
	}

	// Is the Entry-Point does not require a login, simply go there
	if(to.meta.requiresAuth === false) {
		squeezeStore.commit("setLogin", false);
		next();
		return
	}

	const authorization = localStorage.getItem("authorization");

	// If Authorization is bearer, check if keycloak is active. If not, re-trigger keycloak login
	if (authorization && authorization === "bearer" && !ClientManager.getInstance().keycloak) {
		localStorage.setItem("firstUrl", to.fullPath);
		await ClientManager.getInstance().setPossibleAuthentications();
		await ClientManager.getInstance().openKeyCloakLogin();
		return;
	}

	// Is the store has not saved the login yet, check by calling an endpoint
	const isLoggedIn = await ClientManager.getInstance().isAuthenticatedAtSqueeze();
	// This is often true, if the cookie is set. If not, check keycloak again
	if (!isLoggedIn) {
		localStorage.setItem("firstUrl", to.fullPath);
		next({name: 'Login'})
	} else {
		squeezeStore.commit("setLogin", true);
		try{
			const promiseProducts =  productStore.dispatch("fetchProducts");
			const promiseUserData = squeezeStore.dispatch("fetchUserData");
			const promiseFeatureSet =  squeezeStore.dispatch("fetchFeatureSet");
			const promiseMigrations =  squeezeStore.dispatch("fetchMigrations");
			const promiseSystemInformation =  squeezeStore.dispatch("fetchSystemInformation");

			await Promise.all([promiseUserData, promiseFeatureSet, promiseMigrations, promiseSystemInformation, promiseProducts]);

			// UserSettings have to loaded, AFTER the feature-sets have been set. Do not include in parallel execution
			await squeezeStore.dispatch("fetchUserSettings");
		}
		catch (err: any) {/**/}

		const firstUrl = localStorage.getItem("firstUrl");
		if (firstUrl) {
			localStorage.removeItem("firstUrl")
			let querySet: {[key: string]: string} = {};

			// In case something bad happens, just ignore the query params
			try {
				// Get Query Params from URL String
				const queryParams = firstUrl.split("?")[1];
				if (queryParams) {
					querySet = Object.fromEntries(new URLSearchParams(queryParams));
				}
			}catch(e) {/**/}

			next({path: firstUrl, query: querySet});
		} else {
			next()
		}
	}
})

export default router
