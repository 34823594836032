<template>
	<Toast position="bottom-right" group="br" />
	<ConfirmDialog></ConfirmDialog>
	<div v-if="loading" class="loadingSpinner" >
		<i class="mdi mdi-spin mdi-loading" style="fontSize: 8rem"></i>
	</div>
	<VerticalNavbar @onCollapseSidebar="opened = !opened" @userLoggedOut="opened = false" v-if="route.name && !isRouteWithoutLogin() && !loading" :links="navbarLinks" @isUploadDialogShown="isUploadDialogShown" />
	<div v-if="!loading" :class="{'view-open': opened, 'view': !isRouteWithoutLogin()}">
		<router-view :isUploadShown="isUploadShown"></router-view>
	</div>
</template>

<script lang="ts">

import VerticalNavbar from "@/components/VerticalNavbar.vue";
import ConfirmDialog from 'primevue/confirmdialog';
import {ClientManager} from "@/singletons/ClientManager";
import { useSqueezeStore } from '@/apps/squeeze/store';
import moment from "moment";
import {computed, defineComponent, onBeforeMount, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {changePalette} from "@/util/StylesHelper";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import useProductStore from "@/store/product-store";

export default defineComponent({
	name: "App",
	components: {
		VerticalNavbar,
		ConfirmDialog,
	},
	emits: ["onCollapseSidebar"],
	setup() {
		/** Is the request for checking user-login currently loading? */
		const {t} = useI18n();
		const toast = useToast();

		const loading = ref(false);
		const opened = ref(false);
		const store = useSqueezeStore();
		const route = useRoute();
		const { locale } = useI18n({ useScope: 'global' })

		/** Api for Theme */
		const themeApi = ClientManager.getInstance().squeeze.theme;
		const storeProduct = useProductStore();

		const navbarLinks = computed(() => {
			const navBarLinks = [
				/*
				{
					label: "Login",
					to: {
						name: "Login",
						params: { lang: DEFAULT_LOCALE },
					},
				},
				 */
				/*{
					label: "Home",
					icon: "mdi mdi-home",
					isAdminRoute: false,
					to: {
						name: "Home",
					},
				},*/
				{
					label: t('Squeeze.General.Squeeze'),
					//icon: "mdi mdi-view-dashboard",
					icon: "mdi mdi-home",
					isAdminRoute: false,
					to: {
						name: "SqueezeDashboard",
					},
				},
				{
					label: t('Squeeze.General.Admin'),
					icon: "mdi mdi-cog",
					isAdminRoute: store.state.scopes.sqzAdminView,
					to: {
						name: "Administration",
					},
				},
				{
					label: t('Squeeze.Reports.Picker'),
					icon: "mdi mdi-chart-line",
					isAdminRoute: false,
					to: {
						name: "Reports",
					},
				},
			]

			if (storeProduct.state.freeze) {
				navBarLinks.push(
					{
						label: t('Freeze.Freeze'),
						//icon: "mdi mdi-view-dashboard",
						icon: "mdi mdi-archive",
						isAdminRoute: false,
						to: {
							name: "ArchiveSearchView",
						},
					})
			}

			return navBarLinks;
		});

		/** Is the upload dialog shown? */
		const isUploadShown = ref(false);

		/** Is the route a route that requires to be logged in? */
		const isRouteWithoutLogin = () => {
			if (route.meta && route.meta.requiresAuth === false) {
				return true;
			}

			return false;
		}

		/** Watch locale to reset locale storage if needed */
		watch(locale, () => {
			localStorage.setItem('language', locale.value);
			moment.locale(locale.value);
		})

		onMounted(() => {
			if(isRouteWithoutLogin() || !route.name) {
				loading.value = false;
				return;
			}

			loading.value = false;
		});

		/** Get main color of client */
		const getColor = () => {
			themeApi.getColor()
				.then(response => response.json().then ((color: string) => {
					if (color) {
						changePalette(color);
					}
				}))
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Triggered the upload dialog */
		const isUploadDialogShown = (isShown: boolean) => {
			isUploadShown.value = isShown;
		}

		onBeforeMount(() => {
			getColor();
			try {
				// get userSettings fontSize before loggedIn
				const localStorageFontSize = localStorage.getItem('fontSize');
				if (localStorageFontSize) {
					const fontSize = JSON.parse(localStorageFontSize);
					document.documentElement.style.setProperty("--global-font-size", fontSize + 'rem');
				}
			} catch(err) {
				return;
			}
		});

		return { navbarLinks, opened, loading, route, isUploadShown,
			isRouteWithoutLogin, changePalette, getColor, isUploadDialogShown }
	},
});
</script>

<style lang="scss" scoped>

.top-level-router {
	padding: 0;
}

.loadingSpinner {
	background: white;
	width: 100%;
	height: 100vh;
	position: fixed;
	text-align: center;
	vertical-align: middle;
	margin-top: 25%;
}

.view {
	//height: 100vh;
	transition: margin-left 0.5s;
	overflow: hidden;
	margin-left: 4.688rem;
}

.view-open {
	transition: margin-left 0.5s;
	overflow: hidden;
	margin-left: 9.375rem !important;
}

</style>

<style lang="scss">
	h1,h2 {
		&.view-title {
			margin: 0 0 2rem;
		}
	}

	h1.view-title {
		font-size: 4rem;
	}

	h2.view-title {
		font-size: 3.5rem;
	}

	html {
		font-size: var(--global-font-size) !important;
	}

	// Set Padding to null of setting content dialog
	.p-dialog .p-dialog-content.setting-dialog {
		padding: 0;
	}

</style>
