
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import QueueNavigationCard from "@/apps/squeeze/components/QueueNavigationCard.vue";
import router from "@/router";
import {
	DocumentSearchFilterWidget,
	QueueStepDetailsDto,
	QueueStepDto
} from "@dex/squeeze-client-ts";
import Skeleton from 'primevue/skeleton';
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "QueueNavigator",
	components: {
		QueueNavigationCard,
		Skeleton,
		Dropdown,
	},
	props: {
		step: {
			type: Object as PropType<QueueStepDto>,
			required: true,
		},
		customTiles: {
			type: Array as PropType<DocumentSearchFilterWidget[]>,
			default: [],
		},
		isValidation: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** List of all document classes in QueueStep */
		const queueDocClasses = ref<QueueStepDetailsDto[]>([]);

		/** Indicates end of request */
		const loading = ref<boolean>(false);

		/** Total amount of QueueEntries in QueueStep Validation */
		const totalEntries = ref<number>(0);

		/** Total amount of QueueEntries in QueueStep Validation in error state*/
		const totalErrorEntries = ref<number>(0);

		/** References for Searches */
		const op = ref<any[]>([]);

		/** Array with list for Creating/Changing Searches, filtered by DocumentClass ID*/
		const filterForDocumentClass  = (docClassId: number) => {
			const privateSearches = props.customTiles?.filter(search => search.global === false  && search.documentClassId === docClassId);
			const selection = [];

			// default
			selection.push({
				label: '',
				id: -2,
				items: [{
					description: t('Squeeze.Queue.General.ShowAll'),
					id: -1,
				}],
			});

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			const globalSearches = props.customTiles?.filter(search => search.global === true && search.documentClassId === docClassId);
			if (globalSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Global'),
					icon: 'mdi mdi-account-group',
					items: globalSearches,
				})
			}

			return selection;
		};

		const showQueueEntry = (docClassId: number, searchFilters?: string, searchId?: number|undefined) => {
			if (!searchId) {
				searchId = 0;
			}

			if (!searchFilters) {
				router.push({name: "DocumentListValidation", params: {
					documentClassId: docClassId,
				}});
			} else if (props.isValidation) {
				router.push({name: "DocumentListValidationWithSearch", params: {
					documentClassId: docClassId,
					searchRequest: encodeURIComponent(searchFilters),
					searchId: searchId,
				}});
			} else {
				router.push({name: "DocumentList", params: {
					documentClassId: docClassId,
					searchRequest: encodeURIComponent(searchFilters),
					searchId: searchId,
				}});
			}
		}

		/* Sets the last selected search */
		const setLastSearch = (index: number, search: DocumentSearchFilterWidget) => {
			if (store.state.featureSet.savedDocumentSearches) {
				try{
					let searches: any|null|string = localStorage.getItem("ChosenSearches");
					if (searches) {
						searches = JSON.parse(searches) as any[];
						searches['' + index] = search;
					} else {
						searches = {};
						searches['' + index] = search;
					}

					localStorage.setItem("ChosenSearches", JSON.stringify(searches))
				}
				catch(e) {
					localStorage.removeItem("ChosenSearches")
				}
			} else {
				localStorage.removeItem("ChosenSearches")
			}
		}

		/**
		 * Move to QueueListView of selected DocumentClass
		 * @param docClassId
		 */
		const showQueueList = (event: Event, docClassId: number, index?: number) => {
			if (props.customTiles?.length === 0) {
				showQueueEntry(docClassId);
				return;
			}
			let searchId: number|undefined = undefined;
			let searchFilters: string|undefined = undefined;
			if ((index || index === 0) && op.value[index]) {
				try {
					const currentSearch = op.value[index] as DocumentSearchFilterWidget;
					const searchCheck = props.customTiles?.find(search => search.id === currentSearch.id);
					if (searchCheck && currentSearch.searchRequestJson) {
						searchFilters = searchCheck.searchRequestJson;
						searchId = searchCheck.id;
					}

					setLastSearch(index, currentSearch);
				}catch (e) {/**/}
			}

			showQueueEntry(docClassId, searchFilters, searchId);
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.step, () => {
			loading.value = true;

			try {
				totalEntries.value = props.step.stepDetails
					.map(detail => detail.count)
					.reduce((a, x) => a + x, 0);

				totalErrorEntries.value = props.step.stepDetails
					.map(detail => detail.errorCount)
					.reduce((a, x) => a + x, 0);

				// There may be documents without valid class. These are filtered to avoid exceptions.
				queueDocClasses.value = props.step.stepDetails.filter(detail => detail.documentClass);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		})

		onMounted(() => {
			if (store.state.featureSet.savedDocumentSearches) {
				try {
					let searches: any = localStorage.getItem("ChosenSearches");
					if (searches) {
						searches = JSON.parse(searches);
						for (const key in searches) {
							op.value[Number(key)] = searches[key];
						}
					}
				}catch (e) {
					localStorage.removeItem("ChosenSearches")
				}
			} else {
				localStorage.removeItem("ChosenSearches")
			}
		})

		/** Triggered when dropdown panel is shown */
		const onShowDropdown = () => {
			showDropdownOverlay();

			// get dropdown panel to delete the first dropdown list item
			const dropdownPanel = document.querySelector("div.p-dropdown-panel");
			if (dropdownPanel && dropdownPanel.firstElementChild && dropdownPanel.firstElementChild.firstElementChild) {
				const dropdownItemsContainer = dropdownPanel.firstElementChild.firstElementChild;
				if (dropdownItemsContainer && dropdownItemsContainer.firstElementChild) {
					const firstDropdownListItem = dropdownItemsContainer.firstElementChild;
					if (firstDropdownListItem) {
						dropdownItemsContainer.removeChild(firstDropdownListItem);
					}
				}
			}
		}

		return {
			t, toast, queueDocClasses, loading, totalEntries, totalErrorEntries, showQueueList, showQueueEntry, showDropdownOverlay, onShowDropdown, filterForDocumentClass, op, store,
		};
	},
});
