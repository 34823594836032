<template>
	<div class="p-grid p-p-2" v-if="record.attachments && record.attachments.length > 0 && record.currentDocumentBase64" style="height: 95%; width: 98%; margin-left: 10px" >
		<div class="p-col-12 p-shadow-2">
			<Dropdown
				:model-value="selectedDocument"
				@change="changeDocument($event)"
				:options="record.attachments"
				placeholder=""
				style="max-width: 31.25rem"
			>
				<template #value="slotProps">
					<div v-if="slotProps.value.name">
						<div>{{slotProps.value.name}}</div>
					</div>
				</template>
				<template #option="slotProps">
					<div class="month-item">{{ slotProps.option.name }}</div>
				</template>
			</Dropdown>
			&nbsp;<a :download="selectedDocument.name" :href="'data:;base64, ' + selectedDocument.documentBase64">
				<Button class="p-button-md" icon="mdi mdi-folder-download-outline" v-tooltip.bottom="$t('Freeze.Eas.Download')" />
			</a>
		</div>
		<template v-if="selectedDocument.name.toLowerCase().indexOf('.pdf') !== -1">
			<div class="p-col-12 p-shadow-2" style="height: 100%; width: 100%;">
				<iframe :src="createPdfUrlPath(selectedDocument.documentBase64)" style="height: 100%; width: 100%;"  width="100%"></iframe>
			</div>
		</template>
		<template v-else-if="(selectedDocument.name.toLowerCase().indexOf('.jpg') !== -1 || selectedDocument.name.toLowerCase().indexOf('.jpeg') !== -1)">
			<div class="p-col-12 p-shadow-2" style="height: 100%; width: 100%; overflow: scroll;">
				<div class="container">
					<img class="base64Image" :src="'data:image/jpeg;base64, ' + selectedDocument.documentBase64" :title="selectedDocument.name">
				</div>
			</div>
		</template>
		<template v-else-if="(selectedDocument.name.toLowerCase().indexOf('.png') !== -1)">
			<div class="p-col-12 p-shadow-2" style="height: 100%; width: 100%; overflow: scroll;">
				<div class="container">
					<img class="base64Image" :src="'data:image/png;base64, ' + selectedDocument.documentBase64" :title="selectedDocument.name">
				</div>
			</div>
		</template>
		<template v-else-if="(selectedDocument.name.toLowerCase().indexOf('.json') !== -1)">
			<div class="p-col-12 p-shadow-2" style="height: 100%; width: 100%;  overflow: scroll;">
				<div class="container">
					<pre>{{decodeJSON(selectedDocument.documentBase64)}}</pre>
				</div>
			</div>
		</template>
		<template v-else-if="(selectedDocument.name.toLowerCase().indexOf('.txt') !== -1) || selectedDocument.name.toLowerCase().indexOf('.csv') !== -1">
			<div class="p-col-12 p-shadow-2" style="height: 100%; width: 100%;  overflow: scroll;">
				<div class="container">
					<pre>{{decodeText(selectedDocument.documentBase64)}}</pre>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="p-col-12 p-shadow-2  justify-content: center;" style="height: 100%; width: 100%;">
				<div class="p-col-12 p-p-4">
					<div class="loadingSpinner" >
						<i class="mdi mdi-close-octagon-outline" style="fontSize: 8rem"></i><br/>
						{{$t('Freeze.SearchMask.NoPreview')}}
					</div>
				</div>
			</div>
		</template>
	</div>
	<div class="p-grid" v-else-if="record.attachments && record.attachments.length === 0" >
		<div class="p-col-12 p-p-4">
			{{$t('Freeze.Eas.NoDocument')}}
		</div>
	</div>
	<div v-else>
		<div class="p-col-12 p-p-4">
			<div class="loadingSpinner" >
				<i class="mdi mdi-spin mdi-loading" style="fontSize: 8rem"></i>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import {
	defineComponent
} from 'vue';

import Dropdown from 'primevue/dropdown';
import {showDropdownOverlay} from "@/util/StylesHelper";
import {useI18n} from "vue-i18n";
import {createPdfUrlPath} from "@/util/BlobHelper";

export default defineComponent({
	name: 'RecordViewer',
	components: {
		Dropdown,
	},
	props: {
		record: Object,
		selectedDocument: Object,
	},
	emits: ["onChangeDocument"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const changeDocument = (event: { value: any}) => {
			emit("onChangeDocument", event.value)
		}

		/**
		 * Decodes from base64 to text
		 * @param base64String
		 */
		const decodeText = (base64String: string) => {
			try {
				const actual = atob(base64String)
				return actual.toString();
			} catch(e) {
				return t('Freeze.SearchMask.NoPreview');
			}
		}

		/**
		 * Decodes from base64 to JSON (if the JSON ist valid)
		 * @param base64String
		 */
		const decodeJSON = (base64String: string) => {
			try {
				const actual = JSON.parse(atob(base64String))
				return JSON.stringify(actual, null, 2);
			} catch(e) {
				return t('Freeze.SearchMask.NoPreviewJSON');
			}
		}

		/**
		 * Download an attachment on click of the button
		 * Source: https://stackoverflow.com/questions/3916191/download-data-url-file
		 * @param fileName
		 * @param base64String
		 */
		const downloadAttachment = (fileName: string, base64String: string) => {
			const link = document.createElement("a");
			link.download = fileName;
			link.href = 'data:;base64, ' + base64String;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		return {
			downloadAttachment,
			decodeText,
			decodeJSON,
			changeDocument,
			showDropdownOverlay,
			createPdfUrlPath,
		};
	},
});

</script>
<style lang="scss" scoped>
.loadingSpinner {
	background: white;
	text-align: center;
	vertical-align: middle;
	margin-top: 25%;
}

.base64Image {
	max-width: 100%;
	max-height: 100%;
	height: inherit !important;
}
</style>
