<template>
	<div class="p-grid p-mx-2">
		<div class="p-col-12 p-lg-12 p-xl-8 p-xxl-8 dashboard-container p-mt-2">
			<Button :label="$t('Freeze.General.OpenUpload')" @click="showUploadDialog = true"></Button>
		</div>
		<div class="p-col-12 p-xl-8 p-xxl-6 dashboard-container search-container">
			<DashboardTile class="search-content" :title="$t('Platform.Search.StoreTitle')">
				<StoresSearchMask @onChangeStore="onChangeStore" @onChangeHitList="onChangeHitList"/>
			</DashboardTile>
		</div>
	</div>
	<EntryDialog
		:show="showUploadDialog"
		@onClose="showUploadDialog = false"
		:headerText="$t('Squeeze.General.Upload')"
		:showSaveButton="false"
		:showAbortButton="false"
		width="100%"
	>
		<template #content>
			<FreezeUpload
				:defaultStore="selectedStore"
				:defaultHitList="selectedMask"
			/>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import DashboardTile from "@/components/DashboardTile.vue";
import StoresSearchMask from "@/apps/freeze/components/StoresSearchMask.vue";
import {defineComponent, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useProductStore from "@/store/product-store";
import FreezeUpload from "@/apps/freeze/components/FreezeUpload.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {FreezeSearchMask} from "@/apis/freeze/Types";

export default defineComponent({
	name: 'ArchiveSearchView',
	components: {
		DashboardTile,
		StoresSearchMask,
		FreezeUpload,
		EntryDialog,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const storeProduct = useProductStore();

		/** Should the dialog upload be shown? */
		const showUploadDialog = ref(false);

		/** Currently selected Store */
		const selectedStore = ref('');

		/** Currently selected Store */
		const selectedMask = ref<FreezeSearchMask>({
			name: '',
			desc: '',
			fields: [],
		});

		const onChangeStore = (store: string) => {
			selectedStore.value = store;
		}

		const onChangeHitList = (hitList: any) => {
			selectedMask.value = hitList;
		}

		return {
			t, toast, storeProduct, showUploadDialog, selectedStore, selectedMask, onChangeStore, onChangeHitList,
		};
	},
});
</script>
<style lang="scss" scoped>

@media screen and (min-width: 1800px) {
	.p-xxl-4 {
		width: 33.3333%;
	}
}

@media screen and (min-width: 1800px) {
	.p-xxl-1, .p-xxl-2, .p-xxl-3, .p-xxl-4, .p-xxl-5, .p-xxl-6, .p-xxl-7, .p-xxl-8, .p-xxl-9, .p-xxl-10, .p-xxl-11, .p-xxl-12 {
		flex: 0 0 auto;
	}
}

@media screen and (max-width: 767px) {
	.dashboard-container {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 1199px) {
	.search-container {
		margin-top: 0;
	}
}

// Sticky Header
.sticky-header {
	position: fixed;
	top: 0;
	width: 95%;
	z-index: 2;
	background-color: var(--surface-a);
}

.search-content {
	max-height: calc(100vh - 2.5rem);
	overflow-y: auto;
	margin-bottom: 0 !important;
}

</style>
