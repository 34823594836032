<template>
	<div class="p-component">
		<!--div class="navigator-header p-mb-3">
			<Chip class="chip-secondary chip-header p-mr-3">{{ totalEntries }}</Chip>
			<template v-if="totalErrorEntries > 0">
				<Chip class="chip-danger chip-header">{{ totalErrorEntries }}</Chip>
			</template>
		</div-->

		<div class="p-grid">
			<template v-if="!loading">
				<div class="p-col-12 p-md-6 p-lg-4 p-xl-3" v-for="(queueDocClass, index) in queueDocClasses" v-bind:key="queueDocClass.documentClass.id">
					<QueueNavigationCard
						:className="queueDocClass.documentClass.description" :currentCount="queueDocClass.count" :errorCount="queueDocClass.errorCount"
						@click="showQueueList($event, queueDocClass.documentClass.id, index)"
						aria-haspopup="true" :aria-controls="'overlay_panel_' + index"
					/>
					<template v-if="store.state.featureSet.savedDocumentSearches && customTiles.filter(tileFilter => tileFilter.documentClassId === queueDocClass.documentClass.id).length > 0">
						<div style="width: 100%; background-color: var(--dex-primary-color)">
							<Dropdown
								class="queueDropDown"
								:id="'allSearches_' + index"
								v-model="op[index]"
								:options="filterForDocumentClass(queueDocClass.documentClass.id)"
								dataKey="id"
								optionLabel="description"
								optionGroupLabel="label"
								optionGroupChildren="items"
								:filter="customTiles.filter(tileFilter => tileFilter.documentClassId === queueDocClass.documentClass.id || tileFilter.id === -1).length > 8"
								:placeholder="$t('Squeeze.Queue.General.ShowAll')"
								@change="showQueueList($event, queueDocClass.documentClass.id, index)"
								@show="onShowDropdown"
							>
								<template #optiongroup="slotProps">
									<span>
										<i :class="slotProps.option.icon"></i>
										{{ slotProps.option.label }}
									</span>
								</template>
							</Dropdown>
						</div>
					</template>
				</div>
				<template v-if="!isValidation">
					<div class="p-col-12 p-md-6 p-lg-4 p-xl-3" v-for="(tile, index) in customTiles" v-bind:key="tile.id">
						<QueueNavigationCard
							:className="tile.description" :currentCount="-1" :errorCount="-1"
							@click="showQueueEntry(tile.documentClassId, tile.searchRequestJson)"
							aria-haspopup="true" :aria-controls="'overlay_panel_' + index"
						/>
					</div>
				</template>
			</template>
			<template v-else>
				<div class="p-col-12 p-md-6 p-lg-4 p-xl-3" v-for="idx in 8" v-bind:key="idx">
					<Skeleton height="5.9rem" borderRadius="1rem"/>
				</div>
			</template>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import QueueNavigationCard from "@/apps/squeeze/components/QueueNavigationCard.vue";
import router from "@/router";
import {
	DocumentSearchFilterWidget,
	QueueStepDetailsDto,
	QueueStepDto
} from "@dex/squeeze-client-ts";
import Skeleton from 'primevue/skeleton';
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "QueueNavigator",
	components: {
		QueueNavigationCard,
		Skeleton,
		Dropdown,
	},
	props: {
		step: {
			type: Object as PropType<QueueStepDto>,
			required: true,
		},
		customTiles: {
			type: Array as PropType<DocumentSearchFilterWidget[]>,
			default: [],
		},
		isValidation: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** List of all document classes in QueueStep */
		const queueDocClasses = ref<QueueStepDetailsDto[]>([]);

		/** Indicates end of request */
		const loading = ref<boolean>(false);

		/** Total amount of QueueEntries in QueueStep Validation */
		const totalEntries = ref<number>(0);

		/** Total amount of QueueEntries in QueueStep Validation in error state*/
		const totalErrorEntries = ref<number>(0);

		/** References for Searches */
		const op = ref<any[]>([]);

		/** Array with list for Creating/Changing Searches, filtered by DocumentClass ID*/
		const filterForDocumentClass  = (docClassId: number) => {
			const privateSearches = props.customTiles?.filter(search => search.global === false  && search.documentClassId === docClassId);
			const selection = [];

			// default
			selection.push({
				label: '',
				id: -2,
				items: [{
					description: t('Squeeze.Queue.General.ShowAll'),
					id: -1,
				}],
			});

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			const globalSearches = props.customTiles?.filter(search => search.global === true && search.documentClassId === docClassId);
			if (globalSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Global'),
					icon: 'mdi mdi-account-group',
					items: globalSearches,
				})
			}

			return selection;
		};

		const showQueueEntry = (docClassId: number, searchFilters?: string, searchId?: number|undefined) => {
			if (!searchId) {
				searchId = 0;
			}

			if (!searchFilters) {
				router.push({name: "DocumentListValidation", params: {
					documentClassId: docClassId,
				}});
			} else if (props.isValidation) {
				router.push({name: "DocumentListValidationWithSearch", params: {
					documentClassId: docClassId,
					searchRequest: encodeURIComponent(searchFilters),
					searchId: searchId,
				}});
			} else {
				router.push({name: "DocumentList", params: {
					documentClassId: docClassId,
					searchRequest: encodeURIComponent(searchFilters),
					searchId: searchId,
				}});
			}
		}

		/* Sets the last selected search */
		const setLastSearch = (index: number, search: DocumentSearchFilterWidget) => {
			if (store.state.featureSet.savedDocumentSearches) {
				try{
					let searches: any|null|string = localStorage.getItem("ChosenSearches");
					if (searches) {
						searches = JSON.parse(searches) as any[];
						searches['' + index] = search;
					} else {
						searches = {};
						searches['' + index] = search;
					}

					localStorage.setItem("ChosenSearches", JSON.stringify(searches))
				}
				catch(e) {
					localStorage.removeItem("ChosenSearches")
				}
			} else {
				localStorage.removeItem("ChosenSearches")
			}
		}

		/**
		 * Move to QueueListView of selected DocumentClass
		 * @param docClassId
		 */
		const showQueueList = (event: Event, docClassId: number, index?: number) => {
			if (props.customTiles?.length === 0) {
				showQueueEntry(docClassId);
				return;
			}
			let searchId: number|undefined = undefined;
			let searchFilters: string|undefined = undefined;
			if ((index || index === 0) && op.value[index]) {
				try {
					const currentSearch = op.value[index] as DocumentSearchFilterWidget;
					const searchCheck = props.customTiles?.find(search => search.id === currentSearch.id);
					if (searchCheck && currentSearch.searchRequestJson) {
						searchFilters = searchCheck.searchRequestJson;
						searchId = searchCheck.id;
					}

					setLastSearch(index, currentSearch);
				}catch (e) {/**/}
			}

			showQueueEntry(docClassId, searchFilters, searchId);
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.step, () => {
			loading.value = true;

			try {
				totalEntries.value = props.step.stepDetails
					.map(detail => detail.count)
					.reduce((a, x) => a + x, 0);

				totalErrorEntries.value = props.step.stepDetails
					.map(detail => detail.errorCount)
					.reduce((a, x) => a + x, 0);

				// There may be documents without valid class. These are filtered to avoid exceptions.
				queueDocClasses.value = props.step.stepDetails.filter(detail => detail.documentClass);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		})

		onMounted(() => {
			if (store.state.featureSet.savedDocumentSearches) {
				try {
					let searches: any = localStorage.getItem("ChosenSearches");
					if (searches) {
						searches = JSON.parse(searches);
						for (const key in searches) {
							op.value[Number(key)] = searches[key];
						}
					}
				}catch (e) {
					localStorage.removeItem("ChosenSearches")
				}
			} else {
				localStorage.removeItem("ChosenSearches")
			}
		})

		/** Triggered when dropdown panel is shown */
		const onShowDropdown = () => {
			showDropdownOverlay();

			// get dropdown panel to delete the first dropdown list item
			const dropdownPanel = document.querySelector("div.p-dropdown-panel");
			if (dropdownPanel && dropdownPanel.firstElementChild && dropdownPanel.firstElementChild.firstElementChild) {
				const dropdownItemsContainer = dropdownPanel.firstElementChild.firstElementChild;
				if (dropdownItemsContainer && dropdownItemsContainer.firstElementChild) {
					const firstDropdownListItem = dropdownItemsContainer.firstElementChild;
					if (firstDropdownListItem) {
						dropdownItemsContainer.removeChild(firstDropdownListItem);
					}
				}
			}
		}

		return {
			t, toast, queueDocClasses, loading, totalEntries, totalErrorEntries, showQueueList, showQueueEntry, showDropdownOverlay, onShowDropdown, filterForDocumentClass, op, store,
		};
	},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.chip-header {
	font-size: 1.2rem;
}

.p-card.p-component.card {
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
}

.queueDropDown{
	width: 100%;
	background-color: var(--dex-primary-color);
	border: 0px;
	color: white;
}

::v-deep(span.p-dropdown-label.p-inputtext){
	color: var(--dex-text-color);
}
</style>
