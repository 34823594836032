
import { Options , Vue } from 'vue-class-component';
import {ClientManager} from "@/singletons/ClientManager";
import {BatchClass, BatchClassClassification} from "@dex/squeeze-client-ts";
import Dropdown from "primevue/dropdown";
import {ToastManager} from "@/util/ToastManager";
import FileUpload from "@/components/DexFileUpload.vue";

// TODO: This is a fix for https://github.com/swagger-api/swagger-codegen/issues/6403
// Remove if fixed
import * as url from "url";
url.URLSearchParams = URLSearchParams;

@Options({
	name: "DocumentUpload",
	components: {Dropdown, FileUpload},
	props: {
		documentId: String,
	},
})
export default class DocumentUpload extends Vue {

	/** List of all Batch-Class */
	batchClasses?: BatchClass[] = [];

	/** Service for Batch-Classes */
	batchClassService = ClientManager.getInstance().squeeze.batchClass;

	/** List of all Classifications of BatchClass */
	classifications?: BatchClassClassification[] = [];

	/** Currently active Batch-Class */
	activeBatchClass = 0;

	/** Currently active Document-Class */
	activeDocumentClass = 0;

	/** Message that shows the number of the currently uploaded documents  */
	uploadLabel = "";

	/** List of all files to be uploaded */
	files = [{
		uploadFinished: false,
		loading: false,
		errorText: '',
		error: false,
	}]

	/** Current Progress of upload */
	progress = 0;

	/** Show Loading on load batchClass-data */
	loadingBatchClasses = false;

	/** Show Loading on load classification-data */
	loadingClassifications = false;

	mounted() {
		this.loadingBatchClasses = true;

		// Get all Batch-Classes
		this.batchClassService.getAllBatchClasses()
			.then(data => {
				this.batchClasses = data;

				// Pre-Select first valid entry
				if (data[0] && data[0].id) {
					this.activeBatchClass = data[0].id;
				}
			})
			.catch((error: {message: string}) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + error.message);
			})
			.finally(() => {
				this.loadingBatchClasses = false;
				this.getBatchClassClassifications();
			});
	}

	/** Uploads the files from the file-uploader */
	getBatchClassClassifications() {
		this.loadingClassifications = true;
		this.batchClassService.getBatchClassClassifications(this.activeBatchClass)
			.then(data => {
				this.classifications = data;
			})
			.catch((error: {message: string}) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + error.message);
			})
			.finally(() => {
				this.loadingClassifications = false;
			});
	}

	/** Uploads the files from the file-uploader */
	fileUploader(event: any) {
		this.files = event.files;

		this.progress = 0;
		// Calculate progress
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";

		// TODO: Limit the amount of uploads running in parallel (?)
		event.files
			.forEach((file: any, index: number) => {
				if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
					const idx = index;
					this.files[idx].error = false;
					this.files[idx].errorText = "";
					this.files[idx].loading = true;
					this.files = [...this.files];

					this.manualFileUpload(file)
						.then(data => {
							this.files[idx].uploadFinished = true;
						})
						.catch(err => {
							this.files[idx].error = true;
							this.files[idx].errorText = err.message;
						})
						.finally(() => {
							this.files[idx].loading = false;
							this.files = [...this.files];

							// Calculate progress
							const finished = this.files.filter(file => file.uploadFinished);
							this.progress = Math.round((finished.length * 100) / this.files.length);
							this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + finished.length + "/" + this.files.length + ")";
						});
				}
			})
	}

	/**
	 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
	 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
	 * @param file
	 * @returns Object with the id of the created document
	 */
	async manualFileUpload(file: File) {
		// Todo: Once the generated client works, make this function deprecated and use the client function:
		// const result = this.documentService.processDocument(this.activeBatchClass,1, "0", fileName);

		const body = new FormData();
		body.set("batchClassId", String(this.activeBatchClass));
		if (this.activeDocumentClass) {
			body.set("documentClassId", String(this.activeDocumentClass));
		} else {
			body.set("documentClassId", "");
		}
		body.set("file", file);

		const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/documents", {
			method: "POST",
			body: body,
		});

		const data = await response.json();

		if (response.status !== 200) {
			throw new Error(data.message);
		}

		return {
			id: data.id,
		}
	}

	/**
	 * Is triggered when files are selected from the Component
	 * @param event File-Select event
	 */
	onSelectFiles(event: any) {
		this.files = event.files;
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";
	}

	/**
	 * Is triggered when the "clear" button is pressed in the Upload-Component
	 * @param event
	 */
	clearFiles(event: any) {
		this.uploadLabel = this.$t("Squeeze.General.Upload");
	}

	/**
	 * Is triggered when a single file is removed from upload
	 * @param event
	 */
	removeFile(event: any) {
		this.files = event.files;
		this.uploadLabel = this.$t("Squeeze.General.Upload") + " (" + this.files.filter(file => file.uploadFinished).length + "/" + this.files.length + ")";
	}

}

