
import {
	defineComponent, PropType
} from 'vue';
import InputText from "primevue/inputtext";

export default defineComponent({
	name: 'RecordFields',
	components: {
		InputText,
	},
	props: {
		fields: Object as PropType<any>,
		searchMaskFields: Array as PropType<any[]>,
	},
	setup(props, {emit}) {
		/**
		 * Gets the value for search mask field
		 * @param fieldName
		 */
		const getValueFromSearchMask = (fieldName: string) => {
			const fields = props.fields as any;
			if (fields[fieldName]) {
				return fields[fieldName] as string;
			}

			return "";
		}

		return {
			getValueFromSearchMask,
		};
	},
});

