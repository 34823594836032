<template>
	<DataTable
		:value="allScripts"
		:loading="loading"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		class="p-datatable-sm"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:autoLayout="true"
		columnResizeMode="fit"
		:rowHover="true"
		:scrollable="true"
		scrollHeight="flex"
    @page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{ $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.Scripts') }) }}
		</template>

		<Column field="name" :header="$t('Squeeze.System.Script')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
		</Column>
		<Column field="type" :header="$t('Squeeze.System.Type')" :sortable="false" style="min-width: 8rem;" />

		<Column class="buttonColumn" style="min-width: 4rem; max-width: 4rem;">
			<template #body="slotProps">
				<Button v-tooltip.left="$t('Squeeze.System.ExecuteScript')"
						icon="mdi mdi-play-speed"
						class="p-button-lg p-button-rounded p-button-text"
						@click="executeScript(slotProps.data)"
						:disabled="!store.state.scopes.sqzScriptsExecute"
				/>
			</template>
		</Column>

		<template #paginatorLeft></template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {ScriptDto} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "ScriptsList",
	components: {DataTable, Column},
	props: {
		allScripts: {
			type: Array as PropType<ScriptDto[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ['executeScript'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Execute the Script
		 * @param script The script to execute
		 */
		const executeScript = (script: ScriptDto) => {
			emit('executeScript', script)
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {store, tableListPagination, executeScript, onChangePage};
	},
});

</script>

<style scoped>

/** Reduce height of edit/delete buttons */
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>


