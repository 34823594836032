
import {Options, Vue} from "vue-class-component";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import LocatorForm from "@/apps/administration/components/locators/LocatorForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import BlockUI from "primevue/blockui";
import router from "@/router";
import useSqueezeStore from "@/apps/squeeze/store";
import {Clients, ConsoleLogger, LocatorConfig, LocatorExporter} from "@dex/squeeze-configframework";
import {copyToClipboard, downloadData} from "@/util/Export";

@Options({
	name: 'LocatorSubView',
	methods: {copyToClipboard, downloadData},
	components: {
		LocatorForm, BlockUI, EntryDialog,
	},
	props: {
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onLocatorChange',
	],
	watch: {
		locatorId: function() {
			this.getLocator();
		},
	},
})

export default class LocatorSubView extends Vue {

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Currently active Locator */
	locatorId!: number

	/** All Document-Class-Export-Interfaces */
	locator?: any = { }

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** List of all locators **/
	locatorTypes?: LocatorType[] = [];

	/** Service for Document-Classes */
	locatorService = ClientManager.getInstance().squeeze.locator

	/** Is the page currently loading it's data? */
	loading = false;

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	/** Exported locator data as string  */
	locatorData: string = '';

	/** Is Export Dialog visible? */
	showExportDialog: boolean = false;

	mounted() {
		this.getLocator()
	}

	/** Gets the Document-Classes */
	getLocator() {
		const handleErr = (msg: string, err: any) => ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t(msg) + ": " + err);
		this.loading = true;

		let promise1 = undefined;
		if (this.locatorId) {
			promise1 = this.locatorService.getLocatorById(this.locatorId)
				.then(data => this.locator = data)
				.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))
		}

		const promise2 = this.locatorService.getLocatorTypes()
			.then((data: any) => this.locatorTypes = data)
			.catch((err: any) => handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err));

		const promise3 = this.locatorService.getAllLocators()
			.then(data => {
				// add defaultLocator to locators
				const defaultLocator: any = {
					id: 0,
					name: 'NoLocator',
					description: this.$t('Squeeze.Locators.NoLocator'),
				}
				this.locators = data.filter(loc => loc.id !== this.locator.id); // locators should not be able to reference themselves
				this.locators.unshift(defaultLocator);
			})
			.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))

		// Wait until promises are finished
		Promise.all([promise1, promise2, promise3]).then(() => this.loading = false);
	}

	/** Saves a Locator */
	saveLocator() {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		let promise;

		this.showErrorMessage = false;
		this.loading = true;

		// Some old entries do have an empty string as type, which is the same as "Text", but can't be saved as such
		if (this.locator.dataType === "" || this.locator.locatorType === 2) {
			//the Locator-Type "Invoice Amounts" only allows "Text"
			this.locator.dataType = "Text";
		}

		if (!this.locator.id) {
			// Create New Entry
			promise = this.locatorService.postLocator(this.locator)
				.then(data => {
					ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.CreateSuccess'))
					if (data.id) {
						router.push({ name: 'LocatorSubView', params: { locatorId: data.id}})
						this.locator.id = data.id;
					}
				})
		} else {
			// Update Entry
			promise = this.locatorService.putLocatorById(this.locatorId!, this.locator)
				.then(() => ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.SaveSuccess')))
		}

		promise
			.catch((err: { statusText: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.statusText)
			})
			.finally(() => {
				this.loading = false;
			});
	}

	/** Triggered on update of attribute-form
	 * @param data
	 * @param valid
	 */
	onUpdate(data: DocumentLocator, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.locator, data);
		this.$emit("onLocatorChange", this.locator);
	}

	/** Export of a locator */
	async exportLocator() {
		const clients: Clients = {
			documentClass: ClientManager.getInstance().squeeze.documentClass,
			masterData: ClientManager.getInstance().squeeze.masterData,
			locator: ClientManager.getInstance().squeeze.locator,
		}
		const locatorExporter = new LocatorExporter(clients, new ConsoleLogger());

		if (this.locator.name) {
			locatorExporter.addLocator(this.locator.name);

			await this.runExportOfLocator(locatorExporter);
		}
	}

	/** Run export of locator */
	async runExportOfLocator(locatorExporter: LocatorExporter) {
		this.loading = true;
		this.locatorData = "";

		try {
			const data: LocatorConfig[] = await locatorExporter.run();
			this.locatorData = JSON.stringify({locators: data}, null, 2);
			this.showExportDialog = true;
		} catch (error) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), error);
		} finally {
			this.loading = false;
		}
	}

}
