<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="searchValue">{{$t('Squeeze.Locators.Tabs.SearchValue')}} *</label>
			<InputText id="searchValue" v-model="value.searchValue" :class="{'p-invalid':v$.searchValue.$invalid && showErrorMessage}" @change="update" autofocus />
			<small v-if="v$.searchValue.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.SearchValue')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorSearchValue} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "SearchValuesForm",
	components: {
		InputText,
	},
	props: {
		searchValue: {
			type: Object as PropType<LocatorSearchValue>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorSearchValue>({searchValue: ''});

		/** Determines the required rules for validation */
		const rules = {searchValue: { required }}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(() => {
			Object.assign(value, props.searchValue);
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.searchValue, () => {
			Object.assign(value, props.searchValue);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
