<template>
	<DataTable class="p-component p-shadow-2"
		:value="locatorValues"
		:rowHover="true"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		:loading="loading"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:autoLayout="true"
		columnResizeMode="fit"
		selectionMode="single"
		@row-click="onMarkWord($event)"
		@row-hover="onMarkWord($event)"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.General.Values') }) }}
		</template>
		<Column field="value" :header="$t('Squeeze.Locators.Value')"></Column>
		<Column field="boundingBox.page" :header="$t('Squeeze.Locators.Site')"></Column>
		<Column field="boundingBox.x0" header="x0"></Column>
		<Column field="boundingBox.y0" header="y0"></Column>
		<Column field="boundingBox.x1" header="x1"></Column>
		<Column field="boundingBox.y1" header="y1"></Column>

		<template #paginatorLeft></template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DocumentFieldValue} from "@dex/squeeze-client-ts";
import DataTable from "@/components/DexDataTable.vue";
import Column from "primevue/column";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "DocumentLocatorTestingTable",
	components: { DataTable, Column },
	props: {
		locatorValues: {
			type: Array as PropType<DocumentFieldValue[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onMarkWord',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t,
			toast,
			store,
			tableListPagination,
			onMarkWord,
			onChangePage,
		}
	},
});
</script>

<style scoped>

</style>


