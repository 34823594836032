<template>
	<DataTable
		:value="locatorSettings"
		:loading="loading"
		class="p-datatable-sm"
		:paginator="true"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		:rows="tableListPagination.pageSize"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Locators.LocatorsAttributes') }) }}
		</template>
		<Column field="setting" :header="$t('Squeeze.Locators.Name')" :sortable="false" filterMatchMode="contains" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getSettingsDescription(slotProps.data.setting)">
					{{ getSettingsDescription(slotProps.data.setting) }}
				</div>
			</template>
		</Column>
		<Column field="value" :header="$t('Squeeze.Locators.Value')" :sortable="false" filterMatchMode="contains" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.value">
					{{ slotProps.data.value }}
				</div>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="openEditDialog({})" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import {DocumentField, LocatorSetting} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

@Options({
	name: 'LocatorAttributes',
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** Currently active document-class */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** All available Locators */
		locatorSettings: {
			type: Array,
			default: [],
		},
		/** All Valid Settings */
		supportedLocatorSettings: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "openDelete"],
})

export default class LocatorAttributes extends Vue  {

	/** List of all locators **/
	locatorSettings!: LocatorSetting[];

	/** List of valid-settings */
	supportedLocatorSettings!: LocatorSetting[];

	/** Currently active Locator-ID */
	locatorId!: number;

	/** Should the loading be shown in the table? */
	loading!: boolean;

	/** Vuex Store */
	store = useSqueezeStore();

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current table list pagination */
	tableListPagination: TableListSettings = {};

	/** Data of selected row */
	rowData = {};

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentField} data Data to edit
	 */
	openEditDialog(data: DocumentField) {
		this.rowData = {...data};
		this.$emit("openEntryDialog", this.rowData, this.locatorId)
	}

	/**
	 * Opens the Delete Dialog
	 * @param {LocatorSettingDto} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: LocatorSetting) {
		this.rowData = {...data};
		this.$emit("openDelete", this.rowData)
	}

	getSettingsDescription(settingName: string) {
		if (!this.supportedLocatorSettings) {
			return settingName
		}

		const locatorType = this.supportedLocatorSettings.find((i) => {
			return i.setting === settingName
		});

		if (!locatorType) {
			return settingName
		}

		return locatorType.setting
	}

	/** on page mounted */
	mounted() {
		this.tableListPagination.pageSize = TableSettings.getTableListPagination(this.store, this.$route.name);
	}

	/** Triggered when page is changed */
	onChangePage(event: { page: number; rows: number }) {
		this.tableListPagination.pageSize = event.rows;
		TableSettings.saveTableListPagination(this.$t, this.$toast, this.store, this.userApi, this.$route.name, event.rows);
	}

}
</script>

<style scoped>
/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
