<template>
	<div class="p-p-2 content">
		<MigrationsList :migrations="migrations" :loading="loading" @openEntryDialog="openEntryDialog"/>
	</div>

	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false, isValidationInvalid = true, showValidationMessage = false, showInvalidPassword = false"
		@onConfirm="runMigrations"
		:loading="loadingDialog"
		:headerText="headerText"
		@keydown.enter="runMigrations"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<MigrationsForm
					:showErrorMessage="showValidationMessage"
					:invalidPassword="showInvalidPassword"
					@update="onUpdate"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {MigrationStatus} from "@dex/squeeze-client-ts";
import MigrationsList from "@/apps/administration/components/system/MigrationsList.vue";
import MigrationsForm from "@/apps/administration/components/system/MigrationsForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "MigrationView",
	components: {MigrationsList, MigrationsForm, EntryDialog, BlockUI},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on refresh */
		const loadingDialog = ref(false);

		/** Is the Form of the migrations invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Show invalid password text */
		const showInvalidPassword = ref(false);

		/** Array with the migrations */
		const migrations = ref<MigrationStatus[]>([]);

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Service for getting all system services */
		const systemService = ClientManager.getInstance().squeeze.system;

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.SpecifyEntry', { entryName: t('Squeeze.Login.Password') }));

		/** Password to run Migrations */
		const migrationsPassword = ref<string>('');

		/** Get all migrations */
		const getAllMigrations = () => {
			loading.value = true;
			systemService.getMigrations()
				.then(data => {
					// Reverse order of migrations
					data.reverse();
					migrations.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			getAllMigrations();
		})

		/**
		 * Triggered when an entry should be shown
		 * @param password
		 */
		const openEntryDialog = (password: string) => {
			Object.assign(migrationsPassword, password);
			showDialog.value = true;
		}

		/** Run migrations to reload */
		const runMigrations = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;

			systemService.runMigrations(migrationsPassword.value)
				.then(() => {
					showDialog.value = false;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.MigrationSuccess'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					showInvalidPassword.value = true;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingDialog.value = false;
					getAllMigrations();
					store.dispatch("fetchMigrations");
				})
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: { password: string }, isInvalid: boolean) => {
			showInvalidPassword.value = false;
			isValidationInvalid.value = isInvalid;
			migrationsPassword.value = data.password;
		}

		return {t, toast, loading, loadingDialog, isValidationInvalid, showDialog, showValidationMessage, showInvalidPassword, migrations, headerText, openEntryDialog, runMigrations, onUpdate};
	},
});

</script>

<style scoped>

.content {
	height: calc(100vh - 5.6rem);
}

</style>
