<template>
	<div class="p-component">
		<div v-if="fields.length > 0" class="p-fluid p-formgrid p-grid" :class="maxFieldHeight ? 'fields-scroll' : ''" :style="{maxHeight: maxFieldHeight}">
			<div class="p-field p-input-filled p-col-12">
				<label :for="'input-fulltext'">{{$t('Squeeze.General.Fulltext')}}</label>
				<InputText v-model="fullText" id="input-fulltext" @keyup.enter="submit()"></InputText>
			</div>
			<div v-for="field in fields" :key="field.id" class="p-field p-input-filled p-col-12 p-md-6">
				<label :for="'input-' + field.id">{{field.name}}</label>
				<InputText v-model="field.filter.value" :id="'input-' + field.id" @keyup.enter="submit()"></InputText>
			</div>
		</div>
		<div class="p-fluid p-formgrid p-grid">
			<div class="p-col-12">
				<Button @click="submit()" :disabled="!canSubmit">{{$t('Platform.Search.Title')}}</Button>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import {computed, defineComponent, PropType, ref} from 'vue';
import InputText from "primevue/inputtext";
import {Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {DocumentSearchRequestDto, FulltextFilterDto} from "@dex/squeeze-client-ts";
import {FieldValueFilterDto} from "@dex/squeeze-client-ts";

/**
 * Defines a field that can be searched in
 */
interface SearchField {
	id: number;
	name: string;
	type: string;
}

/**
 * Defines a search mask that can be displayed
 */
export interface SearchMask {
	id: number;
	fields: Array<SearchField>;
}

type SearchRequest = DocumentSearchRequestDto;

export default defineComponent({
	name: 'DocumentSearchMask',
	props: {
		searchMask: {
			type: Object as PropType<SearchMask>,
			required: true,
		},
		maxFieldHeight: {
			type: String,
			required: false,
			default: "",
		},
	},
	components: {
		InputText,
	},
	emits: [
		"submit",
	],

	setup(props, {emit}) {
		/** Fulltext string */
		const fullText = ref('');

		const fields = computed(() => {
			return props.searchMask.fields.map(field => {
				const filter: Filter = {
					value: "",
					matchMode: FilterMatchMode.CONTAINS,
				}
				return Object.assign({}, field, {filter});
			});
		})

		const searchRequest = computed<SearchRequest>(() => {
			const fullTextArray: FulltextFilterDto[] = [];
			if (fullText.value) {
				fullTextArray.push({
					searchValue: "*" + fullText.value + "*",
					comp: 'raw',
				})
			}
			return {
				workflowContextFilters: [],
				fulltextFilters: fullTextArray,
				fieldFilters: fields.value.map(field => {
					const target: FieldValueFilterDto = {
						fieldId: field.id,
						searchValue: field.filter.value,
						comp: "raw" as any, // FIXME: Using the proper enum here somehow breaks our ts build.
						fieldType: field.type.toLowerCase(),
					}
					return target;
				}).filter(f => f.searchValue !== ""),
			}
		});

		const submit = () => {
			emit("submit", searchRequest.value);
		}

		const canSubmit = computed(() => {
			return fields.value.length > 0;
		});

		return {
			fields,
			searchRequest,
			submit,
			canSubmit,
			fullText,
		}
	},
});

</script>
<style lang="scss" scoped>

.fields-scroll {
	overflow-y: auto;
	margin-bottom: 1rem;
}

/* width & height */
::-webkit-scrollbar {
	width: 0.525rem;
	height: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--dex-primary-light-color);
	border-radius: 0.525rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--dex-primary-color);
}

</style>
