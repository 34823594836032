<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="id">{{$t('Squeeze.UserManagement.Field')}} *</label>
			<Dropdown
				id="id"
				v-model="value.fieldId"
				:options="documentClassFields"
				@change="update"
				:loading="loading"
				optionValue="id"
				optionLabel="description"
				:filter="true"
				@show="showDropdownOverlay"
			>
			</Dropdown>
			<small v-if="v$.fieldId.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Field')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="operator">{{$t('Squeeze.UserManagement.Operator')}} *</label>
			<Dropdown
				id="operator"
				v-model="value.operator"
				:options="operators"
				@change="update"
				:loading="loading"
				optionValue="id"
				optionLabel="description"
				:filter="true"
				@show="showDropdownOverlay"
			>
			</Dropdown>
			<small v-if="v$.operator.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Operator')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="filterValue">{{$t('Squeeze.UserManagement.FilterValue')}} *</label>
			<InputText id="filterValue" v-model="value.filterValue" :class="{'p-invalid':v$.filterValue.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.filterValue.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.FilterValue')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import {RoleFilterFieldCondition, DocumentField} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import {CompareOperations} from "@/util/CompareOperations";
import InputText from "primevue/inputtext";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "FieldConditionsForm",
	components: {
		Dropdown, InputText,
	},
	props: {
		fieldCondition: {
			type: Object as PropType<RoleFilterFieldCondition>,
			required: true,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			required: true,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
		operators: {
			type: Array as PropType<CompareOperations[]>,
			required: true,
			default: [],
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<RoleFilterFieldCondition>({
			operator: undefined,
			fieldId: undefined,
			id: undefined,
			filterValue: undefined,
		});

		/** Determines the required rules for validation */
		const rules = {
			fieldId: {required},
			operator: {required},
			filterValue: {required},
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldCondition);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldCondition, () => {
			Object.assign(value, props.fieldCondition);
		});

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		};

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
			showDropdownOverlay,
		}
	},
});

</script>

<style scoped>

</style>
