<template>
	<DataTable
		:value="migrations"
		:loading="loading"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		class="p-datatable-sm"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:autoLayout="true"
		columnResizeMode="fit"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{ $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.Migrations') }) }}
		</template>

		<Column field="status" :header="$t('Administration.Migrations.Status')" style="min-width: 4rem; max-width: 4rem;">
			<template #body="migration">
				<div>
					<i :class="migration.data.up ? 'mdi mdi-check' : 'mdi mdi-close'"></i>
				</div>
			</template>
		</Column>

		<Column field="raw" :header="$t('Administration.Migrations.Migration')">
			<template #body="slotProps">
				<div>
					{{slotProps.data.raw}}
				</div>
			</template>
		</Column>

		<template #paginatorLeft>
			<Button type="button" :label="$t('Squeeze.System.RunMigration')" icon="mdi mdi-refresh" @click="openEntryDialog" :disabled="loading" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {MigrationStatus} from "@dex/squeeze-client-ts";
import useSqueezeStore from "@/apps/squeeze/store";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {ClientManager} from "@/singletons/ClientManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "MigrationsList",
	components: {DataTable, Column},
	props: {
		migrations: {
			type: Array as PropType<MigrationStatus[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['openEntryDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		const openEntryDialog = () => {
			emit("openEntryDialog", {password: ''});
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return { tableListPagination, openEntryDialog, onChangePage };
	},
});

</script>

<style scoped>

</style>

