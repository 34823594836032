
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import {Options, Vue} from "vue-class-component";
import {FieldTraining} from '@dex/squeeze-client-ts';
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: "DocumentFieldTrainingForm",
	components: {
		InputText,
		Dropdown,
		Button,
	},
	props: {
		documentClassId: Number,
		documentFields: Array,
		fieldTrainingValue: Object,
		fieldTestingValues: Array,
		tableTrainingValues: Array,
		documentClassFields: Array,
		trainingKeyField: Object,
		showTrainDocument: Boolean,
		warnAmount: Boolean,
		blockButtonTraining: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onFocusField',
		'trainFieldValues',
		'onChangeDocumentField',
		'testingFieldValues',
	],
})
export default class DocumentFieldTrainingForm extends Vue {

	/** ID of document class*/
	documentClassId!: number;

	/** Current field training value of form  */
	fieldTrainingValue!: FieldTraining;

	/** List of all training values of FieldTraining */
	tableTrainingValues!: FieldTraining[];

	/** List Values for Train Document */
	trainWhitespaceOptions  = [
		{
			id: true,
			name: "Yes",
		},
		{
			id: false,
			name: "No",
		},
	];

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		this.trainWhitespaceOptions = [
			{
				id: true,
				name: this.$t('Squeeze.General.Yes'),
			},
			{
				id: false,
				name: this.$t('Squeeze.General.No'),
			},
		]
		this.fieldTrainingValue.valueIgnoreWhitespace = false;
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusField(fieldName: string) {
		this.$emit("onFocusField", fieldName);
	}

	/** Get all field values */
	trainFieldValues() {
		this.$emit("trainFieldValues");
	}

	/** Get all training values for training table */
	onChangeDocumentField() {
		this.$emit('onChangeDocumentField');
	}

	/** Testing all training values for training table */
	testingFieldValues() {
		this.$emit("testingFieldValues");
	}

}
