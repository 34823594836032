<template>
	<div></div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import router from "@/router";

export default defineComponent({
	name: "KeyCloakLoginCheck",
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		onMounted(async () => {
			// Set OAuth-Data
			await ClientManager.getInstance().setPossibleAuthentications();

			ClientManager.getInstance().checkKeyCloakLogin().then(async (auth: boolean) => {
				if (!auth) {
					// Reset All Key-Cloak Information, if login failed. Otherwise endless login-loops might occur
					ClientManager.getInstance().resetKeyCloakTokens();
					await router.push({ name: 'Login'});
				} else {
					const fullPath = localStorage.getItem("firstUrl");
					if (!fullPath) {
						await router.push({ name: 'SqueezeDashboard'});
					} else {
						await router.push({path: fullPath});
					}
				}
			})
		});

		return {
			t,
			toast,
			store,
			props,
		}
	},
});

</script>

<style scoped>

</style>
