<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled p-input-filled">
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.DocumentClasses.Name')}} *</label>
			<InputText id="name" v-model.trim="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
			<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
			<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.DocumentClasses.Description')}} *</label>
			<InputText id="description" v-model.trim="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Description')}) }}
			</small>
		</div>
		<div v-if="false" class="p-field p-col-6">
			<label for="type">{{$t('Squeeze.DocumentClasses.Type')}}</label>
			<Dropdown id="type" style="min-width: 250px" v-model="props.fieldEntry.type" :options="fieldGroupsTypes" optionValue="id" optionLabel="description" />
		</div>
		<div v-if="fieldEntry.type === 1" class="p-field p-col-6">
			<label for="tableField">{{$t('Squeeze.DocumentClasses.TableField')}}</label>
			<InputText id="tableField" v-model.trim="props.fieldEntry.tableField" required="true" />
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentFieldGroup} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import {useI18n} from "vue-i18n";
import {technicalValidation} from "@/util/ValidationHelper";

interface FieldGroupsTypes {
	id: number | undefined;
	description: string;
}

export default defineComponent({
	name: "FieldFormFieldGroups",
	components: {
		InputText, Dropdown,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<DocumentFieldGroup>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Valid field-group types. Have to be set in created, otherwise i18n is not initialized */
		const fieldGroupsTypes = computed(() => {
			return [
				{
					id: 0,
					description: t('Squeeze.DocumentClasses.Head'),
				},
				{
					id: 1,
					description: t('Squeeze.DocumentClasses.Position'),
				},
			] as FieldGroupsTypes[]
		});

		/** Entry-Data of the field-group */
		const value = reactive<DocumentFieldGroup>({name: '', description: '', type: 0});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/** Triggered when input-field is changed */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			fieldGroupsTypes,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
