<template>
	<DataTable
		:value="lookupFilters"
		:loading="loading"
		class="p-datatable-sm editable-cells-table"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.DocumentClasses.Filter') }) }}
		</template>
		<template #footer>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<!--<Column field="id" header="ID" :sortable="false" class="id-column" />-->
		<!--Column field="batchClassId" :header="$t('Squeeze.BatchClasses.BatchClass')" :sortable="false" /-->
		<Column field="masterDataColumnId" :header="$t('Squeeze.DocumentClasses.DatabaseColumn')" :sortable="false">
			<template #body="slotProps">
				{{getDescriptionFromColumn(slotProps.data.masterDataColumnId)}}
			</template>
		</Column>
		<Column field="masterDataColumnId" :header="$t('Squeeze.DocumentClasses.Operand')" :sortable="false">
			<template #body="slotProps">
				<template v-if="slotProps.data.operand ==='eq'">
					{{$t('Squeeze.DocumentClasses.Equal')}}
				</template>
				<template v-else-if="slotProps.data.operand ==='cn'">
					{{$t('Squeeze.DocumentClasses.Contains')}}
				</template>
				<template v-else>
					{{slotProps.data.operand}}
				</template>
			</template>
		</Column>
		<Column field="valueFieldId" :header="$t('Squeeze.DocumentClasses.Field')" :sortable="false" >
			<template #body="slotProps">
				{{getDescriptionFromDocumentClassField(slotProps.data.valueFieldId)}}
			</template>
		</Column>
		<!-- Bearbeiten / Löschen -->
		<Column class="buttonColumn">
			<template #body="files">
				<Button v-if="false" v-tooltip="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(files.data)" />
				<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(files.data)" />
			</template>
		</Column>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, PropType} from "vue";
import {DocumentField, FieldLookupFilter, MasterDataColumn} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "LookupFilters",
	components: {
		DataTable, Column,
	},
	props: {
		lookupFilters: {
			type: Array as PropType<FieldLookupFilter[]>,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: [],
		},
		masterDataTableColumns: {
			type: Array as PropType<MasterDataColumn[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: FieldLookupFilter) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				masterDataColumnId: undefined,
				valueFieldId: undefined,
				operand: "eq",
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: FieldLookupFilter) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a Column
		 * @param fieldId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromColumn  = (columnId: number) => {
			if (columnId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.masterDataTableColumns!.find(i => i.id === columnId);

			if (!documentClassDescription) {
				return String(columnId)
			}

			return documentClassDescription.description
		}

		/**
		 * Gets the Description of a documentClass Field
		 * @param fieldId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClassField = (fieldId: number) => {
			if (fieldId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.documentClassFields!.find(i => i.id === fieldId);

			if (!documentClassDescription) {
				return String(fieldId)
			}

			return documentClassDescription.description
		}

		return {
			openDeleteDialog, onNewEntryClick, openEditDialog, getDescriptionFromDocumentClassField,
			getDescriptionFromColumn,
		}
	},
});

</script>

<style scoped>

/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

/*::v-deep(td.id-column) {
	width: 5rem;
}*/

</style>

