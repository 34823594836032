<template>
	<DataTable
		:value="passwords"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="25"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Imports.Passwords') }) }}
		</template>

		<Column field="id" header="ID" :sortable="false" class="id-column" style="min-width: 4rem; max-width: 6rem;" />
		<Column field="email" :header="$t('Squeeze.UserManagement.Email')" :sortable="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.email">
					{{slotProps.data.email}}
				</div>
			</template>
		</Column>
		<Column field="password" :header="$t('Squeeze.Login.Password')" style="min-width: 7rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.password">
					{{slotProps.data.password}}
				</div>
			</template>
		</Column>

		<!-- Bearbeiten / Löschen -->
		<Column class="buttonColumn" style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="password">
				<Button icon="mdi mdi-pencil-outline" v-tooltip="$t('Squeeze.Locators.Edit')" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(password.data)" />
				<Button icon="mdi mdi-delete-outline" v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(password.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import {defineComponent, PropType} from "vue";
import {MailZipPassword} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "PasswordList",
	components: {
		DataTable, Column,
	},
	props: {
		passwords: {
			type: Array as PropType<MailZipPassword[]>,
			default: [],
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onEntrySelect", "onClickDelete",
	],
	setup(props, {emit}) {
		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				email: '',
				password: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: MailZipPassword) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MailZipPassword) => {
			emit("onClickDelete", row);
		}

		return {
			onNewEntryClick, openEditDialog, openDeleteDialog,
		}
	},
});
</script>

<style lang="scss" scoped>

/** Reduce height of edit/delete buttons */
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
