<template>
	<div class="p-p-2 content">
		<LocatorList
			:locators="locators"
			:locatorTypes="locatorTypes"
			:loading="loading"
			@openEntryDialog="openEntryDialog"
			@deleteEntry="deleteEntry"
			@exportAllLocators="exportAllLocators"
		/>
	</div>

	<EntryDialog
		:show="showExportDialog"
		@onClose="showExportDialog = false"
		headerText="Export"
		:showSaveButton="false"
		:abortButtonText="$t('Squeeze.General.Abort')"
	>
		<template #content>
			<p>{{  $t('Squeeze.General.SuccessfulExport', { entryName: $t('Squeeze.Locators.Locators') }) }}</p>
			<div class="p-fluid p-formgrid p-grid p-jc-end">
				<div class="p-field p-col-12">
					<Button
						:label="$t('Squeeze.General.CopyToClipboard')"
						icon="mdi mdi-content-copy"
						@click="copyToClipboard($t, $toast, allLocatorData), allLocatorData = '', showExportDialog = false"
					/>
				</div>
				<div class="p-field p-col-12">
					<Button
						:label="$t('Squeeze.General.Download')"
						icon="mdi mdi-file-download-outline"
						@click="downloadData('locators', allLocatorData), allLocatorData = '', showExportDialog = false"
					/>
				</div>
			</div>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import LocatorList from "@/apps/administration/components/locators/LocatorList.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";
import {Clients, ConsoleLogger, LocatorConfig, LocatorExporter} from "@dex/squeeze-configframework";
import {copyToClipboard, downloadData} from "@/util/Export";

@Options({
	name: 'LocatorsListView',
	methods: {copyToClipboard, downloadData},
	components: {
		LocatorList,
		EntryDialog,
	},
})

export default class LocatorsListView extends Vue  {

	/** Service for getting the locator-data */
	locatorService = ClientManager.getInstance().squeeze.locator;

	/** List of all locators **/
	locators?: DocumentLocator[] = [];

	/** List of all locators **/
	locatorTypes?: LocatorType[] = [];

	/** Should the loading be shown in the table? */
	loading = false;

	/** Exported all locator data as string  */
	allLocatorData: string = '';

	/** Is Export Dialog visible? */
	showExportDialog: boolean = false;

	mounted() {
		this.getLocators()
	}

	getLocators() {
		this.loading = true

		const handleErr = (msg: string, err: any) => ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t(msg) + ": " + err);

		const promiseAllLocators = this.locatorService.getAllLocators()
			.then(data => this.locators = data)
			.catch(response => response.json().then ((err: any) => {
				handleErr('Squeeze.Locators.ErrorLoadLocators', err.message)
			}))

		const promiseLocatorTypes = this.locatorService.getLocatorTypes()
			.then((data: any) => this.locatorTypes = data)
			.catch(response => response.json().then ((err: any) => {
				handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err.message)
			}))

		// Wait until promises are finished
		Promise.all([promiseAllLocators, promiseLocatorTypes]).then(() => this.loading = false);
	}

	/** Opens the LocatorSubView-Component */
	openEntryDialog() {
		router.push({ name: 'LocatorSubView', params: { locatorId: 0}})
	}

	/** Deletes an entry */
	deleteEntry(rowData: any) {
		if (!rowData) {
			return
		}
		this.loading = true;
		const id = rowData.id;
		this.locatorService.deleteLocatorById(id as any).then(() => {
			this.getLocators();
		}).catch(err => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.ErrorDelete'), this.$t('Squeeze.General.DeleteError') + ": " + err)
		}).finally(() => {
			this.loading = false;
		});
	}

	/** Export all locators */
	async exportAllLocators() {
		/** Api-Clients */
		const clients: Clients = {
			documentClass: ClientManager.getInstance().squeeze.documentClass,
			masterData: ClientManager.getInstance().squeeze.masterData,
			locator: ClientManager.getInstance().squeeze.locator,
		};

		const locatorExporter = new LocatorExporter(clients, new ConsoleLogger());
		await this.runExportOfAllLocators(locatorExporter);
	}

	/** Run export of all locators */
	async runExportOfAllLocators(exporter: LocatorExporter) {
		this.loading = true;
		this.allLocatorData = "";

		try {
			const data: LocatorConfig[] = await exporter.run();
			this.allLocatorData = JSON.stringify({locators: data}, null, 2);
			this.showExportDialog = true;
		} catch (error) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), error);
		} finally {
			this.loading = false;
		}
	}

}
</script>

<style scoped>
</style>
