import {RouteRecordRaw} from "vue-router";
import App from "../App.vue";
import DashboardView from "../views/Dashboard.vue";
import ValidationDocumentListView from "../views/ValidationDocumentList.vue";
import DocumentListView from "../views/DocumentListView.vue";
import ValidationView from "../views/Validation.vue";
import {DocumentSearchRequestDto} from "@dex/squeeze-client-ts";
import QueueView from "@/apps/squeeze/views/QueueView.vue";
import {defaultSort} from "@/util/SortingHelper";
import Reports from "@/apps/squeeze/views/Reports.vue";

/**
 * Maps route params to props datatypes for ValidateEntry named path
 * @param route
 */
function mapValidationProps(route: any) {
	return {
		documentId: route.params.documentId ? Number(route.params.documentId) : undefined,
		documentClassId: route.params.documentClassId ? Number(route.params.documentClassId) : undefined,
		searchRequest: route.params.searchRequest ? JSON.parse(route.params.searchRequest) : {},
		pagination: route.params.pagination ? JSON.parse(route.params.pagination) : {pageSize: 25,page: 0,total: 0},
		tableSortStart: route.params.tableSortStart ? JSON.parse(route.params.tableSortStart) : [defaultSort],
		searchId: route.params.searchId ? Number(route.params.searchId) : undefined,
	}
}

/**
 * Route that goes directly to the Validation-List of a Document-Class
 * @param route
 */
function mapValidationListPropsValidation(route: any) {
	let searchRequest = {} as DocumentSearchRequestDto;
	searchRequest.workflowContextFilters = [
		{
			fieldName: "queueStep",
			comp: "eq",
			searchValue: "validation",
			fieldType: "text",
		},
	]

	if (route.params.searchRequest) {
		searchRequest = JSON.parse(decodeURIComponent(route.params.searchRequest)) as DocumentSearchRequestDto;
	}

	return {
		documentClassId: route.params.documentClassId ? Number(route.params.documentClassId) : undefined,
		searchRequest: searchRequest,
		tableSortStart: route.params.tableSortStart ? JSON.parse(route.params.tableSortStart) : [defaultSort],
		pagination: route.params.pagination ? JSON.parse(route.params.pagination) : {pageSize: 25,page: 0,total: 0},
		searchId: route.params.searchId ? Number(route.params.searchId) : undefined,
	}
}

/**
 * Maps route params to props datatypes for ValidationList named path
 * @param route
 */
function mapValidationListProps(route: any) {
	return {
		documentClassId: route.params.documentClassId ? Number(route.params.documentClassId) : undefined,
		searchRequest: route.params.searchRequest ? JSON.parse(decodeURIComponent(route.params.searchRequest)) : {},
		tableSortStart: route.params.tableSortStart ? JSON.parse(route.params.tableSortStart) : [defaultSort],
		pagination: route.params.pagination ? JSON.parse(route.params.pagination) : {pageSize: 25,page: 0,total: 0},
		searchId: route.params.searchId ? Number(route.params.searchId) : undefined,
	}
}

/**
 * @param pathBase URL path that defines this app.
 */
export function createRoutes(pathBase: string): Array<RouteRecordRaw> {
	return [
		{
			path: pathBase,
			name: "Squeeze",
			component: App,
			children: [
				{
					path: "",
					name: "SqueezeDashboard",
					component: DashboardView,
					meta: {
						translate: true,
					},
				},
				{
					path: "reports",
					name: "Reports",
					component: Reports,
				},
				{
					path: "validation/:documentClassId",
					name: "ValidationList",
					component: ValidationDocumentListView,
					props: mapValidationListProps,
					meta: {
						translate: true,
					},
				},
				{
					path: "dc/:documentClassId/list/:searchRequest",
					name: "DocumentList",
					component: DocumentListView,
					props: mapValidationListProps,
					meta: {
						translate: true,
					},
				},
				{
					path: "dc/:documentClassId/list",
					name: "DocumentListNoSearch",
					component: DocumentListView,
					props: mapValidationListProps,
					meta: {
						translate: true,
					},
				},
				{
					path: "dc/:documentClassId/validation",
					name: "DocumentListValidation",
					component: DocumentListView,
					props: mapValidationListPropsValidation,
					meta: {
						translate: true,
					},
				},
				{
					path: "dc/:documentClassId/validation/:searchRequest",
					name: "DocumentListValidationWithSearch",
					component: DocumentListView,
					props: mapValidationListProps,
					meta: {
						translate: true,
					},
				},
				{
					path: "dc/:documentClassId/:documentId",
					name: "ValidateEntry",
					component: ValidationView,
					props: mapValidationProps,
					meta: {
						translate: true,
					},
				},
				{
					path: "queue/documentClassList",
					name: "QueueView",
					component: QueueView,
				},
			],
		},
	];
}
