<template>
	<TabView @tab-change="onTabChange($event.index)">
		<TabPanel :header="$t('Squeeze.DocumentClasses.FieldAttributes')">
			<div class="p-fluid p-formgrid p-grid p-input-filled">
				<div class="p-field p-col-6">
					<label for="name">{{$t('Squeeze.DocumentClasses.Name')}} *</label>
					<InputText id="name" v-model.trim="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="onChangeInput" required="true" autofocus />
					<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Name')}) }}
					</small>
					<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
						{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.DocumentClasses.Name')}) }}
					</small>
					<small v-else-if="!!value.id">
						{{ $t('Squeeze.Admin.WarnRename') }}
					</small>
				</div>
				<div class="p-field p-col-6">
					<label for="description">{{$t('Squeeze.DocumentClasses.Description')}} *</label>
					<InputText id="description" v-model.trim="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="onChangeInput" required="true" />
					<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Description')}) }}
					</small>
				</div>
				<div class="p-field p-col-6">
					<label for="dataType">{{$t('Squeeze.DocumentClasses.DataType')}} *</label>
					<Dropdown id="dataType" v-model="v$.dataType.$model" :options="dataTypes" :class="{'p-invalid':!v$.dataType.$model && showErrorMessage}" optionValue="id" optionLabel="text" @change="onChangeDataType" />
					<small v-if="(!v$.dataType.$model && showErrorMessage)" class="p-error">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.DataType')}) }}
					</small>
				</div>
				<div class="p-field p-col-6">
					<label for="externalName">{{$t('Squeeze.DocumentClasses.ExternalName')}}</label>
					<InputText id="externalName" v-model.trim="value.externalName" required="true" />
				</div>
				<div class="p-field p-col-6">
					<label for="headerLocatorId">{{$t('Squeeze.DocumentClasses.HeaderLocator')}}</label>
					<Dropdown id="headerLocatorId" v-model="value.headerLocatorId" :options="locators" :filter="true" optionValue="id" optionLabel="description" :showClear="true" :editable="false" @show="showDropdownOverlay" />
				</div>
				<div class="p-field p-col-6">
					<label for="valueLocatorId">{{$t('Squeeze.DocumentClasses.ValueLocator')}}</label>
					<Dropdown id="valueLocatorId" v-model="value.valueLocatorId" :options="locators" :filter="true" optionValue="id" optionLabel="description" :showClear="true" :editable="false" @show="showDropdownOverlay" />
				</div>
				<div class="p-field p-col-12">
				</div>
				<div class="p-field p-col-3">
					<label for="mandatory">{{$t('Squeeze.DocumentClasses.Mandatory')}}</label><br/>
					<Checkbox id="mandatory" v-model="value.mandatory" :binary="true" @change="onChangeCheckBox('mandatory')" />
				</div>
				<div class="p-field p-col-3">
					<label for="readonly">{{$t('Squeeze.DocumentClasses.ReadOnly')}}</label><br/>
					<Checkbox id="readonly" v-model="value.readonly" :binary="true" @change="onChangeCheckBox('readonly')" />
				</div>
				<div class="p-field p-col-3">
					<label for="hidden">{{$t('Squeeze.DocumentClasses.Hidden')}}</label><br/>
					<Checkbox id="hidden" v-model="value.hidden" :binary="true" @change="onChangeCheckBox('hidden')" />
				</div>
				<div class="p-field p-col-3">
					<label for="forceValidation">{{$t('Squeeze.DocumentClasses.ForceValidation')}}</label><br/>
					<Checkbox id="forceValidation" v-model="value.forceValidation" :binary="true" @change="onChangeCheckBox('forceValidation')" />
				</div>
			</div>
		</TabPanel>
		<TabPanel :disabled="!value.dataType || value.dataType !== 'Text'" :header="$t('Squeeze.DocumentClasses.DbLookup')">
			<DbLookupForm :dbLookUpEntry="value.lookup" :showErrorMessage="showErrorMessage" @update="onUpdateTableLookup"/>
			<template v-if="value.id && value.lookup.active">
				<div class="p-mt-4">{{ $t('Squeeze.DocumentClasses.FieldFilterInfo') }}</div>
				<LookupColumnFiltersView
					:document-class-id="documentClassId"
					:tab-id="fieldEntry.tableId"
					:field-id="fieldEntry.id"
					:table-id="fieldEntry.lookup.tableId"
					:tableColumns="tableColumns"
				></LookupColumnFiltersView>
			</template>
			<template v-else-if="value.lookup.active">
				{{ t('Squeeze.DocumentClasses.PleaseSaveFieldFirst') }}
			</template>
		</TabPanel>
	</TabView>
</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentTableColumn, DocumentLocator, LookupDefinition} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DbLookupForm from "@/apps/administration/components/documentclasses/DbLookupForm.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {technicalValidation} from "@/util/ValidationHelper";
import LookupColumnFiltersView from "@/apps/administration/views/squeeze/documentclasses/LookupColumnFiltersView.vue";
import {showDropdownOverlay} from "@/util/StylesHelper";

interface DataTypes {
	id: string | undefined;
	text: string;
}

export default defineComponent({
	name: 'TableColumnForm',
	components: {
		Checkbox, InputText, Dropdown, DbLookupForm, TabView, TabPanel, LookupColumnFiltersView,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
		fieldEntry: {
			type: Object as PropType<DocumentTableColumn>,
			default: {},
			required: true,
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
		tableColumns: {
			type: Array as PropType<DocumentTableColumn[]>,
			default: [],
		},
	},
	emits: ['update', 'onTabChange'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<DocumentTableColumn>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
			dataType: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value, { $scope: false });

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			] as DataTypes[];
		});

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/** Triggered on change tab */
		const onTabChange = (activeTabIndex: number) => {
			emit("onTabChange", activeTabIndex);
		}

		/** Triggered on change of any field */
		const update = async (isInvalidLookup?: boolean) => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			if (isInvalidLookup) {
				emit("update", value, true, 1);
			} else{
				emit("update", value, v$.value.$invalid, 0);
			}
		}

		/** Triggered on change of any field lookup */
		const onUpdateTableLookup = async (lookupTable: LookupDefinition, isInvalid: boolean) => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			Object.assign(value.lookup, lookupTable)
			await update(isInvalid);
		}

		/** Triggered on change of input field */
		const onChangeInput = () => {
			update(false);
		}

		/**
		 * Set the lookup to inactive if the Type is not text
		 */
		const onChangeDataType = () => {
			if (value.dataType !== "Text") {
				value.lookup!.active = false;
			}
			// Trigger validation
			update(false);
		}

		/**
		 * Is triggered when a checkbox from mandatory, forceValidation or hidden is clicked
		 * @param fieldName Name of the field
		 */
		const onChangeCheckBox = (fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (value.mandatory === true || value.forceValidation === true) {
					value.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behavior
				if (value.hidden) {
					value.mandatory = false;
					value.forceValidation = false;
				}
				break;
			}
			}
		}

		return {
			t,
			dataTypes,
			v$,
			value,
			onTabChange,
			update,
			onUpdateTableLookup,
			onChangeInput,
			onChangeDataType,
			onChangeCheckBox,
			showDropdownOverlay,
		}
	},
});
</script>

<style scoped>
/** Otherwise there will be scrollbar at the bottom */
.p-grid {
	margin-left: 0px;
	margin-right: 0px;
}

::v-deep(.p-tabview-panel) {
	overflow: hidden;
}
</style>

