<template>
	<div class="p-p-2 child-content">
		<UserList
			:users="users"
			:loading="loading"
			@onClickDelete="openDeleteDialog"
			@onEntrySelect="onEntrySelect"
			:showEditButton="false"
		/>
	</div>
	<DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry" />

	<!-- Dialog für Felder -->
	<EntryDialog
		:show="showDialog"
		@onClose="showDialog = false, isValidationInvalid = true, showValidationMessage = false"
		@onConfirm="saveUserToRole"
		:loading="loadingDialog"
		:headerText="headerText"
		:showKeepDialogOpen="true"
	>
		<template #content>
			<BlockUI :blocked="loadingDialog">
				<UserRoleForm
					:user="user"
					:allUsers="allUsers"
					:showErrorMessage="showValidationMessage"
					@update="onUpdate"
				/>
			</BlockUI>
		</template>
	</EntryDialog>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {User, UserCollection} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import UserRoleForm from "@/apps/administration/components/roles/UserRoleForm.vue";
import UserList from "@/apps/administration/components/users/UserList.vue";

/** Extended Interface for Inherited Users */
interface InheritedUser extends User {
	isInherited: boolean;
}

export default defineComponent({
	name: "UsersForRoleView",
	components: {
		UserRoleForm, UserList, DialogDelete, EntryDialog, BlockUI,
	},
	props: {
		roleId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the users invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** List of all users in this list */
		const users = ref<User[]>([]);

		/** List of all users of the system */
		const allUsers = ref<User[]>([]);

		/** Should the Entry-Dialog for users be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active user */
		const user = reactive<User>({
			login: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Triggered on update of attribute-form */
		const onUpdate = (data: User, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(user, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.UserManagement.User') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: User) => {
			Object.assign(user, row);
			showDialog.value = true;
		}

		/** Reloads the table to get all users */
		const reloadData = () => {
			loading.value = true;
			userService.getAllUsers(props.roleId)
				.then(collection => {
					const data = collection as UserCollection;
					users.value = data.users!.map(user => {
						return {...user, isInherited: false};
					}) as InheritedUser[];

					// Add inherited users
					const inheritedUsers = data.inheritedUsers!.map(user => {
						return {...user, isInherited: true};
					}) as InheritedUser[];

					// Combine arrays
					users.value = users.value.concat(inheritedUsers);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Gets a list of all users of the system */
		const loadAllUsers = () => {
			userService.getAllUsers()
				.then(data => {
					allUsers.value = data as User[];
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
			loadAllUsers();
		})

		/** Saves an user */
		const saveUserToRole = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			userService.addRoletoUser(user.id!, props.roleId).then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					user.id = undefined;
					isValidationInvalid.value = true;
				}
				reloadData();
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: User) => {
			deleteDialog.value = true;
			Object.assign(user, row);
		}

		/** Deletes a user */
		const deleteEntry = () => {
			if (user && user.id) {
				loading.value = true;
				userService.deleteRoleFromUser(user.id, props.roleId).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog,
			showDialog, headerText, users, user, deleteDialog, userService,
			allUsers,
			deleteEntry, openDeleteDialog, onUpdate, onEntrySelect, saveUserToRole};
	},
});

</script>

<style scoped>

.child-content {
	height: calc(100vh - 5.6rem - 3.538rem);
}

</style>
