
import InputText from 'primevue/inputtext';
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import {Options, Vue} from "vue-class-component";
import {DocumentField, DocumentTable, DocumentTableColumn, TableColumnTraining} from "@dex/squeeze-client-ts";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: "DocumentPositionTrainingForm",
	components: {
		InputText,
		Dropdown,
		Button,
	},
	props: {
		trainingKeyField: Object,
		positionTrainingValues: Object,
		columnValues: Array,
		tables: Object,
		tablePositionTrainingValues: Object,
		buttonTrainText: Boolean,
	},
	computed: {
		columnRegionValue: function() {
			let columnValue = this.positionTrainingValues.columnRegion.x0 + ':' + this.positionTrainingValues.columnRegion.x1;
			if (columnValue === "0:0") {
				columnValue = "";
			}
			return columnValue
		},
	},
	emits: [
		'onFocusFieldOfPositionTraining',
		'getDataOfTraining',
		'trainPositionValues',
		'testingPositionValues',
	],
})
export default class DocumentPositionTrainingForm extends Vue {

	/** TrainingKey of the document */
	trainingKeyField!: DocumentField;

	/** Service for getting the values of position for training */
	positionTrainingValues!: TableColumnTraining;

	/** Columns of the Table */
	columnValues!: DocumentTableColumn[];

	/** Tables of the document */
	tables!: DocumentTable[];

	/** List of all training values of PositionTraining */
	tablePositionTrainingValues!: TableColumnTraining[];

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		if (this.tables.length !== 0) {
			this.positionTrainingValues.tableId = this.tables[0].id;
		}
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusFieldOfPositionTraining(fieldName: string) {
		this.$emit("onFocusFieldOfPositionTraining", fieldName);
	}

	/** Get all position training values of column for training table */
	getDataOfTraining() {
		this.$emit('getDataOfTraining');
	}

	/** Train all position values (save and retrain) */
	trainPositionValues() {
		this.$emit('trainPositionValues');
	}

	/** Testing all training columns for training table */
	testingPositionValues() {
		this.$emit("testingPositionValues");
	}

}
