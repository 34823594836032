<template>
	<div v-if="showHeadline" class="p-col-12 p-grid p-mr-0 p-ml-0 p-pb-0 p-pt-3">
		<div class="p-col-8 p-pl-0">
			<h1 class="headline p-component p-m-0">{{ $t('Squeeze.System.System') }} {{ $t('Squeeze.System.Info') }}</h1>
		</div>
		<div class="p-col-4 p-as-center p-grid p-jc-end">
			<Button class="p-button p-button-rounded p-button-text" icon="mdi mdi-close" @click="clickSettingDialog" />
		</div>
	</div>
	<div :class="showHeadline ? 'p-col-12 p-m-0' : ''">
		<div v-if="Object.keys(systemInformation).length === 0">
			{{ $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.Info') }) }}
		</div>
		<div v-else>
			<table>
				<tbody>
				<template v-for="(info, index) in Object.entries(systemInformation)" :key="index">
					<tr class="p-p-2">
						<td class="p-text-bold">
							{{ info[0] }}:
						</td>
						<td>
							{{ info[1] }}
						</td>
						<td>
							<Button
								icon="mdi mdi-content-copy"
								type="button"
								v-tooltip.top="$t('Squeeze.General.CopyToClipboard')"
								class="p-button p-button-lg p-button-rounded p-button-text"
								style="height: 1.5rem;"
								@click="onClickCopyToClipboard(info[0], info[1])"
							/>
						</td>
					</tr>
				</template>
				</tbody>
			</table>
			<div class="p-fluid p-formgrid p-grid p-mt-2">
				<div class="p-offset-8"></div>
				<div class="p-field p-col-4">
					<Button
						:label="$t('Squeeze.General.CopyAll')"
						type="button"
						icon="mdi mdi-content-save-all-outline"
						class="p-button"
						@click="onCopyAllClick"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "SystemInformation",
	components: {},
	props: {
		showHeadline: {
			type: Boolean,
			default: false,
		},
		systemInformation: {
			type: Object,
		},
	},
	emits: [
		"clickSettingDialog",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Triggered on click to copy a system information */
		const onClickCopyToClipboard = (key: string, value: string) => {
			if(key && value && navigator && navigator.clipboard) {
				navigator.clipboard.writeText(key + ': ' + value)
					.then(() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SystemInformationCopied'));
					});
			}
		}

		/** Triggered on click event to copy all system information */
		const onCopyAllClick = () => {
			const labels = props.systemInformation;
			if (labels && navigator.clipboard) {
				const text = Object
					.keys(labels)
					.map(key => {
						return `${key}: ${labels[key]}`;
					})
					.join("\n");

				navigator.clipboard.writeText(text)
					.then(() => {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.AllSystemInformationCopied'));
					});
			}
		}

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		return {
			t,
			toast,
			onClickCopyToClipboard,
			onCopyAllClick,
			clickSettingDialog,
		};
	},
});

</script>

<style scoped>

.headline {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin-bottom: 1rem;
}

table {
	display: table;
	width: 100%;
}

@media only screen and (max-width: 600px) {
	table {
		display: block;
		overflow-x: auto;
		width: 100%;
	}
}

</style>
