<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="documentClassId">{{$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.DocumentClass')}}</label>
			<Dropdown id="documentClassId" v-model="value.documentClassId"
				:options="documentClasses"
				:loading="loading"
				optionValue="documentClassId"
				optionLabel="description"
				@change="update"
				:placeholder="$t('Squeeze.General.None')"
				:showClear="true"
			/>
		</div>
		<div class="p-field p-col-12">
			<label for="active">{{$t('Squeeze.Imports.Active')}}</label>
			<Dropdown id="active" v-model="value.active" :options="active" :loading="loading" optionValue="id" optionLabel="name" @change="update"  />
		</div>
		<div class="p-field p-col-12">
			<label for="path">{{$t('Squeeze.Imports.Path')}} *</label>
			<InputText id="path" v-model="value.path" :class="{'p-invalid':v$.path.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.path.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.Path')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="indexFileType">{{$t('Squeeze.Imports.FileType')}}</label>
			<Dropdown id="indexFileType" v-model="value.indexFileType" :options="indexFileType" :loading="loading" optionValue="id" optionLabel="description" @change="update"  />
		</div>
		<div class="p-field p-col-6">
			<label for="indexFileExtension">{{$t('Squeeze.Imports.IndexFileExtension')}} *</label>
			<InputText id="indexFileExtension" v-model="value.indexFileExtension" :class="{'p-invalid':v$.indexFileExtension.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.indexFileExtension.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.IndexFileExtension')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="signalFileName">{{$t('Squeeze.Imports.FileName')}} *</label>
			<InputText id="signalFileName" v-model="value.signalFileName" :class="{'p-invalid':v$.signalFileName.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.signalFileName.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Imports.FileName')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="imageFileExtension">{{$t('Squeeze.Imports.ImageFileExtension')}}</label>
			<Dropdown id="imageFileExtension" v-model="value.imageFileExtension" :options="imageFileExtension" :loading="loading" optionValue="id" optionLabel="description" @change="update"  />
		</div>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import {BatchClass, BatchClassClassification, FileImportConfig} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "FileSystemForm",
	components: {
		InputText, Dropdown,
	},
	props: {
		file: {
			type: Object as PropType<FileImportConfig>,
			required: true,
		},
		batchClassId: {
			type: Number,
			default: 1,
			required: true,
		},
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: [],
			required: true,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Array with the indexFileType */
		const indexFileType = computed(() => {
			return [
				{
					id: 'SCM',
					description: 'SCM',
				},
				{
					id: 'Workday EIB XML',
					description: 'Workday EIB XML',
				},
			]
		});

		/** Array with the imageFileExtension */
		const imageFileExtension = computed(() => {
			return [
				{
					id: 'TIFF',
					description: 'TIFF',
				},
				{
					id: 'PDF',
					description: 'PDF',
				},
			]
		});

		/** List of active booleans */
		const active = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<FileImportConfig>({
			batchClassId: props.batchClassId,
			documentClassId: undefined,
			active: true,
			path: '',
			indexFileType: FileImportConfig.IndexFileTypeEnum.SCM,
			indexFileExtension: '',
			signalFileName: '',
			imageFileExtension: FileImportConfig.ImageFileExtensionEnum.TIFF,
		});

		/** Determines the required rules for validation */
		const rules = {
			path: { required },
			indexFileExtension: { required },
			signalFileName: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.file);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.file, () => {
			Object.assign(value, props.file);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			indexFileType,
			imageFileExtension,
			active,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
		}
	},
});

</script>

<style scoped>

</style>

