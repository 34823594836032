<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="barcodeType">{{$t('Squeeze.Locators.Tabs.BarcodeTypes')}} *</label>
			<Dropdown id="barcodeType" v-model="value.barcodeType" :options="barcodeTypes" :loading="loading" :class="{'p-invalid':v$.barcodeType.$invalid && showErrorMessage}" optionValue="id" optionLabel="name" @change="update"  />
			<small v-if="v$.barcodeType.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.BarcodeTypes')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="barcodeRegex">{{$t('Squeeze.Locators.Tabs.BarcodeRegex')}} *</label>
			<InputText id="barcodeRegex" v-model="value.barcodeRegex" required="true" :class="{'p-invalid':v$.barcodeRegex.$invalid && showErrorMessage}" @change="update" />
			<small v-if="v$.barcodeRegex.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Tabs.BarcodeRegex')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Dropdown from 'primevue/dropdown';
import InputText from "primevue/inputtext";
import {BarcodeLocatorDetails, BarcodeType} from "@dex/squeeze-client-ts";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default defineComponent({
	name: "BarcodeForm",
	components: { Dropdown, InputText },
	props: {
		barcodeTypes: {
			type: Array as PropType<BarcodeType[]>,
			default: {},
		},
		barcode: {
			type: Object as PropType<BarcodeLocatorDetails>,
			default: {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields  */
		const value = reactive<BarcodeLocatorDetails>({});

		/** Determines the required rules for validation */
		const rules = {
			barcodeType: { required },
			barcodeRegex: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(() => {
			Object.assign(value, props.barcode);
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.barcode, () => {
			Object.assign(value, props.barcode);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});

</script>

<style scoped>

</style>
