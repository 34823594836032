<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="maxDocumentAge">{{ $t('Squeeze.System.MaxDocumentAge') }}</label>
			<span class="p-input-icon-right" v-tooltip.bottom="$t('Squeeze.System.MaxDocumentAgeDescription')">
				<i :class="props.loading ? 'mdi mdi-spin mdi-loading' : 'mdi mdi-comment-question-outline'" />
				<InputText id="maxDocumentAge" v-model="props.systemCleanUpConfig.maxDocumentAge" class="readonly" :readonly="true" />
			</span>
		</div>
		<div class="p-field p-col-6">
			<label for="maxLogAge">{{ $t('Squeeze.System.MaxLogAge') }}</label>
			<span class="p-input-icon-right" v-tooltip.bottom="$t('Squeeze.System.MaxLogAgeDescription')">
				<i :class="props.loading ? 'mdi mdi-spin mdi-loading' : 'mdi mdi-comment-question-outline'" />
				<InputText id="maxLogAge" v-model="props.systemCleanUpConfig.maxLogAge" class="readonly" :readonly="true" />
			</span>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import InputText from "primevue/inputtext";
import {SystemCleanupConfig} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";

export default defineComponent({
	name: "SystemCleanUpConfig",
	components: {
		InputText,
	},
	props: {
		systemCleanUpConfig: {
			type: Object as PropType<SystemCleanupConfig>,
			default: {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	setup(props) {
		return {props};
	},
});

</script>

<style scoped>

.readonly {
	font-weight: bold;
	color: #2b2b2b;
	opacity: 0.5;
}

</style>
