
import { Options, Vue } from 'vue-class-component';
import Skeleton from 'primevue/skeleton';

@Options({
	name: "ValidationFieldSetSkeleton",
	components: {
		Skeleton,
	},
})
export default class ValidationFieldSetSkeleton extends Vue {

}
