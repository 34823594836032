<template>
	<div class="p-p-2 p-pb-0 scroll-content" style="background-color: black; color: white;" :style="logs.length !== 0 ? 'height: 70vh !important' : ''">
		<BlockUI :blocked="loading">
			<template v-if="loading === true">
				<div class="p-grid">
					<div class="p-col" style="text-align: center">{{$t('Squeeze.General.Loading')}}</div>
				</div>
			</template>
			<template v-else-if="logsInternal.length === 0">
				<div class="p-grid">
					<div class="p-col" style="text-align: center">{{$t('Squeeze.Validation.Log.NoLogs')}}</div>
				</div>
			</template>
			<template v-else>
				<div class="p-grid p-mb-2 p-p-2" v-if="showSort">
					<Button :label="$t('Squeeze.Validation.Log.ChangeSortOrder')" type="button" icon="mdi mdi-reload" class="p-button" @click="changeSortOrder" />
				</div>
			</template>
			<template v-for="log of logsInternal" v-bind:key="log">
				<div class="p-grid">
					<div class="p-col-fixed timestamp" style="width:13rem;">{{formatDate(log.time)}}</div>
					<div v-if="log.message.includes('\n')" class="p-col">
						<div v-for="item in log.message.split('\n')" :key="item">{{ item }}</div>
					</div>
					<div v-else class="p-col">{{ log.message }}</div>
				</div>
			</template>
		</BlockUI>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import BlockUI from "primevue/blockui";
import {LocatorLogLine} from "@dex/squeeze-client-ts";

export interface LogItem {
	time: string;
	message: string;
}

export default defineComponent({
	name: "Log",
	components: {
		BlockUI,
	},
	props: {
		logs: {
			type: Array as PropType<LogItem[] | LocatorLogLine[]>,
			default: [],
		},
		loading: {
			type: Boolean,
		},
		showSort: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { locale } = useI18n({ useScope: 'global' });

		/** All log lines
		 * The type LogItem is for the document log
		 * The type LocatorLogLine is for the locator log (test locator on document)
		 * We need to use this both types, so that we don't need the same component twice (Log.vue)
		 */
		const logsInternal = ref<LogItem[] | LocatorLogLine[]>(props.logs);

		/** Sort-Order */
		const sortOrder = ref(1); // -1 desc, 1 asc

		/** Sort the logs by timestamp */
		const sortLogs = () => {
			logsInternal.value.sort((a: any, b: any) => {
				if (new Date(a.time) > new Date(b.time)) {
					return 1 * sortOrder.value;
				}
				return -1 * sortOrder.value;
			});
		}

		onMounted(() => {
			sortLogs();
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.logs, () => {
			logsInternal.value = props.logs;
			sortLogs();
		})

		/** Change the sort order to the opposite direction */
		const changeSortOrder = () => {
			sortOrder.value = sortOrder.value * -1;
			sortLogs();
		}

		/** Formats date string to desired locale string
		 * @param dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options) + ":" + date.getMilliseconds();
				}
			}
			return dateToFormat;
		}

		return {
			TabMenu, logsInternal, sortOrder, sortLogs, changeSortOrder, formatDate,
		}
	},
});
</script>

<style scoped>

.timestamp {
	color: orange;
}

.p-col {
	word-break: break-all;
}

</style>
