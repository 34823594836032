
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, nextTick, onMounted, PropType, reactive} from "vue";
import {LocatorSearchValue} from "@dex/squeeze-client-ts";
import Textarea from 'primevue/textarea';
import {helpers} from "@vuelidate/validators";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "SearchValues",
	components: {
		DataTable, Column, Textarea,
	},
	props: {
		searchValues: {
			type: Array as PropType<LocatorSearchValue>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect", 'onChangeSearchValue',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<LocatorSearchValue>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: LocatorSearchValue) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {searchValue: '', id: 0, locatorId: 0})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: LocatorSearchValue) => {
			emit("onEntrySelect", row)
		}

		/** Check the Search Value is invalid */
		const isInvalidSearchValue = (row: LocatorSearchValue) => {
			return !helpers.req(row.searchValue)
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * (change-Event in editing cell doesn't work in firefox)
		 * @param event
		 */
		const onCellComplete = (event: any) => {
			if (event.type === 'outside') {
				emit("onChangeSearchValue", event.data, isInvalidSearchValue(event.data));
			} else if (entryDataCheck.searchValue !== event.data.searchValue) {
				emit("onChangeSearchValue", event.data, isInvalidSearchValue(event.data));
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, entryDataCheck, DataTable, Column, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, isInvalidSearchValue, onCellEdit, onCellComplete, onChangePage,
		}
	},
});
