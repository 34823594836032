
import {defineComponent, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import SelectButton from 'primevue/selectbutton';
import SystemImport from "@/apps/administration/components/system/SystemImport.vue";
import {UploadFile} from "@/shims-prime-vue";
import {ToastManager} from "@/util/ToastManager";
import {LocatorConfig, SqueezeConfig} from "@dex/squeeze-configframework";
import {DocumentClassConfig, FieldsConfig, TableConfig} from "@dex/squeeze-configframework/build/export/SqueezeConfig";
import ConfigurationsDataList from "@/apps/administration/components/system/configurations/ConfigurationsDataList.vue";
import {
	DocumentClassDto,
	DocumentField,
	DocumentLocator,
	DocumentTableColumn,
	MasterDataTable
} from "@dex/squeeze-client-ts";
import {MasterDataConfig} from "@dex/squeeze-configframework/build/export/MasterDataConfig";

export interface TreeTableEntry {
	key: string;
	data: {
		label: string | undefined;
		type?: string | undefined;
	};
	children?: TreeTableEntry[];
}

export default defineComponent({
	name: "ConfigurationsView",
	components: {
		ConfigurationsDataList,
		SelectButton,
		SystemImport,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Current active option */
		const activeOption = ref({icon: 'mdi mdi-file-import-outline', value: 'Import'});

		/** All options of a file */
		const options = ref([
			{icon: 'mdi mdi-file-export-outline', value: 'Export', constant: true},
			{icon: 'mdi mdi-file-import-outline', value: 'Import'},
		]);

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Used File-Type for Upload */
		const fileType = ref('json');

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** All file data */
		const fileData = reactive<SqueezeConfig>({
			documentClasses: [],
			locators: [],
			masterDataTables: [],
		});

		/** Should the data list be shown? */
		const showDataList = ref<boolean>(false);

		/** Data list of imported file */
		const dataList = ref<TreeTableEntry[]>([]);

		/**
		 * Triggered on update of file upload
		 * @param data
		 * @param isInvalid
		 */
		const onUpdate = (data: any, isInvalid: boolean) => {
			showErrorMessage.value = isInvalid;
			fileType.value = data.fileType;
		}

		/**
		 * Process the document class field data
		 * the used master data table (with all columns) and the used locator
		 * @param {DocumentField} field
		 */
		const processDcFieldData = (field: DocumentField) => {
			const fieldChildren: TreeTableEntry[] = [];

			if (field.lookup) {
				// find used master data table in field lookup
				if (field.lookup.tableId && fileData.masterDataTables) {
					fileData.masterDataTables.forEach((table: MasterDataConfig, index: number) => {
						if (table.masterDataTable.id === field.lookup?.tableId) {
							fieldChildren.push({key: "2-" + index, data: {label: table.masterDataTable.name, type: t('Squeeze.MasterData.MasterDataTable')}, children: []});
						}
					})
				}

				// find used locator in field data
				if (field.locatorId && fileData.locators) {
					fileData.locators.forEach((locator: LocatorConfig, index: number) => {
						if (locator.locator.id === field.locatorId && locator.locator.name) {
							fieldChildren.push({key: "1-" + index, data: {label: locator.locator.name, type: t('Squeeze.DocumentClasses.Locator')}, children: []});
						}
					})
				}
			}

			return fieldChildren;
		}

		/**
		 * Process the document class table column data
		 * the used locator(s)
		 * @param {DocumentTableColumn} column
		 */
		const processDcTableColumnData = (column: DocumentTableColumn) => {
			const columnChildren: TreeTableEntry[] = [];

			// find used header and value locator
			if (column.headerLocatorId || column.valueLocatorId) {
				fileData.locators.forEach((locator: LocatorConfig, index: number) => {
					if (locator.locator.id === column.headerLocatorId) {
						columnChildren.push({key: "1-" + index, data: {label: locator.locator.name, type: t('Squeeze.DocumentClasses.HeaderLocator')}});
					} else if (locator.locator.id === column.valueLocatorId) {
						columnChildren.push({key: "1-" + index, data: {label: locator.locator.name, type: t('Squeeze.DocumentClasses.ValueLocator')}});
					}
				});
			}

			return columnChildren;
		}

		/**
		 * Process the document class table data
		 * the used locator
		 * @param {TableConfig} table
		 * @param {number} dcIndex
		 * @param {number} tableIndex
		 */
		const processDcTable = (table: TableConfig, dcIndex: number, tableIndex: number) => {
			let tableChildren: TreeTableEntry[] = [];

			if (table.columns.length > 0) {
				tableChildren = table.columns.map((column: DocumentTableColumn, columnIndex: number) => {
					return {key: '0-' + dcIndex + '-table-' + tableIndex + '-' + columnIndex, data: {label: column.name, type: t('Squeeze.General.TableColumn')}, children: processDcTableColumnData(column)};
				})
			}

			if (table.table.locatorId) {
				fileData.locators.forEach((locator: LocatorConfig, index: number) => {
					if (locator.locator.id === table.table.locatorId) {
						tableChildren.push({key: '1-' + index, data: {label: locator.locator.name, type: t('Squeeze.DocumentClasses.Locator')}})
					}
				})
			}

			return tableChildren;
		}

		/**
		 * Process the config data of document class
		 * join two arrays
		 * @param dc current document class
		 * @param index document class index
		 */
		const processConfigDcData = (dc: DocumentClassConfig, index: number) => {
			// map field groups data
			const fieldGroups = dc.fieldGroups.map((fieldGroup: FieldsConfig, fieldGroupIndex: number) => {
				return {key: '0-' + index + '-fieldgroup-' + fieldGroupIndex, data: {label: fieldGroup.fieldGroup.name, type: t('Squeeze.DocumentClasses.FieldGroup')}, children: fieldGroup.fields.map((field: DocumentField, fieldIndex: number) => {
					return {key: '0-' + index + '-fieldgroup-' + fieldGroupIndex + '-' + fieldIndex, data: {label: field.name, type: t('Squeeze.DocumentClasses.Field')}, children: processDcFieldData(field)}})}
			});

			// map tables data
			const tables = dc.tables.map((table: TableConfig, tableIndex: number) => {
				return {key: '0-' + index + '-table-' + tableIndex, data: {label: table.table.name, type: t('Squeeze.DocumentClasses.Table')}, children: processDcTable(table, index, tableIndex)}
			});

			// Document class data
			const dcData: TreeTableEntry[] = [];

			return dcData.concat(fieldGroups, tables);
		}

		/**
		 * Process the config data of locator details
		 * @param {LocatorConfig} locator
		 */
		const processLocatorDetailsData = (locator: LocatorConfig) => {
			const children: TreeTableEntry[] = [];

			// get used locator details of locator
			const locatorDetails = locator.locatorDetails;
			if (locatorDetails) {
				// find used source locator of locator
				if (locator.locatorDetails.sourceLocator && locator.locatorDetails.sourceLocator.name && locator.locator.sourceLocatorId) {
					const currentLocator: DocumentLocator = locator.locatorDetails.sourceLocator;
					const locatorIndex = fileData.locators ? fileData.locators.findIndex(loc => loc.locator.name === currentLocator.name) : Math.random();
					children.push({key: '1-' + locatorIndex, data: {label: currentLocator.name, type: t('Squeeze.Locators.Tabs.SourceLocator')}});
				}

				if (locatorDetails.sourceTable) {
					// master data table of tableKeyColumnFilter
					if (locatorDetails.sourceTable.masterDataTables.length > 0) {
						const currentMasterData: MasterDataTable = locatorDetails.sourceTable.masterDataTables[0];
						const masterDataIndex = fileData.masterDataTables ? fileData.masterDataTables.findIndex(table => table.masterDataTable.name === currentMasterData.name) : Math.random();
						children.push({key: '2-' + masterDataIndex, data: {label: currentMasterData.name, type: t('Squeeze.MasterData.MasterDataTable')}});
					}

					// document class of tableKeyFieldFilter
					if (locatorDetails.sourceTable.documentClass && locatorDetails.sourceTable.documentClass.documentClass && locatorDetails.sourceTable.documentClass.documentClass.name) {
						const currentDocumentClass: DocumentClassDto = locatorDetails.sourceTable.documentClass.documentClass;
						const dcIndex = fileData.documentClasses ? fileData.documentClasses.findIndex(dc => dc.documentClass.name === locatorDetails.sourceTable!.documentClass.documentClass.name) : Math.random();
						children.push({key: '0-' + dcIndex, data: {label: currentDocumentClass.name, type: t('Squeeze.DocumentClasses.DocumentClass')}, children: processConfigDcData(locatorDetails.sourceTable.documentClass, Math.random())});
					}

					// locator of tableKeyLocatorFilter
					if (locatorDetails.sourceTable.locators.length > 0) {
						const currentLocator: DocumentLocator = locatorDetails.sourceTable.locators[0];
						const locatorIndex = fileData.locators ? fileData.locators.findIndex(loc => loc.locator.name === currentLocator.name) : Math.random();
						children.push({key: '1-' + locatorIndex, data: {label: currentLocator.name, type: t('Squeeze.DocumentClasses.Locator')}});
					}
				} else if (locatorDetails.valueFromRegexDetails && locatorDetails.valueFromRegexDetails.masterDataTables.length > 0) {
					locatorDetails.valueFromRegexDetails.masterDataTables.map((table: MasterDataTable) => {
						const tableIndex = fileData.masterDataTables ? fileData.masterDataTables.findIndex(t => t.masterDataTable.name === table.name) : Math.random();
						children.push({key: '2-'+ tableIndex, data: {label: table.name, type: t('Squeeze.MasterData.MasterDataTable')}});
					})
				} else if (locatorDetails.invoiceAmountDetails && locatorDetails.invoiceAmountDetails.locators.length > 0) {
					locatorDetails.invoiceAmountDetails.locators.map((loc: DocumentLocator) => {
						const locIndex = fileData.locators ? fileData.locators.findIndex(l => l.locator.name === loc.name) : Math.random();
						children.push({key: '1-'+ locIndex, data: {label: loc.name, type: t('Squeeze.DocumentClasses.Locator')}});
					})
				} else if (locatorDetails.dbLinkLocatorDetails && locatorDetails.dbLinkLocatorDetails.locators.length > 0) {
					locatorDetails.dbLinkLocatorDetails.locators.map((loc: DocumentLocator) => {
						const locIndex = fileData.locators ? fileData.locators.findIndex(l => l.locator.name === loc.name) : Math.random();
						children.push({key: '1-'+ locIndex, data: {label: loc.name, type: t('Squeeze.DocumentClasses.Locator')}});
					})
				} else if (locatorDetails.lineItemsDetails && locatorDetails.lineItemsDetails.lineItemsDetails.tableId) {
					const dc = locatorDetails.lineItemsDetails.documentClass;
					children.push({key: '0-0', data: {label: dc.documentClass.name, type: t('Squeeze.DocumentClasses.DocumentClass')}, children: processConfigDcData(dc, Math.random())});
				}
			}
			return children;
		}

		/** Process to build the data config */
		const processConfigData = () => {
			showDataList.value = true;

			if (fileData.documentClasses) {
				dataList.value.push({
					key: '0',
					data: {
						label: t('Squeeze.DocumentClasses.DocumentClasses'),
					},
					children: fileData.documentClasses.map((dc: DocumentClassConfig, index: number) => {
						return {key: '0-' + index, data: {label: dc.documentClass.name, type: t('Squeeze.DocumentClasses.DocumentClass')}, children: processConfigDcData(dc, index)}
					}),
				});
			}

			if (fileData.locators) {
				dataList.value.push({
					key: '1',
					data: {
						label: t('Squeeze.Breadcrumbs.Locators'),
					},
					children: fileData.locators.map((locator: LocatorConfig, index: number) => {
						return {key: '1-' + index, data: {label: locator.locator.name, type: t('Squeeze.DocumentClasses.Locator')}, children: processLocatorDetailsData(locator)}
					}),
				});
			}

			if (fileData.masterDataTables) {
				dataList.value.push({
					key: '2',
					data: {
						label: t('Squeeze.Breadcrumbs.MasterDataList'),
					},
					children: fileData.masterDataTables.map((masterDataTable: MasterDataConfig, index: number) => {
						return {key: '2-' + index, data: {label: masterDataTable.masterDataTable.name, type: t('Squeeze.MasterData.MasterDataTable')}, children: []}
						// columns are not shown - fix for later --> children: masterDataTable.columns?.map((column, columnIndex) => return {key: '2-' + index + '-' + columnIndex, data: {label: column.name, type: t('Squeeze.General.TableColumn')}}})}
					}),
				});
			}
		}

		/**
		 * Starts the File upload for the given files
		 * In this function a (import) file will be read
		 */
		const startFileUpload = () => {
			loading.value = true;

			files.value
				.forEach((file: any, index: number) => {
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					files.value[idx].text()
						.then((data: string) => {
							files.value[idx].uploadFinished = true;
							dataList.value = [];

							const allData: SqueezeConfig = JSON.parse(data);
							fileData.documentClasses = allData.documentClasses;
							fileData.locators = allData.locators;
							fileData.masterDataTables = allData.masterDataTables;

							processConfigData();
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];

							loading.value = false;
						});
				})
		}

		/** Uploads the files from the file-uploader
		 * @param filesSend
		 */
		const fileUploader = (filesSend: UploadFile[]) => {
			files.value = filesSend;
			startFileUpload();
		}

		/** Close the DataList-Dialog */
		const onCloseDialog = () => {
			dataList.value = [];
			showDataList.value = false;
		}


		/** Remove (all) files after import */
		const removeFiles = () => {
			files.value = [];
		}

		return {
			t,
			toast,
			loading,
			activeOption,
			options,
			files,
			fileType,
			showErrorMessage,
			fileData,
			showDataList,
			dataList,
			onUpdate,
			fileUploader,
			startFileUpload,
			onCloseDialog,
			removeFiles,
		};
	},
});

