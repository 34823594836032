
/* eslint max-lines: off */
import {Options, Vue} from 'vue-class-component';
import router from "@/router";
import {RouteLocationRaw} from 'vue-router';

import Skeleton from 'primevue/skeleton';
import {FilterMatchMode} from "primevue/api";

import QueueTimeline from '@/apps/squeeze/components/QueueTimeline.vue';
import DocumentList from '@/apps/squeeze/components/DocumentList.vue';
import QueueEntryList from "@/apps/squeeze/components/QueueEntryList.vue";
import SearchForm from "@/apps/squeeze/components/SearchForm.vue";

import {
	Document, DocumentSearchFilterWidget,
	DocumentSearchRequestDto, ErrorDto,
	FieldValueFilterDto,
	PaginationDto,
	QueueEntry,
	WorkflowContextFilterDto
} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

import {FieldColumn} from "@/apps/squeeze/interfaces/FieldColumn";
import {ListDocument, ListDocumentField} from "@/apps/squeeze/interfaces/ListDocument";
import {Filter, DocumentFilterObject, DocumentSortObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {WorkflowContext} from "@dex/squeeze-client-ts";
import {defaultSort} from "@/util/SortingHelper";
import {nextTick} from "vue";
import {TableSettings} from "@/util/TableSettings";
import useSqueezeStore from "@/apps/squeeze/store";
import EntryDialog from "@/components/EntryDialog.vue";

export interface CustomSearchDto {
	id?: string;
	description: string;
	documentClass: number;
	search: DocumentSearchRequestDto;
	type: string;
	tile: string;
}

export interface SearchFormDto {
	actionType: string;
	description: string;
	searchId: number;
	global: boolean;
}

@Options({
	name: "DocumentListView",
	components: {
		QueueEntryList,
		QueueTimeline,
		DocumentList,
		Skeleton,
		EntryDialog,
		SearchForm,
	},
	props: {
		documentClassId: Number,
		searchRequest: {
			type: Object as DocumentSearchRequestDto,
			default: {},
		},
		tableSortStart: {
			type: Array,
			default: [defaultSort],
		},
		pagination: {
			type: Object as PaginationDto,
			default: {},
		},
		isUploadShown: {
			type: Boolean,
		},
		searchId: {
			type: Number,
			default: 0,
		},
	},
	emits: ["onRowSelect"],
})
/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default class DocumentListView extends Vue {

	/** ID of document class */
	documentClassId!: number;

	/** Context of the List ('validation' or 'search' */
	workflowContext!: string;

	/** Vuex Store */
	store = useSqueezeStore();

	/** Name of document class */
	documentClassName = "";

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Search API endpoint */
	searchApi = ClientManager.getInstance().squeeze.search;

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	/** Documents to list */
	documents: ListDocument[] = [];

	/** List of fields as columns array for datatable */
	fieldColumns: FieldColumn[] = [];

	/** Indicates end of request */
	loaded = false;

	/** Loading for the entry dialog */
	loading = false;

	/** Indicates end of mounted */
	initialLoaded = false;

	/** Indicates that queue entries are loaded instead of searching for documents via the ES search endpoint */
	usingQueueEntryFallback = false;

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** DocumentClass API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** List of all QueueEntries in QueueStep Validation */
	queueEntries: QueueEntry[] = [];

	/** Currently-Selected row */
	selection = null

	/** Has the table been fully loaded at least once? */
	elasticLoadedSuccess = false;

	/** Should  the Dialog for Search Saving be shown? */
	showSaveSearch = false;

	/** Should  the Dialog for Search Selecting be shown? */
	showSelectSearch = false;

	/** List with all saves searches */
	allSearches: DocumentSearchFilterWidget[] = [];

	/** Currently active search */
	searchId!: number;

	/** Workflow Filter Standard for validation */
	standardWorkflowFilter = [
		{
			fieldName: "queueStep",
			comp: "eq",
			searchValue: "validation",
			fieldType: "text",
		},
	]

	/** Info / Options of pagination */
	paginationOption: PaginationDto = {
		pageSize: 25,
		page: 0,
		total: 0,
	};

	/** Filters of Documentlist */
	filters: DocumentFilterObject = {
		"id": {value: null, matchMode: FilterMatchMode.EQUALS},
		"queueStatus": {value: null, matchMode: FilterMatchMode.EQUALS},
		"queueStep": {value: null, matchMode: FilterMatchMode.EQUALS},
	};

	/** Search request for document search filtering */
	searchRequest!: DocumentSearchRequestDto;

	/** Defines the sort order of the table */
	tableSort: string[] = [
		defaultSort,
	]

	/** Defines the sort order of the table */
	tableSortStart!: string[];

	/** Current Pagination prop from route */
	pagination!: PaginationDto;

	/** Currently fulltext-string */
	fullText: string = "";

	/** Search Init */
	searchSave: SearchFormDto = {
		description: '',
		actionType: 'new',
		searchId: 0,
		global: false,
	}

	/** Triggered the valid of form */
	isInvalid: boolean = true;
	showErrorMessage: boolean = false;

	/** On view ready */
	async mounted() {
		// Initialize pagination info / options
		if (this.pagination) {
			this.paginationOption.pageSize = TableSettings.getTableListPagination(this.store, "DocumentListView");
			this.paginationOption.page = this.pagination.page;
			this.paginationOption.total = this.pagination.total;
		}
		this.tableSort = this.tableSortStart;

		// load fields of the documentClasses
		await this.documentClassApi.getAllDocumentClassFields(this.documentClassId)
			.then(fields => {
				// Sort by fieldgroup
				fields.sort((field1, field2) => {
					if (field1!.fieldGroupId! > field2!.fieldGroupId!) {
						return 1;
					}

					if (field1!.fieldGroupId! < field2!.fieldGroupId!) {
						return -1;
					}

					return 0;
				})

				fields.forEach(field => {
					if (field.hidden !== true) {
						this.fieldColumns.push({
							id: field.id,
							name: field.name as string,
							header: field.description as string,
							type: field.dataType as string,
						});
						this.filters['' + field.id] = {value: null, matchMode: "raw"};
					}
				});
			})
			.catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			})

		if (this.searchRequest && this.searchRequest.fulltextFilters && this.searchRequest.fulltextFilters[0]) {
			const fullTextFilter = this.searchRequest.fulltextFilters[0];
			if (fullTextFilter && fullTextFilter.searchValue) {
				this.fullText = fullTextFilter.searchValue;
				this.tableSort = [""];
			}
		}

		await this.reloadData();
		this.loadSearches();
		this.initialLoaded = true;
	}

	/** Triggered when a row is selected in documentList */
	onRowSelectDocumentList(document: Document) {
		const route: RouteLocationRaw = {
			name: 'ValidateEntry',
			params: {
				documentId: document.id as any,
				searchRequest: JSON.stringify(this.searchRequest),
				pagination: JSON.stringify(this.paginationOption),
				tableSortStart: JSON.stringify(this.tableSort),
				searchId: this.searchId,
			}};
		router.push(route);
	}

	/** Triggered when a row is selected in queueEntryList */
	onRowSelectQueueEntryList(document: QueueEntry) {
		const route: RouteLocationRaw = {
			name: 'ValidateEntry',
			params: {
				documentId: document.documentId as any,
				searchRequest: JSON.stringify(this.searchRequest),
				pagination: JSON.stringify(this.paginationOption),
				tableSortStart: JSON.stringify(this.tableSort),
				searchId: this.searchId,
			}};
		router.push(route);
	}

	/** Triggered when page is changed */
	onChangePage(event: { rows: number }) {
		TableSettings.saveTableListPagination(this.$t, this.$toast, this.store, this.userApi, "DocumentListView", event.rows);
	}

	/** Triggered when the next page should be loaded */
	onPage(event: { page: number; rows: number; first: number }) {
		// Set current page
		this.paginationOption.page = event.page;
		this.paginationOption.pageSize = event.rows;

		// If there are more than 10.000 entries, show an error
		if (event.first >= 10000 || (event.first + event.rows >= 10000)) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Queue.General.MaxNumberReached'));
			nextTick(() => {
				this.paginationOption.page = 0;
				this.reloadData();
			})
			return;
		}

		this.reloadData();
		this.onChangePage(event);
	}

	/** Encode query element for router */
	encodeFilterQuery() {
		const query: {[key: string]: string} = {};
		if (this.searchRequest.workflowContextFilters && this.searchRequest.workflowContextFilters.length > 0) {
			query.wf = encodeURI(JSON.stringify(this.searchRequest.workflowContextFilters));
		}

		if (this.searchRequest.fieldFilters && this.searchRequest.fieldFilters.length > 0) {
			query.f = encodeURI(JSON.stringify(this.searchRequest.fieldFilters));
		}

		return query;
	}
	/** Sets available filters of search request for Vue to update */
	setDataTableFilter() {
		const searchRequestFilters: {[type: string]: FieldValueFilterDto[] | WorkflowContextFilterDto[] | undefined} = {
			"fields": this.searchRequest.fieldFilters,
			"wf": this.searchRequest.workflowContextFilters,
		};

		for(const filterType in searchRequestFilters) {
			const isField = filterType === "fields";
			const filters = searchRequestFilters[filterType];
			if(filters) {
				// TODO: TELL TYPESCRIPT TYPE OF FILTER
				filters.forEach((filter: any) => {
					const id = isField ? '' + filter.fieldId : filter.fieldName;
					const listFilter = {value: filter.searchValue, matchMode: FilterMatchMode.CONTAINS};

					if(!isField && filter.fieldName === "queueStatus") {
						listFilter.value = listFilter.value.toUpperCase();
					}

					switch(filter.comp) {
					case "eq": listFilter.matchMode = FilterMatchMode.EQUALS; break;
					case "bw": listFilter.matchMode = FilterMatchMode.STARTS_WITH; break;
					case "ew": listFilter.matchMode = FilterMatchMode.ENDS_WITH; break;
					default: break;
					}

					this.filters[id] = listFilter;
				});
			}
		}

		let routeName = "DocumentList";

		if (this.$route.name === "DocumentListValidation" || this.$route.name === "DocumentListValidationWithSearch") {
			routeName = "DocumentListValidationWithSearch";
		}

		// SET FILTER QUERY
		router.replace({name: routeName, params: {
			documentClassId: this.documentClassId,
			searchRequest: encodeURIComponent(JSON.stringify(this.searchRequest)),
			searchId: this.searchId,
		}});
	}

	/** Triggered on sort of the table (with multi-sort activated) */
	async onSort(sortColumns: DocumentSortObject[]) {
		this.tableSort = [defaultSort];

		// if there is no sort, use default sort
		if (sortColumns.length === 0) {
			await this.reloadData();
			return;
		}

		const sortArray = sortColumns.map(sortColumn => {
			const sortOrder = sortColumn.order === -1 ? "desc": "asc";

			// Special handling for sorting special fields
			if (sortColumn.field === "id") {
				return "id:" + sortOrder;
			}
			else if (sortColumn.field === "create_ts") {
				return "queueCreateTs:" + sortOrder;
			}
			else if (sortColumn.field === "errorText") {
				return "queueErrorText:" + sortOrder;
			}
			else if (sortColumn.field == "status") {
				return "queueStatus:" + sortOrder;
			}

			// Get Column id for sort
			const column = this.fieldColumns.find(column => column.name === sortColumn.field);
			if (column) {
				return 'fields.' + column.id + '.value.' + column.type.toLowerCase() + ':' + sortOrder;
			}
		})

		if (sortArray) {
			this.tableSort = sortArray as string[];
		}

		await this.reloadData();
	}

	/** Triggered when a filter has been entered */
	async onFilter(tableFilters: DocumentFilterObject) {
		this.searchRequest.fulltextFilters = [];
		this.searchRequest.fieldFilters = [];
		this.searchRequest.workflowContextFilters = this.standardWorkflowFilter;

		this.filters = tableFilters;
		const fieldFilters: FieldValueFilterDto[] = [];
		const workflowContextFilter: WorkflowContextFilterDto[] = [];

		for(const id in tableFilters) {
			const filterId = Number(id);
			const isField = !isNaN(filterId);

			const filter: Filter = tableFilters[id];
			if(filter && filter.value != null && filter.value != "") {
				this.paginationOption.page = 0;

				const comp = this.getCompareOperator(filter.matchMode!);

				if(comp != "") {
					if(!isField) {
						// WF CONTEXT
						workflowContextFilter.push({
							fieldName: id,
							searchValue: filter.value.toLowerCase(),
							comp: comp as string,
							fieldType: "text",
						});
					} else {
						// FIELD
						const column = this.fieldColumns.find((column) => {
							return column.id && column.id === filterId;
						});

						if (column) {
							fieldFilters.push({
								fieldId: filterId,
								searchValue: this.mapFieldSearchValue(column.type, filter.value) as string,
								comp: comp as FieldValueFilterDto.CompEnum,
								fieldType: column.type.toLowerCase(),
							})
						}
					}
				}
			}
		}

		this.searchRequest.fieldFilters = fieldFilters;
		this.searchRequest.workflowContextFilters = workflowContextFilter;
		await this.reloadData();
	}

	/** Reloads QueueStepEntries for step Validation */
	async reloadData() {
		this.loaded = false;

		// Load documents
		try {
			await this.loadDocuments();
			this.usingQueueEntryFallback = false;
			this.elasticLoadedSuccess = true;
		} catch (err) {
			// Only use fallback on first load. Otherwise it's all fine
			if (this.elasticLoadedSuccess === true) {
				err.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + this.$t('Squeeze.Queue.General.SearchInvalid'));
					//ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
				})
			} else{
				ToastManager.showInfo(this.$toast, this.$t('Toasts.Title.Info'), this.$t('Squeeze.Queue.QueueEntryFallback.Info'));
				// todo: Send to sentry
				try {
					this.usingQueueEntryFallback = true;
					await this.loadQueueEntries();
				} catch (err) {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), String(err));
				}
			}
		}

		this.loaded = true;
	}

	/** Loads all queue entries of a specified document class in queue step "Validation" */
	private async loadQueueEntries() {
		const result = await this.queueApi.getQueueStepEntries("Validation", undefined, undefined, undefined, this.documentClassId, undefined, undefined, undefined, undefined, this.paginationOption.page, this.paginationOption.pageSize)
		const entries = result.elements;
		this.paginationOption = result.pagination;

		if (entries && entries.length > 0) {
			this.queueEntries = entries;
		} else {
			throw new Error("Missing entries in documents class!")
		}
	}

	/** Loads all documents of a specified document class in queue step */
	private async loadDocuments() {
		this.searchRequest.fieldFilters?.forEach(filter => {
			// If the field is of type "amount"/"date" or the search value already includes <, >, = or *, nothing should be added
			if (filter.fieldType !== "amount" && filter.fieldType !== "date" && !this.containsValidElasticOperator(filter.searchValue)) {
				filter.searchValue = "*" + filter.searchValue + "*";
			}
		})

		this.searchRequest.fulltextFilters = [];
		if (this.fullText) {
			if (!this.containsValidElasticOperator(this.fullText)) {
				this.fullText = "*" + this.fullText + "*";
			}

			this.searchRequest.fulltextFilters.push({
				searchValue: this.fullText,
				comp: 'raw',
			})
		}

		const result = await this.documentApi.searchDocuments(this.documentClassId, this.searchRequest, 'index', this.paginationOption.page, this.paginationOption.pageSize, this.tableSort);
		const documents = result.elements as Document[];
		const pagination = result.pagination;
		if(pagination != undefined) this.paginationOption = pagination;
		this.documents = [];

		documents.forEach((document: any) => {
			const workflowContext: WorkflowContext = {
				step: document.queueStep,
				status: document.queueStatus.toUpperCase(),
				errorText: document.queueErrorText,
			}

			const listDocument: ListDocument = {
				id: document.id as any,
				createdAt: document.queueCreateTs,
				workflowContext: workflowContext,
				fields: {},
				exports: [],
			};

			if (document.exportStates) {
				listDocument.exports = document.exportStates;
			}

			// Map fields to current document-class
			for (const key in document.fields) {
				const field = document.fields[key];
				const column = this.fieldColumns.find(field => field.id === Number(key));

				if (column && column.id) {
					const value = field.value.text;

					const listField: ListDocumentField = {
						id: column.id,
						name: column.name,
						value: value,
						dataType: column.type,
					};
					listDocument.fields[key] = listField;
				}
			}

			this.documents.push(listDocument);
		});

		this.setDataTableFilter();
	}

	getCompareOperator(filterMatchMode: string): FieldValueFilterDto.CompEnum | string {
		let comp = filterMatchMode;
		switch(filterMatchMode) {
		// We currently ignore all comparators and always use "raw". This results in the backend sending our
		// search request as-is to Elasticsearch (except for minimal escaping) and therefore resulting in better search results.

		// case FilterMatchMode.CONTAINS: comp = "like"; break;
		// case FilterMatchMode.STARTS_WITH: comp = "bw"; break;
		// case FilterMatchMode.ENDS_WITH: comp = "ew"; break;
		// case FilterMatchMode.EQUALS: comp = "eq"; break;
		default: comp = "raw"; break;
		}

		return comp;
	}

	/**
	 * @param fieldType
	 * @param value
	 */
	mapFieldSearchValue(fieldType: string, value: unknown): string|unknown {
		switch (fieldType.toLowerCase()) {
		case "amount":
			if (typeof value == "string") {
				return value.replaceAll(",", ".") // todo: respect localization
			} else {
				return value;
			}
		default:
			return value;
		}
	}

	/**
	 * Checks if an operator is an valid elastic operator
	 * @param operator
	 */
	containsValidElasticOperator(searchValue: string): boolean {
		if (searchValue.includes("=") || searchValue.includes("<") || searchValue.includes(">") || searchValue.includes("*")
			|| searchValue.includes('"'))
		{
			return true;
		}

		return false;
	}

	loadSearches(showFirstElement: boolean = false) {
		// Do not load tiles if feature is disabled
		if (!this.store.state.featureSet.savedDocumentSearches) {
			return;
		}

		this.searchApi.getSearchWidgets().then((data) => {
			this.allSearches = data;
			if (this.$route.name === "DocumentListValidation" || this.$route.name === "DocumentListValidationWithSearch") {
				this.allSearches = this.allSearches.filter(search => search.relatedSubject === "validation" && search.documentClassId === this.documentClassId);
			} else {
				this.allSearches = this.allSearches.filter(search => search.relatedSubject !== "validation"  && search.documentClassId === this.documentClassId);
			}

			// Sort by description
			this.allSearches .sort((a,b) => {
				if (a.description! > b.description!) {
					return 1;
				}
				return -1;
			});

			if (showFirstElement) {
				const firstEntry = this.allSearches[0];
				if (firstEntry) {
					this.searchSave.searchId = firstEntry.id!
					this.searchSave.description = firstEntry.description!;
					this.searchSave.global = firstEntry.global!;
				} else {
					this.searchSave.global = false;
					this.searchSave.actionType = 'new';
					this.searchSave.description = '';
					this.isInvalid = true;
				}
			}
		}).catch(response => response.json().then ((err: ErrorDto) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
		}))
	}

	saveSearch(keepDialogOpen: boolean) {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		this.isInvalid = false;
		this.showErrorMessage = false;
		//let fullSearchSearch: any = {};

		const body: DocumentSearchFilterWidget = {
			searchRequestJson: JSON.stringify(this.searchRequest),
			documentClassId: this.documentClassId,
			description: this.searchSave.description,
			global: this.searchSave.global,
		}

		// Always set global to false, if user is not admin
		if (!this.store.state.isAdminUser) {
			body.global = false;
		}

		if (this.$route.name === "DocumentListValidation" || this.$route.name === "DocumentListValidationWithSearch") {
			body.relatedSubject = "validation";
		} else {
			body.relatedSubject = "other";
		}

		this.loading = true;
		if (this.searchSave.actionType === 'new') {
			this.searchApi.createSearchWidget(body).then(() => {
				this.showSaveSearch = false;
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				this.loadSearches();
				this.loading = false
			})
		} else if (this.searchSave.actionType === 'change') {
			body.id = this.searchSave.searchId;
			this.searchApi.updateSearchWidget(this.searchSave.searchId, body).then(() => {
				this.showSaveSearch = false;
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				this.loadSearches();
				this.loading = false
			})
		} else if (this.searchSave.actionType === 'delete') {
			this.searchApi.deleteSearchWidget(this.searchSave.searchId).then(() => {
				if (!keepDialogOpen) {
					this.showSaveSearch = false;
				}
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				this.loadSearches(keepDialogOpen);
				this.loading = false;
			})
		}
	}

	/** Trigged on update of attribute-form */
	onUpdate(data: any, invalid: boolean) {
		this.isInvalid = invalid;
		Object.assign(this.searchSave, data);
	}

	/** Triggered when a search ist selected */
	onSearchSelect(data: SearchFormDto) {
		Object.assign(this.searchSave, data);
		this.triggerSearch();
	}

	/** Clears the current search */
	async clearSearch() {
		this.fieldColumns.forEach(field => {
			this.filters["" + field.id] = {value: null, matchMode: "raw"};
		})

		this.filters["id"] = {value: null, matchMode: FilterMatchMode.EQUALS};
		this.filters["queueStatus"] = {value: null, matchMode: FilterMatchMode.EQUALS};
		this.filters["queueStep"] = {value: null, matchMode: FilterMatchMode.EQUALS};
		this.fullText = "";
		this.searchRequest.fulltextFilters = [];
		this.searchRequest.fieldFilters = [];


		if (this.$route.name !== "DocumentListValidation" && this.$route.name !== "DocumentListValidationWithSearch") {
			this.searchRequest.workflowContextFilters = [];
		}else {
			this.searchRequest.workflowContextFilters = this.standardWorkflowFilter;
		}

		await this.reloadData();
	}

	/** Triggers a search */
	async triggerSearch() {
		this.showSelectSearch = false;
		const search = this.allSearches.find(searchEntry => searchEntry.id === this.searchSave.searchId);
		if (search) {
			this.loaded = false;
			let routeName = "DocumentList";

			if (this.$route.name === "DocumentListValidation" || this.$route.name === "DocumentListValidationWithSearch") {
				routeName = "DocumentListValidationWithSearch";
			}

			await router.replace({name: routeName, params: {
				documentClassId: this.documentClassId,
				searchRequest: encodeURIComponent(search.searchRequestJson as string),
				searchId: search.id!,
			}});
			this.fieldColumns.forEach(field => {
				this.filters["" + field.id] = {value: null, matchMode: "raw"};
			})

			this.filters["id"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			this.filters["queueStatus"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			this.filters["queueStep"] = {value: null, matchMode: FilterMatchMode.EQUALS};

			if (this.searchRequest && this.searchRequest.fulltextFilters && this.searchRequest.fulltextFilters[0]) {
				const fullTextFilter = this.searchRequest.fulltextFilters[0];
				if (fullTextFilter && fullTextFilter.searchValue) {
					this.fullText = fullTextFilter.searchValue;
				}
				else {
					this.fullText = "";
				}
			}else {
				this.fullText = "";
			}

			await this.reloadData();
		}
	}

	/** Opens the Search */
	openSaveSearch() {
		this.showSaveSearch = true;
		this.searchSave.global = false;
		this.searchSave.actionType = 'new';
		this.searchSave.description = '';
		if (this.searchId !== 0) {
			const savedSearch = this.allSearches.find(search => search.id === this.searchId);

			if (savedSearch) {
				// Wenn eine Suche persönlich ist, darf diese immer editiert werden. Wenn sie global ist, dann nur
				// von Admin-Nutzern
				if(!savedSearch.global ||(savedSearch.global && this.store.state.isAdminUser)) {
					this.searchSave.actionType = "change";
					this.searchSave.searchId = this.searchId;
					this.searchSave.description = savedSearch.description!;
					this.searchSave.global = savedSearch.global!;
				}
			}
		}
	}

}
