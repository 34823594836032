
import {Options, Vue} from "vue-class-component";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {TableColumnTraining} from "@dex/squeeze-client-ts";

@Options({
	name: "DocumentPositionTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		tablePositionTrainingValues: Array,
		clickOnTestingButton: Boolean,
		loaded: Boolean,
	},
	emits: [
		'changeTrainingRow',
		'deleteTrainingRow',
		'onMarkRegion',
	],
})
export default class DocumentPositionTrainingTable extends Vue {

	/** List of all training values of PositionTraining */
	tablePositionTrainingValues!: TableColumnTraining[];

	/** Indicates end of request */
	loaded!: boolean;

	/**
	 * Change a training value
	 * @param rowData
	 */
	changeTrainingRow(rowData: any) {
		this.$emit("changeTrainingRow", rowData);
	}

	/**
	 * Deletes a row of the training table
	 * @param id
	 */
	deleteTrainingRow(id: number) {
		this.$emit("deleteTrainingRow", id);
	}

	/**
	 * Click a testing row to mark
	 * @param event
	 */
	onMarkRegion(event: any) {
		this.$emit("onMarkRegion", event);
	}

}
