<template>
	<div class="p-component p-fluid p-formgrid p-grid">
		<div class="p-field p-input-filled p-col-12">
			<label>{{$t('Squeeze.DocumentClasses.DocumentClass')}}</label>
			<Dropdown
				id="active"
				v-model="selectedDocumentClass"
				:options="documentClasses"
				optionValue="id"
				optionLabel="description"
				:placeholder="$t('Squeeze.DocumentClasses.PleaseSelect')"
				:filter="true"
				:showClear="true"
				@change="selectDocumentClass($event)"
				@show="showDropdownOverlay"
			/>
		</div>
		<div class="p-field p-col-12">
			<!-- You may use the prop max-field-height="24rem" to enable scrolling -->
			<DocumentSearchMask @submit="submit" :search-mask="searchMask" />
		</div>
	</div>
</template>
<script lang="ts">
import {
	computed,
	defineComponent,
	onMounted,
	reactive,
	ref
	// watch
} from 'vue';
import DocumentSearchMask, {SearchMask} from "@/components/DocumentSearchMask.vue";
import {DocumentClass, DocumentField, DocumentSearchRequestDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import Dropdown from 'primevue/dropdown';
import router from "@/router";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: 'DocumentClassSearchMask',
	components: {
		DocumentSearchMask,
		Dropdown,
	},

	setup() {
		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** List of all Document-Classes */
		const documentClasses = reactive<DocumentClass[]>([]);

		/** Currently selected Document-Class */
		const selectedDocumentClass = ref(0);

		/** List of all Document-Class-Fields */
		const classFields = reactive<DocumentField[]>([]);

		/** Search-Mask-Fields */
		const searchMask = computed(() => {
			return {
				id: selectedDocumentClass.value,
				fields: classFields.map(field => {
					return {
						id: field.id,
						name: field.description,
						type: field.dataType,
					};
				}),
			} as SearchMask
		});

		/** Get all Document Class Fields */
		const getAllDocumentClassFields = () => {
			if (selectedDocumentClass.value) {
				documentClassApi.getAllDocumentClassFields(selectedDocumentClass.value)
					.then(fields => {
						classFields.splice(0);
						classFields.push(...fields);
					})
					.catch(() => {
						//ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
					})
			} else {
				classFields.splice(0);
			}
		};

		/** get the document class when changed */
		const selectDocumentClass = (event: { value: number }) => {
			selectedDocumentClass.value = event.value;
			getAllDocumentClassFields();
		}

		const submit = (search: DocumentSearchRequestDto) => {
			const searchFilters = search;
			router.push({name: "DocumentList", params: {
				documentClassId: selectedDocumentClass.value,
				searchRequest: encodeURIComponent(JSON.stringify(searchFilters)),
			}});
		}

		onMounted(() => {
			/** Get all Document Classes */
			documentClassApi.getAllDocumentClasses()
				.then(allDocumentClasses => {
					documentClasses.splice(0);
					documentClasses.push(...allDocumentClasses.filter(documentClass => documentClass.maySeeDocuments));

					if (documentClasses.length > 0) {
						selectDocumentClass({ value: documentClasses[0].id! });
					}
				})
				.catch(() => {
					//ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
				})
		});

		return {
			searchMask,
			selectedDocumentClass,
			documentClasses,
			documentClassApi,
			getAllDocumentClassFields,
			selectDocumentClass,
			submit,
			showDropdownOverlay,
		};
	},
});

</script>
<style lang="scss" scoped>
</style>
