<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="roleId">{{ $t('Squeeze.UserManagement.Role') }} *</label>
			<Dropdown id="roleId" v-model="value.roleId"
				:options="allRoles"
				optionValue="id"
				optionLabel="description"
				:class="{'p-invalid':v$.roleId.$invalid && showErrorMessage}"
				@change="update"
				:filter="true"
				:disabled="dialogMode === 'edit'"
				@show="showDropdownOverlay"
			/>
			<small v-if="(v$.roleId.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.UserManagement.Role')}) }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="canManage">{{$t('Squeeze.MasterData.TableManage')}}</label>
			<br>
			<Checkbox id="canManage" v-model="value.canManage" :binary="true" @change="update" />
		</div>
		<div class="p-field p-col-6">
			<label for="canEdit">{{$t('Squeeze.MasterData.EditData')}}</label>
			<br>
			<Checkbox id="canEdit" v-model="value.canEdit" :binary="true" @change="update" />
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {MasterDataTablePermission, UserRole} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "MasterDataTablePermissionForm",
	components: {
		Dropdown,
		Checkbox,
	},
	props: {
		masterDataPermission: {
			type: Object as PropType<MasterDataTablePermission>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
		allRoles: {
			type: Array as PropType<UserRole[]>,
			default: [],
		},
		dialogMode: {
			type: String,
			default: '',
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<MasterDataTablePermission>({tableId: undefined, roleId: undefined, canManage: false, canEdit: false});

		/** Determines the required rules for validation */
		const rules = {
			roleId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.masterDataPermission);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataPermission, async () => {
			Object.assign(value, props.masterDataPermission);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			Dropdown,
			Checkbox,
			update,
			showDropdownOverlay,
		}
	},
});
</script>

<style scoped>

</style>

