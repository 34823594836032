<template>
	<DataTable :value="attributes"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.BatchClasses.Settings') }) }}
		</template>

		<Column field="settingName" :header="$t('Squeeze.BatchClasses.SettingName')" style="min-width: 10rem;">
			<template #body="attribute">
				<div class="column-cell" v-tooltip.bottom="attribute.data.settingName">
					{{ attribute.data.settingName }}
				</div>
			</template>
		</Column>
		<Column field="value" :header="$t('Squeeze.BatchClasses.Value')" style="min-width: 10rem;">
			<template #body="attribute">
				<div class="column-cell" v-tooltip.bottom="attribute.data.value">
					{{ attribute.data.value }}
				</div>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.data)" />
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, reactive, ref} from "vue";
import {BatchClass, BatchClassSetting} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "BatchClassAttributes",
	components: {
		DataTable, Column,
	},
	props: {
		attributes: {
			type: Array,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<BatchClassSetting>({});

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: BatchClass) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {settingName: '', value: '', schema: {}}, false)
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {BatchClass} data Data to edit
		 */
		const openEditDialog = (data: BatchClassSetting) => {
			emit("onEntrySelect", data, true)
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: BatchClassSetting) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			DataTable, Column, store, tableListPagination, entryDataCheck, openDeleteDialog, onNewEntryClick, openEditDialog, changeEventInInput, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Reduce height of edit/delete buttons */
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text.p-mr-2 {
	height: 1rem;
}

::v-deep(td.column-cell) {
	width: 30%;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
