<template>
	<div class="container p-p-2">
		<div class="header p-d-none p-d-md-flex">
			<QueueTimeline />
		</div>
		<div class="content">
			<QueueNavigatorView />
		</div>
	</div>
</template>

<script lang="ts">
import QueueTimeline from "@/apps/squeeze/components/QueueTimeline.vue";
import QueueNavigatorView from "@/apps/squeeze/views/QueueNavigatorView.vue";
import {defineComponent} from "vue";

interface ReportOption {
	name: string;
	code: number;
}

export default defineComponent({
	name: "QueueView",
	components: {
		QueueTimeline,
		QueueNavigatorView,
	},
	setup() {
		return {}
	},

})

</script>

<style lang="scss" scoped>

.container {
	display: flex;
	flex-flow: column nowrap;
	height: 100vh;
}

.header {
	height: 100px;
}

.content {
	height: calc(100vh - 100px - 15px);

	@media only screen and (max-width: 767px) {
		height: 100vh;
	}
}

</style>
