
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref} from "vue";
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import Dropdown from "primevue/dropdown";
import MultiSelect from 'primevue/multiselect';
import InputNumber from '@/components/DexInputNumber.vue';
import useVuelidate from "@vuelidate/core";
import {notZeroValidation} from "@/util/ValidationHelper";
import {required} from "@vuelidate/validators";
import {LookupDefinition, MasterDataColumn, MasterDataTable} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: 'DbLookupForm',
	components: {
		Dropdown, MultiSelect, InputNumber,
	},
	props: {
		dbLookUpEntry: {
			type: Object as PropType<LookupDefinition>,
			default: {
				active: true,
			},
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Object of Db-Lookup-Entry */
		const value = reactive<any>(props.dbLookUpEntry);

		/** Determines the required rules for validation */
		const rules = {
			minInputLength: { required },
			tableId: { required },
			resultKeyColumnId: { required },
			searchColumnIds: { required },
			resultValueColumnIds: { required },
			maxLookupResultValues: { notZeroValidation },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value, { $scope: false });

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all tables available from Master-Data */
		const allTables = ref<MasterDataTable[]>([]);

		/** List of all Columns of a table */
		const allTableColumn = ref<MasterDataColumn[]>([]);

		/** List with Yes/No */
		const dbLookupActive = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			] as { id: boolean; name: string }[]
		});

		/** List with Lookup-Modes */
		const dbLookupModes = dbLookupActive;

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param column Current-Column
		 */
		const getColumnLabel = (column: MasterDataColumn): string => {
			return column.description + " (" + column.name + ")";
		}

		/** Reloads the Columns for the Dropdowns */
		const reloadColumns = () => {
			if (props.dbLookUpEntry.tableId) {
				masterDataService.getMasterDataTableColumns(props.dbLookUpEntry.tableId)
					.then(data => {
						allTableColumn.value = data;
					})
			}
		}

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			if (!value.active) {
				emit("update", value, false)
			} else {
				if (!value.maxLookupResultValues && value.maxLookupResultValues !== 0) {
					// set default value
					value.maxLookupResultValues = 50;
				}

				emit("update", value, v$.value.$invalid);
			}
		}

		/** Triggered when the Table-Dropdown changes */
		const onChangeTable = () => {
			value.searchColumnIds = [];
			value.resultKeyColumnId = null;
			value.resultValueColumnIds = [];
			update();
			reloadColumns();
		}

		onMounted(() => {
			masterDataService.getAllMasterDataTables()
				.then(data => {
					allTables.value = data;
					if(value.tableId === 0) {
						value.tableId = null;
					}
				})
				.catch((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				});

			reloadColumns();

			// set default ignore input value
			if (!value.ignoreInputValue) {
				value.ignoreInputValue = false;
			}
		});

		return {
			t,
			toast,
			v$,
			value,
			allTables,
			allTableColumn,
			dbLookupActive,
			dbLookupModes,
			getColumnLabel,
			reloadColumns,
			onChangeTable,
			update,
			showDropdownOverlay,
		}
	},
});
