<template>
	<div class="p-p-2 content">
		<DocumentClassList
			:loading="loading"
			:documentClasses="documentClasses"
			:isAdministrationPathOpen="isAdministrationPathOpen"
			@openEditDialog="openEditDialog"
			@openDeleteDialog="openDeleteDialog"
			@onRowSelect="onRowSelect"
		/>
	</div>
	<!-- Lösch-Dialog -->
	<DialogDelete v-if="isAdministrationPathOpen" :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry()" />
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import DocumentClassList from "@/apps/administration/components/documentclasses/DocumentClassList.vue";
import {DocumentClass, ErrorDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import DialogDelete from "@/components/DialogDelete.vue";
import router from "@/router";

@Options({
	name: 'DocumentClassListView',
	components: {
		DocumentClassList, DialogDelete,
	},
})

export default class DocumentClassListView extends Vue {

	/** All Document-Classes */
	documentClasses?: DocumentClass[] = []

	/** A single item (used for insert/edit/delete */
	rowData?: DocumentClass = {name: "", description: ""}

	/** Show delete dialog? */
	deleteDialog = false

	/** Service for Document-Classes */
	documentClassService = ClientManager.getInstance().squeeze.documentClass

	/** Show edit-Dialog? */
	editDialog = false
	/** Show loading? **/
	loading = false

	/** Message to be shown on screen in case of information/error */
	message = ""

	/** Message-Type to be shown: success, info, warn, error */
	messageType = "error"

	/** Text of the header in Entry-Dialog */
	headerText = ''

	/** Is the Administration Path open? */
	isAdministrationPathOpen = false;

	mounted() {
		this.getDocumentClasses(true);
		this.isAdministrationPathOpen = this.$router.currentRoute.value.fullPath === '/app/administration/documentclasses';
	}

	/**
	 * Opens the Delete-Dialog
	 * @param {DocumentClass} row The row to delete
	 */
	openDeleteDialog(row: DocumentClass) {
		this.rowData = {...row};
		this.deleteDialog = true;
	}

	/**
	 * Deletes an entry
	 */
	deleteEntry() {
		if (!this.rowData) {
			return
		}
		this.loading = true;
		const id = this.rowData.id;
		this.documentClassService.deleteDocumentClassById(id as any).then(() => {
			this.deleteDialog = false;
			this.getDocumentClasses(false); // Reload View
		}).catch(response => response.json().then ((err: ErrorDto) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
		})).finally(() => {
			this.loading = false;
		})
	}

	/**
	 * Gets the Document-Classes
	 */
	async getDocumentClasses(showLoading: boolean) {
		if (showLoading) {
			this.loading = true
		}

		try {
			this.documentClasses = await this.documentClassService.getAllDocumentClasses();
		} catch (response: any) {
			const err = await response.json();
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
		} finally {
			this.loading = false;
		}
	}

	/**
	 * Is triggered when a row in the table is clicked
	 * @param {number} documentClassId ID of the Document-Class that has been clicked
	 */
	onRowSelect(documentClassId: number) {
		if(this.isAdministrationPathOpen) {
			router.push({ name: 'DocumentClassSubView', params: { documentClassId: documentClassId}})
		} else {
			router.push({name: "DocumentListValidation", params: {documentClassId: documentClassId}});
		}
	}

	/** Opens the Edit-Component */
	openEditDialog(row: DocumentClass) {
		if (row.id) {
			router.push({ name: 'DocumentClassSubView', params: { documentClassId: row.id}})
		}else {
			router.push({ name: 'DocumentClassSubView', params: { documentClassId: 0}})
		}
	}

}

</script>

<style scoped>

</style>
