<template>
	<div class="p-component p-fluid p-formgrid p-grid">
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{trainingKeyField.description}}</p>
		</div>
		<div  class="p-field p-input-filled p-col-9">
			<InputText type="text" v-model="trainingKeyField.value.value" disabled/>
			<small v-if="trainingKeyField.value.value !== '' && trainingKeyField.value.value !== null ? false : true" class="p-error">{{$t("Squeeze.Training.TrainingKey")}}</small>
		</div>

		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.DocumentClasses.Field")}}</p>
		</div>
		<div class="p-field p-input-filled p-col-9">
			<Dropdown @change="onChangeDocumentField" v-model="fieldTrainingValue.fieldId" :options="documentClassFields" optionLabel="description" optionValue="id" :filter="true" @show="showDropdownOverlay"/>
			<small v-if="warnAmount">{{$t("Squeeze.Training.WarnAmount")}}</small>
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Training.Anchor")}}</p>
		</div>
		<div class="p-col-9">
			<div class="p-field p-input-filled p-inputgroup">
				<InputText
					type="text"
					v-model="fieldTrainingValue.keyWordPattern"
					@focus="onFocusField('keyWordPattern')"
				/>
				<Button class="active" icon="mdi mdi-auto-fix" @click="onFocusField('keyWordPattern')" />
			</div>
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Locators.Value")}}</p>
		</div>
		<div class="p-col-9">
			<div class="p-field p-input-filled p-inputgroup">
				<InputText
					type="text"
					v-model="fieldTrainingValue.valuePattern"
					@focus="onFocusField('valuePattern')"
				/>
				<Button class="active" icon="mdi mdi-auto-fix" @click="onFocusField('valuePattern')" />
			</div>
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Training.IgnoreSpaces")}}</p>
		</div>
		<div class="p-field p-input-filled p-col-9">
			<Dropdown v-model="fieldTrainingValue.valueIgnoreWhitespace" :options="trainWhitespaceOptions" optionLabel="name" optionValue="id" />
		</div>
	</div>
	<div class="p-col-12">
		<Button @click="trainFieldValues"
				:label="showTrainDocument ? $t('Squeeze.Training.Train')
				:$t('Squeeze.Training.Retrain')"
				:disabled="((fieldTrainingValue.fieldId !== 0 && fieldTrainingValue.keyWordPattern !== '' && fieldTrainingValue.valuePattern !== ''
				&& trainingKeyField.value.value !== '' && trainingKeyField.value.value !== null)) ? false : true"/>
		<Button @click="testingFieldValues"
				:label="$t('Squeeze.Training.Test')"
				:disabled="(tableTrainingValues.length >= 1 ? false : true) || loading" />
	</div>
</template>

<script lang="ts">
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import {Options, Vue} from "vue-class-component";
import {FieldTraining} from '@dex/squeeze-client-ts';
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: "DocumentFieldTrainingForm",
	components: {
		InputText,
		Dropdown,
		Button,
	},
	props: {
		documentClassId: Number,
		documentFields: Array,
		fieldTrainingValue: Object,
		fieldTestingValues: Array,
		tableTrainingValues: Array,
		documentClassFields: Array,
		trainingKeyField: Object,
		showTrainDocument: Boolean,
		warnAmount: Boolean,
		blockButtonTraining: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onFocusField',
		'trainFieldValues',
		'onChangeDocumentField',
		'testingFieldValues',
	],
})
export default class DocumentFieldTrainingForm extends Vue {

	/** ID of document class*/
	documentClassId!: number;

	/** Current field training value of form  */
	fieldTrainingValue!: FieldTraining;

	/** List of all training values of FieldTraining */
	tableTrainingValues!: FieldTraining[];

	/** List Values for Train Document */
	trainWhitespaceOptions  = [
		{
			id: true,
			name: "Yes",
		},
		{
			id: false,
			name: "No",
		},
	];

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		this.trainWhitespaceOptions = [
			{
				id: true,
				name: this.$t('Squeeze.General.Yes'),
			},
			{
				id: false,
				name: this.$t('Squeeze.General.No'),
			},
		]
		this.fieldTrainingValue.valueIgnoreWhitespace = false;
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusField(fieldName: string) {
		this.$emit("onFocusField", fieldName);
	}

	/** Get all field values */
	trainFieldValues() {
		this.$emit("trainFieldValues");
	}

	/** Get all training values for training table */
	onChangeDocumentField() {
		this.$emit('onChangeDocumentField');
	}

	/** Testing all training values for training table */
	testingFieldValues() {
		this.$emit("testingFieldValues");
	}

}
</script>

<style scoped>

::v-deep(.p-button) {
	margin-right: .5rem;
}

</style>
