<template>
	<div class="p-fluid p-formgrid p-grid p-input-filled p-input-filled">
		<div class="p-field p-col-12">
			<label for="classificationClasses">{{$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.NewDocumentClass')}}</label>
			<Dropdown
				id="classificationClasses"
				v-model="documentClass"
				:options="classificationClasses"
				optionValue="documentClassId"
				optionLabel="description"
				@change="onChangeDocumentClass"
			/>
		</div>
		<div class="p-field p-col-12">
			<label for="trainDocument">{{$t('Squeeze.Validation.Dialogs.ChangeDocumentClass.TrainDocument')}}</label>
			<Dropdown
				id="trainDocument"
				v-model="trainDocumentComponent"
				:options="trainDocumentOptions"
				optionValue="id"
				optionLabel="name"
				@change="onChangeDocumentClass"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import Dropdown from "primevue/dropdown";

import {
	BatchClassClassification
} from '@dex/squeeze-client-ts';

@Options({
	name: "DocumentClassChangeForm",
	components: {Dropdown},
	props: {
		classificationClasses: {
			type: Array,
			default: [],
		},
		trainDocument: {
			type: Boolean,
			default: true,
		},
	},
	watch: {
		trainDocument: function() {
			this.trainDocumentComponent = this.trainDocument;
		},
	},
	emits: ["onChange"],
})
export default class DocumentClassChangeForm extends Vue {

	/** List of all Classification Classes */
	classificationClasses!: BatchClassClassification[];

	/** Currently active Classification Class */
	documentClass = 0;

	/** Should the Document be trained? */
	trainDocument!: boolean;

	/** Should the Document be trained (Component-Prop) */
	trainDocumentComponent = true;

	/** List Values for Train Document */
	trainDocumentOptions  = [
		{
			id: true,
			name: "Yes",
		},
		{
			id: false,
			name: "No",
		},
	]

	mounted() {
		this.trainDocumentOptions = [
			{
				id: true,
				name: this.$t('Squeeze.General.Yes'),
			},
			{
				id: false,
				name: this.$t('Squeeze.General.No'),
			},
		]

		// Pre Select first active Classification-Class
		if (this.classificationClasses[0] && this.classificationClasses[0].documentClassId) {
			this.documentClass = this.classificationClasses[0].documentClassId;
			this.$emit("onChange", this.documentClass, this.trainDocumentComponent);
		}
	}

	/** Triggered when the Document-Class is changed */
	onChangeDocumentClass() {
		this.$emit("onChange", this.documentClass, this.trainDocumentComponent);
	}

}

</script>
<style lang="scss" scoped>

</style>
