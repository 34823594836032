
import { Options, Vue } from 'vue-class-component';
import Message from 'primevue/message';
import Panel from 'primevue/panel';

@Options({
	name: "Home",
	components: {
		Panel, Message,
	},
	props: {
		apps: {
			type: Array,
		},
	},
	computed: {
		showDevDisclaimer() {
			return false;
		},
	},
})
export default class Home extends Vue {}
