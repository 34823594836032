<template>
	<div class="p-component p-fluid p-formgrid p-grid">
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{trainingKeyField.description}}</p>
		</div>
		<div  class="p-field p-input-filled p-col-9">
			<InputText type="text" v-model="trainingKeyField.value.value" disabled/>
			<small v-if="trainingKeyField.value.value !== '' && trainingKeyField.value.value !== null ? false : true" class="p-error">{{$t("Squeeze.Training.TrainingKey")}}</small>
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.DocumentClasses.Table")}}</p>
		</div>
		<div class="p-field p-input-filled p-col-9">
			<Dropdown @change="getDataOfTraining" v-model="positionTrainingValues.tableId" :options="tables" optionLabel="description" optionValue="id" />
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Training.Column")}}</p>
		</div>
		<div class="p-field p-input-filled p-col-9">
			<Dropdown @change="getDataOfTraining" v-model="positionTrainingValues.columnId" :options="columnValues" optionLabel="description" optionValue="id" :filter="true" @show="showDropdownOverlay" />
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Training.Region")}}</p>
		</div>
		<div class="p-col-9">
			<div class="p-field p-input-filled p-inputgroup">
				<InputText type="text" v-model="columnRegionValue" disabled/>
				<Button class="active" icon="mdi mdi-auto-fix" @click="onFocusFieldOfPositionTraining('columnRegion')" />
			</div>
		</div>
		<div class="p-col-3">
			<p style="font-size: 1rem;">{{$t("Squeeze.Locators.Value")}}</p>
		</div>
		<div class="p-col-9">
			<div class="p-field p-input-filled p-inputgroup">
				<InputText
					type="text"
					v-model="positionTrainingValues.valuePattern"
					@focus="onFocusFieldOfPositionTraining('valuePattern')"
				/>
				<Button class="active" icon="mdi mdi-auto-fix" @click="onFocusFieldOfPositionTraining('valuePattern')" />
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<Button @click="trainPositionValues"
				:label="buttonTrainText ? $t('Squeeze.Training.Train')
				:$t('Squeeze.Training.Retrain')"
				:disabled="positionTrainingValues.columnId !== 0 && positionTrainingValues.valuePattern !== '' && trainingKeyField.value.value !== '' && trainingKeyField.value.value !== null ? false : true"/>
		<Button @click="testingPositionValues"
				:label="$t('Squeeze.Training.Test')"
				:disabled="tablePositionTrainingValues.length >= 1 ? false : true" />
	</div>
</template>

<script lang="ts">
import InputText from 'primevue/inputtext';
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import {Options, Vue} from "vue-class-component";
import {DocumentField, DocumentTable, DocumentTableColumn, TableColumnTraining} from "@dex/squeeze-client-ts";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: "DocumentPositionTrainingForm",
	components: {
		InputText,
		Dropdown,
		Button,
	},
	props: {
		trainingKeyField: Object,
		positionTrainingValues: Object,
		columnValues: Array,
		tables: Object,
		tablePositionTrainingValues: Object,
		buttonTrainText: Boolean,
	},
	computed: {
		columnRegionValue: function() {
			let columnValue = this.positionTrainingValues.columnRegion.x0 + ':' + this.positionTrainingValues.columnRegion.x1;
			if (columnValue === "0:0") {
				columnValue = "";
			}
			return columnValue
		},
	},
	emits: [
		'onFocusFieldOfPositionTraining',
		'getDataOfTraining',
		'trainPositionValues',
		'testingPositionValues',
	],
})
export default class DocumentPositionTrainingForm extends Vue {

	/** TrainingKey of the document */
	trainingKeyField!: DocumentField;

	/** Service for getting the values of position for training */
	positionTrainingValues!: TableColumnTraining;

	/** Columns of the Table */
	columnValues!: DocumentTableColumn[];

	/** Tables of the document */
	tables!: DocumentTable[];

	/** List of all training values of PositionTraining */
	tablePositionTrainingValues!: TableColumnTraining[];

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	mounted() {
		if (this.tables.length !== 0) {
			this.positionTrainingValues.tableId = this.tables[0].id;
		}
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusFieldOfPositionTraining(fieldName: string) {
		this.$emit("onFocusFieldOfPositionTraining", fieldName);
	}

	/** Get all position training values of column for training table */
	getDataOfTraining() {
		this.$emit('getDataOfTraining');
	}

	/** Train all position values (save and retrain) */
	trainPositionValues() {
		this.$emit('trainPositionValues');
	}

	/** Testing all training columns for training table */
	testingPositionValues() {
		this.$emit("testingPositionValues");
	}

}
</script>

<style scoped>

::v-deep(.p-button) {
	margin-right: .5rem;
}

</style>
