<template>
	<DataTable
		:value="classifications"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		:rowHover="true"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.BatchClasses.Classifications') }) }}
		</template>

		<Column field="id" header="ID" :sortable="false" filterMatchMode="contains" style="min-width: 4rem; max-width: 6rem;" />
		<Column field="documentClassId" :header="$t('Squeeze.DocumentClasses.DocumentClass')" :sortable="false" filterMatchMode="contains" style="min-width: 10rem;">
			<template #body="slotProp">
				<div class="column-cell" v-tooltip.bottom="getDescription(slotProp.data)">
					{{ getDescription(slotProp.data) }}
				</div>
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
				<Button
					icon="mdi mdi-file-upload-outline"
					class="p-button-lg p-button-rounded p-button-text"
					@click="openTraining(slotProps.data)"
					v-tooltip.bottom="$t('Squeeze.BatchClasses.TrainClassification')"
				/>
			</template>
		</Column>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button p-mr-2" @click="onNewEntryClick" />
			<Button :label="$t('Squeeze.BatchClasses.TestClassification')" type="button" icon="mdi mdi-clipboard-edit" class="p-button" @click="openTesting" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {BatchClassClassification} from "@dex/squeeze-client-ts";
import {DocumentClass} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "ClassificationList",
	components: {
		DataTable, Column,
	},
	props: {
		classifications: {
			type: Array as PropType<BatchClassClassification[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
		},
		batchClassId: {
			type: Number,
			required: true,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "openTraining", "openTesting",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: BatchClassClassification) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect")
		}

		/** Get the description of a documentClass instead of number */
		const getDescription = (row: BatchClassClassification) => {
			if (props.documentClasses) {
				const docClass = props.documentClasses.find(docClass => docClass.id === row.documentClassId);
				if (docClass) {
					return docClass.description
				}
			}

			return row.documentClassId
		}

		/** Triggered when Open Training is clicked */
		const openTraining = (row: BatchClassClassification) => {
			emit("openTraining", row);
		}

		/** Triggered when Open Training is clicked */
		const openTesting = () => {
			emit("openTesting");
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			DataTable, Column, store, tableListPagination, openDeleteDialog, onNewEntryClick, getDescription, openTraining, openTesting, onChangePage,
		}
	},
});
</script>

<style scoped>

/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text.p-mr-2 {
	height: 1rem;
}

::v-deep(td div.column-cell) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
