<template>
	<DataTable
		:value="allLanguages"
		:loading="loading"
		:paginator="true"
		class="p-datatable-sm"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="tableListPagination.pageSize"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		@page="onChangePage($event)"
		:autoLayout="true"
		columnResizeMode="fit"
		:scrollable="true"
		scrollHeight="flex"
		@row-dblclick="openEditDialog($event.data)"
		:rowHover="true"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.System.Languages') }) }}
		</template>

		<Column field="id" header="ID" style="min-width: 4rem; max-width: 4rem; justify-content: center;" />
		<Column field="countryCode" :header="$t('Squeeze.System.CountryCode')" style="min-width: 8rem; max-width: 8rem; justify-content: center;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.countryCode">
					{{slotProps.data.countryCode }}
				</div>
			</template>
		</Column>
		<!--<Column field="translatedDescription" :header="$t('Squeeze.System.TranslatedDescription')" style="min-width: 10rem; max-width:15rem;" />
		<Column field="translationKey" :header="$t('Squeeze.System.TranslationKey')" style="min-width: 10rem;" />-->
		<Column field="description" :header="$t('Squeeze.Locators.Description')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{ slotProps.data.description }}
				</div>
			</template>
		</Column>
		<Column field="name" :header="$t('Squeeze.System.Name')" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{ slotProps.data.name }}
				</div>
			</template>
		</Column>
		<Column field="active" :header="$t('Squeeze.System.Active')" style="min-width: 6rem; max-width: 6rem; justify-content: center;">
			<template #body="slotProps">
				<Checkbox @change="onChangeActiveStatus(slotProps.data)" v-model="slotProps.data.active" :binary="true" :disabled="slotProps.data.countryCode === 'en' || slotProps.data.countryCode === 'de'" />
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.data)" />
			</template>
		</Column>

		<template #paginatorLeft></template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from "primevue/checkbox";
import {TranslationCountry} from "@dex/squeeze-client-ts";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "LanguagesList",
	components: {
		DataTable, Column, Checkbox,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		allLanguages: {
			type: Array as PropType<TranslationCountry[]>,
			default: [],
		},
	},
	emits: [
		'onChangeActiveStatus',
		'onEntrySelect',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Triggered when the 'active' checkbox is clicked
		 * @param {TranslationCountry} data Data to edit
		 */
		const onChangeActiveStatus = (data: TranslationCountry) => {
			emit('onChangeActiveStatus', data);
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {TranslationCountry} row Row to edit
		 */
		const openEditDialog =(row: TranslationCountry) => {
			emit('onEntrySelect', row)
		}

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		return {
			tableListPagination,
			onChangeActiveStatus,
			openEditDialog,
			onChangePage,
		};
	},
});

</script>

<style scoped>

/** Reduce height of edit/delete buttons */
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
	height: 1rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
