<template>
	<DataTable class="p-component p-shadow-2"
		:value="allElements"
		:rowHover="true"
		selectionMode="single"
		:autoLayout="true"
		:paginator="true"
		:lazy="true"
		:loading="!loaded"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:rows="pagination.pageSize"
		:totalRecords="pagination.total"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords} '"
		@page="onPage($event)"
		@row-click="onMarkWord($event)"
		@row-hover="onMarkWord($event)"
		@rowHover="true"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Validation.General.Position') }) }}
		</template>
		<Column field="value" :header="$t('Squeeze.Locators.Value')"></Column>
		<Column field="offset" header="Offset"></Column>
		<Column field="boundingBox.page" :header="$t('Squeeze.Locators.Site')"></Column>
		<Column field="boundingBox.x0" header="x0"></Column>
		<Column field="boundingBox.y0" header="y0"></Column>
		<Column field="boundingBox.x1" header="x1"></Column>
		<Column field="boundingBox.y1" header="y1"></Column>

		<template #paginatorLeft>
			<Button type="button" icon="mdi mdi-refresh" class="p-button-text" @click="onReload" :disabled="!loaded" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";
import DataTable from "@/components/DexDataTable.vue";
import Column from 'primevue/column';
import {PaginationDto} from "@dex/squeeze-client-ts";
import {DocumentSpan} from "@dex/squeeze-client-ts";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: 'OCRWords',
	components: {
		DataTable,
		Column,
	},
	props: {
		documentId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		'onMarkWord',
	],

	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Indicates end of request */
		const loaded = ref(false);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Document Class API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Empty pagination object & Info / Options of pagination  */
		const pagination = reactive<PaginationDto>({});

		/** Empty element array */
		const allElements = ref<DocumentSpan[]>([]);

		/** Get all Document Spans */
		const getDocumentSpans = () => {
			loaded.value = false;
			documentApi.getDocumentSpans(props.documentId, pagination.page, pagination.pageSize)
				.then((documentSpans) => {
					allElements.value = documentSpans.elements as DocumentSpan[];
					Object.assign(pagination, documentSpans.pagination)
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loaded.value = true;
				})
		}

		/** Triggered when the next page is selected */
		const onPage = (event: {page: number; rows: number}) => {
			pagination.page = event.page;
			pagination.pageSize = event.rows;

			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name as string + '/ocrWords', event.rows);
			getDocumentSpans();
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			getDocumentSpans();
		}

		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		onMounted(() => {
			// Initialize pagination info / options
			pagination.pageSize = TableSettings.getTableListPagination(store, route.name as string + '/ocrWords');
			pagination.page = 0;
			pagination.total = 0;

			getDocumentSpans();
		});

		return {
			t,
			toast,
			store,
			documentApi,
			loaded,
			allElements,
			pagination,
			getDocumentSpans,
			onPage,
			onReload,
			onMarkWord,
		};
	},
});

</script>

<style lang="scss" scoped>

</style>
