

import {Options, Vue} from "vue-class-component";

@Options({
	name: 'DocumentClassAttributesSubView',
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
})

export default class DocumentClassAttributesSubView extends Vue {

	documentClassId!: number

	/** Currently active field-group */
	activeFieldGroup?: number = -1

	/**
	 * Is triggered when the Field-Groups triggers a change
	 * @param activeFieldGroup
	 */
	onFieldGroupChange(activeFieldGroup: number) {
		this.activeFieldGroup = activeFieldGroup
	}

}

