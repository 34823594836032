<template>
    <DataTable class="p-component p-shadow-2" :value="tablePositionTrainingValues" :autoLayout="true" :rowHover="true" @row-click="onMarkRegion" :loading="!loaded">
        <template #loading>
            {{  $t('Squeeze.General.Loading') }}
        </template>
        <template #empty>
            {{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Validation.General.Position') }) }}
        </template>
        <Column field="id" header="ID" style="width:3rem; justify-content: center;"></Column>
        <Column field="columnName" :header="$t('Squeeze.Training.Column')"></Column>
        <Column field="valuePattern" :header="$t('Squeeze.Locators.PatternValue')"></Column>
        <template v-if="clickOnTestingButton">
            <Column field="columnHits" header="Hits" style="width:3rem; justify-content: center;"></Column>
        </template>
        <Column style="width:8rem">
            <template #body="slotProps">
                <Button v-tooltip="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button p-component p-button-icon-only p-button-lg p-button-rounded p-button-success p-button-text" @click="changeTrainingRow(slotProps.data)"/>
                <Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text deleteButtonRow" @click="deleteTrainingRow(slotProps.data)"/>
            </template>
        </Column>
    </DataTable>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {TableColumnTraining} from "@dex/squeeze-client-ts";

@Options({
	name: "DocumentPositionTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		tablePositionTrainingValues: Array,
		clickOnTestingButton: Boolean,
		loaded: Boolean,
	},
	emits: [
		'changeTrainingRow',
		'deleteTrainingRow',
		'onMarkRegion',
	],
})
export default class DocumentPositionTrainingTable extends Vue {

	/** List of all training values of PositionTraining */
	tablePositionTrainingValues!: TableColumnTraining[];

	/** Indicates end of request */
	loaded!: boolean;

	/**
	 * Change a training value
	 * @param rowData
	 */
	changeTrainingRow(rowData: any) {
		this.$emit("changeTrainingRow", rowData);
	}

	/**
	 * Deletes a row of the training table
	 * @param id
	 */
	deleteTrainingRow(id: number) {
		this.$emit("deleteTrainingRow", id);
	}

	/**
	 * Click a testing row to mark
	 * @param event
	 */
	onMarkRegion(event: any) {
		this.$emit("onMarkRegion", event);
	}

}
</script>

<style scoped>

</style>
