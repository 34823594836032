
import { defineComponent } from 'vue';
import DashboardTile from "@/components/DashboardTile.vue";
import DocumentReportExportedDocuments from "@/apps/squeeze/components/DocumentReportExportedDocuments.vue";
import DocumentReportExports from "@/apps/squeeze/components/DocumentReportExports.vue";
import DocumentReportOrigin from "@/apps/squeeze/components/DocumentReportOrigin.vue";
import DocumentReportProcessed from "@/apps/squeeze/components/DocumentReportProcessed.vue";

export default defineComponent({
	components: {
		DocumentReportProcessed,
		DocumentReportOrigin,
		DocumentReportExports,
		DocumentReportExportedDocuments,
		DashboardTile,
	},
	setup() {
		return {}
	},
});
