
import { Options, Vue } from 'vue-class-component';
import {DocumentField, PaginationDto, QueueEntry} from "@dex/squeeze-client-ts";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import ConfirmDialog from 'primevue/confirmdialog';
import Column from 'primevue/column';
import {FilterMatchMode} from 'primevue/api';
import {DEFAULT_LOCALE} from "@/lang";
import { i18n } from "@/main";

import {DateTimeOptions} from "@/util/DateTimeOptions";
import {ToastManager} from "@/util/ToastManager";
import { getSqueezeStepIconMap } from "@/apps/squeeze/App.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {useSqueezeStore} from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";

interface MenuItem {
	label: string;
	items: MenuSubItem[];
}

interface MenuSubItem {
	label: string;
	icon: string;
	command: {};
}

@Options({
	name: "QueueEntries",
	components: {
		DataTable,
		Column,
		Button,
		Menu,
		ConfirmDialog,
	},
	props: {
		documentClassId: Number,
		documentClassName: String,
		queueEntries: Array,
		loaded: Boolean,
		paginationInfo: Object,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage"],
})
/**
 * @description Uses Queue API endpoint instead of Elastic Search API endpoint
 */
export default class QueueEntries extends Vue {

	/** Queue API endpoint */
	queueApi = ClientManager.getInstance().squeeze.queue;

	/** ID of document class */
	documentClassId!: number;

	/** Name of document class */
	documentClassName = "";

	/** List of all QueueEntries in QueueStep Validation */
	queueEntries: QueueEntry[] = [];

	/** Pagination information */
	paginationInfo: PaginationDto = {};

	filters: any = {
		"id": {value: null, matchMode: FilterMatchMode.EQUALS},
		"create_ts": {value: null, matchMode: FilterMatchMode.CONTAINS},
		"status": {value: null, matchMode: FilterMatchMode.EQUALS},
		"errorText": {value: null, matchMode: FilterMatchMode.CONTAINS},
	};

	/** Requeue steps to choose in menu */
	steps: MenuItem[] = [];

	/** Indicates end of request */
	loaded = false;

	/** Currently selected row or rows */
	selection: QueueEntry | null = null
	selectedRows: QueueEntry[] = [];

	lockTable = false;

	/** Current locale */
	lang = DEFAULT_LOCALE;

	/** Current Vuex Store */
	store = useSqueezeStore();

	mounted() {
		this.steps = [{
			label: this.$t('Squeeze.Queue.General.Steps'),
			items: [],
		}];
		getSqueezeStepIconMap().forEach((icon: string, step: string) => {
			this.steps[0].items.push({
				label: this.$t('Squeeze.Queue.Steps.' + step),
				icon: icon,
				command: (ev: { item: any; originalEvent: any }) => {
					let messageText = '';
					let acceptFn = null;

					// get parent element of target
					const el = ev.originalEvent.target as HTMLElement;
					const closestParent = el.closest('div');

					if (closestParent && closestParent.matches('#multi_entry_menu_goto')) {
						// If entry in multi_menu is clicked (Requeue-Button under table for multi-selection)
						messageText = this.$t('Squeeze.Queue.Requeue.ForwardEntries');
						acceptFn = () => {
							this.requeueSelectedEntries(step);
						};
					}
					else if ((ev.originalEvent.target as HTMLElement).matches('#single_entry_menu *')) {
						// If entry in single_menu is clicked (Requeue-Button in every single row)
						messageText = this.$t('Squeeze.Queue.Requeue.ForwardEntry');
						acceptFn = () => {
							this.requeueEntry(step);
						};
					}
					else {
						return;
					}

					this.$confirm.require({
						message: messageText + ": " + this.$t('Squeeze.Queue.Steps.' + step) + " ?",
						header: this.$t('Squeeze.General.Confirmation'),
						icon: 'pi pi-exclamation-triangle',
						accept: acceptFn,
						reject: () => { /**/ },
					});
				},
			});
		});
	}

	/**
	 * Triggered when a row is clicked
	 * @param event
	 */
	onRowClick(event: { originalEvent: MouseEvent; data: QueueEntry; index: number }) {
		/* Prevent the row-click in the selection-column with the checkbox
		* Use the selectionMode 'multiple' only at the respective column and not at the table, when you also use the row-click event
		* If the selectionMode is set on the table and the row click event is active, these two events overwrite each other
		*/
		if (event.originalEvent
			&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
			&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')) {
			this.$emit("onRowSelect", event.data);
		}
		return;
	}

	/** Triggered when the next page is selected */
	onPage(event: any) {
		this.$emit("onPage", event)
	}

	/** Triggered when table content should be reloaded */
	onReload() {
		this.$emit("onReload");
	}

	/** Opens requeue menu */
	openRequeueMenu(event: MouseEvent, data: QueueEntry) {
		this.selection = data;
		(this.$refs.single_entry_menu as any).toggle(event);
	}

	/**
	 * Opens the Requeue Menu
	 * @param event
	 */
	openMultiRequeueMenu(event: any) {
		if (!this.selectedRows.length) {
			return;
		}
		(this.$refs.multi_entry_menu as any).toggle(event);
	}

	/**
	 * Opens the Requeue Menu
	 * @param event
	 */
	openGoToMenu(event: any) {
		(this.$refs.multi_entry_menu as any).toggle(event);
	}

	/** Requeues selected QueueEntry to specified step */
	requeueEntry(step: string) {
		if(this.selection && this.selection.id) {
			// REQUEUE API ENDPOINT
			this.lockTable = true;
			this.queueApi.requeueEntry(this.selection.id, step).then(() => {
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.Queue.Requeue.Success'), this.$t('Squeeze.Queue.Steps.' + step));
			}).catch(reason => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), reason);
			}).finally(() => {
				this.selection = null;
				this.lockTable = false;
				this.onReload();
			})
		} else {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Select.Entry.One'));
		}
	}

	requeueSelectedEntries(step: string) {
		if (!this.selectedRows || !this.selectedRows.length) {
			return;
		}

		this.lockTable = true;
		this.selectedRows.reduce((chain, { id }) => {
			if (id == undefined) {
				return chain;
			}
			return chain.finally(() => this.queueApi.requeueEntry(id, step).catch(() => { /* swallow errors */ }))
		}, Promise.resolve()).finally(() => {
			ToastManager.showSuccess(this.$toast, this.$t('Squeeze.Queue.Requeue.Success'), this.$t('Squeeze.Queue.Steps.' + step));
			this.selectedRows = [];
			this.lockTable = false;
			this.onReload();
		});
	}

	/** Formats date string to desired locale string */
	formatDate(dateToFormat: string) {
		if(dateToFormat && dateToFormat.length) {
			const dateNumber = Date.parse(dateToFormat);

			if(!isNaN(dateNumber)) {
				const date = new Date(dateToFormat);
				const options: DateTimeOptions = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
				this.lang = i18n.global.locale;
				return date.toLocaleDateString(this.lang.toLowerCase() + '-' + this.lang.toUpperCase(), options);
			}
		}
		return "";
	}

	/** Returns field value of passed DocumentField array */
	getFieldValue(fields: DocumentField[], fieldId: number, type: string) {
		if(fields && fieldId) {
			const fieldValue = fields.find((field) => {
				return field.id === fieldId;
			});

			if(fieldValue != undefined) {
				if(fieldValue.value && fieldValue.value.value) {
					switch(type.toLowerCase()) {
					case "amount": return this.parseAmounts(fieldValue.value.value);
					case "date": return this.formatDate(fieldValue.value.value);
					default: return fieldValue.value.value;
					}
				}
			}
		}

		return "";
	}

	/** Parses an amount (string) to locale string */
	parseAmounts(value: string) {
		value = value.replace(/[^0-9.,]/g, "");

		if(value.indexOf(",") != -1) {
			value = value.replaceAll(".", "").replace(",", ".");
		}

		if(value.length > 0) {
			return parseFloat(value).toLocaleString(this.lang.toLowerCase() + '-' + this.lang.toUpperCase(), {minimumFractionDigits: 2});
		} else {
			return "0,00";
		}
	}

}
