
import {defineComponent, PropType, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {SqueezeConfig, Clients, Importer, ConsoleLogger, LocatorConfig} from "@dex/squeeze-configframework";
import {useToast} from "primevue/usetoast";
import EntryDialog from "@/components/EntryDialog.vue";
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import BlockUI from 'primevue/blockui';
import ConfigurationsList, {DataEntry} from "@/apps/administration/components/system/configurations/ConfigurationsList.vue";
import {ToastManager} from "@/util/ToastManager";
import {DocumentClassConfig, FieldsConfig, TableConfig} from "@dex/squeeze-configframework/build/export/SqueezeConfig";
import {TreeTableEntry} from "@/apps/administration/views/squeeze/system/ConfigurationsView.vue";
import {DocumentField, DocumentLocator, DocumentTableColumn, MasterDataTable} from "@dex/squeeze-client-ts";
import {MasterDataConfig} from "@dex/squeeze-configframework/build/export/MasterDataConfig";
import {LineItemLocatorDetailsConfig, SourceTableConfig} from "@dex/squeeze-configframework/build/export/LocatorConfig";

interface SelectedKey {
	[key: string]: SelectedChecked;
}

interface SelectedChecked {
	partialChecked: boolean;
	checked: boolean;
}

export default defineComponent({
	name: "ConfigurationsDataList",
	components: {
		ConfigurationsList,
		EntryDialog,
		TreeTable,
		Column,
		BlockUI,
	},
	props: {
		fileData: {
			type: Object as PropType<SqueezeConfig>,
		},
		showDataList: {
			type: Boolean,
			default: false,
		},
		dataList: {
			type: Array as PropType<TreeTableEntry[]>,
			default: [],
		},
	},
	emits: ["onCloseDialog", "removeFiles"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Api-Clients */
		const apiClients = {
			documentClassApi: ClientManager.getInstance().squeeze.documentClass,
			locatorApi: ClientManager.getInstance().squeeze.locator,
			masterDataApi: ClientManager.getInstance().squeeze.masterData,
			userApi: ClientManager.getInstance().squeeze.user,
		};

		/** Selected Keys of data list */
		const selectedKeys = ref<SelectedKey>({});

		/** Data to import */
		const importData: SqueezeConfig = {
			documentClasses: [],
			locators: [],
			masterDataTables: [],
		};

		/** Should the check of data list be shown? */
		const showDataCheckList = ref<boolean>(false);

		/** Should the skeleton be shown? */
		const showSkeleton = ref<boolean>(false);

		/** The new changed data of import file */
		const newChangedData = ref<DataEntry[]>([]);

		/** Abort the keydown event in entry dialog? */
		const abortKeydownEvent = ref<boolean>(false);

		/**
		 * Data of document class
		 * This function assigns all data to the main object
		 * @param data
		 * @param currentEl
		 * @param newDc
		 * @param lastChild
		 * @param childObject
		 */
		function dataOfDocumentClass(data: TreeTableEntry[], currentEl: DocumentClassConfig | LocatorConfig | MasterDataConfig | FieldsConfig | TableConfig, newDc: DocumentClassConfig, lastChild?: string, childObject?: DataEntry) {
			data.forEach((element, index: number) => {
				let child: string = "";
				if (element.key.includes('table')) {
					child = "tables";
				} else {
					child = "fieldGroups";
				}

				if (lastChild) {
					const currentColumn: DocumentField | DocumentTableColumn = (currentEl as any)[lastChild].find((c: DocumentField | DocumentTableColumn) => c.name === element.data.label);
					if (currentColumn && (selectedKeys.value as any)[element.key] && (selectedKeys.value as any)[element.key].checked) {
						(childObject as any)[lastChild].push(currentColumn);
					}
					if ((currentEl as any)[lastChild].length === index +1 && (childObject as any)[lastChild].length > 0) {
						(newDc as any)[child].push(childObject);
					}
				} else {
					const currentElement = (currentEl as any)[child].find((el: any) => el[child.slice(0, -1)].name === element.data.label);
					if ((selectedKeys.value as any)[element.key] && (selectedKeys.value as any)[element.key].checked) {
						(newDc as any)[child].push(currentElement);
						newDc.documentClass = (currentEl as DocumentClassConfig).documentClass;

						// check locators of document class properties
						if (currentElement.columns) {
							currentElement.columns.forEach((col: any) => {
								if (col.headerLocatorId) {
									const searchedLocator = props.fileData!.locators?.find(loc => loc.locator.id === col.headerLocatorId);
									if (searchedLocator && searchedLocator.locator.name && !importData.locators.find(locator => locator.locator.name === searchedLocator.locator.name)) {
										importData.locators.push(searchedLocator);
									}
								}

								if (col.valueLocatorId) {
									const searchedLocator = props.fileData!.locators?.find(loc => loc.locator.id === col.valueLocatorId);
									if (searchedLocator && searchedLocator.locator.name && !importData.locators.find(locator => locator.locator.name === searchedLocator.locator.name)) {
										importData.locators.push(searchedLocator);
									}
								}
							});
						}

						if (currentElement.table && currentElement.table.locatorId) {
							const searchedLocator = props.fileData!.locators?.find(loc => loc.locator.id === currentElement.table.locatorId);
							if (searchedLocator && searchedLocator.locator.name && !importData.locators.find(locator => locator.locator.name === searchedLocator.locator.name)) {
								importData.locators.push(searchedLocator);
							}
						}

						if (currentElement.fields) {
							currentElement.fields.forEach((field: any) => {
								if (field.locatorId) {
									const searchedLocator = props.fileData!.locators?.find(loc => loc.locator.id === field.locatorId);
									if (searchedLocator && searchedLocator.locator.name && !importData.locators.find(locator => locator.locator.name === searchedLocator.locator.name)) {
										importData.locators.push(searchedLocator);
									}
								}
							});
						}
					} else if (element.children) {
						const elementObject: any = {};
						if (element.key.includes('table')) {
							child = "columns";
							Object.assign(elementObject, {columns: [], table: currentElement.table});
						} else {
							child = "fields";
							Object.assign(elementObject, {fields: [], fieldGroup: currentElement.fieldGroup})
						}
						elementObject.key = element.key;
						elementObject.data = element.data;
						elementObject.children = element.children;

						dataOfDocumentClass(element.children, currentElement, newDc, child, elementObject);
					}
				}
			})
		}

		/**
		 * Check child of element to import data
		 * @param children
		 * @param fileElement
		 * @param element
		 */
		const checkChildElement = (children: TreeTableEntry[], fileElement: (DocumentClassConfig |  LocatorConfig |  MasterDataConfig)[], element: "documentClasses" | "locators" | "masterDataTables") => {
			children.forEach((child: TreeTableEntry) => {
				// in loop check for document classes
				if (element === "documentClasses") {
					const dc: DocumentClassConfig = {
						documentClass: {
							name: "",
							description: "",
						},
						fieldGroups: [],
						tables: [],
					}

					const currentDocumentClass = fileElement.find((docC: any) => docC.documentClass.name === child.data.label);
					if (currentDocumentClass) {
						if ((selectedKeys.value as any)[child.key] && (selectedKeys.value as any)[child.key].checked) {
							if (child.children) {
								dc.documentClass = (currentDocumentClass as DocumentClassConfig).documentClass;
								dataOfDocumentClass(child.children, currentDocumentClass, dc);

								if (dc.tables.length > 0 || dc.fieldGroups.length > 0) {
									// push document class data to importData
									importData.documentClasses.push(dc);
								}
							} else {
								(importData as any)[element].push(currentDocumentClass);
							}
						} else if (child.children) {
							dc.documentClass = (currentDocumentClass as DocumentClassConfig).documentClass;
							dataOfDocumentClass(child.children, currentDocumentClass, dc);

							if (dc.tables.length > 0 || dc.fieldGroups.length > 0) {
								// push document class data to importData
								importData.documentClasses.push(dc);
							}
						}
					}
				}

				if (element === "locators") {
					const loc: LocatorConfig = {
						locator: {
							name: '',
							description: '',
						},
						locatorDetails: {},
						settings: [],
					}

					const currentLocator = fileElement.find((loc: any) => loc.locator.name === child.data.label);
					if (currentLocator && child.children) {
						child.children.forEach(c => {
							if ((selectedKeys.value as any)[c.key] && (selectedKeys.value as any)[c.key].checked
								|| ((selectedKeys.value as any)[child.key] && (selectedKeys.value as any)[child.key].checked && child.children)) {
								loc.locator = (currentLocator as LocatorConfig).locator;

								switch (loc.locator.locatorType) {
								case 8: {
									// Search for line items
									const currentElement: LineItemLocatorDetailsConfig | undefined = (currentLocator as LocatorConfig).locatorDetails.lineItemsDetails;
									if (currentElement
										&& currentElement.lineItemsDetails.tableId
										&& currentElement.documentClass
										&& !importData.documentClasses.find(d => d.documentClass.name === currentElement.documentClass.documentClass.name)
										&& (selectedKeys.value as any)[child.key] && (selectedKeys.value as any)[child.key].checked) {
										importData.documentClasses.push(currentElement.documentClass);
									}
									break;
								}
								case 9: {
									// Search for DB linked data
									const currentElement: SourceTableConfig | undefined = (currentLocator as LocatorConfig).locatorDetails.sourceTable;

									// push current document class
									if (currentElement && currentElement.documentClass
										&& currentElement.documentClass.documentClass.name === c.data.label
										&& !importData.documentClasses.find(d => d.documentClass.name === currentElement.documentClass.documentClass.name)
										&& (selectedKeys.value as any)[c.key] && (selectedKeys.value as any)[c.key].checked) {
										importData.documentClasses.push(currentElement.documentClass);
									}

									if (currentElement && currentElement.masterDataTables[0].name === c.data.label && (selectedKeys.value as any)[c.key] && (selectedKeys.value as any)[c.key].checked) {
										// remove id column
										const idColIndex = currentElement.masterDataTables[0].columns!.findIndex((col: any) => col.name === 'id');
										currentElement.masterDataTables[0].columns!.splice(idColIndex, 1);

										// push current master data table
										if (!importData.masterDataTables.find(m => m.masterDataTable.name === currentElement.masterDataTables[0].name)) {
											importData.masterDataTables.push({masterDataTable: currentElement.masterDataTables[0], columns: currentElement.masterDataTables[0].columns!});
										}
									}

									if (currentElement && currentElement.locators[0] && currentElement.locators[0].name === c.data.label && (selectedKeys.value as any)[c.key] && (selectedKeys.value as any)[c.key].checked) {
										// push current locator
										if (!importData.locators.find(locator => locator.locator.name === currentElement.locators[0].name)) {
											importData.locators.push({locator: currentElement.locators[0], locatorDetails: {}, settings: []});
											// FIXME: Add locatorDetails and settings of locator
										}
									}
									break;
								}
								case 14: {
									// Value from Regular Expression
									const currentElement: any = (currentLocator as LocatorConfig).locatorDetails.valueFromRegexDetails;
									if (currentElement.masterDataTables[0].name === c.data.label) {
										// remove id column
										const idColIndex = currentElement.masterDataTables[0].columns.findIndex((col: any) => col.name === 'id');
										currentElement.masterDataTables[0].columns.splice(idColIndex, 1);

										// push current master data table
										if (!importData.masterDataTables.find(m => m.masterDataTable.name === currentElement.masterDataTables[0].name)) {
											importData.masterDataTables.push({masterDataTable: currentElement.masterDataTables[0], columns: currentElement.masterDataTables[0].columns});
										}
									}
									break;
								}
								}
							}
						})
					}

					if (currentLocator && loc.locator.name && !importData.locators.find(l=> l.locator.name === loc.locator.name) && (selectedKeys.value as any)[child.key] && (selectedKeys.value as any)[child.key].checked) {
						loc.locatorDetails = (currentLocator as LocatorConfig).locatorDetails;
						importData.locators.push(loc);
					}
				}

				// in loop check for master data tables
				if ((selectedKeys.value as any)[child.key] && (selectedKeys.value as any)[child.key].checked && element === 'masterDataTables') {
					// in next line: element.slice is singular of element
					const currentElement = fileElement.find((el: any) => el[element.slice(0, -1)].name === child.data.label);
					if (currentElement && !importData.masterDataTables.find(m => m.masterDataTable.name === (currentElement as MasterDataConfig).masterDataTable.name)) {
						(importData as any)[element].push(currentElement);
					}
				}
			})
		}

		/**
		 * Check (parent) element data to import data
		 * @param data
		 * @param element
		 */
		const checkElement = (data: TreeTableEntry, element: "documentClasses" | "locators" | "masterDataTables") => {
			// check current file element
			let fileElement: (DocumentClassConfig |  LocatorConfig |  MasterDataConfig)[];
			switch (element) {
			case "documentClasses":
				fileElement = props.fileData!.documentClasses;
				break;
			case 'locators':
				fileElement = props.fileData!.locators;
				break;
			case 'masterDataTables':
				fileElement = props.fileData!.masterDataTables;
				break;
			}

			// check if data key is checked
			if ((selectedKeys.value as any)[data.key] && (selectedKeys.value as any)[data.key].checked) {
				if (!data.children) {
					(importData as any)[element] = fileElement;
					return;
				}
				checkChildElement(data.children, fileElement, element);
			} else if (data.children) {
				checkChildElement(data.children, fileElement, element);
			}
		}

		/** Check the selected data */
		const checkSelectedData = async () => {
			if (!selectedKeys.value) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + t('Viewer.General.NoEntriesFound'));
				return;
			}

			emit("onCloseDialog");
			showDataCheckList.value = true;

			// reset import data
			importData.documentClasses = [];
			importData.locators = [];
			importData.masterDataTables = [];

			props.dataList.forEach((data: TreeTableEntry) => {
				switch (data.key) {
				case '0':
					// check all document classes for import
					checkElement(data, "documentClasses");
					break;
				case '1':
					// check all locators for import
					checkElement(data, "locators");
					break;
				case '2':
					// check all master data for import
					checkElement(data, "masterDataTables");
					break;
				}
			})
		}

		/** Trigger to close Dialog */
		const onClose = () => {
			abortKeydownEvent.value = false;

			selectedKeys.value = {};
			emit("onCloseDialog");
		}

		/**
		 * Changed the data structure of document class
		 * Mapping to data type
		 * @param data
		 */
		const changeDataStructureOfDc = (data: DataEntry[]) => {
			const dc: DocumentClassConfig = {
				documentClass: {
					name: "",
					description: "",
				},
				fieldGroups: [],
				tables: [],
			};

			data.map((d: any) => {
				if (d.key.includes("fg")) {
					dc.fieldGroups.push({ fieldGroup: d.fieldGroup, fields: d.children });
				}

				if (d.key.includes("table")) {
					dc.tables.push({ table: d.table, columns: d.children });
				}
			});

			return dc;
		}

		/**
		 * Change data structure to import data structure
		 * @param currentData
		 */
		const changeDataStructureOfData = (currentData: DataEntry[]) => {
			const data: (DocumentClassConfig | DocumentLocator | MasterDataTable)[] = [];

			currentData.forEach((object: DataEntry) => {
				if (object.children && object.children.length > 0) {
					const dc = changeDataStructureOfDc(object.children);
					delete object['children'];
					(dc.documentClass as any) = object;
					data.push(dc as never);
				} else if (object.data.type === t('Squeeze.DocumentClasses.DocumentClass') && !object.children) {
					data.push({ documentClass: object, fieldGroups: [], tables: [] } as never);
				} else {
					data.push(object as never);
				}

				delete (object as any)['data'];
			})

			return data;
		}

		/** Import of data */
		const importFinallyData = async (finallyData: SqueezeConfig) => {
			const clients: Clients = {
				documentClass: apiClients.documentClassApi,
				masterData: apiClients.masterDataApi,
				locator: apiClients.locatorApi,
			};

			try {
				const importer = new Importer(clients, new ConsoleLogger());
				await importer.run(finallyData);
			} catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			} finally {
				showDataCheckList.value = false;
				loading.value = false;
				showSkeleton.value = false;
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessDataImport'));
			}
		}

		/** Confirm with imported data */
		const confirmData = () => {
			let shouldSkip: boolean = false;
			// check if all data are free of errors
			newChangedData.value.forEach((data: DataEntry) => {
				const checkError = data.children!.find((el: DataEntry) => el.data.status === "error");
				if (checkError) {
					shouldSkip = true;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + t('Squeeze.System.ErrorDataImport'));
				}
			});
			if (shouldSkip) {
				return;
			}

			loading.value = true;

			const finallyData: SqueezeConfig = {
				documentClasses: [],
				locators: [],
				masterDataTables: [],
			};

			try {
				showSkeleton.value = true;

				newChangedData.value.forEach((data: DataEntry) => {
					switch (data.key) {
					case '0':
						// check all document classes for import
						(finallyData.documentClasses as any) = changeDataStructureOfData(data.children!);
						break;
					case '1':
						// check all locators for import
						(finallyData.locators as any) = changeDataStructureOfData(data.children!);
						break;
					case '2':
						// check all master data for import
						(finallyData.masterDataTables as any) = changeDataStructureOfData(data.children!);
						break;
					}
				});

				importFinallyData(finallyData);
			} catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			} finally {
				emit("removeFiles");
			}
		}

		/**
		 * Triggered when data change
		 * @param data
		 */
		const onDataChange = (data: DataEntry[]) => {
			newChangedData.value = data;
		}

		/**
		 * Is edit dialog shown?
		 * Prevent keydown event in entry dialogs
		 * @param isEditDialogShown
		 */
		const onShowEditDialog = (isEditDialogShown: boolean) => {
			abortKeydownEvent.value = isEditDialogShown;
		}

		return {
			t,
			toast,
			loading,
			apiClients,
			selectedKeys,
			importData,
			showDataCheckList,
			showSkeleton,
			newChangedData,
			abortKeydownEvent,
			checkSelectedData,
			onClose,
			confirmData,
			onDataChange,
			onShowEditDialog,
		};
	},
});

