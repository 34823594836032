<template>
	<Editor v-model="value" editorStyle="height: 250px">
		<template #toolbar>
			<span class="ql-formats">
				<select class="ql-header" defaultValue="0" style="width: 7.5rem">
					<option value="0">{{$t("Squeeze.Validation.Email.FontSizeNormal")}}</option>
					<option value="3">{{$t("Squeeze.Validation.Email.FontHeadline1")}}</option>
					<option value="2">{{$t("Squeeze.Validation.Email.FontHeadline2")}}</option>
					<option value="1">{{$t("Squeeze.Validation.Email.FontHeadline3")}}</option>
				</select>
				<select class="ql-font">
					<option></option>
					<option value="serif"></option>
					<option value="monospace"></option>
				</select>
			</span>
			<!--
			<span class="ql-formats">
				<select class="ql-emailVars">
					<option value="1" data-label="1">1</option>
					<option selected data-label="2">2</option>
					<option value="2" data-label="3">3</option>
					<option value="3" data-label="4">4</option>
				</select>
			</span>
			-->
			<span class="ql-formats">
				<button class="ql-bold" type="button"></button>
				<button class="ql-italic" type="button"></button>
				<button class="ql-underline" type="button"></button>
			</span>
			<span class="ql-formats">
				<select class="ql-color"></select>
				<select class="ql-background"></select>
			</span>
			<span class="ql-formats">
				<button class="ql-list" value="ordered" type="button"></button>
				<button class="ql-list" value="bullet" type="button"></button>
			</span>
			<span class="ql-formats">
				<button class="ql-align" value="" type="button"></button>
				<button class="ql-align" value="center" type="button"></button>
				<button class="ql-align" value="right" type="button"></button>
				<button class="ql-align" value="justify" type="button"></button>
			</span>
			<span class="ql-formats">
				<button class="ql-link" type="button"></button>
				<button class="ql-image" type="button"></button>
				<button class="ql-code-block" type="button"></button>
			</span>
			<span class="ql-formats">
				<button class="ql-clean" type="button"></button>
			</span>
		</template>
	</Editor>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import Editor from 'primevue/editor';

@Options({
	name: "EmailForm",
	components: {Editor},
	props: {
		value: String,
	},
})
export default class EmailForm extends Vue {

	/** Value of the Form */
	value!: string;

}

</script>
<style>
/*
// Might be relevant later for "Vorlagen"
.ql-picker.ql-emailVars {
	width: 120px;
}

.ql-snow .ql-picker.ql-emailVars .ql-picker-item[data-label]:not([data-label=''])::before{
	content: attr(data-label);
}
 */
</style>
