
import {defineComponent, onMounted, PropType, reactive, ref} from 'vue';
import {GridStack} from "gridstack";
import {ClientManager} from "@/singletons/ClientManager";
import DialogDelete from "@/components/DialogDelete.vue";
import BlockUI from "primevue/blockui";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {DocumentField} from "@dex/squeeze-client-ts";

interface GridStackItemField {
	x?: number;
	y?: number;
	w?: number;
	maxH: number;
	noResize?: boolean;
	content?: string;
	id?: number | string;
	fieldGroupId?: number;
	documentClassId?: number;
}

interface GridStackField extends DocumentField {
	gridStack?: GridStackItemField;
}

export interface GridStackFieldDetails {
	id?: number | string;
	width?: number;
	row?: number;
	offset?: number;
	type?: string;
	fieldGroupId?: number;
	documentClassId?: number;
}

export default defineComponent({
	name: "DocumentClassFieldsLayoutView",
	components: {
		DialogDelete,
		BlockUI,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
		rows: {
			type: Array as PropType<DocumentField[]>,
			default: [],
		},
		fieldGroupId: {
			type: Number,
			default: -1,
		},
	},
	emits: ["onChangeSortOrder", "isLayoutViewVisible"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Document Class API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Show Loading on load data */
		const loading = ref<boolean>(false);

		/** Show dialog to restore the fields layout */
		const showDialog = ref<boolean>(false);

		/** Editor-Grid */
		const grid = ref<any>(null);

		/** Count for widget id */
		const count = ref<number>(1);

		/** Main grid options */
		const gridOptions = reactive<any>({
			cellHeight: 3,
			cellHeightUnit: "rem",
			margin: 5,
			minRow: 1,
			id: 'main',
			column: 12,
			children: [],
			resizable: { handles: 'all'},
			float: true,
			disableOneColumnMode: true,
		});

		/** Array with all fields */
		const allFields = ref<GridStackField[]>([]);

		/** Array with all field-widget details */
		const fieldLayout = ref<GridStackFieldDetails[]>([]);

		/** Current fields-layout of documentClassGroup */
		const currentFieldLayout = ref<GridStackFieldDetails[]>([]);

		/** Delete hr-widget by click on button */
		const deleteHrWidget = (event: any) => {
			event.target.removeEventListener('click', function(event: MouseEvent) {deleteHrWidget(event)});
			grid.value.removeWidget(event.target.parentNode.parentNode);

			// Delete the node (widget) in grid nodes
			grid.value.engine.nodes = grid.value.engine.nodes.filter((node: GridStackItemField) => node.id !== 'hr-' + props.fieldGroupId + '-' + event.target.id.substr(event.target.id.length - 1));
		}

		/** Delete all hr-widget by layout reset */
		const deleteHrWidgetByReset = () => {
			const allHrLines = document.querySelectorAll('.grid-stack-item-content > button');
			if (allHrLines) {
				allHrLines.forEach((hrLine: any) => {
					hrLine.removeEventListener('click', function(event: MouseEvent) {deleteHrWidget(event)});
					grid.value.removeWidget(hrLine.parentNode.parentNode);

					// Delete the node (widget) in grid nodes
					grid.value.engine.nodes = grid.value.engine.nodes.filter((node: GridStackItemField) => node.id !== 'hr-' + props.fieldGroupId + '-' + hrLine.id.substr(hrLine.id.length - 1));
				})
			}

			// re-layout grid items to reclaim any empty space
			grid.value.compact();
		}

		/** Get the fields layout of fieldGroup */
		const getFieldLayout = () => {
			loading.value = true;
			documentClassApi.getDocumentClassFieldGroupLayout(props.documentClassId, props.fieldGroupId)
				.then(response => response.json().then((layout) => {
					currentFieldLayout.value = JSON.parse(layout);

					// set hr-lines in layout
					const allLayoutLines = currentFieldLayout.value.filter((line: GridStackFieldDetails) => line.type === 'hr');
					if (allLayoutLines.length > 0) {
						gridOptions.children = allLayoutLines.map((line: GridStackFieldDetails) => {
							const lineNode: GridStackItemField = {
								x: line.offset,
								y: line.row,
								w: line.width,
								maxH: 1,
								id: line.id,
								noResize: true,
								content: '<button id="hr-button-' + (line.id as string).substring(3) +'" class="mdi mdi-delete-outline delete-button"></button>' +
									'<hr class="horizontial-line"/>',
							}
							return lineNode;
						});
					}

					// check if exists a fields Layout
					if (currentFieldLayout.value.length > 0) {
						allFields.value.forEach((field: GridStackField) => {
							const layoutField = currentFieldLayout.value.find(currField => currField.id === field.id);

							if (layoutField && layoutField.id === field.id && layoutField.type === 'field') {
								field.gridStack = {
									x: layoutField.offset,
									y: layoutField.row,
									w: layoutField.width,
									maxH: 1,
									content: field.description,
									id: field.id,
									fieldGroupId: field.fieldGroupId,
									documentClassId: field.documentClassId,
								}
								gridOptions.children.push(field.gridStack);
							}
							else if (!layoutField && field.gridStack) {
								gridOptions.children.push(field.gridStack);
							}
							else if (!field.gridStack) {
								// add field to grid-editor
								field.gridStack = {
									x: 0,
									y: gridOptions.children.length,
									w: 12,
									maxH: 1,
									content: field.description,
									id: field.id,
									fieldGroupId: field.fieldGroupId,
									documentClassId: field.documentClassId,
								}
								gridOptions.children.push(field.gridStack);
							}
						});
					} else {
						// add default fields to grid-editor when no layout exist
						gridOptions.children = allFields.value.map((field: GridStackField) => {
							field.gridStack = {
								w: 12,
								maxH: 1,
								content: field.description,
								id: field.id,
								fieldGroupId: field.fieldGroupId,
								documentClassId: field.documentClassId,
							}
							return field.gridStack;
						});
					}
				}))
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					const divContainer = document.querySelector('.grid-stack-group-' + props.fieldGroupId) as HTMLElement;
					grid.value = GridStack.addGrid(divContainer, gridOptions);

					// Sort array to get highest id
					const allLayoutLines = currentFieldLayout.value.filter((line: GridStackFieldDetails) => line.type === 'hr').sort((a: GridStackFieldDetails, b: GridStackFieldDetails) => {
						if (a.id! > b.id!) {
							return 1;
						}
						else if (a.id! < b.id!) {
							return -1;
						}

						return 0;
					});

					if (allLayoutLines) {
						allLayoutLines.forEach((line: GridStackFieldDetails) => {
							const deleteButton = document.getElementById('hr-button-' + (line.id as string).substring(3));
							if (deleteButton) {
								deleteButton.addEventListener('click', function(event) {deleteHrWidget(event)});
							}

							// set count to current hr-button
							count.value = Number((line.id as string).substring(6));
							count.value++;
						})
					}

					loading.value = false;
				})
		}

		onMounted(() => {
			allFields.value = props.rows;
			getFieldLayout();
		});

		/** Add line widget */
		const addLineWidget = () => {
			try {
				grid.value.float(false);

				const lineNode = {y: 0, x: 0, w: 12, maxH: 1, id: 'hr-' + props.fieldGroupId + '-' + String(count.value++), noResize: true};
				grid.value.addWidget('<div class="grid-stack-item">' +
					'<div class="grid-stack-item-content">' +
					'<button id="hr-button-' + props.fieldGroupId + '-' + (count.value -1) +'" class="mdi mdi-delete-outline delete-button"></button>' +
					'<hr class="horizontial-line"/>' + '</div></div>', lineNode);

				const deleteButton = document.getElementById('hr-button-' + props.fieldGroupId + '-' + String(count.value -1));
				if (deleteButton) {
					deleteButton.addEventListener('click', function(event) {deleteHrWidget(event)});
				}
			} finally {
				grid.value.float(true);
			}
		}

		/** Removes empty lines from the grid. Note: The layout has to be reloaded afterwards */
		const removeEmptyLinesFromNodes = () => {
			let lastY = -1;
			let reduceY = 0;
			grid.value.engine.nodes.sort((a: GridStackItemField, b: GridStackItemField) => {
				if (a.y! > b.y!) {
					return 1;
				}
				else if (a.y! < b.y!) {
					return -1;
				}

				return 0;
			});

			grid.value.engine.nodes.forEach((widget: GridStackItemField) => {
				if (lastY === -1 && widget.y! > 0) {
					reduceY = widget.y!;
				}
				else if (lastY !== widget.y!) {
					if (widget.y! - lastY >= 2) {
						reduceY = reduceY + ((widget.y! - lastY) - 1);
					}
				}
				lastY = widget.y!;
				widget.y = widget.y! - reduceY;
			});
		}

		/** Removes empty lines from the grid and reloads the grid */
		const removeEmptyLines = () => {
			removeEmptyLinesFromNodes();
			getFieldLayout();
		}

		/** Save Layout */
		const saveLayout = () => {
			loading.value = true;

			// sortOrder of fields without hr-element
			const gridFieldNodes = grid.value.engine.nodes.filter((widget: GridStackItemField) => widget.content && !widget!.id!.toString().includes('hr'));
			const orderedList = gridFieldNodes.map((widget: GridStackItemField) => {
				return widget.id
			});
			emit("onChangeSortOrder", orderedList, props.fieldGroupId);

			// set fieldWidget
			let fieldWidget: GridStackFieldDetails = {};
			fieldLayout.value = [];

			removeEmptyLinesFromNodes();

			grid.value.engine.nodes.forEach((widget: GridStackItemField) => {
				if (widget.content && !widget!.id!.toString().includes('hr')) {
					fieldWidget = {
						id: widget.id as number,
						width: widget.w,
						row: widget.y,
						offset: widget.x,
						type: 'field',
						fieldGroupId: widget.fieldGroupId,
						documentClassId: widget.documentClassId,
					}
				} else {
					fieldWidget = {
						id: widget.id as number,
						width: widget.w,
						row: widget.y,
						offset: widget.x,
						type: 'hr',
					}
				}

				fieldLayout.value.push(fieldWidget);
				fieldWidget = {};
			});

			// save fieldLayout
			documentClassApi.putDocumentClassFieldGroupLayout(props.documentClassId, props.fieldGroupId, JSON.stringify(fieldLayout.value))
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.DocumentClasses.LayoutSaveSuccess'));
					emit("isLayoutViewVisible");
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Reset the layout of fieldGroup in documentClass */
		const resetLayout = () => {
			loading.value = true;
			currentFieldLayout.value = [];
			documentClassApi.putDocumentClassFieldGroupLayout(props.documentClassId, props.fieldGroupId, JSON.stringify(currentFieldLayout.value))
				.then(() => {
					// Update all NodeElements of GridLayout
					grid.value.engine.nodes.forEach((node: GridStackItemField) => {
						grid.value.update(node, node.w = 12);
					})
					deleteHrWidgetByReset();

					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.DocumentClasses.SuccessResetFieldsLayout'));
					emit("isLayoutViewVisible");
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		return {
			t,
			toast,
			loading,
			showDialog,
			grid,
			count,
			gridOptions,
			allFields,
			fieldLayout,
			deleteHrWidget,
			deleteHrWidgetByReset,
			addLineWidget,
			saveLayout,
			resetLayout,
			removeEmptyLines,
		};
	},
});

