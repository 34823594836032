<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<template v-if="!showSearchesOnly">
			<div class="p-field p-col-12">
				<label for="createNewSearch">{{$t('Squeeze.Queue.General.Action')}}</label>
				<Dropdown id="createNewSearch" v-model="value.actionType" :options="createNewSearch" :loading="loading" optionValue="id" optionLabel="description" @change="onChangeSaveType" />
			</div>
			<div v-if="value.actionType !== 'new'" class="p-field p-col-12">
				<label for="allSearches">{{$t('Squeeze.Queue.General.Search')}}</label>
				<Dropdown
					id="allSearches"
					v-model="value.searchId"
					:options="allSearchesGroupedFiltered"
					:loading="loading"
					optionValue="id"
					optionGroupLabel="label"
					optionGroupChildren="items"
					optionLabel="description"
					:filter="true"
					@change="onChangeSearch">
					<template #optiongroup="slotProps">
						<div class="flex align-items-center country-item">
							<span>
								<i :class="slotProps.option.icon"></i>
								{{ slotProps.option.label }}
							</span>
						</div>
					</template>
				</Dropdown>
			</div>
			<div v-if="value.actionType !== 'delete' && store.state.isAdminUser" class="p-field p-col-12">
				<label for="privateOrGlobal">{{$t('Squeeze.Queue.General.Type')}}</label>
				<Dropdown id="privateOrGlobal" v-model="value.global" :options="privateOrGlobalSearch" :loading="loading" optionValue="id" optionLabel="description" @change="update" />
			</div>
			<div v-if="value.actionType !== 'delete'"  class="p-field p-col-12">
				<label for="name">{{value.actionType === 'new' ? $t('Squeeze.DocumentClasses.Name') : $t('Squeeze.Queue.General.NewName')}} *</label>
				<InputText id="name" v-model="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
				<small v-if="v$.description.required.$invalid && showErrorMessage" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Name')}) }}
				</small>
			</div>
		</template>
		<template v-else>
			<div class="p-field p-col-12">
				<Listbox
					id="allSearches"
					v-model="value.searchId"
					:options="allSearchesGrouped"
					:loading="loading"
					optionValue="id"
					optionGroupLabel="label"
					optionGroupChildren="items"
					optionLabel="description"
					@change="onChangeSearchList"
					:filter="true"
					listStyle="max-height: 60vh"
				>
					<template #optiongroup="slotProps">
						<div class="flex align-items-center country-item">
							<span>
								<i :class="slotProps.option.icon"></i>
								{{ slotProps.option.label }}
							</span>
						</div>
					</template>
				</Listbox>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {SearchFormDto} from "@/apps/squeeze/views/DocumentListView.vue";
import Dropdown from "primevue/dropdown";
import Listbox from 'primevue/listbox';
import {useI18n} from "vue-i18n";
import {DocumentSearchFilterWidget} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "SearchForm",
	components: {
		InputText,
		Dropdown,
		Listbox,
	},
	props: {
		search: {
			type: Object as PropType<DocumentSearchFilterWidget>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
		allSearches: {
			type: Array as PropType<DocumentSearchFilterWidget[]>,
			default: [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showSearchesOnly: {
			type: Boolean,
			default: false,
		},
		searchId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['update', "searchSelected"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const store = useSqueezeStore();
		/** Current Object of all input-fields */
		const value = reactive<SearchFormDto>({description: '', actionType: 'new', searchId: 0, global: false});

		/** Determines the required rules for validation */
		const rules = {
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/** Array with list for Creating/Changing Searches*/
		const createNewSearch = computed(() => {
			const selection = [
				{
					id: "new",
					description: t('Squeeze.Queue.General.NewSearchList'),
				},
			]

			if (props.allSearches?.length > 0) {
				selection.push({
					id: "change",
					description: t('Squeeze.Queue.General.ChangeSearch'),
				})

				selection.push({
					id: "delete",
					description: t('Squeeze.Queue.General.DeleteSearch'),
				})
			}
			return selection;
		});

		/** Array with list for Creating/Changing Searches*/
		const privateOrGlobalSearch = computed(() => {
			return [
				{
					id: false,
					description: t('Squeeze.Queue.General.Personal'),
				},
				{
					id: true,
					description: t('Squeeze.Queue.General.Global'),
				},
			]
		});

		/** Array with list for Creating/Changing Searches*/
		const allSearchesGroupedFiltered = computed(() => {
			const privateSearches = props.allSearches?.filter(search => search.global === false);
			const selection = []

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			if (store.state.isAdminUser) {
				const globalSearches = props.allSearches?.filter(search => search.global === true);
				if (globalSearches.length > 0) {
					selection.push({
						label: t('Squeeze.Queue.General.Global'),
						icon: 'mdi mdi-account-group',
						items: globalSearches,
					})
				}
			}

			return selection;
		});

		/** Array with list for Creating/Changing Searches*/
		const allSearchesGrouped = computed(() => {
			const privateSearches = props.allSearches?.filter(search => search.global === false);
			const selection = []

			if (privateSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Personal'),
					icon: 'mdi mdi-account',
					items: privateSearches,
				});
			}

			const globalSearches = props.allSearches?.filter(search => search.global === true);
			if (globalSearches.length > 0) {
				selection.push({
					label: t('Squeeze.Queue.General.Global'),
					icon: 'mdi mdi-account-group',
					items: globalSearches,
				})
			}

			return selection;
		});

		onMounted(async () => {
			Object.assign(value, props.search);
			if (props.showSearchesOnly) {
				value.searchId = 0;

				// Focus Search Field
				const list = document.querySelectorAll(".p-listbox-filter.p-inputtext.p-component");
				if (list && list[0] && (list[0] as any).focus) {
					(list[0] as any).focus();
				}
			}

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.search, () => {
			Object.assign(value, props.search);
		})

		/** Triggered when keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		const onChangeSaveType = async () => {
			if (value.actionType !== 'new' && props.allSearches?.length > 0) {
				const firstEntry = props.allSearches[0];

				if (firstEntry) {
					value.searchId = firstEntry.id!
					value.description = firstEntry.description!;
					value.global = firstEntry.global!;
				}
			} else {
				value.description = '';
				value.global = false;
			}
			await v$.value.$validate();
			update();
		}

		/** Triggered on change of selected search */
		const onChangeSearch = () => {
			const currentSearch = props.allSearches?.find(search => search.id === value.searchId);
			if (currentSearch) {
				value.description = currentSearch.description!;
				value.global = currentSearch.global!;
			}

			update();
		}

		/** Triggered on select of a search on list */
		const onChangeSearchList = () => {
			const currentSearch = props.allSearches?.find(search => search.id === value.searchId);
			if (currentSearch) {
				value.description = currentSearch.description!;
				value.global = currentSearch.global!;
			}
			emit("searchSelected", value);
		}

		return {
			value,
			props,
			v$,
			InputText,
			createNewSearch,
			privateOrGlobalSearch,
			allSearchesGroupedFiltered,
			allSearchesGrouped,
			update,
			onChangeSaveType,
			onChangeSearch,
			onChangeSearchList,
			store,
		}
	},
});
</script>

<style scoped>
::v-deep(.p-listbox .p-listbox-list .p-listbox-item) {
	color: black !important;
}
</style>
