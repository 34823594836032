<template>
	<DataTable
		:value="rows"
		:loading="loading"
		class="p-datatable-sm"
		:autoLayout="true"
		columnResizeMode="fit"
		v-model:expandedRows="expandedRows"
		@row-dblclick="openEditDialog($event.data)"
		:paginator="true"
		:rows="tableListPagination.pageSize"
		paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
		:rowsPerPageOptions="[25,50,100]"
		:currentPageReportTemplate="'{first} ' + $t('Squeeze.General.To') + ' {last} ' + $t('Squeeze.General.Of') + ' {totalRecords}  '"
		:scrollable="true"
		scrollHeight="flex"
		@page="onChangePage"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.DocumentClasses.ExportInterfaces') }) }}
		</template>

		<Column :expander="true" headerStyle="width: 3rem" style="min-width: 4rem; max-width: 4rem;" />
		<Column field="id" header="ID" :sortable="false" filterMatchMode="contains" style="min-width: 4rem; max-width: 4rem;" />
		<Column field="interfaceName" :header="$t('Squeeze.DocumentClasses.Type')" :sortable="false" filterMatchMode="contains" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="getExportInterfaceTypeName(slotProps.data.interfaceName)">
					{{ getExportInterfaceTypeName(slotProps.data.interfaceName) }}
				</div>
			</template>
		</Column>

		<Column field="description" :header="$t('Squeeze.DocumentClasses.Description')" :sortable="false" filterMatchMode="contains" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{slotProps.data.description}}
				</div>
			</template>
		</Column>
		<Column field="active" :header="$t('Squeeze.DocumentClasses.Active')" :sortable="false" filterMatchMode="contains" style="min-width: 4rem; max-width: 4rem; justify-content: center;">
			<template #body="slotProps">
				<Checkbox @change="onChangeCheckbox(slotProps.data)"  v-model="slotProps.data.active" :binary="true" />
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 16rem; max-width: 16rem; justify-content: end;">
			<template #body="slotProps">
				<Button :label="$t('Squeeze.Locators.Testing')" class="p-button" @click="testExportInterface(slotProps.data)" />
				<Button v-tooltip="$t('Squeeze.DocumentClasses.ShowExportStates')" icon="mdi mdi-table-eye" class="p-button-rounded p-button-text p-mr-2" @click="openExportStates(slotProps.data)" />
				<Button v-tooltip="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-rounded p-button-success p-button-text p-mr-2" @click="openEditDialog(slotProps.data)" />
				<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
			</template>
		</Column>
		<template #expansion="slotProps">
			<table class="exportInterfaceExpansionTable p-ml-sm-0 p-ml-md-6">
				<template v-for="(property, index) in getExportInterfaceProperties(slotProps.data.interfaceName)" v-bind:key="index">
					<tr class="p-p-2">
						<td class="p-text-bold p-p-2">
							{{property.name}}:
						</td>
						<td>
							<template v-if="property.type === 'string'">
								{{slotProps.data.configuration[property.id]}}
							</template>
							<template v-else-if="property.type === 'password'">
								***
							</template>
							<template v-else-if="property.type === 'select'">
								{{ getSelectedValueName(property.options, slotProps.data.configuration[property.id]) }}
							</template>
							<template v-else-if="property.type === 'sequenceFromSet'">
								<template v-if="!slotProps.data.configuration[property.id].length">-</template>
								<template v-else>
									{{ slotProps.data.configuration[property.id].join('/') }}
								</template>
							</template>
							<template v-else>
								{{slotProps.data.configuration[property.id]}}
							</template>
						</td>
					</tr>
				</template>
			</table>
		</template>

		<template #paginatorLeft>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="openNewEntry()" />
		</template>
		<template #paginatorRight></template>
	</DataTable>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {
	ExportInterfaceDocumentationDto,
	ExportInterfaceDto,
	ExportInterfacePropertyDocumentationDto
} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";

@Options({
	name: 'DocumentClassExportInterface',
	components: {
		DataTable, Column, DialogDelete, Checkbox,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array,
			default: [],
		},
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		allExportInterfaces: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "testExportInterface", "onChangeCheckbox"],
})

export default class DocumentClassExportInterface extends Vue  {

	/** Current active document-class */
	documentClassId!: number

	/** Entry-Data of the field-group */
	allExportInterfaces!: ExportInterfaceDocumentationDto[];

	/** Show loading? **/
	loading!: boolean;

	/** All Document-Class-Export-Interfaces */
	rows?: ExportInterfaceDto[] = [];

	/** A single item (used for insert/edit/delete */
	rowData?: ExportInterfaceDto = {interfaceName: "", configuration: {}};

	/** Array with the expanded rows-data */
	expandedRows = [];

	/** Vuex Store */
	store = useSqueezeStore();

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current table list pagination */
	tableListPagination: TableListSettings = {};

	/**
	 * Opens the Delete-Dialog
	 * @param {ExportInterfaceDto} row The row to delete
	 */
	openDeleteDialog(row: ExportInterfaceDto) {
		this.rowData = {...row};
		this.$emit("deleteEntry", this.rowData);
	}

	openNewEntry() {
		this.$emit("openEntryDialog", { id: undefined, active: true, description: '', configuration: undefined, interfaceName: ''})
	}

	/**
	 * Opens the Edit-Dialog
	 * @param row The Row to edit
	 */
	openEditDialog(row: ExportInterfaceDto) {
		this.rowData = {...row};
		this.$emit("openEntryDialog", this.rowData)
	}

	/**
	 * Gets the properties for an Export-Interface
	 * @param name Name of the Export-Interface
	 */
	getExportInterfaceProperties(name: string) {
		const exportInterface = this.allExportInterfaces.find(i => i.id === name);

		if (exportInterface) {
			return exportInterface.properties;
		}

		return {};
	}

	/**
	 * Test the current Export Interface
	 * @param row Current row
	 */
	testExportInterface(row: ExportInterfaceDto) {
		this.$emit("testExportInterface", row.id)
	}

	/** Get the name of an export interface type instead of id
	 * @param {string} interfaceId Current export interface id
	 */
	getExportInterfaceTypeName(interfaceId: string) {
		if (this.allExportInterfaces) {
			const interfaceData = this.allExportInterfaces.find(exportInterface => exportInterface.id === interfaceId);
			if (interfaceData) {
				return interfaceData.name;
			}
		}

		return interfaceId;
	}

	/**
	 * Gets the selected value from a field and returns the name
	 * @param properties Property to get the name from
	 * @param value Value to get the name from
	 */
	getSelectedValueName(properties: ExportInterfacePropertyDocumentationDto[], value: string) {
		const property = properties.find(property => property.id === value);

		if (property) {
			return property.name;
		}

		return value;
	}

	/** Triggered when a checkbox is clicked
	 * @param {ExportInterfaceDto} data Data to edit
	 */
	onChangeCheckbox(data: ExportInterfaceDto) {
		this.$emit("onChangeCheckbox", data)
	}

	/** Triggered to show the ExportStates
	 * @param {ExportInterfaceDto} data
	 */
	openExportStates(data: ExportInterfaceDto) {
		router.push({ name: 'DocumentClassExportStatesSubView', params: { documentClassId: String(data.documentClassId), exportId: String(data.id) }});
	}

	/** on page mounted */
	mounted() {
		this.tableListPagination.pageSize = TableSettings.getTableListPagination(this.store, this.$route.name);
	}

	/** Triggered when page is changed */
	onChangePage(event: { page: number; rows: number }) {
		this.tableListPagination.pageSize = event.rows;
		TableSettings.saveTableListPagination(this.$t, this.$toast, this.store, this.userApi, this.$route.name, event.rows);
	}

}
</script>
<style scoped>

.exportInterfaceExpansionTable {
	width: auto;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
