<template>
	<div class="p-p-2">
		<BlockUI :blocked="loading">
			<DocumentClassForm :documentClass="documentClass" :showErrorMessage="showErrorMessage" @update="onUpdate" />
		</BlockUI>
		<div class="p-fluid p-formgrid p-grid p-jc-end p-p-2">
			<div class="p-field p-col-3">
				<Button :disabled="disableButton" :label="$t('Squeeze.DocumentClasses.RebuildSearchIndex')" type="button" class="p-button" @click="rebuildSearchIndex" />
			</div>
			<div class="p-offset-3"></div>
			<div class="p-field p-col-3">
				<Button v-if="store.state.featureSet.uiConfigExport" :disabled="disableButton" label="Export" type="button" icon="mdi mdi-database-export" class="p-button" @click="exportDocumentClass" />
			</div>
			<div class="p-field p-col-3">
				<Button :disabled="loading" :label="saveButtonText" type="button" icon="mdi mdi-content-save-outline" class="p-button" @click="saveDocumentClass" />
			</div>
		</div>
	</div>

	<EntryDialog
		:show="showExportDialog"
		@onClose="showExportDialog = false"
		headerText="Export"
		:showSaveButton="false"
		:abortButtonText="$t('Squeeze.General.Abort')"
	>
		<template #content>
			<p>{{ $t('Squeeze.General.SuccessfulExport', { entryName: $t('Squeeze.DocumentClasses.DocumentClass')}) }}</p>
			<div class="p-fluid p-formgrid p-grid p-jc-end">
				<div class="p-field p-col-12">
					<Button :label="$t('Squeeze.General.CopyToClipboard')" icon="mdi mdi-content-copy" @click="copyToClipboard($t, $toast, documentClassData), documentClassData = '', showExportDialog = false" />
				</div>
				<div class="p-field p-col-12">
					<Button :label="$t('Squeeze.General.Download')" icon="mdi mdi-file-download-outline" @click="downloadData(documentClass.name, documentClassData), documentClassData = '', showExportDialog = false" />
				</div>
			</div>
		</template>
	</EntryDialog>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import DocumentClassForm from "@/apps/administration/components/documentclasses/DocumentClassForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import router from "@/router";
import BlockUI from "primevue/blockui";
import EntryDialog from "@/components/EntryDialog.vue";
import {Clients, ConsoleLogger, Exporter, SqueezeConfig} from "@dex/squeeze-configframework";
import useSqueezeStore from "@/apps/squeeze/store";
import {copyToClipboard, downloadData} from "@/util/Export";

@Options({
	name: 'DocumentClassSubView',
	methods: {copyToClipboard, downloadData},
	components: {
		DocumentClassForm, BlockUI, EntryDialog,
	},
	computed: {
		disableButton() {
			// Show button to rebuild index only if not already loading or document class does not yet exist
			return this.loading || !this.documentClassId
		},
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		documentClassId: function() {
			this.getDocumentClass()
		},
		'$i18n.locale': function() {
			this.getTranslateOfSaveButton();
		},
	},
})

export default class DocumentClassSubView extends Vue {

	/** Currently active Document-Class-ID */
	documentClassId?: number;

	/** Service for Document-Classes */
	documentClassService = ClientManager.getInstance().squeeze.documentClass

	/** Currently active Document-Class */
	documentClass: DocumentClass = {
		name: '',
		description: '',
	}

	/** Is the page currently loading it's data? */
	loading = false

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** Triggered the valid of form */
	isInvalid: boolean = true;

	saveButtonText = '';

	/** Exported document class data as string  */
	documentClassData: string = '';

	/** Is Export Dialog visible? */
	showExportDialog: boolean = false;

	/** Vuex Store */
	store = useSqueezeStore();

	created() {
		this.getTranslateOfSaveButton();
	}

	mounted() {
		this.getDocumentClass();
	}

	getTranslateOfSaveButton() {
		if (!this.documentClassId) {
			this.saveButtonText = this.$t('Squeeze.General.CreateEntry', { entryName: this.$t('Squeeze.DocumentClasses.DocumentClass') })
		} else {
			this.saveButtonText = this.$t('Squeeze.General.Save');
		}
	}

	/** Gets the Data of a Document-Class */
	getDocumentClass() {
		if (this.documentClassId) {
			this.loading = true;
			this.documentClassService.getDocumentClassById(this.documentClassId)
				.then(data => {
					this.documentClass = data
					this.loading = false
				})
				.catch(err => {
					this.loading = false
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.DocumentClasses.DocumentClass') + ": " + err.statusText)
				});
		}
	}

	/** Triggered on update of attribute-form */
	onUpdate(data: DocumentClass, valid: boolean) {
		this.isInvalid = valid;
		Object.assign(this.documentClass, data);
	}

	async rebuildSearchIndex(): Promise<void> {
		if (!this.documentClassId) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError'))
			return
		}

		this.loading = true;

		try {
			await this.documentClassService.rebuildDocumentClassSearchIndex(this.documentClassId);
		} catch (error) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), error);
		} finally {
			this.loading = false;
		}
	}

	/** Saves a document-class */
	saveDocumentClass(): void {
		if (this.isInvalid) {
			this.showErrorMessage = true;
			return;
		}

		let promise;

		this.showErrorMessage = false;
		this.loading = true;

		if (!this.documentClass.id) {
			// Create New Entry
			promise = this.documentClassService.postDocumentClass(this.documentClass)
				.then(data => {
					ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.CreateSuccess'));
					if (data.id) {
						router.push({ name: 'DocumentClassFieldsSubView', params: { documentClassId: data.id}})
					}
				})
		} else {
			// Update Entry
			promise = this.documentClassService.putDocumentClass(this.documentClassId!, this.documentClass)
				.then(() => ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.General.SaveSuccess')));
		}

		promise
			.catch((err: { statusText: string }) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.SaveError') + ": " + err.statusText)
			})
			.finally(() => {
				this.loading = false;
			});
	}

	/** Export of document class */
	async exportDocumentClass() {
		const clients: Clients = {
			documentClass: this.documentClassService,
			masterData: ClientManager.getInstance().squeeze.masterData,
			locator: ClientManager.getInstance().squeeze.locator,
		}
		const exporter = new Exporter(clients, new ConsoleLogger());

		if (this.documentClass.name) {
			exporter.addDocumentClass(this.documentClass.name);

			await this.runExportOfDocumentClass(exporter);
		}
	}

	/** Run export of document class */
	async runExportOfDocumentClass(exporter: Exporter) {
		this.loading = true;
		this.documentClassData = '';

		try {
			const docClassData: SqueezeConfig = await exporter.run();
			this.documentClassData = JSON.stringify(docClassData, null, 2);
			this.showExportDialog = true;
		} catch (error) {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), error);
		} finally {
			this.loading = false;
		}
	}

}

</script>

<style scoped>
</style>
