
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {MasterDataTable, ValueFromRegexDetails} from "@dex/squeeze-client-ts";
import Dropdown from 'primevue/dropdown';
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "ValueFromRegExForm",
	components: { Dropdown },
	props: {
		valueFromRegEx: {
			type: Object as PropType<ValueFromRegexDetails>,
			default: {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		masterDataTables: {
			type: Array as PropType<MasterDataTable[]>,
			default: [],
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<ValueFromRegexDetails>({sourceTableId: 1});

		/** Determines the required rules for validation */
		const rules = {
			sourceTableId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.valueFromRegEx);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.valueFromRegEx, async () => {
			Object.assign(value, props.valueFromRegEx);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			rules,
			v$,
			props,
			Dropdown,
			update,
			showDropdownOverlay,
		}
	},
});

