<template>
	<div class="p-ml-2 p-p-2">
		<h1 class="header p-component">{{$t('Squeeze.System.SystemCheck')}}</h1>
		<CheckList :checks="checks" :allSystemCheckResults="allSystemCheckResults" :loading="loading" />
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {SystemCheckResultDto, SystemCheckResultEntryDto} from "@dex/squeeze-client-ts";
import CheckList from "@/apps/administration/components/system/CheckList.vue";
import ResultStateEnum = SystemCheckResultEntryDto.ResultStateEnum;

interface SystemCheckType {
	key: string;
	label?: string;
	data?: any; //ResultStateEnum;
	icon?: string;
	style?: string;
	children: SystemCheckType[];
}

export default defineComponent({
	name: "CheckView",
	components: {CheckList},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Array with checks */
		const checks = ref<SystemCheckType[]>([])

		/** Object with all system check results */
		const allSystemCheckResults = reactive<SystemCheckResultDto>({})

		/** Service for getting all system services */
		const systemService = ClientManager.getInstance().squeeze.system;

		/**
		 * Sort all checks by label
		 * @param data
		 * @param key
		 * @param resultState
		 */
		const sortAllChecks = (data: SystemCheckResultDto, key: string, resultState: SystemCheckResultEntryDto.ResultStateEnum) => {
			const check = checks.value.find((checkFind: SystemCheckType) => checkFind.key === key)
			if(!check) {
				return
			}
			// filter of the subCategories
			check.children = data.checks!
				.filter((check: SystemCheckResultEntryDto) => check.resultState === resultState)
				.map((item: SystemCheckResultEntryDto) => item.check)
				.filter((value: string | undefined, index: number, self: (string | undefined)[]) => self.indexOf(value) === index)
				.map((el: string | undefined) => {return {key: '', label: el, data: '', icon: '', style: '', children: []}});

			// filter the children of a subCategory
			check.children.forEach((child: SystemCheckType, index: number) => {
				child.key = key + '-' + index;
				const resultStateChildren = data.checks!
					.filter((check: SystemCheckResultEntryDto) => check.resultState === resultState && check.check === child.label)
					.map((el: SystemCheckResultEntryDto) => {return {key: '', label: el.resultMessage, data: '', icon: 'mdi mdi-circle-medium', style: '', children: []}});
				resultStateChildren.forEach((resultStateChild: SystemCheckType, index: number) => {
					resultStateChild.key = child.key + '-' + index;
					child.children.push(resultStateChild)
				})
			})
		}

		/** Get all checks */
		const getAllChecks = () => {
			loading.value = true;
			systemService.runSystemCheck()
				.then(data => {
					// check if checks not empty
					if(data.failCount) {
						checks.value.push({
							key: 'fail',
							data: ResultStateEnum.Fail,
							label: t('Squeeze.System.Fail'),
							icon: 'mdi mdi-progress-close',
							style: 'color: var(--error-color)',
							children: [],
						})
					}
					if(data.warningCount) {
						checks.value.push({
							key: 'warning',
							data: ResultStateEnum.Warning,
							label: t('Squeeze.System.Warning'),
							icon: 'mdi mdi-alert-circle-outline',
							style: 'color: var(--warning-color)',
							children: [],
						})
					}
					if(data.skippedCount) {
						checks.value.push({
							key: 'skipped',
							data: ResultStateEnum.Skipped,
							label: t('Squeeze.System.Skipped'),
							icon: 'mdi mdi-skip-next-outline',
							style: 'color: gray',
							children: [],
						})
					}
					if(data.unknownCount) {
						checks.value.push({
							key: 'unknown',
							//data: ResultStateEnum.Unknown,
							label: t('Squeeze.System.Unknown'),
							icon: 'mdi mdi-progress-question',
							style: 'color: grey',
							children: [],
						})
					}
					if(data.okCount) {
						checks.value.push({
							key: 'ok',
							data: ResultStateEnum.Ok,
							label: t('Squeeze.System.Success'),
							icon: 'mdi mdi-progress-check',
							style: 'color: var(--success-color)',
							children: [],
						})
					}

					checks.value.forEach((check: SystemCheckType) => {
						sortAllChecks(data, check.key, check.data);
					})

					// get all systemCheckResults
					Object.assign(allSystemCheckResults, data);
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			getAllChecks();
		})

		return {t, toast, loading, checks, allSystemCheckResults};
	},
});

</script>

<style scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

::v-deep(span.p-badge.p-component.p-badge-info.p-mr-2) {
	background: var(--primary-color);
	margin-left: 0.5rem;
}

::v-deep(.p-tree .p-tree-container .p-treenode .p-treenode-content) {
	padding: 0;
}

::v-deep(.p-tree .p-tree-container .p-treenode) {
	padding: 0;
}

</style>
