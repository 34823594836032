
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref} from "vue";
import {MasterDataColumn} from "@dex/squeeze-client-ts";
import {helpers} from "@vuelidate/validators";
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "MasterDataColumns",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataColumns: {
			type: Array as PropType<MasterDataColumn[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		permissions: {
			type: Object,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onResetClick", "onChangeInputField",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** The event.data values to check it */
		const entryDataCheck = reactive<MasterDataColumn>({name: '', description: '', sourceColumn: ''});

		/** Is InputField changed?  */
		const changeInputField = ref<boolean>(false);

		/** Check masterData-Permissions */
		const checkPermissions = computed(() => {
			if (props.permissions) {
				return props.permissions.permissionEdit && !props.permissions.permissionManage
					|| store.state.featureSet.masterDataTablePermissions && !props.permissions.permissionEdit && !props.permissions.permissionManage
					|| !store.state.featureSet.masterDataTablePermissions && !store.state.scopes.sqzAdmin;
			} else {
				return false
			}
		});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataColumn) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {id: undefined, name: '', description: '', sourceColumn: ''})
		}

		/** Triggered when the New Entry Button is clicked */
		const openEditDialog = (row: MasterDataColumn) => {
			// check if permissions return true, to prevent double click
			if (checkPermissions.value) {
				return
			}
			emit("onEntrySelect", row)
		}

		/** Triggered when the Reset-Button is clicked */
		const resetTable = () => {
			emit("onResetClick");
		}

		/** Check the MasterDataColumn is invalid */
		const isInvalidMasterDataColumn = (row: MasterDataColumn) => {
			return (!helpers.req(row.name) || !helpers.req(row.description) || !helpers.req(row.sourceColumn));
		}

		/**
		 * Focus the editing cell
		 * @param event
		 */
		const onCellEdit = async (event: any) => {
			await nextTick();
			Object.assign(entryDataCheck, event.data);
			event.originalEvent.target.childNodes[2].focus();
		}

		/**
		 * Check if value is changed, then emit & Triggered on change of any field
		 * @param event
		 */
		const onCellCompleteEditing = (event: any) => {
			if(changeInputField.value) {
				emit("onChangeInputField", event.data, isInvalidMasterDataColumn(event.data));
				changeInputField.value = false;
			} else {
				event.preventDefault();
			}
		}

		/**
		 * Check if input was changed
		 * @param inputData
		 */
		const changeEventInInput = (inputData: MasterDataColumn) => {
			if (inputData !== entryDataCheck) {
				changeInputField.value = true;
			}
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, entryDataCheck, changeInputField, DataTable, Column, checkPermissions,
			openDeleteDialog, onNewEntryClick, openEditDialog, resetTable, isInvalidMasterDataColumn, onCellEdit, onCellCompleteEditing, changeEventInInput, onChangePage,
		}
	},
});
