<template>
	<Breadcrumb :home="home" :model="breadCrumbs">
		<template #item="{item}">
			<router-link :to="item.to">
				<a :class="item.icon">
					<template v-if="item.label && !item.customTranslation">
						{{ $t('Squeeze.Breadcrumbs.' + item.label)}}
					</template>

					<!-- MasterData Breadcrumb Label -->
					<template v-if="item.customTranslation && route.path.includes('masterdata') && (route.params.masterDataTableId === 'undefined' || route.params.masterDataTableId === '0')">
						{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}
					</template>
					<template v-else-if="item.customTranslation && route.path.includes('masterdata') && route.params.masterDataTableId">
						{{ selectedItemLabel }}
					</template>

					<!-- Roles Breadcrumb Label -->
					<template v-if="item.customTranslation && route.path.includes('roles') && route.params.roleId === 'undefined'">
						{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}
					</template>
					<template v-else-if="item.customTranslation && route.path.includes('roles') && route.params.roleId">
						{{ selectedItemLabel }}
					</template>

					<!-- Job Management Breadcrumb Label -->
					<template v-if="item.customTranslation && route.path.includes('jobManagement') && route.params.jobId === 'undefined'">
						{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}
					</template>
					<template v-else-if="item.customTranslation && route.path.includes('jobManagement') && route.params.jobId">
						{{ selectedItemLabel }}
					</template>
				</a>
			</router-link>
			<!-- BatchClass Breadcrumb Label -->
			<template v-if="item.customTranslation && route.path.includes('bc') && route.params.batchClassId === 'undefined'">
				<a :class="item.icon">{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}</a>
			</template>
			<template v-else-if="item.customDropdown && route.path.includes('bc') && route.params.batchClassId">
				<Dropdown v-model="selectedItem" :options="batchClasses" optionLabel="description" optionValue="id" :loading="loading" :filter="true" @change="changeItem" @show="showDropdownOverlay" />
			</template>

			<!-- DocumentClass Breadcrumb Label -->
			<template v-if="item.customTranslation && route.path.includes('documentclasses') && route.params.documentClassId === '0'">
				<a :class="item.icon">{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}</a>
			</template>
			<template v-else-if="item.customDropdown && route.path.includes('documentclasses') && route.params.documentClassId">
				<Dropdown v-model="selectedItem" :options="documentClasses" optionLabel="description" optionValue="id" :loading="loading" :filter="true" @change="changeItem" @show="showDropdownOverlay" />
			</template>

			<!-- Locator Breadcrumb Label -->
			<template v-if="item.customTranslation && route.path.includes('locators') && route.params.locatorId === '0'">
				<a :class="item.icon">{{ $t('Squeeze.Breadcrumbs.' + selectedItemLabel)}}</a>
			</template>
			<template v-else-if="item.customDropdown && route.path.includes('locators') && route.params.locatorId">
				<Dropdown v-model="selectedItem" :options="locators" optionLabel="description" optionValue="id" :loading="loading" :filter="true" @change="changeItem" @show="showDropdownOverlay" />
			</template>
		</template>
	</Breadcrumb>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import {RouteLocationRaw, useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import Breadcrumb from "primevue/breadcrumb";
import Dropdown from "primevue/dropdown";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {BatchClass, DocumentClass, DocumentLocator, MasterDataTable, UserRole} from "@dex/squeeze-client-ts";
import {showDropdownOverlay} from "@/util/StylesHelper";

export interface BreadcrumbLink {
	label: string;
	icon?: string;
	to: RouteLocationRaw;
}

export default defineComponent({
	name: 'SqueezeBreadcrumb',
	components: {
		Breadcrumb, Dropdown,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Breadcrumb Home */
		const home = reactive<BreadcrumbLink>({label: '', icon: 'mdi mdi-home', to: '/'})

		/** Service for getting the batchClasses */
		const bathClassService = ClientManager.getInstance().squeeze.batchClass;

		/** List of all batchClasses */
		const batchClasses = ref<BatchClass[]>([]);

		/** Service for getting the documentClasses */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** List of all documentClasses */
		const documentClasses = ref<DocumentClass[]>([]);

		/** Service for getting the locators */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all locators */
		const locators = ref<DocumentLocator[]>([]);

		/** Service for getting the masterData */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all masterdata tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Service for getting the roles */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** List of all roles */
		const roles = ref<UserRole[]>([]);

		/** Show loading in dropdown? */
		const loading = ref<boolean>(false);

		/** Current selected Item in Dropdown */
		const selectedItem = ref<number>(0);

		/** Current selected Item Label */
		const selectedItemLabel = ref<string | undefined>('');

		/** Check what is the current homeLink */
		const checkHomeLink = () => {
			if (route.path.includes('squeeze')) {
				Object.assign(home, {label: '', icon: 'mdi mdi-home', to: '/'})
			} else if (route.path.includes('administration')) {
				Object.assign(home, {label: '', icon: "mdi mdi-cog", to: {name: 'QueueEntryView'}})
			}
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;

			if (route.path.includes('bc')) {
				bathClassService.getAllBatchClasses()
					.then((data) => {
						batchClasses.value = data
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}).finally(() => {
						loading.value = false;
					});
			} else if (route.path.includes('documentclasses')) {
				documentClassService.getAllDocumentClasses()
					.then((data) => {
						documentClasses.value = data
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}).finally(() => {
						loading.value = false;
					});
			} else if (route.path.includes('locators')) {
				locatorService.getAllLocators()
					.then((data) => {
						locators.value = data
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}).finally(() => {
						loading.value = false;
					});
			} else if (route.path.includes('masterdata')) {
				masterDataService.getAllMasterDataTables()
					.then((data) => {
						masterDataTables.value = data
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}).finally(() => {
						loading.value = false;
					});
			} else if (route.path.includes('roles')) {
				roleService.getRoles()
					.then((data) => {
						roles.value = data
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}).finally(() => {
						loading.value = false;
					});
			}
		}

		/** Array with the Menu-Items */
		const breadCrumbs = computed(() => {
			if (typeof route.meta.breadCrumb === "function") {
				return route.meta.breadCrumb.call(this, route);
			}
			return route.meta.breadCrumb;
		})

		/**
		 * Set MasterDataTable Breadcrumb Label
		 * @param masterDataId
		 */
		const setMasterDataBreadcrumb = (masterDataId: number) => {
			if (route.params.masterDataTableId === 'undefined' || route.params.masterDataTableId === '0') {
				selectedItemLabel.value = 'NewMasterDataTable';
			} else {
				masterDataService.getMasterDataTableById(masterDataId)
					.then((masterDataTable) => {
						selectedItemLabel.value = masterDataTable.description;
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					});
			}
		}

		/**
		 * Set Role Breadcrumb Label
		 * @param roleId
		 */
		const setRoleBreadcrumb = (roleId: number) => {
			if (route.params.roleId === 'undefined') {
				selectedItemLabel.value = 'NewRole';
			} else {
				roleService.getRoleById(roleId)
					.then((role) => {
						selectedItemLabel.value = role.description;
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					});
			}
		}

		/** Sets the texts and lists of the breadcrumbs */
		const setSelectedItem = () => {
			selectedItemLabel.value  = '';
			checkHomeLink();
			reloadData();
			if (route.path.includes('bc')) {
				if (route.params.batchClassId === 'undefined') {
					selectedItemLabel.value = 'NewBatchClass';
				} else {
					selectedItem.value = Number(route.params.batchClassId);
				}
			} else if (route.path.includes('documentclasses')) {
				if (route.params.documentClassId === '0') {
					selectedItemLabel.value = 'NewDocumentclass';
				} else {
					selectedItem.value = Number(route.params.documentClassId);
				}
			} else if (route.path.includes('locators')) {
				if (route.params.locatorId === '0') {
					selectedItemLabel.value = 'NewLocator';
				} else {
					selectedItem.value = Number(route.params.locatorId);
				}
			} else if (route.path.includes('masterdata') && route.params.masterDataTableId) {
				setMasterDataBreadcrumb(Number(route.params.masterDataTableId));
				router.push({ name: 'MasterDataView', params: { masterDataTableId: route.params.masterDataTableId }});
			} else if (route.path.includes('roles') && route.params.roleId) {
				setRoleBreadcrumb(Number(route.params.roleId));
				router.push({ name: 'UserManagementRolesTabsView', params: { roleId: route.params.roleId }});
			}
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => route.params, () => {
			setSelectedItem();
		})

		onMounted(() => {
			setSelectedItem();
		});

		/**
		 * When Item is change then push new route
		 * @param event
		 */
		const changeItem = (event: { value: string }) => {
			if (route.path.includes('bc')) {
				selectedItem.value = Number(route.params.batchClassId);
				router.push({ name: 'BatchClassView', params: { batchClassId: event.value }});
			} else if (route.path.includes('documentclasses')) {
				selectedItem.value = Number(route.params.documentClassId);
				router.push({ name: 'DocumentClassView', params: { documentClassId: event.value }});
			} else if (route.path.includes('locators')) {
				selectedItem.value = Number(route.params.locatorId);
				router.push({ name: 'LocatorView', params: { locatorId: event.value }});
			}
		}

		return {
			t, route, home, batchClasses, documentClasses, locators, masterDataTables, roles, loading, selectedItem, selectedItemLabel, breadCrumbs, changeItem, showDropdownOverlay,
		}
	},
});
</script>

<style lang="scss" scoped>

.p-breadcrumb {
	border: 0;
	border-radius: 0;
	padding-bottom: 0.5rem;
}

a {
	text-decoration: none;
	color: var(--dex-primary-color);
}

.p-dropdown {
	border: 0;
}

::v-deep(.p-dropdown span.p-dropdown-label.p-inputtext) {
	color: var(--dex-primary-color);
}

::v-deep(.p-inputtext) {
	padding: 0 0;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-focus {
	box-shadow: none;
}

</style>
