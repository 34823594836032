<template>
	<DataTable
		:value="masterDataTables"
		:loading="loading"
		class="p-datatable-sm p-datatable-documents"
		:autoLayout="true"
		columnResizeMode="fit"
		v-model:selection="selection"
		selectionMode="single"
		@row-select="onRowSelect"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		:removableSort="true"
		filterDisplay="row"
		v-model:filters="filters"
		@filter="onFilter($event)"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.MasterData.MasterData') }) }}
		</template>

		<Column field="id" header="ID" filterField="id" :sortable="true" :showFilterMenu="false" style="min-width: 4rem; max-width: 4rem;">
			<template #body="slotProps">
				{{slotProps.data.id}}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 3rem; max-width: 3rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="name" filterField="name" :header="$t('Squeeze.MasterData.Name')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="description" filterField="description" :header="$t('Squeeze.MasterData.Description')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{slotProps.data.description}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;" v-if="!store.state.featureSet.masterDataTablePermissions && store.state.scopes.sqzAdmin">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')"
						icon="mdi mdi-delete-outline"
						class="p-button-lg p-button-rounded p-button-danger p-button-text"
						@click="openDeleteDialog(slotProps.data)"
				/>
			</template>
		</Column>

		<template #footer>
			<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="onNewEntryClick" :disabled="!store.state.scopes.sqzMasterDataManage" />
			<Button v-if="showBtnClearFilter" :label="$t('Squeeze.General.ClearFilters')" type="button" icon="mdi mdi-filter-off-outline" class="p-button p-ml-2" @click="clearFilters"/>
			<Button v-if="store.state.featureSet.uiConfigExport && store.state.isAdminUser" :disabled="loading || !masterDataTables.length" :label="$t('Squeeze.MasterData.Export')" type="button" icon="mdi mdi-database-export" class="p-button p-ml-2" @click="exportAllMasterData" />
		</template>
	</DataTable>
</template>

<script lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import {defineComponent, PropType, ref} from "vue";
import {MasterDataTable} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {FilterMatchMode} from "primevue/api";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";

export default defineComponent({
	name: "MasterDataTableList",
	components: {
		DataTable, Column, InputText,
	},
	props: {
		masterDataTables: {
			type: Array as PropType<MasterDataTable[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "exportAllMasterData",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Currently-Selected row */
		const selection = ref(null);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MasterDataTable) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", 0)
		}

		/** Triggered when a row is selected */
		const onRowSelect = (event: any) => {
			emit("onEntrySelect", event.data.id)
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			if (event.filteredValue.length < props.masterDataTables!.length) {
				showBtnClearFilter.value = true;
			} else {
				showBtnClearFilter.value = false;
			}
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		initFilters();

		/** Export all master data */
		const exportAllMasterData = () => {
			emit("exportAllMasterData");
		}

		return {
			t, toast, store, DataTable, Column, filters, showBtnClearFilter, initFilters, openDeleteDialog, onRowSelect, selection, onNewEntryClick, onFilter, clearFilters, exportAllMasterData,
		}
	},
});
</script>

<style lang="scss" scoped>

/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text.p-mr-2 {
	height: 1rem;
}
::v-deep(.p-datatable-tbody tr) {
	height: 3rem;
}

::v-deep(td) {
	white-space: nowrap;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
   background-color: var(--dex-primary-light-color);
   padding: 0.6rem 0.6rem 0.2rem 0.3rem;
   border-bottom-color: var(--dex-primary-light-color);
   color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th:first-child) {
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

</style>
