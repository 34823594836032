
import {defineComponent} from "vue";
import {useRoute} from "vue-router";

export default defineComponent({
	name: 'ErrorPage',
	components: {},
	props: {
		apps: {
			type: Array,
		},
	},
	setup() {
		const route = useRoute();

		return { route }
	},
});
