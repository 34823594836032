<template>
	<DataTable
		:value="documentClasses"
		:loading="loading"
		class="p-datatable-sm p-datatable-documents"
		:class="!isAdministrationPathOpen ? 'p-shadow-2' : ''"
		:autoLayout="true"
		columnResizeMode="fit"
		v-model:selection="selection"
		selectionMode="single"
		@row-select="onRowSelect"
		:scrollable="true"
		scrollHeight="flex"
		:rowHover="true"
		:removableSort="true"
		filterDisplay="row"
		v-model:filters="filters"
		@filter="onFilter($event)"
	>
		<template #loading>
			{{  $t('Squeeze.General.Loading') }}
		</template>
		<template #empty>
			{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.DocumentClasses.DocumentClasses') }) }}
		</template>

		<Column field="id" header="ID" filterField="id" :sortable="true" :showFilterMenu="false" style="min-width: 4rem; max-width: 4rem;">
			<template #body="slotProps">
				{{slotProps.data.id}}
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 3rem; max-width: 3rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="name" filterField="name" :header="$t('Squeeze.DocumentClasses.Name')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
					{{slotProps.data.name}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<Column field="description" filterField="description" :header="$t('Squeeze.DocumentClasses.Description')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
			<template #body="slotProps">
				<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
					{{slotProps.data.description}}
				</div>
			</template>
			<template #filter="{filterModel, filterCallback}">
				<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
			</template>
		</Column>
		<!-- Delete & Edit -->
		<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
			<template #body="slotProps">
				<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')"
						icon="mdi mdi-delete-outline"
						class="p-button-lg p-button-rounded p-button-danger p-button-text"
						@click="openDeleteDialog(slotProps.data)"
						:disabled="!isAdministrationPathOpen"
				/>
			</template>
		</Column>

		<template #footer>
			<Button v-if="isAdministrationPathOpen" :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="openEditDialog({})" />
			<Button v-if="showBtnClearFilter" :label="$t('Squeeze.General.ClearFilters')" type="button" icon="mdi mdi-filter-off-outline" class="p-button p-ml-2" @click="clearFilters"/>
		</template>
	</DataTable>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import { DocumentClass } from '@dex/squeeze-client-ts';
import Tooltip from "primevue/tooltip";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";

@Options({
	name: 'DocumentClassList',
	components: {
		DataTable, Column, InputText,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		documentClasses: {
			type: Array,
			default: [],
		},
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		isAdministrationPathOpen: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEditDialog", "openDeleteDialog", "onRowSelect"],
})

export default class DocumentClassList extends Vue {

	/** All Document-Classes */
	documentClasses?: DocumentClass[] = []

	/** A single item (used for insert/edit/delete */
	rowData?: DocumentClass = {name: "", description: ""}

	/** Currently-Selected row */
	selection = null;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Should the clear filter button be shown in the table? */
	showBtnClearFilter: boolean = false;

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	beforeMount() {
		this.initFilters();
	}

	/**
	 * Opens the Delete-Dialog
	 * @param {DocumentClass} row The row to delete
	 */
	openDeleteDialog(row: DocumentClass) {
		this.rowData = {...row};
		this.$emit("openDeleteDialog", this.rowData)
	}

	/**
	 * Opens the Edit-Dialog with "New Entry"-Setting
	 */
	openNewEntryDialog() {
		this.rowData = {name: '', description: ''}
		this.$emit("openEntryDialog", this.rowData)
	}

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentClass} data Data to edit
	 */
	openEditDialog(data: DocumentClass) {
		this.rowData = {...data}
		this.$emit("openEditDialog", this.rowData)
	}

	/** Triggered when a row is selected */
	onRowSelect(event: any) {
		this.$emit("onRowSelect", event.data.id)
	}

	/**
	 * Triggered when a filter has been entered
	 * @param event
	 */
	onFilter(event: any) {
		if (event.filteredValue.length < this.documentClasses!.length) {
			this.showBtnClearFilter = true;
		} else {
			this.showBtnClearFilter = false;
		}
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

}
</script>

<style lang="scss" scoped>

/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text.p-mr-2 {
	height: 1rem;
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
   background-color: var(--dex-primary-light-color);
   padding: 0.6rem 0.6rem 0.2rem 0.3rem;
   border-bottom-color: var(--dex-primary-light-color);
   color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th:first-child) {
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

</style>
