
import {Options, Vue} from "vue-class-component";
import {
	ExportInterfaceDocumentationDto,
	ExportInterfaceDto,
	ExportInterfacePropertyDocumentationDto
} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import router from "@/router";
import Tooltip from "primevue/tooltip";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";

@Options({
	name: 'DocumentClassExportInterface',
	components: {
		DataTable, Column, DialogDelete, Checkbox,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array,
			default: [],
		},
		/** Currently active document-class */
		documentClassId: {
			type: Number,
			default: 0,
		},
		allExportInterfaces: {
			type: Array,
			default: [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "testExportInterface", "onChangeCheckbox"],
})

export default class DocumentClassExportInterface extends Vue  {

	/** Current active document-class */
	documentClassId!: number

	/** Entry-Data of the field-group */
	allExportInterfaces!: ExportInterfaceDocumentationDto[];

	/** Show loading? **/
	loading!: boolean;

	/** All Document-Class-Export-Interfaces */
	rows?: ExportInterfaceDto[] = [];

	/** A single item (used for insert/edit/delete */
	rowData?: ExportInterfaceDto = {interfaceName: "", configuration: {}};

	/** Array with the expanded rows-data */
	expandedRows = [];

	/** Vuex Store */
	store = useSqueezeStore();

	/** User API endpoint */
	userApi = ClientManager.getInstance().squeeze.user;

	/** Current table list pagination */
	tableListPagination: TableListSettings = {};

	/**
	 * Opens the Delete-Dialog
	 * @param {ExportInterfaceDto} row The row to delete
	 */
	openDeleteDialog(row: ExportInterfaceDto) {
		this.rowData = {...row};
		this.$emit("deleteEntry", this.rowData);
	}

	openNewEntry() {
		this.$emit("openEntryDialog", { id: undefined, active: true, description: '', configuration: undefined, interfaceName: ''})
	}

	/**
	 * Opens the Edit-Dialog
	 * @param row The Row to edit
	 */
	openEditDialog(row: ExportInterfaceDto) {
		this.rowData = {...row};
		this.$emit("openEntryDialog", this.rowData)
	}

	/**
	 * Gets the properties for an Export-Interface
	 * @param name Name of the Export-Interface
	 */
	getExportInterfaceProperties(name: string) {
		const exportInterface = this.allExportInterfaces.find(i => i.id === name);

		if (exportInterface) {
			return exportInterface.properties;
		}

		return {};
	}

	/**
	 * Test the current Export Interface
	 * @param row Current row
	 */
	testExportInterface(row: ExportInterfaceDto) {
		this.$emit("testExportInterface", row.id)
	}

	/** Get the name of an export interface type instead of id
	 * @param {string} interfaceId Current export interface id
	 */
	getExportInterfaceTypeName(interfaceId: string) {
		if (this.allExportInterfaces) {
			const interfaceData = this.allExportInterfaces.find(exportInterface => exportInterface.id === interfaceId);
			if (interfaceData) {
				return interfaceData.name;
			}
		}

		return interfaceId;
	}

	/**
	 * Gets the selected value from a field and returns the name
	 * @param properties Property to get the name from
	 * @param value Value to get the name from
	 */
	getSelectedValueName(properties: ExportInterfacePropertyDocumentationDto[], value: string) {
		const property = properties.find(property => property.id === value);

		if (property) {
			return property.name;
		}

		return value;
	}

	/** Triggered when a checkbox is clicked
	 * @param {ExportInterfaceDto} data Data to edit
	 */
	onChangeCheckbox(data: ExportInterfaceDto) {
		this.$emit("onChangeCheckbox", data)
	}

	/** Triggered to show the ExportStates
	 * @param {ExportInterfaceDto} data
	 */
	openExportStates(data: ExportInterfaceDto) {
		router.push({ name: 'DocumentClassExportStatesSubView', params: { documentClassId: String(data.documentClassId), exportId: String(data.id) }});
	}

	/** on page mounted */
	mounted() {
		this.tableListPagination.pageSize = TableSettings.getTableListPagination(this.store, this.$route.name);
	}

	/** Triggered when page is changed */
	onChangePage(event: { page: number; rows: number }) {
		this.tableListPagination.pageSize = event.rows;
		TableSettings.saveTableListPagination(this.$t, this.$toast, this.store, this.userApi, this.$route.name, event.rows);
	}

}
