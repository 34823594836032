<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-6">
			<label for="name">{{$t('Squeeze.DocumentClasses.Name')}} *</label>
			<InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && showErrorMessage}" @change="update" required="true" autofocus />
			<small v-if="v$.name.required.$invalid && showErrorMessage && !v$.name.technicalValidation.$invalid" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
			<small v-if="v$.name.technicalValidation.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.OnlyAllowAlphaNumericUnderscore', { field: $t('Squeeze.DocumentClasses.Name')}) }}
			</small>
			<small v-else-if="!!value.id">
				{{ $t('Squeeze.Admin.WarnRename') }}
			</small>
		</div>
		<div class="p-field p-col-6">
			<label for="description">{{$t('Squeeze.DocumentClasses.Description')}} *</label>
			<InputText id="description" v-model="v$.description.$model" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update" required="true" />
			<small v-if="(v$.description.$invalid && showErrorMessage)" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.DocumentClasses.Description')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentClass} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {technicalValidation} from "@/util/ValidationHelper";

export default defineComponent({
	name: "DocumentClassForm",
	components: {
		InputText,
	},
	props: {
		documentClass: {
			type: Object as PropType<DocumentClass>,
			default: {},
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<DocumentClass>({name: '', description: ''});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.documentClass);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.documentClass, () => {
			Object.assign(value, props.documentClass);
		})

		/** Triggered when keyup in input-field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
</script>

<style scoped>

</style>
