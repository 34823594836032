<template>
		<template v-if="!loading">
			<Message v-if="allSystemCheckResults.failCount" severity="error" :closable="false">
				<h3>{{ $t('Squeeze.System.Fail') }}</h3>
				{{ $t('Squeeze.System.FailMessage') }}
			</Message>
			<Message v-if="allSystemCheckResults.warningCount && !allSystemCheckResults.failCount" severity="warn" :closable="false">
				<h3>{{ $t('Squeeze.System.Warning') }}</h3>
				{{ $t('Squeeze.System.WarningMessage') }}
			</Message>
			<Message v-if="allSystemCheckResults.okCount && !allSystemCheckResults.warningCount && !allSystemCheckResults.failCount" severity="success" :closable="false">
				<h3>{{ $t('Squeeze.System.Success') }}</h3>
				{{ $t('Squeeze.System.SuccessMessage') }}
			</Message>
			<Message v-if="(allSystemCheckResults.unknownCount || allSystemCheckResults.skippedCount) && !allSystemCheckResults.failCount" severity="info" :closable="false">
				<h3>{{ $t('Squeeze.System.Info') }}</h3>
				{{ $t('Squeeze.System.InfoMessage', { stateNameOne: $t('Squeeze.System.Skipped'), stateNameTwo: $t('Squeeze.System.Unknown') }) }}
			</Message>

			<Tree :value="checks" :expandedKeys="{'fail': true, 'warning': true}">
				<template #default="slotProps">
					<span>{{slotProps.node.label}}</span>
					<Badge v-if="countChildren(slotProps.node, slotProps.node.children) !== 0" :value="countChildren(slotProps.node, slotProps.node.children)" severity="info" class="p-mr-2"></Badge>
				</template>
			</Tree>
		</template>
		<template v-else>
			<Skeleton width="100%" height="20rem" borderRadius="1rem"/>
		</template>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Tree from 'primevue/tree';
import Badge from 'primevue/badge';
import Message from 'primevue/message';
import {SystemCheckResultDto} from "@dex/squeeze-client-ts";
import Skeleton from 'primevue/skeleton';

export default defineComponent({
	name: "CheckList",
	components: {Tree, Badge, Message, Skeleton},
	props: {
		checks: {
			type: Array as any,
		},
		allSystemCheckResults: {
			type: Object as PropType<SystemCheckResultDto>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const countChildren = (parent: any, children: any) => {
			if(parent.key === 'fail') {
				return props.allSystemCheckResults!.failCount
			}
			if(parent.key === 'warning') {
				return props.allSystemCheckResults!.warningCount
			}
			if(parent.key === 'skipped') {
				return props.allSystemCheckResults!.skippedCount
			}
			if(parent.key === 'unknown') {
				return props.allSystemCheckResults!.unknownCount
			}
			if(parent.key === 'ok') {
				return props.allSystemCheckResults!.okCount
			}
			return children ? children.length : 0
		}

		return {countChildren};
	},
});

</script>

<style scoped>

::v-deep(div.p-message-wrapper) {
	padding-top: 0;
}

::v-deep(span.p-message-icon.pi.pi-times-circle) {
	display: none;
}

.p-tree.p-component {
	border: none;
	padding-top: 0;
}

</style>
