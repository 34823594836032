<template>
	<div class="p-mb-3">
		<span>{{ $t('Squeeze.System.ColorPickerText') }}</span>
	</div>
	<div class="p-fluid p-formgrid p-grid p-p-2">
		<div class="p-col-4">
			<div class="p-inputgroup" style="height: 2.678rem">
				<input type="color" :value="clientMainColor" @change="onChangeColor($event.target.value)" class="input-color">
				<InputText id="color" v-model="textOfClientMainColor" @change="onChangeInput" :disabled="loading" />
			</div>
		</div>
		<div class="p-col-4 p-jc-end">
			<Button :label="$t('Squeeze.System.DefaultColor')"
					v-tooltip.bottom="$t('Squeeze.System.DefaultColorMessage')"
					type="button"
					icon="mdi mdi mdi-restore"
					class="p-button p-button-danger"
					@click="resetColor"
					:loading="loading"
					:disabled="!isRestoreBtnClickable"
			/>
		</div>
		<div class="p-col-4 p-jc-end">
			<Button :label="$t('Squeeze.General.Save')"
					type="button"
					icon="mdi mdi-content-save-outline"
					class="p-button"
					@click="saveColor"
					:loading="loading"
					:disabled="!isSaveBtnClickable"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import { changePalette } from "@/util/StylesHelper";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "SystemColorPickerView",
	components: { InputText },
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Is the restore button clickable? */
		const isRestoreBtnClickable = ref<boolean>(true);

		/** Is the save button clickable? */
		const isSaveBtnClickable = ref<boolean>(true);

		/** Api for Theme */
		const themeApi = ClientManager.getInstance().squeeze.theme;

		/** Main color of client */
		const clientMainColor = ref<string>('');

		/** Main color of client from inputText */
		const textOfClientMainColor = ref<string>('');

		/**
		 * Triggered when color changed
		 * @param color
		 */
		const onChangeColor = (color: string) => {
			isSaveBtnClickable.value = true;
			clientMainColor.value = color;
			textOfClientMainColor.value = color;
			changePalette(color);
		}

		/** Triggered when color changed in input */
		const onChangeInput = () => {
			// hex regex with string length of 7
			const hexRegex = /^#([0-9a-f]{6})$/i;
			if (hexRegex.test(textOfClientMainColor.value)) {
				onChangeColor(textOfClientMainColor.value);
			}
		}

		/** Reset the main color of client */
		const resetColor = () => {
			loading.value = true;
			themeApi.putColor('')
				.then (() => {
					clientMainColor.value = '#4894ac';
					window.location.reload(true);
				})
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
					isRestoreBtnClickable.value = false;
					isSaveBtnClickable.value = false;
				})
		}

		/** Get main color of client */
		const getColor = () => {
			loading.value = true;
			themeApi.getColor()
				.then(response => response.json().then ((color: string) => {
					if (color) {
						clientMainColor.value = color;
						textOfClientMainColor.value = color;
						onChangeColor(clientMainColor.value);
					} else {
						clientMainColor.value = '#4894ac';
						isRestoreBtnClickable.value = false;
					}
				}))
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
					isSaveBtnClickable.value = false;
				})
		}

		/** Save the main color of client */
		const saveColor = () => {
			loading.value = true;
			themeApi.putColor(clientMainColor.value)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SaveColorMessage'));
				})
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
					isSaveBtnClickable.value = false;
					isRestoreBtnClickable.value = true;
				})
		}

		onMounted(() => {
			getColor();
		})

		return {
			t,
			toast,
			loading,
			isRestoreBtnClickable,
			isSaveBtnClickable,
			clientMainColor,
			textOfClientMainColor,
			onChangeColor,
			onChangeInput,
			resetColor,
			changePalette,
			getColor,
			saveColor,
		}
	},
})
</script>

<style scoped>

.input-color {
	width: 2.678rem;
	height: 2.678rem;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
}

.input-color::-webkit-color-swatch {
	border-radius: 0.25rem;
	border: none;
}

::v-deep(button.p-button.p-component.p-button-icon-only.p-button) {
	color: var(--dex-text-color) !important;
}

</style>
