<template>
	<div>
		<div class="p-grid p-px-4 p-py-4">
			<div class="p-col-12">
				<h1 class="p-component view-title">DEXPRO Plattform</h1>
			</div>

			<div v-if="showDevDisclaimer" class="p-col-12 p-md-6 p-lg-4 p-xl-2">
				<Panel :header="$t('Platform.Guide.WelcomeCard.Title')">
					{{$t("Platform.Guide.WelcomeCard.Text")}}
					<Message severity="warn">{{$t("Platform.Guide.WelcomeCard.Disclaimer")}}</Message>
				</Panel>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Message from 'primevue/message';
import Panel from 'primevue/panel';

@Options({
	name: "Home",
	components: {
		Panel, Message,
	},
	props: {
		apps: {
			type: Array,
		},
	},
	computed: {
		showDevDisclaimer() {
			return false;
		},
	},
})
export default class Home extends Vue {}
</script>

<style scoped lang="scss">
</style>
