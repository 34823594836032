<template>
	<div class="p-fluid p-component p-formgrid p-grid p-input-filled">
		<div class="p-field p-col-12">
			<label for="value">{{$t('Squeeze.Locators.Value')}} *</label>
			<InputText id="value" v-model="value.value" @change="update" :class="{'p-invalid':v$.value.$invalid && showErrorMessage}" autofocus />
			<small v-if="v$.value.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Value')}) }}
			</small>
		</div>
		<div class="p-field p-col-12">
			<label for="description">{{$t('Squeeze.Locators.Description')}} *</label>
			<InputText id="description" v-model="value.description" :class="{'p-invalid':v$.description.$invalid && showErrorMessage}" @change="update"/>
			<small v-if="v$.description.$invalid && showErrorMessage" class="p-error">
				{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Locators.Description')}) }}
			</small>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorValueList} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default defineComponent({
	name: "ValueListForm",
	components: {
		InputText,
	},
	props: {
		valueListEntry: {
			type: Object as PropType<LocatorValueList>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorValueList>({});

		/** Determines the required rules for validation */
		const rules = {
			description: { required },
			value: { required},
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.valueListEntry);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.valueListEntry, () => {
			Object.assign(value, props.valueListEntry);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			InputText,
			update,
			v$,
		}
	},
});

</script>

<style scoped>

</style>

