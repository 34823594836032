<template>
	<TabView>
		<TabPanel :header="$t('Squeeze.DocumentClasses.OCRWords')">
			<OCRWords
				:documentId="documentId"
				@onMarkWord="onMarkWord"
			/>
		</TabPanel>
		<TabPanel :header="$t('Squeeze.DocumentClasses.OCRLines')">
			<OCRLines
				:documentId="documentId"
				@onMarkLines="onMarkLines"
			/>
		</TabPanel>
	</TabView>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import OCRWords from "@/apps/squeeze/components/OCRWords.vue";
import OCRLines from "@/apps/squeeze/components/OCRLines.vue";

export default defineComponent({
	name: 'OcrView',
	components: {
		TabView,
		TabPanel,
		OCRWords,
		OCRLines,
	},
	props: {
		documentId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		'onMarkLines',
		'onMarkWord',
	],

	setup(props, {emit}) {
		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		/**
		 * Click a lines to mark
		 * @param event
		 */
		const onMarkLines = (event: any) => {
			emit("onMarkLines", event);
		}

		return {
			onMarkWord, onMarkLines,
		};
	},
});

</script>

<style lang="scss" scoped>

::v-deep(.p-tabview-panel) {
	overflow-x: hidden;
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%); //p-shadow-2
}

::v-deep(.p-tabview-panels) {
	padding: 1rem 0 0 0 !important;
}

</style>
