
import { Options , Vue } from 'vue-class-component';
import Chip from 'primevue/chip';
import {QueueStepDto} from "@dex/squeeze-client-ts";
import { getSqueezeStepIconMap } from "@/apps/squeeze/App.vue";
import router from "@/router";
import {ToastManager} from "@/util/ToastManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";

@Options({
	name: "QueueTimelineElement",
	components: {
		Chip,
	},
	props: {
		step: Object,
		allDocumentClasses: Array,
	},
	emits: ['onClickQueueStep'],
})
export default class QueueTimelineElement extends Vue {

	/** QueueStep */
	step!: QueueStepDto;

	/** All documentClasses */
	allDocumentClasses!: DocumentClass[];

	/** Set QueueStep icon */
	icon = "mdi mdi-spin mdi-loading";

	/** Store point */
	store = useSqueezeStore();

	mounted() {
		const stepIcon = getSqueezeStepIconMap().get(this.step.name);
		if(stepIcon) this.icon = stepIcon;
	}

	/** Click on queue step */
	onClickQueueStep(stepName: string, status: string) {
		// check if the stepName is clickable
		if(!this.store.state.scopes.sqzRequeue && this.$route.name !== 'QueueEntryView' && stepName !== 'Validation') {
			return
		}

		if(!this.store.state.scopes.sqzRequeue && this.$route.name === 'QueueView' && stepName === 'Validation') {
			return
		}

		// check step errorCount or count
		if (this.step.errorCount >= 1 || this.step.count >= 1) {
			// check route
			if (router.currentRoute.value.fullPath.includes('/app/administration/queue')) {
				router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
				this.$emit('onClickQueueStep', stepName, status);
			} else if (this.store.state.scopes.sqzRequeue && stepName !== 'Validation') {
				router.push({ name: 'QueueEntryView', params: { stepName: stepName, status: status }});
			} else {
				if(this.allDocumentClasses.length > 1) {
					router.push({ name: 'QueueView'});
				} else {
					const documentClassId = this.allDocumentClasses[0].id;
					router.push({name: "DocumentListValidation", params: {documentClassId: (documentClassId as any)}});
				}
			}
		} else {
			ToastManager.showInfo(this.$toast, this.$t('Toasts.Title.Info'), this.$t('Squeeze.Queue.Filter.NoEntries'))
		}
	}

	/** Triggered the mouse leave event on a chip element to remove all visible tooltips */
	onMouseLeave() {
		const tooltips = document.getElementsByClassName("p-tooltip");
		if (tooltips) {
			for (let i = 0; i < tooltips.length; i ++) {
				tooltips[i].remove();
			}
		}
	}

}
