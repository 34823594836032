<template>
	<div class="p-fluid p-input-filled p-formgrid p-grid p-pb-2 p-px-2">
		<div class="p-col-12">
			<p>
				{{$t('Squeeze.Login.RecoverPasswordInfoText')}}
			</p>
		</div>
		<div class="p-field p-col-12">
			<label for="userName"></label>
			<InputText id="userName" v-model.trim="userName" required="true" ref="usernameInput" autofocus @keydown="onKeyDown" />
		</div>
		<div class="p-col-12 p-mt-2">
			<Button :loading="loading" :disabled="userName.length === 0" :label="$t('Squeeze.Login.Send')" @click="onClickSend()"/>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "RecoverPasswordForm",
	props: {
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	components: {
		InputText,
	},
	emit: ['onClickSend'],
	setup(props, {emit}) {
		const userName = ref('');

		/** Triggered on click of send */
		const onClickSend = () => {
			emit('onClickSend', userName.value);
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter" && userName.value.length !== 0) {
				emit('onClickSend', userName.value);
			}
		}

		return {userName, onClickSend, onKeyDown};
	},
})
</script>

<style scoped>

</style>
