<template>
	<iframe
		id="SqueezeViewer"
		style="width:100%"
		class="viewer"
		:src="''"
	></iframe>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import Card from "primevue/card";
import {ClientManager} from "@/singletons/ClientManager";

@Options({
	name: "Viewer",
	components: {Card},
	props: {
		documentId: Number,
	},
	watch: {
		documentId() {
			// https://stackoverflow.com/questions/2245883/browser-back-acts-on-nested-iframe-before-the-page-itself-is-there-a-way-to-av
			// Otherwise, the Browser-Back-Button will just change the PDF
			const iframe = document.getElementById("SqueezeViewer") as any;
			if (iframe instanceof HTMLIFrameElement && iframe.contentWindow != null) {
				iframe.contentWindow.location.replace(this.viewerUrl);
			}
		},
	},
	computed: {
		viewerUrl() {
			return `${this.viewer.basePath}/Viewer.php?aktion=showQueueEntryDetails&sourceSystem=documents&xdocid=${this.documentId}`;
		},
	},
})
export default class Viewer extends Vue {

	/** ID of Currently active Document */
	documentId: number|null = null;

	/** Document API endpoint */
	viewer = ClientManager.getInstance().viewer;

	viewerUrl!: string;

	mounted() {
		const iframe = document.getElementById("SqueezeViewer") as any;
		if (iframe instanceof HTMLIFrameElement && iframe.contentWindow != null) {
			iframe.contentWindow.location.replace(this.viewerUrl);
		}
	}

}

</script>
<style lang="scss" scoped>
.viewer {
	border-style: solid !important;
	border-color: #e0e0e0 !important;
	border-width: 1px !important;
}
</style>
