<template>
  <DataTable
		:value="rows"
		:loading="loading"
		:filters="filters"
		class="p-datatable-sm p-datatable-documents"
		:autoLayout="true"
		columnResizeMode="fit"
		:reorderableColumns="true"
		@row-reorder="onRowReorder"
		@row-dblclick="openEditDialog($event.data)"
		:scrollable="true"
		scrollHeight="flex"
		:removableSort="true"
		filterDisplay="row"
		v-model:filters="filters"
		@filter="onFilter($event)"
    >
    <template #loading>
		{{  $t('Squeeze.General.Loading') }}
    </template>
    <template #empty>
		{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.DocumentClasses.DocumentClassFields') }) }}
    </template>

    <!-- Columns and Filter -->
	<Column :rowReorder="true" style="min-width: 2rem; max-width: 2rem;" />
	<Column field="id" header="ID" filterField="id" :sortable="true" :showFilterMenu="false" style="min-width: 4rem; max-width: 4rem;">
		<template #body="slotProps">
			{{slotProps.data.id}}
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 3rem; max-width: 3rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
	<Column field="name" filterField="name" :header="$t('Squeeze.DocumentClasses.Name')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<div class="column-cell" v-tooltip.bottom="slotProps.data.name">
				{{slotProps.data.name}}
			</div>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
	<Column field="description" filterField="description" :header="$t('Squeeze.DocumentClasses.Description')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<div class="column-cell" v-tooltip.bottom="slotProps.data.description">
				{{slotProps.data.description}}
			</div>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
	<Column field="dataType" filterField="dataType" :header="$t('Squeeze.DocumentClasses.DataType')" :sortable="true" :showFilterMenu="false" style="min-width: 9rem; max-width: 9rem;">
		<template #body="slotProps">
			{{ getTranslationFromDataType(slotProps.data.dataType) }}
		</template>
		<template #filter="{filterModel, filterCallback}">
			<Dropdown
				v-model="filterModel.value"
				@change="filterCallback()"
				:options="dataTypes"
				class="p-column-filter filter-field"
				optionValue="id"
				optionLabel="text"
				:showClear="true"
				style="min-width: 8rem; max-width: 8rem;"
			/>
		</template>
	</Column>
	<Column field="defaultValue" filterField="defaultValue" :header="$t('Squeeze.DocumentClasses.DefaultValue')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<div class="column-cell" v-tooltip.bottom="slotProps.data.defaultValue">
				{{slotProps.data.defaultValue}}
			</div>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
	<Column field="locatorId" filterField="locatorId" :header="$t('Squeeze.DocumentClasses.Locator')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<div class="column-cell" v-tooltip.bottom="getDescriptionFromLocator(slotProps.data.locatorId)">
				{{ getDescriptionFromLocator(slotProps.data.locatorId) }}
			</div>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<Dropdown
				v-model="filterModel.value"
				@change="filterCallback()"
				:options="locators"
				class="p-column-filter filter-field"
				optionValue="id"
				optionLabel="description"
				:showClear="true"
				:filter="true"
				style="min-width: 10rem;"
				@show="showDropdownOverlay"
			/>
		</template>
	</Column>
    <Column field="subFieldName" filterField="subFieldName" :header="$t('Squeeze.DocumentClasses.Subfield')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<!-- FIXME: Currently the Translation is taken from i18n. Generally the correct name should be returned from the API  -->
			<template v-if="$te('Squeeze.Locators.SubFields.' + slotProps.data.subFieldName)">
				<div class="column-cell" v-tooltip.bottom="$t('Squeeze.Locators.SubFields.' + slotProps.data.subFieldName)">
					{{ $t('Squeeze.Locators.SubFields.' + slotProps.data.subFieldName) }}
				</div>
			</template>
			<template v-else>
				<div class="column-cell" v-tooltip.bottom="slotProps.data.subFieldName">
					{{ slotProps.data.subFieldName }}
				</div>
			</template>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
	<!--
    <Column field="fieldGroupId" :header="$t('Squeeze.DocumentClasses.Group')" :sortable="false" filterMatchMode="contains">
		<template #body="slotProps">
			{{ getDescriptionFromFieldGroup(slotProps.data.fieldGroupId) }}
		</template>
	</Column>
	-->
	<Column field="externalName" filterField="externalName" :header="$t('Squeeze.DocumentClasses.ExternalName')" :sortable="true" :showFilterMenu="false" style="min-width: 10rem;">
		<template #body="slotProps">
			<div class="column-cell" v-tooltip.bottom="slotProps.data.externalName">
				{{ slotProps.data.externalName }}
			</div>
		</template>
		<template #filter="{filterModel, filterCallback}">
			<InputText style="min-width: 10rem;" class="p-column-filter filter-field" type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" />
		</template>
	</Column>
    <Column field="mandatory" :header="$t('Squeeze.DocumentClasses.Mandatory')" :sortable="false" filterMatchMode="contains" style="min-width: 6rem; max-width: 6rem; justify-content: center;">
      <template #body="slotProps">
        <Checkbox @change="onChangeCheckbox(slotProps.data, 'mandatory')" v-model="slotProps.data.mandatory" :binary="true" />
      </template>
    </Column>
    <Column field="readonly" :header="$t('Squeeze.DocumentClasses.ReadOnly')" :sortable="false" filterMatchMode="contains" style="min-width: 6rem; max-width: 6rem; justify-content: center;">
      <template #body="slotProps">
        <Checkbox @change="onChangeCheckbox(slotProps.data, 'readonly')" v-model="slotProps.data.readonly" :binary="true" />
      </template>
    </Column>
    <Column field="hidden" :header="$t('Squeeze.DocumentClasses.Hidden')" :sortable="false" filterMatchMode="contains" style="min-width: 6rem; max-width: 6rem; justify-content: center;">
      <template #body="slotProps">
        <Checkbox @change="onChangeCheckbox(slotProps.data, 'hidden')" v-model="slotProps.data.hidden" :binary="true" />
      </template>
    </Column>
    <Column field="forceValidation" :header="$t('Squeeze.DocumentClasses.ForceValidation')" :sortable="false" filterMatchMode="contains" style="min-width: 6rem; max-width: 6rem; justify-content: center;">
      <template #body="slotProps">
        <Checkbox @change="onChangeCheckbox(slotProps.data, 'forceValidation')" v-model="slotProps.data.forceValidation" :binary="true" />
      </template>
    </Column>
	<Column field="sameLineAsPreviousField" :header="$t('Squeeze.DocumentClasses.ShowFieldNextToPreviousField')" :sortable="false" filterMatchMode="contains" style="min-width: 11rem; max-width: 11rem; justify-content: center; text-align: center; word-break: initial;">
		<template #header>
			<Badge class="mdi mdi-crosshairs-question" v-tooltip="$t('Squeeze.DocumentClasses.CheckboxTooltipSameLineField')"></Badge>
		</template>
		<template #body="slotProps">
			<Checkbox @change="onChangeCheckbox(slotProps.data, 'sameLineAsPreviousField')" v-model="slotProps.data.sameLineAsPreviousField" :binary="true" />
		</template>
	</Column>
	<!-- Delete & Edit -->
	<Column style="min-width: 7rem; max-width: 7rem; justify-content: end;">
		<template #body="slotProps">
			<Button v-tooltip.bottom="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button-lg p-button-rounded p-button-success p-button-text" @click="openEditDialog(slotProps.data)" />
			<Button v-tooltip.bottom="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button-lg p-button-rounded p-button-danger p-button-text" @click="openDeleteDialog(slotProps.data)" />
		</template>
	</Column>

	<template #footer>
		<Button :label="$t('Squeeze.General.NewEntry')" type="button" icon="mdi mdi-plus" class="p-button" @click="openEditDialog({})" />
		<Button v-if="isFilterActive" :label="$t('Squeeze.General.ClearFilters')" type="button" icon="mdi mdi-filter-off-outline" class="p-button p-ml-2" @click="clearFilters"/>
		<Button v-if="store.state.featureSet.validationFieldLayout" :label="$t('Squeeze.DocumentClasses.FieldsLayout')" type="button" icon="mdi mdi-view-compact-outline" class="p-button-text" @click="openFieldsLayout" :disabled="!rows.length" />
	</template>
  </DataTable>

  <!-- Lösch-Dialog -->
  <DialogDelete :showDialog="deleteDialog" @onClose="deleteDialog = false" @onConfirm="deleteEntry()" />
</template>

<script lang="ts">

interface DataTypes {
	id: string | undefined;
	text: string;
}

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import  {Options, Vue} from "vue-class-component";
import {DocumentField, DocumentLocator, DocumentFieldGroup} from '@dex/squeeze-client-ts';
import Checkbox from "primevue/checkbox";
import DialogDelete from "@/components/DialogDelete.vue";
import Tooltip from "primevue/tooltip";
import {useSqueezeStore} from "@/apps/squeeze/store";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {showDropdownOverlay} from "@/util/StylesHelper";

@Options({
	name: 'DocumentClassFieldTable',
	components: {
		DataTable, Column, Checkbox, DialogDelete, Badge, InputText, Dropdown,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All rows */
		rows: {
			type: Array,
			default: [],
		},
		/** All available Locators */
		locators: {
			type: Array,
			default: [],
		},
		/** All Field-Groups for the Document-Class */
		fieldGroups: {
			type: Array,
			default: [],
		},
		fieldGroupId: {
			type: Number,
			default: -1,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "deleteEntry", "onChangeCheckbox", "onChangeSortOrder", "openFieldsLayout"],
})

export default class DocumentClassFieldTable extends Vue {

	/** Current Vuex-Store */
	store = useSqueezeStore();

	/** Prop for currently active field-group. Will be returned when emitting openEntryDialog, so this data is available in the parent */
	fieldGroupId!: number

	/** List of all locators **/
	locators!: DocumentLocator[];

	/** List of all Field-Groups **/
	fieldGroups!: DocumentFieldGroup[];

	/** all rows */
	rows!: DocumentField[];

	/** show Delete Dialog? */
	deleteDialog = false;

	/** Data of selected row */
	rowData = {};

	/** Is the Search activated? */
	searchActive = false;

	/** Filters of list (Currently not activated) */
	filters: DocumentFilterObject = {};

	/** Is Filtering active? */
	isFilterActive: boolean = false;

	/** List of Data-Types */
	dataTypes: DataTypes[] = [];

	mounted() {
		this.dataTypes = [
			{
				id: 'Text',
				text: this.$t('Squeeze.General.Text'),
			},
			{
				id: 'Date',
				text: this.$t('Squeeze.General.Date'),
			},
			{
				id: 'Amount',
				text: this.$t('Squeeze.General.Amount'),
			},
		];
	}

	/**
	 * Opens the Delete Dialog
	 * @param {DocumentField} data Data from the Document-Class that should be deleted
	 */
	openDeleteDialog(data: DocumentField) {
		this.rowData = {...data};
		this.deleteDialog = true;
	}

	/**
	 * Opens the Edit-Dialog
	 * @param {DocumentField} data Data to edit
	 */
	openEditDialog(data: DocumentField) {
		if (!data.lookup) {
			data.lookup = {
				active: false,
				allowCustomValues: true,
				tableId: 0,
				resultKeyColumnId: 0,
				searchColumnIds: [],
				resultValueColumnIds: [],
				minInputLength: 1,
			};
		}
		this.rowData = {...data};
		this.$emit("openEntryDialog", this.rowData, this.fieldGroupId)
	}

	/**
	 * Gets the Description of a locator
	 * @param {Number} locatorId Id of the Locator to get the Description from
	 * @return {String} Description of the Locator
	 */
	getDescriptionFromLocator(locatorId?: number) {
		if (!locatorId) {
			return "";
		}

		if (!this.locators) {
			return String(locatorId)
		}

		const locator = this.locators.find(i => i.id === locatorId);

		if (!locator) {
			return String(locatorId)
		}

		return locator.description
	}

	/**
	 * Get the Translation of data type
	 * @param {String} type
	 * @return {String} Current Translation of data type
	 */
	getTranslationFromDataType(type: string) {
		switch (type) {
		case 'Text':
			return this.$t('Squeeze.General.Text');
		case 'Date':
			return this.$t('Squeeze.General.Date');
		case 'Amount':
			return this.$t('Squeeze.General.Amount');
		default:
			return type;
		}
	}

	/**
	 * Gets the Description of a Field Group
	 * @param {Number} fieldGroupId Id of the Locator to get the Description from
	 * @return {String} Description of the Locator
	 */
	getDescriptionFromFieldGroup(fieldGroupId: number) {
		if (!this.fieldGroups) {
			return String(fieldGroupId)
		}

		const fieldGroup = this.fieldGroups.find(i => i.id === fieldGroupId);

		if (!fieldGroup) {
			return String(fieldGroupId)
		}
		return fieldGroup.description;
	}

	/** Emits the "deleteEntry"-Event */
	deleteEntry() {
		this.$emit("deleteEntry", this.rowData)
	}

	/** Triggered when a checkbox is clicked
	 * @param {DocumentField} data Data to edit
	 * @package fieldName Name of the current field
	 */
	onChangeCheckbox(data: DocumentField, fieldName: string) {
		this.$emit("onChangeCheckbox", data, fieldName);
	}

	/**
	 * Triggered when the rows are reordered
	 * @param event
	 */
	onRowReorder(event: any) {
		const orderedList: number[] = [];
		event.value.forEach((documentClass: DocumentField) => {
			orderedList.push(documentClass.id!);
		});

		this.$emit("onChangeSortOrder", orderedList, this.fieldGroupId, this.isFilterActive);
	}

	/** Opens the fields layout */
	openFieldsLayout() {
		this.$emit("openFieldsLayout");
	}

	/** Triggered when a filter has been entered */
	onFilter(event: any) {
		if (event.filteredValue.length < this.rows.length) {
			this.isFilterActive = true;
		} else {
			this.isFilterActive = false;
		}
	}

	/** Styles helper when dropdown overlay is shown */
	showDropdownOverlay() {
		new (showDropdownOverlay as any)();
	}

	/** Init filters */
	initFilters() {
		this.filters = {
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"dataType": {value: null, matchMode: FilterMatchMode.EQUALS},
			"defaultValue": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"locatorId": {value: null, matchMode: FilterMatchMode.EQUALS},
			"subFieldName": {value: null, matchMode: FilterMatchMode.CONTAINS},
			"externalName": {value: null, matchMode: FilterMatchMode.CONTAINS},
		};
	}

	/** Clear filters */
	clearFilters() {
		this.initFilters();
	}

	beforeMount() {
		this.initFilters();
	}

}
</script>

<style lang="scss" scoped>
/** Recuce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-success.p-button-text {
	height: 1rem;
}

button.p-button.p-component.p-button-icon-only.p-button-lg.p-button-rounded.p-button-danger.p-button-text {
	height: 1rem;
}

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars) {
	cursor: grab;
}

::v-deep(i.p-datatable-reorderablerow-handle.pi.pi-bars:active) {
	cursor: grabbing;
}

::v-deep(.p-badge) {
	background-color: transparent;
	max-width: 1.5rem;
	order: 2;
}

.mdi {
	line-height: 1.5rem;
}

::v-deep(td div.column-cell) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Table header
.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th) {
   background-color: var(--dex-primary-light-color);
   padding: 0.6rem 0.6rem 0.2rem 0.3rem;
   border-bottom-color: var(--dex-primary-light-color);
   color: var(--dex-text-color);
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead tr th:first-child) {
	padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-sortable-column-icon) {
	color: var(--dex-text-color);

	&:hover {
		color: var(--dex-text-color);
	}
}

.p-datatable.p-datatable-documents ::v-deep(.p-datatable-thead .p-inputtext.filter-field) {
	padding: 0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);
	border-style: solid;
	border-width: 0.063rem;

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}
}

.p-datatable ::v-deep(.p-datatable-thead .p-dropdown.filter-field) {
	padding:0.4rem;
	background-color: transparent;
	color: var(--dex-text-color);
	border-radius: 0;
	border-color: var(--filter-field-color);

	&:focus {
		border-color: rgba(255, 255, 255, 0.8);
	}

	.p-dropdown-label {
		padding: 0 1rem 0 0.4rem;
		color: var(--filter-field-color);
	}
}

</style>
