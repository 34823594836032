<template>
	<div>
		<div>
			<TabMenu class="removeBorderWidth" :model="items" />
		</div>
		<div class="p-mt-2 p-mb-2">
			<router-view />
		</div>
	</div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import {MenuItem} from "@/shims-prime-vue";
import TabMenu from "@/components/DexTabMenu.vue";

@Options({
	name: 'DocumentClassView',
	components: {
		TabMenu,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	watch: {
		documentClassId: function() {
			this.setTabMenuItems();
		},
		'$i18n.locale': function() {
			this.setTabMenuItems();
		},
	},
})

export default class DocumentClassView extends Vue {

	documentClassId!: number;

	/** List of menu items */
	items: MenuItem[] = []

	mounted() {
		this.setTabMenuItems();
	}

	/**
	 * Sets the Menu-Items
	 */
	setTabMenuItems() {
		this.items = [
			{label: this.$t('Squeeze.DocumentClasses.General'), to: { name: 'DocumentClassSubView', params: { documentClassId: this.documentClassId }}},
		]

		if (this.documentClassId !== 0) {
			this.items.push({label: this.$t('Squeeze.DocumentClasses.Fields'), to: { name: 'DocumentClassFieldsSubView', params: { documentClassId: this.documentClassId }}})
			this.items.push({label: this.$t('Squeeze.DocumentClasses.Tables'), to: { name: 'DocumentClassTablesSubView', params: { documentClassId: this.documentClassId }}})
			this.items.push({label: this.$t('Squeeze.DocumentClasses.ExportInterfaces'), to: { name: 'DocumentClassExportInterfaceSubView', params: { documentClassId: this.documentClassId }}})
			//{label: this.$t('Squeeze.DocumentClasses.Attributes'), to: { name: 'DocumentClassAttributesSubView', params: { documentClassId: this.documentClassId }}},
		}
	}

}

</script>

<style lang="scss" scoped>

.removeBorderWidth  {
	border-bottom-width: 0;
}

</style>
