<template>
	<div class="p-p-2 p-component">
		<h1 class="header p-component">{{$t('Squeeze.Validation.Help')}}</h1>

		<p>{{ $t('Squeeze.Breadcrumbs.Validation') }}</p>
		<ul class="hotkey-list">
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.ValidateDocument') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.SuspendDocument') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.DeleteDocument') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenHotKeyList') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenHeadTraining') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenPositionTraining') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenLocatorTesting') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenLog') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenFieldDropdownList') }}</li>
		</ul>

		<p>{{ $t('Squeeze.Validation.General.Table') }}</p>
		<ul class="hotkey-list">
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.DeleteCurrentLine') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.InsertLine') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.CopyLine') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.OpenTableDropdownList') }}</li>
		</ul>

		<p>Viewer</p>
		<ul class="hotkey-list">
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.ViewerImageWidth') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.ViewerImageHeight') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.ViewerImageScroll') }}</li>
			<li>{{ $t('Squeeze.Validation.Dialogs.HotKeys.ViewerImageLock') }}</li>
		</ul>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HotKeyList',
	setup() {
		return {};
	},
});

</script>

<style lang="scss" scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

p {
	font-weight: bold;
}

.hotkey-list > li {
	padding: 0 0.5rem 0.5rem 0;
}

</style>
