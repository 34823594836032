<template>
	<DocumentPositionTrainingForm
		:trainingKeyField="trainingKeyField"
		:positionTrainingValues="positionTrainingValues"
		:columnValues="columnValues"
		:tables="tables"
		:tablePositionTrainingValues="tablePositionTrainingValues"
		:buttonTrainText="buttonTrainText"
		@onFocusFieldOfPositionTraining="onFocusFieldOfPositionTraining"
		@getDataOfTraining="getDataOfTraining"
		@trainPositionValues="trainPositionValues"
		@testingPositionValues="testingPositionValues"
	/>
	<DocumentPositionTrainingTable
		:tablePositionTrainingValues="tablePositionTrainingValues"
		:clickOnTestingButton="clickOnTestingButton"
		:loaded="loaded"
		@changeTrainingRow="changeTrainingRow"
		@deleteTrainingRow="deleteTrainingRow"
		@onMarkRegion="onMarkRegion"
	/>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import DocumentPositionTrainingForm from "@/apps/squeeze/components/DocumentPositionTrainingForm.vue";
import DocumentPositionTrainingTable from "@/apps/squeeze/components/DocumentPositionTrainingTable.vue";
import {
	DocumentField,
	DocumentTable,
	DocumentTableColumn,
	TableColumnTraining
} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

interface PositionTrainingWithName extends TableColumnTraining {
	columnName?: string;
	columnHits?: number;
}

@Options({
	name: "PositionTraining",
	components: {DocumentPositionTrainingTable, DocumentPositionTrainingForm},
	props: {
		documentClassId: Number,
		documentId: Number,
		tables: Object,
		positionTrainingValues: Object,
		trainingKeyField: Object,
	},
	emits: [
		'onFocusFieldOfPositionTraining',
		'onMarkRegion',
	],
})
export default class PositionTraining extends Vue {

	/** ID of document class*/
	documentClassId!: number;

	/** ID of document */
	documentId!: number;

	/** TrainingKey of the document */
	trainingKeyField!: DocumentField;

	/** Tables of the document */
	tables: DocumentTable[] = [];

	/** Service for getting the values of position for training */
	positionTrainingValues!: TableColumnTraining;

	/** Columns of the Table */
	columnValues: DocumentTableColumn[] = [];

	/** List of all training values of PositionTraining */
	tablePositionTrainingValues: PositionTrainingWithName[] = [];

	/** List of all testing values of PositionTraining */
	positionTestingValues: TableColumnTraining[] = [];

	/** Show Button-Text Train OR Retrain */
	buttonTrainText = true;

	/** Show Testing-Text in table */
	clickOnTestingButton = false;

	/** Indicates end of request */
	loaded = false;

	/** Document Class API endpoint */
	documentClassApi = ClientManager.getInstance().squeeze.documentClass;

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	mounted() {
		this.getDataOfTraining();
	}

	/** get data of training
	 * Promise1 to get all table column values
	 * Promise2 to get all training values for training table
	 * */
	getDataOfTraining() {
		this.loaded = false;

		if (!this.trainingKeyField.value!.value) {
			this.loaded = true;
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.Viewer.Error.NoCreditorField'));
			return;
		}

		const promise1 = this.documentClassApi.getAllDocumentClassTableColumns(this.documentClassId, this.positionTrainingValues.tableId!);
		const promise2 = this.documentClassApi.getColumnTrainingsOfTable(this.documentClassId, this.positionTrainingValues.tableId!, this.trainingKeyField.value!.value);
		// Wait until promises are finished
		Promise.all([promise1, promise2]).then(values => {
			this.columnValues = values[0];

			const positionTrainingsWithName: PositionTrainingWithName[] = values[1];
			positionTrainingsWithName.sort(function(firstValue: any, secondValue: any) {
				return firstValue.columnId - secondValue.columnId;
			})
			positionTrainingsWithName.forEach((tableValue) => {
				this.columnValues.forEach((column) => {
					if (tableValue.columnId === column.id) {
						tableValue.columnName = column.description;
					}
				})
			})
			this.tablePositionTrainingValues = positionTrainingsWithName;
			this.clickOnTestingButton = false;
		}).catch(response => response.json().then ((err: {message: string}) => {
			ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.message);
		})).finally(() => {
			this.loaded = true;
		})
	}

	/** Train or retrain position values */
	trainPositionValues() {
		this.positionTrainingValues.trainingKeyValue = this.trainingKeyField.value!.value;
		const rowIds: any[] = [];
		if (this.positionTrainingValues.tableId !== 0) {
			this.tablePositionTrainingValues.forEach((row) => {
				rowIds.push(row.id);
			})
			if (rowIds.includes(this.positionTrainingValues.id)) {
				this.documentClassApi.retrainDocumentClassTableColumn(this.documentClassId, this.positionTrainingValues.tableId!, this.positionTrainingValues.columnId!, this.positionTrainingValues.id!, this.positionTrainingValues, true)
					.then(() => {
						this.buttonTrainText = true;
						this.getDataOfTraining();
					})
			} else {
				this.documentClassApi.trainDocumentClassTableColumn(this.documentClassId, this.positionTrainingValues.tableId!, this.positionTrainingValues.columnId!, this.positionTrainingValues, false)
					.then(() => {
						this.getDataOfTraining();
					})
			}
			this.positionTrainingValues.valuePattern = '';
		}
	}

	/** Testing all training columns for training table */
	testingPositionValues() {
		this.clickOnTestingButton = true;
		this.loaded = false;
		this.documentApi.testColumnTrainings(this.documentId, this.trainingKeyField.value!.value!)
			.then((trainings) => {
				this.positionTestingValues = trainings;
				this.tablePositionTrainingValues.forEach((tableValue) => {
					this.columnValues.forEach((column) => {
						if (tableValue.columnId === column.id) {
							tableValue.columnName = column.description;
						}
					})
				})
				this.positionTestingValues.forEach((testingValue) => {
					this.tablePositionTrainingValues.forEach((tableValue) => {
						if (testingValue.id === tableValue.id) {
							tableValue.columnHits = testingValue.values?.length;
							if (tableValue.columnHits === undefined) {
								tableValue.columnHits = 0;
							}
						}
					})
				})
			})
			.catch(response => response.json().then ((err: {message: string}) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.message);
			}))
			.finally(() => {
				this.loaded = true;
			})
	}

	/**
	 * Triggered when a field is focused
	 * @param fieldName
	 */
	onFocusFieldOfPositionTraining(fieldName: string) {
		this.$emit("onFocusFieldOfPositionTraining", fieldName);
	}

	/**
	 * Get training value to change it
	 * @param rowData
	 */
	changeTrainingRow(rowData: any) {
		this.buttonTrainText = false;
		this.positionTrainingValues.columnId = rowData.columnId;
		this.positionTrainingValues.valuePattern = rowData.valuePattern;
		this.positionTrainingValues.id = rowData.id;
	}

	/**
	 * Deletes a row of the training table
	 * @param id
	 */
	deleteTrainingRow(rowData: any) {
		this.documentClassApi.deleteDocumentClassTableColumnTraining(this.documentClassId, rowData.tableId, rowData.columnId, rowData.id)
			.then(() => {
				this.getDataOfTraining();
			})
			.catch(response => response.json().then ((err: {message: string}) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), err.message);
			}))
	}

	/**
	 * Set marker regions by clicking the Testing-Row
	 * @param event
	 */
	onMarkRegion(event: any) {
		const row = event.data;
		if (this.clickOnTestingButton) {
			const cellRegion: any[] = [];
			this.positionTestingValues.forEach((value) => {
				if (row.id === value.id) {
					value.values!.forEach((value) => {
						cellRegion.push(value.boundingBox);
					});
				}
			});
			this.$emit("onMarkRegion", cellRegion);
		} else {
			this.$emit("onMarkRegion", row.columnRegion);
		}
	}

}
</script>

<style scoped>

</style>
