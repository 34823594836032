<template>
	<template v-if="clickOnTestingButton">
		<DataTable class="p-component p-shadow-2" :value="fieldTestingValues" :autoLayout="true" :loading="!loaded"
			v-model:selection="selection"
			selectionMode="single"
			@row-select="onRowSelect"
		>
			<template #loading>
				{{  $t('Squeeze.General.Loading') }}
			</template>
			<template #empty>
				{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Validation.General.Position') }) }}
			</template>
			<Column field="id" header="ID" style="width:3rem; justify-content: center;"></Column>
			<Column field="fieldName" :header="$t('Squeeze.DocumentClasses.Field')"></Column>
			<Column field="keyWord.value" :header="$t('Squeeze.Training.Anchor')"></Column>
			<Column field="value.value" :header="$t('Squeeze.Locators.Value')"></Column>
			<Column style="width:8rem">
				<template #body="slotProps">
					<template v-if="slotProps.data.dataType === 'Amount' || slotProps.data.dataType === 'Date'">
						<span v-tooltip="$t('Squeeze.Training.EditButtonDisabled')">
							<Button  icon="mdi mdi-pencil-outline" :disabled="true" class="p-button p-component p-button-icon-only p-button-rounded p-button-success p-button-text" />
						</span>
					</template>
					<template v-else>
						<Button v-tooltip="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button p-component p-button-icon-only p-button-rounded p-button-success p-button-text" @click="changeTrainingRow(slotProps.data)"/>
					</template>

					<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text deleteButtonRow" @click="deleteTrainingRow(slotProps.data)"/>
				</template>
			</Column>
		</DataTable>
	</template>
	<template v-else>
		<DataTable class="p-component p-shadow-2" :value="tableTrainingValues" :autoLayout="true" :rowHover="true" :loading="!loaded">
			<template #loading>
				{{  $t('Squeeze.General.Loading') }}
			</template>
			<template #empty>
				{{  $t('Squeeze.General.NoEntriesFound', { entryName: $t('Squeeze.Validation.General.Position') }) }}
			</template>
			<Column field="id" header="ID" style="width:3rem; justify-content: center;"></Column>
			<Column field="fieldName" :header="$t('Squeeze.DocumentClasses.Field')"></Column>
			<Column field="keyWordPattern" :header="$t('Squeeze.Training.Anchor')"></Column>
			<Column field="valuePattern" :header="$t('Squeeze.Locators.Value')"></Column>
			<Column style="width:8rem">
				<template #body="slotProps">
					<template v-if="slotProps.data.dataType === 'Amount' || slotProps.data.dataType === 'Date'">
						<span v-tooltip="$t('Squeeze.Training.EditButtonDisabled')">
							<Button :disabled="true" icon="mdi mdi-pencil-outline" class="p-button p-component p-button-icon-only p-button-rounded p-button-success p-button-text" />
						</span>
					</template>
					<template v-else>
						<Button v-tooltip="$t('Squeeze.Locators.Edit')" icon="mdi mdi-pencil-outline" class="p-button p-component p-button-icon-only p-button-rounded p-button-success p-button-text" @click="changeTrainingRow(slotProps.data)"/>
					</template>
					<Button v-tooltip="$t('Squeeze.Validation.Buttons.Delete')" icon="mdi mdi-delete-outline" class="p-button p-button-rounded p-button-danger p-button-text deleteButtonRow" @click="deleteTrainingRow(slotProps.data)"/>
				</template>
			</Column>
		</DataTable>
	</template>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {FieldTraining} from "@dex/squeeze-client-ts";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

@Options({
	name: "DocumentFieldTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		fieldTestingValues: Object,
		tableTrainingValues: Array,
		clickOnTestingButton: Boolean,
		loaded: Boolean,
	},
	emits: [
		'deleteTrainingRow',
		'changeTrainingRow',
		'onMarkRegion',
	],
})
export default class DocumentFieldTrainingTable extends Vue {

	/** List of all testing values of FieldTraining */
	fieldTestingValues!: FieldTraining[];

	/** List of all training values of FieldTraining */
	tableTrainingValues!: FieldTraining[];

	/** Indicates end of request */
	loaded!: boolean;

	/** Currently-Selected row */
	selection = null

	/**
	 * Deletes a row of the training table
	 * @param id
	 */
	deleteTrainingRow(id: number) {
		this.$emit("deleteTrainingRow", id);
	}

	/**
	 * Change a training value
	 * @param slotProps
	 */
	changeTrainingRow(rowData: any) {
		this.$emit("changeTrainingRow", rowData);
	}

	/**
	 * Click a testing row to mark
	 * @param event
	 */
	onRowSelect(event: any) {
		this.$emit("onMarkRegion", event);
	}

}
</script>

<style scoped>
/** Reduce height of edit/delete buttons */
button.p-button.p-component.p-button-icon-only.p-button.p-component.p-button-icon-only.p-button-rounded.p-button-success.p-button-text,
button.p-button.p-component.p-button-icon-only.p-button.p-button-rounded.p-button-danger.p-button-text.deleteButtonRow {
	height: 1rem;
}

</style>
