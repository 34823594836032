
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import {DocumentClass, DocumentLocator, DocumentTable} from "@dex/squeeze-client-ts/dist/api";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {technicalValidation} from "@/util/ValidationHelper";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Message from "primevue/message";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;

export default defineComponent({
	name: "TableForm",
	components: {
		Checkbox, InputText, Dropdown, Message,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<DocumentTable>,
			default: {},
			required: true,
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: [],
		},
		tableField: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
		tablesWithLocators: {
			type: Array as PropType<DocumentTable[]>,
			default: [],
		},
		documentTables: {
			type: Array as PropType<DocumentTable[]>,
			default: [],
		},
		allDocumentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: [],
		},
		activeTableId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Array with the tables behaviours */
		const tablesBehaviours = computed(() => {
			return [
				{
					id: TableBehaviourEnum.Dynamic,
					description: t('Squeeze.DocumentClasses.Dynamic'),
				},
				{
					id: TableBehaviourEnum.Open,
					description: t('Squeeze.DocumentClasses.Open'),
				},
				{
					id: TableBehaviourEnum.Closed,
					description: t('Squeeze.DocumentClasses.Closed'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<DocumentTable>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})


		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}


		const onChangeDropDown = async () => {
			await update();
		}

		/**
		 * Is triggered when a checkbox from mandatory, forceValidation or hidden is clicked
		 * @param fieldName Name of the field
		 */
		const onChangeCheckBox = (fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (value.mandatory === true || value.forceValidation === true) {
					value.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behaviour
				if (value.hidden) {
					value.mandatory = false;
					value.forceValidation = false;
				}
				break;
			}
			}
		}

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param documentClassId
		 */
		const getDocumentDocumentClassLabel  = (documentClassId: number): string => {
			const documentClass = props.allDocumentClasses?.find(documentClass => documentClass.id === documentClassId);

			if (documentClass) {
				return documentClass.description;
			}else {
				return ""+ documentClassId;
			}
		}

		const getTableDescription = (activeTableId: number): string => {
			const table = props.documentTables?.find(locatorTable => locatorTable.id === activeTableId);

			if (table) {
				return table.description + " (ID: " +activeTableId +")";
			}else {
				return ""+ activeTableId;
			}
		}

		return {
			t,
			v$,
			tablesBehaviours,
			value,
			update,
			onChangeCheckBox,
			showDropdownOverlay,
			onChangeDropDown,
			getDocumentDocumentClassLabel,
			getTableDescription,
		}
	},
});
