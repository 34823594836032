<template>
	<div class="p-ml-2 p-p-2 p-component">
		<h1 class="header p-component">SQUEEZE {{ $t('Squeeze.System.ApiDocumentation') }}</h1>
		<p>
			{{ $t('Squeeze.System.ApiDocumentationHelpText') }}
			<a href="https://discuss.dexpro-solutions.de/" target="_blank">https://discuss.dexpro-solutions.de/</a>
		</p>
		<a class="urlText" :href="squeezeBasePath + '/dist/'" target="_blank">{{ $t('Squeeze.System.LinkApiDocumentation') }}</a>

		<div class="p-pt-2 content">
			<iframe :src="squeezeBasePath + '/dist/'" width="100%" height="100%" name="SQUEEZE API Documentation" title="SQUEEZE API Documentation"></iframe>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "ApiDocumentationView",
	setup() {
		/** Squeeze base path */
		const squeezeBasePath = ref<string>(ClientManager.getInstance().getSqueezeBasePath());

		return {squeezeBasePath};
	},
});

</script>

<style scoped>

.header {
	font-size: 2rem;
	color: var(--dex-primary-color);
	margin: 0;
}

.urlText {
	text-decoration: none;
	color: var(--text-color);
}

.urlText:hover {
	color: var(--dex-primary-color);
}

.content {
	height: calc(100vh - 16rem);
}

</style>
