<template>
	<div class="p-grid nested-grid p-mx-2 p-pt-2">
		<div class="p-col-12 p-lg-12 p-xl-8 p-xxl-8">
			<div class="p-grid">
				<div class="p-col-12 p-lg-6 p-xl-6 p-xxl-6">
					<DashboardTile>
						<DocumentReportOrigin />
					</DashboardTile>
				</div>
				<div class="p-col-12 p-lg-6 p-xl-6 p-xxl-6">
					<DashboardTile>
						<DocumentReportExports />
					</DashboardTile>
				</div>
			</div>
		</div>
		<div class="p-col-12 p-lg-12 p-xl-4 p-xxl-4">
			<DashboardTile>
				<DocumentReportProcessed />
			</DashboardTile>
		</div>
		<div class="p-col-12">
			<DashboardTile>
				<DocumentReportExportedDocuments />
			</DashboardTile>
		</div>
	</div>

	<!-- When more then 4 reports -->
	<!--<div class="p-grid nested-grid p-mx-2 p-pt-2">
		<div class="p-col-8">
			<div class="p-col-12">
				<DashboardTile>
					<DocumentReportExportedDocuments />
				</DashboardTile>
			</div>
			<div class="p-grid">
				<div class="p-col-6">
					<DashboardTile>
						<DocumentReportProcessed />
					</DashboardTile>
				</div>
				<div class="p-col-6">
					<DashboardTile>
						<DocumentReportOrigin />
					</DashboardTile>
				</div>
			</div>
		</div>
		<div class="p-col-4">
			<div class="p-grid">
				<div class="p-col-12">
					<DashboardTile>
						<DocumentReportExports />
					</DashboardTile>
				</div>
				<div class="p-col-12">
					<DashboardTile>
						<DocumentReportOrigin />
					</DashboardTile>
				</div>
			</div>
		</div>
	</div>-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashboardTile from "@/components/DashboardTile.vue";
import DocumentReportExportedDocuments from "@/apps/squeeze/components/DocumentReportExportedDocuments.vue";
import DocumentReportExports from "@/apps/squeeze/components/DocumentReportExports.vue";
import DocumentReportOrigin from "@/apps/squeeze/components/DocumentReportOrigin.vue";
import DocumentReportProcessed from "@/apps/squeeze/components/DocumentReportProcessed.vue";

export default defineComponent({
	components: {
		DocumentReportProcessed,
		DocumentReportOrigin,
		DocumentReportExports,
		DocumentReportExportedDocuments,
		DashboardTile,
	},
	setup() {
		return {}
	},
});
</script>
<style lang="scss" scoped>

@media screen and (min-width: 1800px) {
	.p-xxl-4 {
		width: 33.3333%;
	}
}

@media screen and (min-width: 1800px) {
	.p-xxl-1, .p-xxl-2, .p-xxl-3, .p-xxl-4, .p-xxl-5, .p-xxl-6, .p-xxl-7, .p-xxl-8, .p-xxl-9, .p-xxl-10, .p-xxl-11, .p-xxl-12 {
		flex: 0 0 auto;
	}
}

</style>
