<template>
	<div class="p-component">
		<Button
			:loading="loading"
			:label="$t('Squeeze.Validation.Email.SendMail')"
			type="button"
			icon="mdi mdi-email-send-outline"
			class="p-button"
			@click="sendMail" />

		<SplitButton
			class="p-button-md p-ml-2"
			:label="labelSplitButton"
			:model="toolbarOptions"
			/>

		<Button
			v-if="showSaveTemplate"
			:loading="loadingSaveTemplate"
			type="button"
			icon="mdi mdi-content-save-outline"
			class="p-button p-ml-2"
			v-tooltip="$t('Squeeze.Validation.Email.SaveTemplate')"
			@click="saveTemplate" />

		<Button
			v-if="showEditTemplate"
			:loading="loadingSaveTemplate"
			type="button"
			icon="mdi mdi-pencil-outline"
			class="p-button p-ml-2"
			v-tooltip="$t('Squeeze.Validation.Email.EditTemplate')"
			@click="editTemplate" />

		<Button v-if="showDeleteTemplate"
			:loading="loadingSaveTemplate"
			type="button"
			icon="mdi mdi-close"
			class="p-button p-button-danger p-ml-2"
			v-tooltip="$t('Squeeze.Validation.Email.DeleteTemplate')"
			@click="openDeleteTemplateDialog" />
		<br/><br/>
		<div class="p-component p-fluid p-grid">
			<div class="p-field p-col-12" v-if="showDescription">
				<span class="p-float-label">
					<InputText id="description" v-model.trim="activeMailTemplate.description" required="true" autofocus />
					<label for="description">{{$t('Squeeze.Validation.Email.TemplateDescription')}}</label>
				</span>
			</div>
			<div class="p-field p-col-12">
				<span class="p-float-label">
					<InputText id="to" v-model.trim="mailBody.to" :class="{'p-invalid': v$.mailBody.to.$invalid && showErrorMessage}" required="true" autofocus />
					<label for="to">{{$t('Squeeze.Validation.Email.To')}}</label>
				</span>
				<small v-if="showErrorMessage" class="p-error">
					<template v-if="v$.mailBody.to.checkEmail.$invalid">
						{{$t('Forms.Val.Email')}}
					</template>
					<template v-else-if="v$.mailBody.to.$invalid">
						{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Validation.Email.To')}) }}
					</template>
				</small>
			</div>
			<div class="p-field p-col-12">
				<span class="p-float-label">
					<InputText id="subject" v-model.trim="mailBody.subject" :class="{'p-invalid': v$.mailBody.subject.$invalid && showErrorMessage}" required="true" autofocus />
					<label for="subject">{{$t('Squeeze.Validation.Email.Subject')}}</label>
				</span>
				<small v-if="v$.mailBody.subject.$invalid && showErrorMessage" class="p-error">
					{{ $t('Forms.Val.MissingRequired', { field: $t('Squeeze.Validation.Email.Subject')}) }}
				</small>
			</div>
		</div>

		<EmailForm @change="onChangeForm" v-model="mailBody.body" />

		<br/><br/>
		<div class="p-fluid p-formgrid p-input-filled">
			<div class="p-field-checkbox p-m-4">
				<label>{{$t('Squeeze.Validation.Email.Attachments')}}</label>
			</div>
			<template v-for="file in files" v-bind:key="file.id">
				<div class="p-field-checkbox p-m-4">
					<Checkbox :name="file.id" :value="file" v-model="activatedFiles" />
					<label :for="file.id">{{file.fileName}}</label>
				</div>
			</template>
		</div>

		<DialogDelete
			:headerLabel="$t('Squeeze.Validation.Email.DeleteTemplate')"
			:messageLabel="$t('Squeeze.Validation.Email.DeleteMassageConfirm')"
			:showDialog="showDeleteTemplateDialog"
			@onClose="showDeleteTemplateDialog = false"
			@onConfirm="deleteTemplate" />
	</div>
</template>

<script lang="ts">
import { Options , Vue } from 'vue-class-component';
import EmailForm from "@/components/EmailForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import { DocumentField, FileDto, MailDto, MailTemplate } from '@dex/squeeze-client-ts';
import Checkbox from 'primevue/checkbox';
import InputText from "primevue/inputtext";
import {ToastManager} from "@/util/ToastManager";
import Dropdown from 'primevue/dropdown';
import SplitButton from "primevue/splitbutton";
import DialogDelete from "@/components/DialogDelete.vue";
import {PropType} from "vue";
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

@Options({
	name: "ValidationEmailView",
	components: {EmailForm, InputText, Checkbox, Dropdown, SplitButton, DialogDelete},
	props: {
		documentId: Number,
		documentFields: Array as PropType<DocumentField[]>,
	},
	watch: {
		activatedFiles: function() {
			// Set attachmentIds to activated files
			this.mailBody.attachmentIds = [];
			this.activatedFiles.forEach((file: any) => {
				this.mailBody.attachmentIds.push(file.id);
			});
		},
		activeMailTemplate: function(newValue) {
			if (this.mailBody) {
				this.mailBody.subject = newValue.subject;
				this.mailBody.body = newValue.body;
				this.mailBodyUnmodified.subject = newValue.subject;
				this.mailBodyUnmodified.body = newValue.body;

				this.replaceTemplateData()
			}
		},
	},
	validations() {
		return {
			mailBody: {
				to: {
					required,
					checkEmail(email: string | undefined) {
						if (typeof email === 'undefined' || email == null || email === '') {
							return true
						}
						return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(email)
					},
				},
				subject: {
					required,
				},
			},
		}
	},
	emits: ['sendSuccess'],
})
export default class ValidationEmailView extends Vue {

	/** ID of Currently active Document */
	documentId!: number

	/** Fields of document */
	documentFields!: DocumentField[];

	/** URL to Viewer */
	value = ""

	/** Show loading in Send-Button? */
	loading = false

	/** Show loading in Save Template Button? */
	loadingSaveTemplate = false

	/** Triggered when (all) field values are invalid */
	showErrorMessage: boolean = false;

	/** List of document-files */
	files?: FileDto[] = [];

	/** List of files to send */
	activatedFiles?: FileDto[] = [];

	/** List of Email Templates */
	mailTemplates?: MailTemplate[] = [];

	/** Document API endpoint */
	documentApi = ClientManager.getInstance().squeeze.document;

	/** Document API endpoint */
	emailApi = ClientManager.getInstance().squeeze.email;

	/** Use Vuelidate */
	v$: any = useVuelidate();

	/** Mail-Body To send */
	mailBody: MailDto = {
		body: '',
		attachmentIds: [],
		contentType: 'html',
		to: '',
		subject: '',
	}

	/** Mail-Body where the content has never been replaced */
	mailBodyUnmodified: MailDto = {
		body: '',
		attachmentIds: [],
		contentType: 'html',
		to: '',
		subject: '',
	}

	activeMailTemplate?: MailTemplate = {
		description: '',
		subject: '',
		body: '',
		module: 'Validation',
		language: 'de',
	};

	/** Options of the toolbar. Due to the translations, those are defined in "mounted()" */
	toolbarOptions?: any[] = [];

	/** Label of the Split-Button */
	labelSplitButton = "";

	/** Should the Button for Edit Template be shown? */
	showEditTemplate = false;

	/** Should the Button for Delete Template be shown? */
	showDeleteTemplate = false;

	/** Should the Button for Save Template be shown? */
	showSaveTemplate = false;

	/** Should the Template Description be shown? */
	showDescription = false;

	/** Should the Dialog for Delete Template be shown? */
	showDeleteTemplateDialog = false;

	mounted() {
		this.labelSplitButton = this.$t('Squeeze.Validation.Email.Templates');

		this.documentApi.getDocumentAttachments(this.documentId, false)
			.then(data => {
				this.files = data;
			})
			.catch(response => response.json().then ((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))

		this.getEmailTemplates();
	}

	/** Get E Email-Templates */
	getEmailTemplates() {
		this.emailApi.getEmailTemplates("Validation")
			.then(data => {
				this.mailTemplates = data;
				this.toolbarOptions = [];

				const toolbarNewEntry = {
					label: this.$t("Squeeze.Validation.Email.NewTemplate"),
					icon: 'mdi mdi-plus',
					command: () => {
						if (this.activeMailTemplate) {
							this.activeMailTemplate.id = undefined;
							this.activeMailTemplate.module = 'Validation';
							this.activeMailTemplate.language = 'de';
						}
						else {
							this.activeMailTemplate = {
								description: '',
								subject: '',
								body: '',
								module: 'Validation',
								language: 'de',
							}
						}

						this.showDescription = true;
						this.showEditTemplate = false;
						this.showSaveTemplate = true;
						this.showDeleteTemplate = false;
						this.labelSplitButton = this.$t("Squeeze.Validation.Email.NewTemplate");
					},
				}
				if (this.toolbarOptions) {
					this.toolbarOptions.push(toolbarNewEntry);
				}

				this.mailTemplates.forEach(template => {
					const toolbarEntry = {
						label: template.description,
						command: () => {
							this.activeMailTemplate = template;
							this.labelSplitButton = template.description;
							this.showEditTemplate = true;
							this.showDescription = false;
							this.showSaveTemplate = false;
							this.showDeleteTemplate = true;
						},
					}

					if (this.toolbarOptions) {
						this.toolbarOptions.push(toolbarEntry);
					}
				})
			})
			.catch(response => response.json().then ((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
			}))
	}

	/** Sends an Email */
	sendMail() {
		if (this.v$.$invalid) {
			this.showErrorMessage = true;
			return;
		}
		this.showErrorMessage = false;
		this.loading = true;

		let body = this.mailBody.body!;
		// replace paragraph of text with breakpoints
		body = body.replaceAll('<p><br></p>', '<br>');
		body = body.replaceAll('<p>', '');
		body = body.replaceAll('</p>', '<br>');
		this.mailBody.body = body;

		this.documentApi.sendDocumentAsEmail(this.documentId, this.mailBody)
			.then(() => {
				this.loading = false;
				ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Email.SendSuccess'));
				this.$emit("sendSuccess");
			})
			.catch(response => response.json().then ((err: any) => {
				ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
				this.loading = false;
			}))
	}

	/** Is triggered when the form-value changes */
	onChangeForm(formValue: string) {
		if (this.mailBody) {
			this.mailBody.body = formValue;
		}
	}

	/** Triggered when the Edit-Template-Button is clicked */
	editTemplate() {
		this.showDescription = true;
		this.showEditTemplate = false;
		this.showSaveTemplate = true;
		this.showDeleteTemplate = true;

		this.mailBody.body = this.mailBodyUnmodified.body;
		this.mailBody.subject = this.mailBodyUnmodified.subject;
	}

	/**
	 * Shos the Delete Templage Dialog
	 **/
	openDeleteTemplateDialog() {
		this.showDeleteTemplateDialog = true;
	}

	/** Saves a Email Template */
	saveTemplate() {
		if (this.activeMailTemplate && this.mailBody) {
			this.activeMailTemplate.body = this.mailBody.body;
			this.activeMailTemplate.subject = this.mailBody.subject;
		}

		if (this.activeMailTemplate && this.activeMailTemplate.id) {
			this.loadingSaveTemplate = true;
			this.emailApi.putEmailTemplate(this.activeMailTemplate.id, this.activeMailTemplate)
				.then(() => {
					this.loadingSaveTemplate = false;
					ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Email.SaveTemplateSuccess'));
					this.getEmailTemplates();
					this.showDescription = false;
					this.showEditTemplate = false;
					this.showSaveTemplate = false;
					this.showDeleteTemplate = false;
					this.labelSplitButton = this.$t("Squeeze.Validation.Email.Templates");
					this.replaceTemplateData();
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					this.loadingSaveTemplate = false;
				}))
		}
		else if (this.activeMailTemplate) {
			this.loadingSaveTemplate = true;
			this.emailApi.postEmailTemplate(this.activeMailTemplate)
				.then(() => {
					this.loadingSaveTemplate = false;
					ToastManager.showSuccess(this.$toast, this.$t('Squeeze.General.Success'), this.$t('Squeeze.Validation.Email.SaveTemplateSuccess'));
					this.getEmailTemplates();
					this.showDescription = false;
					this.showEditTemplate = false;
					this.showSaveTemplate = false;
					this.showDeleteTemplate = false;
					this.labelSplitButton = this.$t("Squeeze.Validation.Email.Templates");
					this.replaceTemplateData();
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					this.loadingSaveTemplate = false;
				}))
		}
	}

	/** Deletes a Template */
	deleteTemplate() {
		if (this.activeMailTemplate && this.activeMailTemplate.id) {
			this.emailApi.deleteEmailTemplate(this.activeMailTemplate.id)
				.then(() => {
					this.showDeleteTemplateDialog = false;
					this.getEmailTemplates();
					this.showDescription = false;
					this.showEditTemplate = false;
					this.showSaveTemplate = false;
					this.showDeleteTemplate = false;
					this.labelSplitButton = this.$t("Squeeze.Validation.Email.Templates");
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					this.loadingSaveTemplate = false;
				}))
		}
	}

	/**
	 * Replaces the template-data with the data from the document
	 */
	replaceTemplateData() {
		if (!this.mailBody) {
			return;
		}

		let subject = this.mailBody.subject!;
		let body = this.mailBody.body!;
		this.documentFields.forEach((field: DocumentField) => {
			subject = subject.replaceAll("#" + field.name + "#", field.value?.value as string);
			body = body.replaceAll("#" + field.name + "#", field.value?.value as string);
		})

		this.mailBody.subject = subject;
		this.mailBody.body = body;
	}

}

</script>

<style lang="scss" scoped>
::v-deep(.p-menu) {
	width: auto !important;
}
</style>
